import _ from 'lodash-es';
import React, { useState, useContext } from "react";
import './styles.less';
import { Button, Form, Card, Radio, Space, Progress, Avatar, Dropdown, Menu, Modal, Tooltip, Checkbox, Alert } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import PollEditModal from './PollEditModal';
import { MdDragIndicator } from 'react-icons/md';
import { apiRoutes } from '../../const';
import { UserAvatar } from '../common';
import { post, put, del } from '../../http';
import { PollEvent, PollEventType, Poll, PollResponse, Board, Item } from '../../models';
import { AppContext } from '../../../store';
import { useForm } from 'antd/es/form/Form';

const { confirm } = Modal;

interface PollVotingFormProps {
  poll: Poll;
  response?: PollResponse;
  onResponded: () => void;
  onCanceled: () => void;
}

const PollVotingForm: React.FC<PollVotingFormProps> = ({ poll, response, onResponded, onCanceled }) => {
  const [form] = useForm();
  const [allowSubmit, setAllowSubmit] = useState<boolean>(response !== undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const submitResponse = async (values: any) => {
    setIsSubmitting(true);
    try {
      const payload = {
        answer: poll.multiple_choices ? JSON.stringify(values.answer) : values.answer,
      };
      if (!response) {
        await post(apiRoutes.pollResponseList(poll.id), payload);
      } else {
        await put(apiRoutes.pollResponseSingle(poll.id, response.id), payload);
      }
    } catch (error) {
      setIsSubmitting(false);
      throw (error);
    }
    onResponded();
    setIsSubmitting(false);
  }

  const removeResponse = async () => {
    setIsRemoving(true);
    try {
      await del(apiRoutes.pollResponseSingle(poll.id, response.id));
    } catch (error) {
      setIsRemoving(false);
      throw (error);
    }
    onResponded();
    setIsRemoving(false);
  }

  return (
    <Form form={form} onFinish={submitResponse} initialValues={_.assign({}, response, {
      answer: poll.multiple_choices ? JSON.parse(response?.answer || '[]') : response?.answer,
    })}>
      <p>{poll.question}</p>
      <Form.Item
        name="answer"
        rules={[{ required: true }]}
      >
        {!poll.multiple_choices ? (
            <Radio.Group onChange={() => setAllowSubmit(true)} disabled={isSubmitting || isRemoving}>
              <Space direction="vertical">
                {JSON.parse(poll.options).map((option: string, index: number) => (
                  <Radio value={`${index}`}>{option}</Radio>
                ))}
              </Space>
            </Radio.Group>
          ) : (
            <Checkbox.Group onChange={() => setAllowSubmit(true)}>
              <Space direction="vertical">
                {JSON.parse(poll.options).map((option: string, index: number) => (
                  <Checkbox value={`${index}`}>{option}</Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          )
        }
      </Form.Item>
      <div>
        <Space direction="horizontal">
          <Button type="primary" htmlType="submit" disabled={!allowSubmit || isRemoving} loading={isSubmitting}>Submit</Button>
          {response && <Button onClick={onCanceled} disabled={isSubmitting || isRemoving}>Cancel</Button>}
          {response && <Button danger onClick={removeResponse} disabled={isSubmitting} loading={isRemoving}>Remove response</Button>}
        </Space>
      </div>
    </Form>
  );
}

interface PollDisplayProps {
  poll: Poll;
  response?: PollResponse;
  viewOnly: boolean;
  onEditClicked: () => void;
}

const VOTER_AVATAR_BREAKPOINT = 3;

const PollDisplay: React.FC<PollDisplayProps> = ({ poll, response, viewOnly, onEditClicked }) => {
  const { state: { currentUser } } = useContext(AppContext);

  let responseCount = {};
  let responseUsers = {};
  _.each(poll.pollresponse_set, (response: PollResponse) => {
    let answers = [];
    if (poll.multiple_choices) {
      answers = JSON.parse(response.answer);
    } else {
      answers = [response.answer];
    }

    answers.forEach((answer: string) => {
      if (answer in responseCount) {
        responseCount[answer] += 1;
      } else {
        responseCount[answer] = 1;
      }
      if (answer in responseUsers) {
        responseUsers[answer].push(response.owner);
      } else {
        responseUsers[answer] = [response.owner];
      }
    });
  });

  let respondedOptionCount = 0;
  _.each(responseCount, (count: number) => {
    respondedOptionCount += count;
  });
  let options = JSON.parse(poll.options).map((option: string, index: number) => {
    let percent = 0;
    if (`${index}` in responseCount) {
      percent = responseCount[`${index}`] / respondedOptionCount * 100;
      percent = Math.round(percent * 10) / 10; // take 1 decimal place
    }

    let avatarGroup=null;
    if (percent > 0) {
      let avatars = [];
      if (responseUsers[`${index}`].length > VOTER_AVATAR_BREAKPOINT) {
        for (let i = 0; i < VOTER_AVATAR_BREAKPOINT-1; i++) {
          avatars.push(<UserAvatar username={responseUsers[`${index}`][i]} />);
        }
        let remainingUsernames = [];
        for (let i = VOTER_AVATAR_BREAKPOINT-1; i < responseUsers[`${index}`].length; i++) {
          remainingUsernames.push(responseUsers[`${index}`][i]);
        }
        avatars.push(
          <Tooltip placement="bottomLeft" title={remainingUsernames.join(',')}>
            <Avatar>+{responseUsers[`${index}`].length-VOTER_AVATAR_BREAKPOINT+1}</Avatar>
          </Tooltip>);
      } else {
        _.each(responseUsers[`${index}`], (username: string) => {
          avatars.push(<UserAvatar username={username} />);
        });
      }
      avatarGroup = (
        <Avatar.Group style={{ marginRight: '0.5em' }}>
          {avatars}
        </Avatar.Group>
      );
    }

    return (
      <>
        <div style={{ marginTop: '0.5em' }}>
          {poll.multiple_choices ? <Checkbox value={`${index}`}>{option}</Checkbox> : <Radio value={`${index}`}>{option}</Radio>}
        </div>
        <div className="teamkit-display-flex">
          {avatarGroup && avatarGroup}
          <Progress className="teamkit-flex-full" style={{ margin: "auto" }} percent={percent} status="normal" />
        </div>
      </>
    )
  })

  return (
    <div className="poll-display-card">
      <p>{poll.question}</p>
      {
        poll.multiple_choices ? (
          <Checkbox.Group disabled={true} style={{ width: '100%' }} value={response?.answer ? JSON.parse(response.answer) : undefined}>
            {options}
          </Checkbox.Group>
        ) : (
          <Radio.Group disabled={true} style={{ width: '100%' }} value={response?.answer}>
            {options}
          </Radio.Group>
        )
      }
      {currentUser && !viewOnly && <div>
        <Button type="link" className="edit-response-link" onClick={onEditClicked}>Edit response</Button>
      </div>}
    </div>
  );
}


interface PollCardProps {
  poll: Poll;
  viewOnly?: boolean;
  viewOnlyReason?: string;
  board?: Board;
  item?:Item;
  dragHandleProps?: any;
  onPollUpdate: (event:PollEvent) => void;
}

const PollCard: React.FC<PollCardProps> = ({ poll, board, item, dragHandleProps, viewOnly, viewOnlyReason, onPollUpdate }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const [isEditingVote, setIsEditingVote] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const myResponse = poll.pollresponse_set.find((response: PollResponse) => response.owner === currentUser?.username);

  const onResponded = () => {
    setIsEditingVote(false);
    onPollUpdate({
      type: PollEventType.Respond,
      user: currentUser,
    });
  }

  const deletePoll = async function(pollID:string) {
    await del(apiRoutes.pollSingle(pollID));
    onPollUpdate({
      type: PollEventType.Delete,
      user: currentUser,
    });
  }

  const onDeleteClicked = (pollID:string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete?',
      onOk: async () => {
        await deletePoll(pollID);
      },
      onCancel: () => {},
    });
  }

  const menuItems = [
    <Menu.Item key="item-edit" onClick={() => setIsEditing(true)}>Edit</Menu.Item>,
    <Menu.Item key="item-delete" onClick={() => onDeleteClicked(poll.id)}>Delete</Menu.Item>,
  ];

  return (
    <Card>
      {!viewOnly && <Dropdown overlay={<Menu>{menuItems}</Menu>}>
        <Button size="small" className="item-card-menu-button">
          <EllipsisOutlined key="ellipsis" />
        </Button>
      </Dropdown>}
      {dragHandleProps && <span className="item-card-drag-span" {...dragHandleProps}>
        <MdDragIndicator />
      </span>}
      {(currentUser && !viewOnly && (!myResponse || isEditingVote)) && <PollVotingForm
        poll={poll}
        response={myResponse}
        onResponded={onResponded}
        onCanceled={() => setIsEditingVote(false)}
      />}
      {(viewOnly || (myResponse && !isEditingVote)) && <PollDisplay
        poll={poll}
        response={myResponse}
        viewOnly={viewOnly}
        onEditClicked={() => setIsEditingVote(true)}
      />}
      {isEditing && 
        <PollEditModal
          key={`form-${poll.id}`}
          board={board}
          poll={poll}
          item={item}
          onCompleted={(event: PollEvent) => {
            setIsEditing(false);
            onPollUpdate(event);
          }}
          onCanceled={() => setIsEditing(false)}
        />}
        {viewOnly && viewOnlyReason && <Alert style={{ marginTop: 6 }} message={`Voting is disabled ${viewOnlyReason ?? ''}`} type="info" />}
    </Card>
  );
};

export default PollCard;
