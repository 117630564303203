import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import './styles.less';
import { Row, Col, Spin, Empty } from 'antd';
import BoardCard from './BoardCard';
import { get } from '../../http';
import { getCurrentTeam } from '../../helpers';
import { apiRoutes } from '../../const';
import { Team } from '../../models';
import { AppContext } from '../../../store';
import { BoardCreate } from './';
import { getBasePathWithTeam } from './../../helpers';

const BoardList: React.FC<any> = ({ history, location }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [boards, setBoards] = useState<any>();
  const { show_create } = queryString.parse(location.search);

  const fetchBoards = async function(team:Team) {
    const data = await get(apiRoutes.boardList, {
      team: team.id,
    });
    setBoards(data);
  }

  const onCreateCompleted = (newBoardID:string) => {
    history.push(`${getBasePathWithTeam()}/boards/${newBoardID}`);
  };

  useEffect(() => {
    if (currentTeam) {
      fetchBoards(currentTeam);
    }
  }, [currentTeam]);

  if (!currentTeam || !boards) {
    return <Spin />;
  }

  return (
    <Fragment>
      {boards && boards.length === 0 
        && <p  className="teamkit-align-center">You do not have any Retrospective Board yet, create one below to start collaborating</p>}
      <BoardCreate expand={show_create === 'true' || (boards && boards.length === 0)} onCreateCompleted={onCreateCompleted}/>
      {boards.length > 0 && 
      <>
        <Row gutter={[16, 16]}>
        <Col xs={24} className="teamkit-section-title">
          PAST
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {Array.isArray(boards) && boards.length === 0 && <Empty />}
        {Array.isArray(boards) && boards.length > 0 && boards.map((board:any) => (
          <Col key={board.id} xl={8} md={12} xs={24}>
            <BoardCard board={board} onRemoteChanged={() => {fetchBoards(currentTeam)}}/>
          </Col>
        ))}
      </Row>
      </>}
    </Fragment>
  );
}

export default withRouter(BoardList);
