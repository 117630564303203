import _ from 'lodash-es';
import React, { useState } from "react";
import './styles.less';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Row, Col, Popconfirm, Space, Tooltip } from 'antd';
import { apiRoutes } from '../../const';
import { del } from '../../http';
import { getBasePathWithTeam } from '../../helpers';
import { VotingSession } from '../../models';
import VotingSessionForm from './VotingSessionForm';

interface VotingSessionCardProps extends RouteComponentProps<any> {
  votingSession: VotingSession;
  onRemoteChanged: () => void;
}

interface CardTitleProps {
  votingSession: VotingSession;
  history: any;
}

const CardTitle: React.FC<CardTitleProps> = ({ votingSession, history }) => (
  <div onClick={() => { history.push(`${getBasePathWithTeam()}/scrum-poker-sessions/${votingSession.id}`) }}>
    <div>{votingSession.name}</div>
  </div>
);

const VotingSessionCard: React.FC<VotingSessionCardProps> = ({ votingSession, onRemoteChanged, history }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const deleteVotingSession = async function(votingSessionID:string) {
    await del(apiRoutes.scrumPokerSessionSingle(votingSessionID));
    onRemoteChanged();
  }

  const actions = [
    <Tooltip placement="bottom" title="Edit">
      <EditOutlined onClick={() => {setEditMode(true)}} />
    </Tooltip>,
    <Popconfirm
      key="delete"
      placement="top"
      title={"Are you sure you want to delete?"}
      onConfirm={()=>{deleteVotingSession(votingSession.id)}}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="bottom" title="Delete">
        <DeleteOutlined />
      </Tooltip>
    </Popconfirm>
  ];

  const onCardClick = (e:any) => {
    // Special handler for card body click, to handle clicks that are in the padding
    const allowedClasses: Array<string> = [
      'ant-card-body',
      'ant-card-head',
      'ant-card-head-title'
    ]
    if (_.includes(allowedClasses,e.target.className)) {
      history.push(`${getBasePathWithTeam()}/scrum-poker-sessions/${votingSession.id}`);
    }
  }

  if (editMode) {
    return (
      <VotingSessionForm
        votingSession={votingSession}
        onCreateCancelled={() => { setEditMode(false) }}
        onCreateCompleted={() => {
          onRemoteChanged();
          setEditMode(false);
        }}
      />
    );
  }

  return (
    <Card
      className="teamkit-small-listing-card"
      title={<CardTitle votingSession={votingSession} history={history} />}
      actions={actions}
      onClick={onCardClick}
    >
      <div onClick={() => { history.push(`${getBasePathWithTeam()}/scrum-poker-sessions/${votingSession.id}`) }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row className="teamkit-small-listing-card-summary">
            <Col xs={8}>
              {votingSession.user_count} users
            </Col>
            <Col xs={8}>
              {votingSession.votingitem_count} items
            </Col>
            <Col xs={8}>
              {votingSession.vote_count} votes
            </Col>
          </Row>
        </Space>
      </div>
    </Card>
  );
}

export default withRouter(VotingSessionCard);
