import _ from 'lodash-es';
import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Form, Row, Col, Button, Space, Card, Divider, Menu, Dropdown, Modal, Slider, InputNumber, Select, Input, Popconfirm, Tooltip, Spin } from 'antd';
import { Comment } from '@ant-design/compatible';
import { EditOutlined, DeleteOutlined, RiseOutlined, EllipsisOutlined, ExclamationCircleFilled, ArrowRightOutlined } from '@ant-design/icons';
import { apiRoutes, textAreaLength } from '../../../const';
import { post, get, put, del } from '../../../http';
import { Goal, TargetStatus, TargetStatusTitle, GoalComment, GoalCommentCreate, Target, TargetProgressType, GoalCommentMetadata, User, GoalComment as GoalCommentModel, isNumericProgressType, formatNumberProgress, parseNumberProgress } from '../../../models';
import TargetProgressBar from './TargetProgressBar';
import { UserAvatar } from '../../common';
import TargetStatusTag from './TargetStatusTag';
import { TargetFormItems, targetFromTargetFormValues, targetToTargetFormValues } from './GoalForm';
import { useForm } from 'antd/lib/form/Form';
import { useForceUpdate, useWindowDimensions } from '../../../hooks';
import './styles.less';

const { confirm } = Modal;

const DefaultTargetProgressUpdateComment = 'Progress update';

interface TargetUpdateCardProps {
  goal: Goal;
  target: Target;
  onCanceled: () => void;
  onUpdateCompleted: () => void;
  onRecordUpdateClicked?: () => void;
}

const TargetUpdateCard: React.FC<TargetUpdateCardProps> = ({ goal, target, onCanceled, onUpdateCompleted, onRecordUpdateClicked}) => {
  const [targetForm] = useForm();

  const updateTargetFormSubmit = async (values: any) => {
    let targetVal = targetFromTargetFormValues(target.id, values);
    await updateTarget(goal.id, target.id, targetVal);
    targetForm.resetFields();
    onUpdateCompleted();
  };

  const updateTarget = async (goalID: string, targetID: string, targetVal: any) => {
    await put(apiRoutes.goalTargetSingle(goalID, targetID), targetVal);
  };

  return (
    <Card>
      <Form
        form={targetForm}
        initialValues={targetToTargetFormValues(target)}
        onFinish={updateTargetFormSubmit}
      >
        <TargetFormItems
          form={targetForm}
          id={target.id}
          isEditMode={true}
          hideRemoveButton={true}
          onRemoveClicked={() => {}}
          onRecordUpdateClicked={() => {
            onCanceled();
            onRecordUpdateClicked && onRecordUpdateClicked();
          }}
        />
        <br/>
        <Space>
          <Button type="primary" htmlType="submit">Save</Button>
          <Button onClick={onCanceled}>Cancel</Button>
        </Space>
      </Form>
    </Card>
  );
}

interface TargetCommentListProps {
  targetComments: Array<GoalComment>;
  target: Target;
  currentUser: User;
  onCommentDeleted: () => void;
  onCommentUpdated: () => void;
}

const TargetCommentList: React.FC<TargetCommentListProps> = ({ target, targetComments, currentUser, onCommentDeleted, onCommentUpdated }) => {
  const [pageSize, setPageSize] = useState(2);

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {_.map(targetComments, (comment:GoalComment, i: number) => {
        if (i < pageSize) {
          return (
            <TargetHistory
              comment={comment}
              target={target}
              currentUser={currentUser}
              onDeleted={onCommentDeleted}
              onUpdated={onCommentUpdated}
            />
          );
        }

        return null
      })}

      {_.size(targetComments) > pageSize && <div style={{textAlign: 'center'}}>
        <Button onClick={() => { setPageSize(pageSize+5) }}>Show more history</Button>
      </div>}
    </Space>
  );
};
interface TargetHistoryProps {
  currentUser?: User;
  comment: GoalCommentModel;
  target: Target;
  onDeleted: () => void;
  onUpdated: () => void;
}

const TargetHistory: React.FC<TargetHistoryProps> = ({ currentUser, comment, target, onDeleted, onUpdated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = useForm();

  const deleteComment = async () => {
    await del(apiRoutes.targetCommentSingle(target.id, comment.id));
    onDeleted();
  }

  const updateComment = async (value:any) => {
    await put(apiRoutes.targetCommentSingle(target.id, comment.id), value);
  }

  useEffect(() => {
    form.setFieldsValue({content: comment.content === DefaultTargetProgressUpdateComment ? '' : comment.content})
  }, [form, comment]);

  const formSubmit = async (values: any) => {
    let content = DefaultTargetProgressUpdateComment;
    if (values.content && values.content.length > 0) {
      content = values.content;
    }
    let newVal = _.assign({}, comment, { content });
    await updateComment(newVal);
    setIsEditing(false);
    onUpdated();
  }

  let metadata: GoalCommentMetadata = undefined;
  if (comment.metadata) {
      metadata = JSON.parse(comment.metadata);
  }

  let content = <div></div>;
  let oldValFmt, newValFmt;
  if (isNumericProgressType(metadata?.v1?.old_target.target_progress.type)) {
    oldValFmt = formatNumberProgress(metadata?.v1?.old_target?.target_progress.current_value_float);
    newValFmt = formatNumberProgress(metadata?.v1?.new_target?.target_progress.current_value_float);
    if (target.target_progress.type === TargetProgressType.Currency) {
      oldValFmt = `${metadata?.v1?.old_target?.target_progress.unit}${oldValFmt}`;
      newValFmt = `${metadata?.v1?.new_target?.target_progress.unit}${newValFmt}`;
    }
  }

  if (metadata?.v1?.new_target) {
    content = (
      <Space direction="vertical" style={{width: '100%'}}>
        <div>
          <b>Status</b>
          <div className="target-status-update">
            <TargetStatusTag status={metadata?.v1?.old_target?.status} /> <ArrowRightOutlined />&nbsp;<TargetStatusTag status={metadata?.v1?.new_target?.status} />
          </div>
        </div>
        {isNumericProgressType(metadata?.v1?.old_target.target_progress.type) && <div>
          <b>Progress</b>
          <div className="teamkit-display-flex">
            <div>
              {oldValFmt} <ArrowRightOutlined />&nbsp;{newValFmt}
            </div>
            <div className="teamkit-flex-full" style={{paddingLeft: 16}}>
              <TargetProgressBar small target={metadata?.v1?.new_target} />
            </div>
          </div>
        </div>}
        {!isEditing && comment.content && comment.content.length > 0 && comment.content !== DefaultTargetProgressUpdateComment && <div>
          <b>Comment</b>
          <p>
            {comment.content}
          </p>
        </div>}
        {isEditing && <div>
          <b>Comment</b>
          <Form
            form={form}
            initialValues={{content: comment.content === DefaultTargetProgressUpdateComment ? '' : comment.content}}
            onFinish={formSubmit}
          >
            <Form.Item name="content">
              <Input.TextArea  maxLength={textAreaLength} rows={4} />
            </Form.Item>
          </Form>
        </div>}
      </Space>
    );
  } else {
    content = (
      <p>
        {comment.content}
      </p>
    );
  }

  let historyActions: Array<React.ReactNode> = [];
  if (isEditing) {
    historyActions = [
      <Button type="primary" onClick={() => { form.submit() }}>Save</Button>,
      <span>&nbsp;</span>,
      <Button onClick={() => { setIsEditing(false) }}>Cancel</Button>,
    ];
  } else if (comment.owner === currentUser?.username) {
    historyActions = [
      <span className="comment-reply-link" onClick={() => { setIsEditing(true) }}>Edit comment</span>,
      <Popconfirm
        key="delete"
        placement="top"
        title={"Are you sure you want to delete?"}
        onConfirm={deleteComment}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip placement="bottom" title="Delete">
          <span className="comment-reply-link">Delete</span>
        </Tooltip>
      </Popconfirm>,
    ];
  }

  return (
    <Card className="goal-comment-cards">
      <Comment
        actions={historyActions}
        avatar={<UserAvatar username={comment.owner} />}
        author={comment.owner}
        datetime={
          <Tooltip title={moment(comment.created_at).format('MMMM Do YYYY, h:mm:ss')}>
            <span>{moment(comment.created_at).fromNow()}</span>
          </Tooltip>
        }
        content={content}
      />
    </Card>
  )
}

interface TargetCardProps {
  onDeleteCompleted: () => void;
  onUpdateCompleted: () => void;
  goal: Goal;
  target: Target;
  isArchived: boolean;
  currentUser: User;
}

const TargetCard: React.FC<TargetCardProps> = ({ onDeleteCompleted, onUpdateCompleted, currentUser, goal, target, isArchived }) => {
  const forceUpdate = useForceUpdate();
  const { width } = useWindowDimensions();
  const [isRecordingProgress, setIsRecordingProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showTargetHistory, setShowTargetHistory] = useState(false);
  const [targetComments, setTargetComments] = useState(Array<GoalComment>());
  const [progressUpdateForm] = useForm();

  useEffect(() => {
    if (goal && target) {
      fetchTargetComments();
      progressUpdateForm.setFieldsValue({
        progress: target.target_progress.current_value_float,
        status: target.status,
      });
    }
  }, [goal, target]);

  const fetchTargetComments = async () => {
    const comments = await get(apiRoutes.targetCommentList(target.id));
    setTargetComments(comments);
  }
  
  const deleteTarget = async () => {
    await del(apiRoutes.goalTargetSingle(goal.id, target.id));
    onDeleteCompleted();
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Delete target',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure delete this target? The action is not reversible and all data will be lost.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTarget();
      },
      onCancel() {},
    });
  };

  const onRecordingUpdateSubmit = () => {
    setIsRecordingProgress(true);
  }

  const progressUpdateFormSubmit = async (values:any) => {
    let newTarget = _.cloneDeep(target);
    if (isNumericProgressType(target.target_progress.type)) {
      newTarget.target_progress.current_value_float = values.progress;
    }
    newTarget.status = values.status;
    if (values.status === TargetStatus.Done && newTarget.target_progress.type === TargetProgressType.Bool) {
      newTarget.target_progress.current_value_bool = true;
    }
    const updatedTarget = await updateTarget(newTarget);
    const metadata:GoalCommentMetadata = {
      v1: {
        old_target: target,
        new_target: updatedTarget,
      }
    };
    const comment: GoalCommentCreate = {
      content: values.comment ? values.comment : 'Progress update',
      metadata: JSON.stringify(metadata)
    };
    await createTargetComment(comment);
    setIsRecordingProgress(false);
    progressUpdateForm.resetFields();
    onUpdateCompleted();
  }

  const updateTarget = async (newTarget: Target): Promise<Target> => {
    let data = await put(apiRoutes.goalTargetSingle(goal.id, target.id), newTarget);
    return data
  }

  const createTargetComment = async (comment: GoalCommentCreate) => {
    await post(apiRoutes.targetCommentList(target.id), comment);
  }

  if (!currentUser) {
    return <Spin />;
  }

  if (isEditing) {
    return (
      <Row>
        <Col span={24}>
          <TargetUpdateCard
            goal={goal}
            target={target}
            onCanceled={() => setIsEditing(false)}
            onUpdateCompleted={() => {
              setIsEditing(false);
              onUpdateCompleted();
            }}
            onRecordUpdateClicked={() => setIsRecordingProgress(true)}
          />
        </Col>
      </Row>
    );
  }

  let actionMenu = (
    <Menu>
      <Menu.Item key="target-update" onClick={() => setIsRecordingProgress(true)}><RiseOutlined /> Record an Update</Menu.Item>
      <Divider style={{ margin: 0 }} />
      <Menu.Item key="target-edit" onClick={() => {setIsEditing(true)}}><EditOutlined /> Edit</Menu.Item>
      <Menu.Item key="target-delete" onClick={showDeleteConfirm}><DeleteOutlined /> Delete</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Modal
        title="Record a progress update"
        open={isRecordingProgress}
        onOk={onRecordingUpdateSubmit}
        onCancel={() => setIsRecordingProgress(false)}
        okButtonProps={{ onClick() { progressUpdateForm.submit() } }}
        cancelButtonProps={{ onClick() { setIsRecordingProgress(false) } }}
        width={width > 900 ? 900 : width}
        closable={false}
        maskClosable={false}
      >
        <Form
          form={progressUpdateForm}
          initialValues={{
            progress: target.target_progress.current_value_float,
            status: target.status,
          }}
          onFinish={progressUpdateFormSubmit}
        >
          {isNumericProgressType(target.target_progress.type) && 
            <Row>
              <Col span={18}>
                <Form.Item
                  name="progress"
                  label="Progress"
                >
                  <Slider
                    min={target.target_progress.initial_value_float}
                    max={target.target_progress.target_value_float}
                    onChange={(value) => {
                      if (value === target.target_progress.target_value_float) {
                        progressUpdateForm.setFieldValue('status', TargetStatus.Done);
                      } else if (value < target.target_progress.target_value_float && progressUpdateForm.getFieldValue('status') === TargetStatus.Done) {
                        progressUpdateForm.setFieldValue('status', TargetStatus.OnTrack);
                      }
                      forceUpdate();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <InputNumber
                  min={target.target_progress.initial_value_float}
                  max={target.target_progress.target_value_float}
                  style={{ margin: '0 16px' }}
                  formatter={formatNumberProgress}
                  parser={parseNumberProgress}
                  value={progressUpdateForm.getFieldValue('progress') !== undefined ? progressUpdateForm.getFieldValue('progress') : target.target_progress.current_value_float}
                  addonAfter={target.target_progress.type === TargetProgressType.Percentage ? '%' : target.target_progress.type === TargetProgressType.Currency ? target.target_progress.unit : undefined}
                  onChange={(value) => {
                    progressUpdateForm.setFieldValue('progress', value);
                    if (value === target.target_progress.target_value_float) {
                      progressUpdateForm.setFieldValue('status', TargetStatus.Done);
                    } else if (value < target.target_progress.target_value_float && progressUpdateForm.getFieldValue('status') === TargetStatus.Done) {
                      progressUpdateForm.setFieldValue('status', TargetStatus.OnTrack);
                    }
                    forceUpdate();
                  }}
                />
              </Col>
            </Row>}
            <Row>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Status is required!' }]}
          >
              <Select
                style={{minWidth: 200}}
                disabled={_.includes([TargetProgressType.Num, TargetProgressType.Percentage], target.target_progress.type) && progressUpdateForm.getFieldValue('progress') == target.target_progress.target_value_float}
                options={[
                  {
                    value: TargetStatus.OnTrack,
                    label: TargetStatusTitle[TargetStatus.OnTrack],
                  },
                  {
                    value: TargetStatus.AtRisk,
                    label: TargetStatusTitle[TargetStatus.AtRisk],
                  },
                  {
                    value: TargetStatus.Done,
                    label: TargetStatusTitle[TargetStatus.Done],
                  },
                  {
                    value: TargetStatus.Canceled,
                    label: TargetStatusTitle[TargetStatus.Canceled],
                  },
                ]}
              />
              </Form.Item>
            </Row>
          <Form.Item
            name="comment"
            label="Comment"
          >
            <Row>
              <Col span={24}>
                <Input.TextArea
                  placeholder="Any notes for future preference? Any challenges you're facing? Anything to celebrate?"
                  maxLength={textAreaLength}
                  rows={4}
                />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Card>
        {!isArchived && goal.owner === currentUser.username && <Dropdown overlay={actionMenu} placement="bottomRight" arrow={{ pointAtCenter: true }}>
          <Button size="small" className="todo-drawer-action-btn" >
            <EllipsisOutlined key="ellipsis" />
          </Button>
        </Dropdown>}
        
        <Space direction="vertical" style={{ width: '100%' }}>
          <p className="goal-show-target-card-title"> {target.title} </p>
          <TargetProgressBar style={{marginTop: 8}} target={target} showActualValue noProgressBarBool />
          {_.size(targetComments) > 0 && <div>
            <a onClick={() => {setShowTargetHistory(!showTargetHistory)}}>{showTargetHistory ? 'Hide history' : 'Show history'} ({_.size(targetComments)})</a>
          </div>}
          {_.size(targetComments) > 0 && showTargetHistory && <TargetCommentList
            target={target}
            targetComments={targetComments}
            currentUser={currentUser}
            onCommentDeleted={onUpdateCompleted}
            onCommentUpdated={async () => { await fetchTargetComments(); }}
          />}
        </Space>
      </Card>
    </>
  );
};

export default TargetCard;
