import _ from 'lodash-es';
import './styles.less';
import React, { useContext, useEffect, useState } from "react";
import votingSessionFormBg from '../../../resources/images/voting-session-create-bg.png';
import { Form, Input, InputNumber, Button, Card, Space, Spin, Select } from 'antd';
import { apiRoutes, mediumTextLength } from '../../const';
import { VotingSession, EstimationUnit, EstimationUnitOptions, EstimationUnitOption } from '../../models';
import { AppContext } from '../../../store';
import { post, put } from '../../http';
import { getCurrentTeam } from '../../helpers';

const { Option } = Select;

interface VotingSessionFormProps {
  votingSession?: VotingSession;
  onCreateCompleted: (newID:string) => void;
  onCreateCancelled: () => void;
}

const VotingSessionForm: React.FC<VotingSessionFormProps> = ({ votingSession, onCreateCompleted, onCreateCancelled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const [ selectedEstimationUnit, setSelectedEstimationUnit] = useState<EstimationUnit>(EstimationUnit.Point);
  const [form] = Form.useForm();

  useEffect(() => {
    if (votingSession) {
      setSelectedEstimationUnit(votingSession.estimation_unit);
    }
  }, [votingSession]);

  if (!currentUser) {
    return <Spin />
  }
  
  const currentTeam = getCurrentTeam(currentUser);
  const isEditMode = () => {
    return votingSession ? true : false;
  }

  const formSubmit = async (values: any) => {
    if (values['freestyle_options']) {
      values['freestyle_options'] = JSON.stringify(values['freestyle_options'])
    }
    if (votingSession) {
      await updateVotingSession(votingSession.id, _.assign({}, votingSession, values));
      return;
    }
    await createVotingSession(values);
  }

  const createVotingSession = async (values: any) => {
    const data = await post(apiRoutes.scrumPokerSessionList, _.assign({}, values, {
      team: currentTeam?.id,
    }));
    onCreateCompleted(data.id);
  }

  const updateVotingSession = async (votingSessionID:string, values: any) => {
    const data = await put(apiRoutes.scrumPokerSessionSingle(votingSessionID), values)
    onCreateCompleted(data.id);
  }

  const onEstimationUnitChanged = (value:EstimationUnit) => {
    setSelectedEstimationUnit(value);
  }

  return(
    <Card>
      <Form
        form={form}
        name="voting-session-form"
        initialValues={{
          name: votingSession ? votingSession.name : '',
          estimation_unit: votingSession ? votingSession.estimation_unit : EstimationUnit.Point,
          hours_per_day: votingSession ? votingSession.hours_per_day : 8,
          days_per_week: votingSession ? votingSession.days_per_week : 5,
          template: 0,
        }}
        onFinish={formSubmit}
      >
        {!isEditMode() &&
          <div className="create-board-bg-image-container">
            <img src={votingSessionFormBg} className="create-board-bg-image" alt="VotingSession create"/>
            <a
              className="card-image-attribute-link"
              href="http://www.freepik.com"
              target="_blank"
              rel="noreferrer"
            >
              Image designed by pch.vector / Freepik
            </a>
          </div>}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the voting session name!' }]}
        >
          <Input maxLength={mediumTextLength} />
        </Form.Item>
        <Form.Item
          label="Estimation Unit"
          name="estimation_unit"
          rules={[{ required: true, message: 'Please select estimation unit!' }]}
        >
          <Select onChange={onEstimationUnitChanged}>
            {_.map(EstimationUnitOptions, (option:EstimationUnitOption, index:number) => {
              return <Option value={option.value}>{ option.displayName }</Option>;
            })}
          </Select>
        </Form.Item>
        { selectedEstimationUnit === EstimationUnit.Hour &&
          <>
            <Form.Item
              label="Hous per day"
              name="hours_per_day"
              rules={[{ required: true, message: 'Please input hours per day!' }]}
            >
              <InputNumber min={1} max={24} />
            </Form.Item>
            <Form.Item
            label="Days per week"
            name="days_per_week"
            rules={[{ required: true, message: 'Please input days per week!' }]}
            >
              <InputNumber min={1} max={7} />
            </Form.Item>
          </>}
        { selectedEstimationUnit === EstimationUnit.Freestyle &&
          <Form.Item
            label="Options"
            name="freestyle_options"
            rules={[
              { required: true, message: 'Please input voting options!' },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  for (let opt of value) {
                    if (opt.length > 3) {
                      return Promise.reject(new Error(`Voting option's max length is 3, option "${opt}" is invalid!`));
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select mode="tags" style={{ width: '100%' }} onChange={() => {}} tokenSeparators={[',']}>
            </Select>
          </Form.Item>}

        <Form.Item >
          <Space>
            <Button type="primary" htmlType="submit">
              { isEditMode() ? 'Update' : 'Create'}
            </Button>
            <Button onClick={onCreateCancelled}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default VotingSessionForm;
