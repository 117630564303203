import Cookies from 'js-cookie';
import axios from 'axios';
import { notification } from 'antd';
import { APP_BASE_PATH, localStorageKeys } from '../const';
import { isLiteMode, isRequireLogin } from '../helpers';

const CSRF_TOKEN_COOKIE_KEY = 'csrftoken';
export const post = async (url:string, body: any):Promise<any> => {
  try {
    const response = await axios.post(url, body, {
      headers: {'X-CSRFToken': Cookies.get(CSRF_TOKEN_COOKIE_KEY) || ''}
    });

    if (response.status > 299) {
        console.error(response.data);
        throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    handleError(url, error);
    throw(error);
  }
}

export const put = async (url:string, body: any):Promise<any> => {
  try {
    const response = await axios.put(url, body, {
      headers: {'X-CSRFToken': Cookies.get(CSRF_TOKEN_COOKIE_KEY) || ''}
    });

    if (response.status > 299) {
        console.error(response.data);
        throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    handleError(url, error);
    throw(error);
  }
}

export const get = async (url:string, params?:any):Promise<any> => {
  try {
    const response = await axios.get(url, {
      params: params,
    });

    if (response.status > 299) {
        console.error(response.data);
        throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    handleError(url, error);
    throw(error);
  }
}

export const del = async (url:string):Promise<any> => {
  try {
    const response = await axios.delete(url, {
      headers: {'X-CSRFToken': Cookies.get(CSRF_TOKEN_COOKIE_KEY) || ''}
    });

    if (response.status > 299) {
        console.error(response.data);
        throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    handleError(url, error);
    throw(error);
  }
}

const handleError = (url: string, error: any) => {
  let basePath = APP_BASE_PATH;
  if (basePath.length > 0 && basePath.charAt(basePath.length-1) === '/') {
    basePath = basePath.substring(0, basePath.length-1);
  }
  const requireLogin = !isLiteMode() && isRequireLogin();
  if (error.response.status === 401) {
    if (requireLogin) {
      Cookies.remove(CSRF_TOKEN_COOKIE_KEY);
      localStorage.setItem(localStorageKeys.loginRedirect, window.location.pathname);
      window.location.href = '/accounts/login/';
    }
  } else if (error.response.status === 403) {
    if (requireLogin) {
      if (error.response.data && error.response.data.detail === "Authentication credentials were not provided.") {
        localStorage.setItem(localStorageKeys.loginRedirect, window.location.pathname);
        window.location.href = '/accounts/login/';
        return
      }
      notification['error']({
        message: 'Permission Denied',
        description: 'No permission to perform the action.',
      });
    }
  } else if (error.response.data && typeof error.response.data === 'string' && error.response.data.includes('Duplicate entry') && error.response.data.includes('username')) {
    notification['error']({
      message: 'Error happened',
      description: "The username has already been taken",
    });
  } else if (url.includes('common/users/') && error.response.status === 404) {
    // Do nothing, the avatar component might be rendering a deleted user, or a removed user from the team
  } else {
    let errDescription = 'Unknown error happened.';
    if (error.response && Array.isArray(error.response.data)) {
      errDescription = error.response.data.join(', ');
    }
    notification['error']({
      message: 'Error happened',
      description: errDescription,
    });
  }
}
