import React, { useEffect, useContext, useState } from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, Row, Button, Col, Space, List } from 'antd';
import { get } from '../../http';
import { apiRoutes, appRoutes } from '../../const';
import { AppContext } from '../../../store';
import { getCurrentTeam, getBasePathWithTeam } from '../../helpers';
import { Board, Team, InviteType } from '../../models';
import { InvitePopover } from '../common';
import WidgetContainer from './WidgetContainer';
import boardFormBg from '../../../resources/images/board-create-bg.png';

interface BoardDescriptionProps {
  board: Board;
}

const BoardDescription: React.FC<BoardDescriptionProps> = ({ board }) => {
  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row className="teamkit-small-listing-card-summary">
          <Col span={24}>
            {board.user_count} users&nbsp;|&nbsp;
            {board.item_count} items&nbsp;|&nbsp;
            {board.todo_count} tasks
          </Col>
        </Row>
      </Space>
    </div>
  );
}

const EmptyContent = () => {
  return (
    <>
      <div >
        <img style={{ margin: 'auto' }} src={boardFormBg} className="create-board-bg-image" alt="Board create"/>
      </div>
      <div>No retrospective board yet. <Button type="link" style={{ padding: 0 }} href={`${getBasePathWithTeam()}/boards/?show_create=true`}>Create new board</Button> to start the collaboration!</div>
    </>
  );
}


const RetrospectiveWidget: React.FC<RouteComponentProps> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [boards, setBoards] = useState<Board[]|undefined>(undefined);

  useEffect(() => {
    if (currentTeam) {
      fetchBoards(currentTeam);
    }
  }, [currentTeam]);

  const fetchBoards = async function(team:Team) {
    const data = await get(apiRoutes.boardList, {
      team: team.id,
    });
    setBoards(data.slice(0, 3));
  }

  return (
    <WidgetContainer route={appRoutes.boards} title="Retrospectives">
      <List
        itemLayout="horizontal"
        locale={{ emptyText: <EmptyContent /> }}
        loading={!boards}
        dataSource={boards}
        renderItem={(board:Board, index:number) => (
          <List.Item>
            <List.Item.Meta
              title={board.name}
              description={<BoardDescription board={board} />}
            />
            {board.public && <Popover placement="bottomRight" content={<InvitePopover inviteType={InviteType.Board} inviteID={board.id} inviteResource="retrospective board" inviteResourceName={board.name} />} trigger="click">
              <Button type="link">Invite</Button>
            </Popover>}
            <Button type="link" href={`${getBasePathWithTeam()}/boards/${board.id}/`}>Join</Button>
          </List.Item>
        )}
      />
      {boards?.length > 0 && <div style={{ textAlign: 'center' }} >
        <Button type="link" href={`${getBasePathWithTeam()}/boards/?show_create=true`}>Create new board</Button>
      </div>}
    </WidgetContainer>
  );
}

export default withRouter(RetrospectiveWidget);
