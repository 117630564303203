import moment from "moment-timezone";
import React, { useState, useContext } from "react";
import './styles.less';
import { Card, Divider, Dropdown, Button, Menu, Modal } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Comment } from '@ant-design/compatible';
import { KudoEvent, Kudo, KudoEventType, User } from '../../models';
import { apiRoutes } from '../../const';
import { AppContext } from '../../../store';
import { del } from '../../http';
import { UserAvatar } from '../common';
import KudoForm from './KudoForm';

const { confirm } = Modal;


interface KudoMiniCardProps {
  boardID?: string;
  kudo: Kudo;
  members?: User[];
  viewOnly?: boolean;
  onKudoUpdate?: (event:KudoEvent) => void;
}

const KudoMiniCard: React.FC<KudoMiniCardProps> = ({ boardID, members, kudo, viewOnly, onKudoUpdate }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onDeleteClicked = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete?',
      onOk: async () => {
        await deleteKudo();
      },
      onCancel: () => {},
    });
  }

  const deleteKudo = async () => {
    await del(apiRoutes.kudoSingle(kudo.id));
    if (onKudoUpdate) {
      onKudoUpdate({
        type: KudoEventType.Delete,
        user: currentUser,
      });
    }
  }

  const onEditCompleted = () => {
    setIsEditing(false);
    if (onKudoUpdate) {
      onKudoUpdate({
        type: KudoEventType.Update,
        user: currentUser,
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="item-edit" onClick={() => setIsEditing(true)}>Edit</Menu.Item>
      <Menu.Item key="item-delete" onClick={onDeleteClicked}>Delete</Menu.Item>
    </Menu>
  );

  let datetime = null;
  if (kudo.created_at) {
    datetime = moment().diff(moment(kudo.created_at), 'hours') <= 6 ? moment(kudo.created_at).fromNow() : moment(kudo.created_at).format('MMM D, YYYY h:mm A');
  }
  return (
    <Card className="kudo-mini-card">
      {!viewOnly && kudo.owner === currentUser.username && <Dropdown overlay={menu}>
        <Button size="small" className="item-card-menu-button kudo-card-menu-button">
          <EllipsisOutlined key="ellipsis" />
        </Button>
      </Dropdown>}
      <div className="kudo-mini-avatar-container">
        <UserAvatar username={kudo.receiver} size={64} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <b>{kudo.receiver}</b>
      </div>
      <Divider style={{ margin: '12px 0 0' }}/>
      {isEditing && <KudoForm boardID={boardID} members={members} kudo={kudo} onCompleted={onEditCompleted} onCanceled={() => setIsEditing(false)}/>}
      {!isEditing && <Comment
        className="teamkit-comment"
        datetime={datetime}
        author={kudo.owner}
        avatar={<UserAvatar username={kudo.owner} />}
        content={kudo.content}
      />}
    </Card>
  );
};

export default KudoMiniCard;
