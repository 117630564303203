
import React from "react";
import { Progress } from 'antd';
import { Target, TargetStatus, Goal } from '../../../models';
import './styles.less';


interface GoalProgressBarProps {
  goal: Goal;
  targets: Array<Target>;
  asProgressBar?: boolean;
}

const GoalProgressBar: React.FC<GoalProgressBarProps> = ({ goal, asProgressBar, targets }) => {
  let totalTargets = targets.filter((target: Target) => target.status !== TargetStatus.Canceled).length;
  let doneTargets = targets.filter((target: Target) => target.status === TargetStatus.Done).length;

  return (
    <span className="goal-progress-bar">
      <Progress
        type={asProgressBar ? 'line' : 'dashboard'}
        percent={totalTargets > 0 ? doneTargets / totalTargets * 100 : 0}
        success={{ percent: totalTargets > 0 && doneTargets === totalTargets ? 100 : 0 }}
        format={() => totalTargets > 0 ? `${doneTargets} / ${totalTargets}` : <span className="goal-progress-bar-no-target">No milestone</span>}
      />
    </span>
  )
} 

export default GoalProgressBar;
