import _ from 'lodash-es';
import React, { ReactElement, useState } from "react";
import './styles.less';
import { Form, Card, Space, Input, Button, Select } from 'antd';
import { apiRoutes, colorSchemeClassMapping } from '../../const';
import { put } from '../../http';
import { BoardColumn } from '../../models';

const { Option } = Select;

interface BoardColumnFormProps {
  column: BoardColumn;
  onUpdateCompleted: () => void;
  onCancel: () => void;
}
const BoardColumnForm: React.FC<BoardColumnFormProps> = ({ column, onUpdateCompleted, onCancel }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFormSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { color_scheme, name } = values;
    try {
      await put(apiRoutes.boardColumnSingle(column.id), { color_scheme, name })
    } finally {
      setIsSubmitting(false);
    }
    onUpdateCompleted();
  }

  return (
    <Card>
      <Form initialValues={column} form={form} name={`item-form-${column.id}`} onFinish={onFormSubmit}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div className="teamkit-display-flex">
            <Form.Item name="color_scheme" noStyle>
              <Select style={{ width: '70px', marginRight: '8px' }}>
                {function() {
                  const options:Array<ReactElement> = [];
                  _.forIn(colorSchemeClassMapping, function(value, key) {
                    options.push(
                      <Option key={key} value={key}><span style={{ color: '#ffffff00' }} className={value}>OO</span></Option>
                    );
                  });
                  return options;
                }()}
              </Select>
            </Form.Item>
            <Form.Item name="name" noStyle>
              <Input className="teamkit-flex-full" />
            </Form.Item>
          </div>
          <div>
            <Space>
              <Button type="primary" htmlType="submit" className="item-form-submit-button" loading={isSubmitting}>
                Update
              </Button>
              <Button onClick={onCancel}>Cancel</Button>
            </Space>
          </div>
        </Space>
      </Form>
    </Card>
  );
}

export default BoardColumnForm;
