import moment from 'moment';
import type { Moment } from 'moment';
import { GoalGroup, Goal } from '../../models';

export const formatPeriod = (goalGroup: GoalGroup | Goal) => {
  if (!goalGroup.start_time || !goalGroup.end_time) {
    return 'No timeline';
  }

  let startTime = moment(goalGroup.start_time);
  let endTime = moment(goalGroup.end_time);

  let startQuarter = startTime.quarter();
  let endQuarter = endTime.quarter();
  if (startQuarter === endQuarter && startTime.year() === endTime.year()) {
    return `${startTime.format('YYYY-')}Q${startTime.quarter()}`;
  }
  if (startQuarter === 1 && endQuarter === 2 && startTime.year() === endTime.year()) {
    return `${startTime.format('YYYY-')}H1`;
  }
  if (startQuarter === 3 && endQuarter === 4 && startTime.year() === endTime.year()) {
    return `${startTime.format('YYYY-')}H2`;
  }
  if (startQuarter === 1 && endQuarter === 4 && startTime.year() === endTime.year()) {
    return `${startTime.format('YYYY-')}FY`;
  }

  let startTimeFmt = `${startTime.format('YYYY-')}Q${startTime.quarter()}`;
  let endTimeFmt = `${endTime.format('YYYY-')}Q${endTime.quarter()}`;
  return `${startTimeFmt} - ${endTimeFmt}`;
}

export interface PeriodTimestamp {
  start_time?: string;
  end_time?: string;
}

export const TimeStampFromPeriod = (period: Array<Moment>|undefined): PeriodTimestamp => {
  if (!period) {
    return {start_time: undefined, end_time: undefined};
  }

  let start_time = undefined;
  let end_time = undefined;
  if (period) {
    if(period.length >= 1) {
      start_time = moment.utc({
        year: period[0].year(),
        month: period[0].month(),
        date: period[0].date(),
      }).format();
    }
    if(period.length >= 2) {
      end_time = moment.utc({
        year: period[1].year(),
        month: period[1].month(),
        date: period[1].date(),
      }).format();
    }
  }

  return {start_time, end_time};
}

export const periodFromTimestamp = (startTime: string | null | undefined, endTime: string | null | undefined): Array<Moment|undefined> => {
  let startTimeMoment: Moment | undefined = undefined;
  if (startTime) {
    startTimeMoment = moment.utc(startTime);
  }
  let endTimeMoment: Moment | undefined = undefined;
  if (endTime) {
    endTimeMoment = moment.utc(endTime);
  }

  return [startTimeMoment, endTimeMoment];
}