
import React from "react";
import { Tag } from 'antd';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';

export const customIcons: Record<number, React.ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

export const maxMoodScore = 5;

export const moodTagFromScore = (score: number):React.ReactNode => {
  if (isNaN(score)) {
    return null;
  }

  let color: string, text: string;
  if (score === 5) {
    color = 'green';
    text = 'Awesome';
  } else if (score === 4) {
    color = 'green';
    text = 'Good';
  } else if (score === 3) {
    color = 'yellow';
    text = 'Okay';
  } else if (score === 2) {
    color = 'orange';
    text = 'Meh';
  } else {
    color = 'red';
    text = 'Bad';
  }


  return <Tag style={{ marginTop: '0.5em' }} color={color}>{text}</Tag>;
};