import _ from 'lodash-es';
import React, { Fragment, useEffect, useState, useContext } from "react";
import './PlanList.less';
import { Card, Button, Row, Col, Spin, notification } from 'antd';
import classNames from 'classnames';
import { apiRoutes, djangoPageRoutes } from '../../const';
import { Plan } from '../../models';
import { get, put } from '../../http';
import { AppContext } from '../../../store';

const PlanList: React.FC<{}> = () => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const [configuredPlans, setConfiguredPlans] = useState<Array<Plan>>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fetchCurrentUser = async function() {
    const data = await get(apiRoutes.currentUser);
    dispatch({
      currentUser: data,
    });
  }

  const fetchPlans = async () => {
    const data = await get(apiRoutes.planList);
    setConfiguredPlans(data);
  }

  const onSelectPlanClicked = async (selectedPlan?: Plan) => {
    if (!selectedPlan) {
      return
    }
    try {
      setIsSubmitting(true);
      await put(apiRoutes.currentUserPlan, {
        plan: selectedPlan.id,
      });
    } finally {
      setIsSubmitting(false);
    }
    notification['info']({
      message: '',
      description: `Successfully switched to ${selectedPlan.title} plan`,
    });
    fetchCurrentUser();
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  if (!configuredPlans) {
    return (
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Spin />
      </Row>
    );
  }

  let currentPlanIndex = -1;
  if (currentUser) {
    _.each(configuredPlans, (plan:Plan, index:number) => {
      if (plan.id === currentUser.current_plan) {
        currentPlanIndex = index;
      }
    })
  }

  let nextDiscountPlan:Plan|undefined = undefined;
  for (let i = currentPlanIndex+1; i < configuredPlans.length; i++) {
    if (configuredPlans[i].discounted_amount >= 0) {
      nextDiscountPlan = configuredPlans[i];
      break;
    }
  }

  let promoBannerCTA = undefined;
  if (nextDiscountPlan) {
    if (!currentUser) {
      promoBannerCTA = (
        <Button onClick={() => { window.location.href=djangoPageRoutes.signup }}>Sign up</Button>
      );
    } else {
      promoBannerCTA = (
        <Button onClick={() => { onSelectPlanClicked(nextDiscountPlan) }} loading={isSubmitting}>Upgrade</Button>
      );
    }
  }

  const colSpan = 24/configuredPlans.length;
  
  return (
    <Fragment>
      {promoBannerCTA && <div>
        <p className="teamkit-align-center" style={{ marginBottom: '24px' }}>
          We are running a full discount on Pro plan. {promoBannerCTA} now to enjoy the great benefit, it's free{nextDiscountPlan && <span> and <b>no card needed</b>, cancel anytime.</span>}
        </p>
      </div>}
      <div>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          {_.map(configuredPlans, (plan:Plan, index:number) => {
            const cardClassNames = classNames('pricing-card', {
              'pricing-lowest-plan': index === 0,
              'pricing-medium-plan': index === 1,
              'pricing-highest-plan': index === 2,
            })
            if (!plan.reveal) {
              return (
                <Col md={colSpan} sm={24} xs={24}>
                  <Card className={cardClassNames} title={plan.title}>
                    <div className="pricing-tier-details-comming-soon-container">
                      <p  className="pricing-tier-details">
                        Coming soon
                      </p>
                    </div>
                  </Card>
                </Col>
              );
            }
            const onDiscount = plan.discounted_amount >= 0;
            return (
              <Col md={colSpan} sm={24} xs={24}>
                <Card className={cardClassNames} title={plan.title}>
                  <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-monetary-value">${onDiscount ? plan.discounted_amount : plan.amount}</b>
                      {onDiscount && <span>&nbsp;<span className="pricing-tier-monetary-value-strikethrough">${plan.amount}</span></span>}
                      &nbsp;/ member / month
                    </p>
                  </div>
                  <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.team_count >= 0 ? plan.team_count : <span>&infin;</span>}</b> team
                    </p>
                  </div>
                  <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.member_count >= 0 ? plan.member_count : <span>&infin;</span>}</b> members
                    </p>
                  </div>
                  <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.board_count >= 0 ? plan.board_count : <span>&infin;</span>}</b> retrospective boards
                    </p>
                  </div>
                  {plan.goalgroup_count!=null && <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.goalgroup_count >= 0 ? plan.goalgroup_count : <span>&infin;</span>}</b> goal groups
                    </p>
                  </div>}
                  {plan.checkingroup_count!=null && <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.checkingroup_count >= 0 ? plan.checkingroup_count : <span>&infin;</span>}</b> daily check-in sessions
                    </p>
                  </div>}
                  <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.votingsession_count >= 0 ? plan.votingsession_count : <span>&infin;</span>}</b> scrum poker sessions
                    </p>
                  </div>
                  <div>
                    <p  className="pricing-tier-details">
                      <b className="pricing-tier-details-value">{plan.todo_count >= 0 ? plan.todo_count : <span>&infin;</span>}</b> active tasks
                    </p>
                  </div>
                  <br/>
                  {currentUser &&
                    <div>
                      {currentPlanIndex === index && <Button disabled>Current plan</Button>}
                      {currentPlanIndex < index && <Button type="primary" onClick={() => { onSelectPlanClicked(plan) }} loading={isSubmitting}>Upgrade</Button>}
                      {currentPlanIndex > index && <Button onClick={() => { onSelectPlanClicked(plan) }} loading={isSubmitting}>Switch</Button>}
                    </div>}
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Fragment>
  );
}

export default PlanList;
