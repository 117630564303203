import _ from 'lodash-es';
import React, { Fragment } from "react";
import { Card, Space, Input } from 'antd';
import { Todo } from '../../../../models';
import {TodoStatusTag} from '../../../boards/todos';
import { AssignmentDropdown } from '../../../common';

const { TextArea } = Input;

interface TodoCardProps {
  todo: Todo;
}

const TodoCard: React.FC<TodoCardProps> = ({ todo }) => {
  return (
    <Card className="todo-card">
        <Fragment>
          <div style={{ paddingRight: 28 }}>
            <TextArea className="todo-title" bordered={false} autoSize readOnly value={todo.content} />
          </div>
          <Space>
            <AssignmentDropdown
              assignable={todo}
              hideUsername={true}
            />
            <TodoStatusTag status={todo.status} />
          </Space>
        </Fragment>
    </Card>
  );
}

export default TodoCard;
