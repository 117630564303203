import _ from 'lodash-es';
import React, { Fragment } from "react";
import './LandingPage.less';
import '../modules/scrum-pokers/styles.less';
import { Card, Layout, Row, Col, Button, Space } from 'antd';
import heroImg from '../../resources/images/hero-img.png';
import heroImgSmaller from '../../resources/images/hero-img-smaller.png';
import heroImgSmallest from '../../resources/images/hero-img-smallest.png';
import heroImgTiny from '../../resources/images/hero-img-tiny.png';
import retroboardImg from '../../resources/images/retroboard.png';
import todoImg from '../../resources/images/todo.png';
import teamImg from '../../resources/images/team.png';
import goalImg from '../../resources/images/goal-create-bg.jpg';
import dailyCheckinImg from '../../resources/images/daily-checkin.jpg';
import background from "../../resources/images/bg.png";
import blogCheckingImg from "../../resources/images/blog/checkin_1.png";
import blogRetro1Img from "../../resources/images/blog/retrospective_1.png";
import blogRetro2Img from "../../resources/images/blog/retrospective_2.png";
import { djangoPageRoutes } from '../const';
import { PlanList } from '../modules/common';

const { Content } = Layout;
const { Meta } = Card;

interface FeatureHighlightDefinition {
  image?: string;
  title: string;
  content: string;
  component?: any;
}
const featureHighlights:Array<FeatureHighlightDefinition> = [
  {
    image: retroboardImg,
    title: 'Retrospective board',
    content: 'Create retrospective board from multiple templates. Real time collaboration with votes and comments.',
  },
  {
    image: goalImg,
    title: 'Goal tracking',
    content: 'Track your way to success with our goal tracking app!',
  },
  {
    image: dailyCheckinImg,
    title: 'Daily check-in',
    content: "Boost team collaboration and productivity with our Daily Check-in feature. Streamline updates, identify blockers and analyse your team mood in minutes.",
  },
  {
    title: 'Planning poker',
    content: 'Gamify your task estimation experience. Individual\'s votes are revealed at the same time for more accuracy.',
    component: (
      <div>
        <Card className="voting-score-card landing-page-score-card landing-page-score-card-left">
          <div className="landing-voting-score-card-number">3</div>
        </Card> 
        <Card className="voting-score-card landing-page-score-card landing-page-score-card-center">
          <div className="landing-voting-score-card-number">5</div>
        </Card>
        <Card className="voting-score-card landing-page-score-card landing-page-score-card-right">
          <div className="landing-voting-score-card-number">8</div>
        </Card>
      </div>
    ),
  },
  {
    image: todoImg,
    title: 'Task tracking',
    content: 'Identify necessary action items from retrospective boards and track its progress with our task tracking tool.',
  },
  {
    image: teamImg,
    title: 'Team management',
    content: 'Add your team members to your team, and he/she will get access to all resources in the team, no URL sharing.',
  }
];

const FeaturesSection: React.FC<{}> = () => {
  return (
    <Fragment>
      <div className="landing-page-section-title">
        OUR FEATURES
      </div>
      <div className="landing-page-container">
        <Space style={{ width: '100%' }} size="large" direction="vertical">
          {_.map(featureHighlights, (featureHighlight:FeatureHighlightDefinition, index: number) => {
            if (index%2 === 0) {
              return (
                <Row style={{ width: '100%' }}>
                  <Col md={0} sm={24} xs={24} className="feature-highlight-img-container">
                    {featureHighlight.component ? featureHighlight.component : <img className="feature-highlight-img" src={featureHighlight.image} alt="retrospective board"/>}
                  </Col>
                  <Col md={12} className="feature-highlight-text-container feature-highlight-text-left">
                    <p className="feature-highlight-title">
                      { featureHighlight.title }
                    </p>
                    <p>{ featureHighlight.content }</p>
                  </Col>
                  <Col md={12} sm={0} xs={0} className="feature-highlight-img-container">
                    {featureHighlight.component ? featureHighlight.component : <img className="feature-highlight-img" src={featureHighlight.image} alt="retrospective board"/>}
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row style={{ width: '100%' }}>
                  <Col md={12} className="feature-highlight-img-container feature-highlight-img-container-right">
                    {featureHighlight.component ? featureHighlight.component : <img className="feature-highlight-img" src={featureHighlight.image} alt="retrospective board"/>}
                  </Col>
                  <Col md={12} className="feature-highlight-text-container feature-highlight-text-right">
                    <p className="feature-highlight-title">
                    { featureHighlight.title }
                    </p>
                    <p>{ featureHighlight.content }</p>
                  </Col>
                </Row>
              );
            }
          })}
        </Space>
      </div>
    </Fragment>
  );
}

const PricingSection: React.FC<{}> = () => {
  return (
    <Fragment>
      <div className="landing-page-section-title">
        PRICING
      </div>
      <div className="landing-page-container">
        <PlanList />
      </div>
    </Fragment>
  )
}

const BlogSection: React.FC<{}> = () => {
  const articles = [
    {
      title: "Your Daily Stand-Up Meeting Should Not Feel Like This",
      description: `🤔 Does your team feel that the stand-up meeting is a way of micromanagement? Do they consider it a boring recurring activity where they simply report what they did? If any of these sentiments resonate with your team, it's time to reflect on whether you might be doing something wrong.`,
      img: blogCheckingImg,
      href: 'https://www.linkedin.com/pulse/your-daily-stand-up-meeting-should-feel-like-teamkitapp/'
    },
    {
      title: "Retrospectives you might be doing wrong",
      description: `It's Friday, and as we wrap up another week of hard work, it's time for a Retrospective session. 🚀 But before you dive into reflecting on your long weeks, are you falling into any of the following pitfalls? #scrum #retrospectives #retrospective #softwareengineering #softwareengineer #agile`,
      img: blogRetro1Img,
      href: 'https://www.linkedin.com/pulse/retrospectives-you-might-doing-wrong-teamkitapp/'
    },
    {
      title: "Unleashing the Power of Retrospectives: Key Discussion Areas for a Meaningful Reflection",
      description: `Have you ever experienced a retrospective session where your team sat in silence, unsure of what to discuss? 🤔 If so, we've got your back. Check this out to explore what you can prompt the team members to reflect on during the next Retrospectives session. 📚✨ #retrospectives #scrum #agile #team #softwareengineering #developer`,
      img:blogRetro2Img,
      href: 'https://www.linkedin.com/pulse/unleashing-power-retrospectives-key-discussion-areas-meaningful/'
    },
    {
      title: "Maximizing Growth and Collaboration: Some Tips on Running Effective Retrospective Sessions",
      description: `📚 Discover key tips and strategies for running an effective Retrospectives session to unlock your team's potential and foster continuous improvement. Check out the article now for actionable insights and take your retrospectives to the next level. #retrospectives #scrum #softwareengineer #team      `,
      img: blogRetro1Img,
      href: 'https://www.linkedin.com/pulse/maximizing-growth-collaboration-some-tips-running-effective/'
    }
  ];


  
  return (
    <Fragment>
      <div className="landing-page-section-title">
        BLOG
      </div>
      <div className="landing-page-container">
        <Row gutter={[16, 16]}>
          {_.map(articles, (article: any) => {
            return (
              <Col xl={8} sm={12} xs={24}>
                <Card
                  hoverable
                  cover={<img alt="LinkedInImage" src={article.img} />}
                  onClick={() => window.open(article.href, '_blank')}
                >
                  <Meta title={article.title} description={article.description} />
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Fragment>
  )
}

const introTextContent = (
  <Fragment>
    <p className="intro-text-headline-primary">
      All-in-one tool
    </p>
    <p className="intro-text-headline">
      for any Scrum team
    </p>
    <p className="intro-text-description">
      Retrospective, goal tracking, daily check-in, scrum poker, task management.<br/>Everything your team need in just one place.
    </p>
    <Button type="primary" className="get-started-btn" size="large" onClick={() => { window.location.href=djangoPageRoutes.signup }}>Sign up for Free</Button>
  </Fragment>
);

const LandingPage: React.FC<{}> = () => {
  return (
    <Content className="site-content" style={{margin: '0', background: 'white'}}>
      <Row className="landing-top-row" style={{ backgroundImage: `url(${background})` }}>
        <Col xxl={2} xs={0}>
        </Col>
        <Col xxl={6} md={8} xs={0}>
            <div className="intro-text-container">
              {introTextContent}
            </div>
        </Col>
        <Col xxl={14} md={16} sm={24}>
          <div className="intro-container" >
            <a
              className="landing-page-image-attribute-link"
              href="http://www.freepik.com"
              target="_blank"
              rel="noreferrer"
            >
              Designed by Freepik
            </a>
            <img src={heroImg} className="hero-img" alt="landing page cover" />
            <img src={heroImgSmaller} className="hero-img-smaller" alt="landing page cover" />
            <img src={heroImgSmallest} className="hero-img-smallest" alt="landing page cover" />
            <img src={heroImgTiny} className="hero-img-tiny" alt="landing page cover" />
          </div>
        </Col>
        <Col md={0} xs={24}>
            <div className="intro-text-container-sm">
              {introTextContent}
            </div>
        </Col>
      </Row>
      <br/>
      <FeaturesSection />
      <br/>
      <PricingSection />
      <br/>
      <BlogSection />
      <br/>
    </Content>
  );
}

export default LandingPage;
