import React from "react";
import { Row, Col, Card, Space, Button, Modal } from 'antd';
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getBasePathWithTeam } from '../../../helpers';
import { apiRoutes } from '../../../const';
import { del } from '../../../http';
import { Target, GoalWithTarget, User, GoalGroup } from '../../../models';
import TargetProgressBar from './TargetProgressBar';
import GoalProgressBar from './GoalProgressBar';
import './styles.less';

const { confirm } = Modal;

interface GoalCardProps extends RouteComponentProps<any> {
  goalGroup: GoalGroup;
  goalWithTarget: GoalWithTarget;
  currentUser: User;
  onDeleted: () => void;
}

const GoalCard: React.FC<GoalCardProps> = ({ goalWithTarget, goalGroup, currentUser, history, onDeleted }) => {
  const deleteGoal = async () => {
    await del(apiRoutes.goalSingle(goalWithTarget.goal.goal_group, goalWithTarget.goal.id));
  }

  const onDeleteClicked = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>Are you sure you want to delete the <b>{goalWithTarget.goal.title}</b> goal? The action is not reversible and all data will be lost.</span>,
      okType: 'danger',
      onOk: async () => {
        await deleteGoal();
        onDeleted();
      },
      onCancel: () => {},
    });
  }
  
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <div className="teamkit-display-flex">
            <div className="teamkit-flex-full">
              <Space direction="vertical" style={{ width: '100%' }}>
                <h3>{goalWithTarget.goal.title}</h3>
                {goalWithTarget.targets.length === 0 &&
                  <p className="goal-card-no-target-text"><i>This goal has no milestone yet. Add one to start tracking the progress</i></p>}
                {goalWithTarget.targets.map((target: Target) => {
                  return (
                    <Card>
                      <Row>
                        <Col lg={20} md={18} xs={24}>
                          {target.title}
                        </Col>
                        <Col lg={4} md={6} xs={24}>
                          <div style={{paddingLeft: '1em'}}>
                            <TargetProgressBar target={target} />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
                <div style={{textAlign: 'right'}}>
                  {!goalGroup.archived && goalWithTarget.goal.owner === currentUser.username && <><Button danger onClick={onDeleteClicked}>Delete</Button>&nbsp;&nbsp;</>}
                  <Button onClick={() => history.push(`${getBasePathWithTeam()}/goals/${goalWithTarget.goal.goal_group}/goals/${goalWithTarget.goal.id}`)}>View details <ArrowRightOutlined /></Button>
                </div>
              </Space>
            </div>
            <div className="goal-progress-container">
              <GoalProgressBar goal={goalWithTarget.goal} targets={goalWithTarget.targets} />
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default withRouter(GoalCard);
