import _ from 'lodash-es';
import React, { useEffect } from "react";
import './Feature.less';
import { Layout, Typography, Row, Col, Image } from 'antd';
import teamImg from '../../resources/images/tutorial-team-1-members.png';
import createBoardImg from '../../resources/images/tutorial-retro-1-create-board.png';
import itemImg from '../../resources/images/tutorial-retro-2-add-item.png';
import itemCommentImg from '../../resources/images/tutorial-retro-3-comment-like.png';
import createTodoImg from '../../resources/images/tutorial-retro-4-create-tasks.png';
import trackTodoImg from '../../resources/images/tutorial-task-1-track-with-status.png';

const { Paragraph } = Typography;

const { Content } = Layout;

interface HowToContent {
  title: string;
  img: string;
  imgClass?: string;
}
const FeatureRetrospective: React.FC<{}> = () => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = 'RetroPlus - How it works?';
    return () => {
      document.title = currentTitle;
    }
  }, []);

  const contents: Array<HowToContent> = [
    { title: '1. Setup your team', img: teamImg },
    { title: '2. Create retrospective board', img: createBoardImg },
    { title: '3. Add the items for discussion', img: itemImg },
    { title: '4. Vote or comment on the items', img: itemCommentImg, imgClass: "img-small" },
    { title: '5. Create tasks linked to the item', img: createTodoImg, imgClass: "img-small" },
    { title: '6. Track the tasks', img: trackTodoImg },
  ];

  return (
    <Content className="site-content">
      <div className="static-page-container" style={{ background: 'white' }}>
        <div className="site-content-inner">
          <Paragraph><h3>Retrospective</h3></Paragraph>
        </div>
        {_.map(contents, ((content: HowToContent) => (
          <div className="site-content-inner">
            <Row>
              <Col lg={6} md={24}>
                <h4>{content.title}</h4>
                <br/>
              </Col>
              <Col lg={18} md={24} className="howto-img-container">
                <Image
                  className={`landing-feature-img ${content.imgClass}`}
                  src={content.img}
                  alt="feature-img"
                />
              </Col>
            </Row>
          </div>
        )))}
      </div>
    </Content>
  )
}

export default FeatureRetrospective;
