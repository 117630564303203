import _ from 'lodash-es';
import React, { useContext, useEffect } from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserProfile } from '../../models';
import { apiRoutes } from '../../const';
import { isLiteMode } from '../../helpers';
import { get } from '../../http';
import { AppContext } from '../../../store';
import { UserOutlined } from '@ant-design/icons';

export const getAvatarClassname = (text?: string):string => {
  if (!text) {
    return "";
  }
  var hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash += text.charCodeAt(i);
  }

  const colorID = hash%8+1;
  return `avatar-${colorID}`;
}

interface UserAvatarProps {
  username?: string;
  user?: UserProfile;
  size?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ username, user, size }) => {
  const { state: { userProfiles }, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (username && !userProfiles[username] && !isLiteMode() && username !== 'public') {
      getUserProfile(username);
    }
  }, [username]);

  const getUserProfile = async (username: string) => {
    const data: UserProfile = await get(apiRoutes.userSingle(username));
    dispatch({
      userProfile: data
    });
    return data;
  }

  if (username === 'public' || (!username && !user)) {
    return (
      <Avatar size={size} icon={<UserOutlined />} />
    );
  }

  let userToDisplay = user || userProfiles[username];
  if (userToDisplay) {
    let avatar = <Avatar size={size} icon={<UserOutlined />} />;
    if (userToDisplay.profile_pic_url) {
      avatar = <Avatar size={size} src={userToDisplay.profile_pic_url} />;
    } else if (userToDisplay.username) {
      avatar = <Avatar size={size} className={getAvatarClassname(userToDisplay.username)}>{_.toUpper(userToDisplay.username.charAt(0))}</Avatar>;
    }
    return (
      <Tooltip placement="bottomLeft" title={userToDisplay.username}>
        {avatar}
      </Tooltip>
    );
  }

  if (username) {
    return (
      <Tooltip placement="bottomLeft" title={username}>
        <Avatar size={size} className={getAvatarClassname(username)}>{_.toUpper(username.charAt(0))}</Avatar>
      </Tooltip>
    );
  }

  return (
    <Avatar size={size} icon={<UserOutlined />} />
  );
}

export default UserAvatar;
