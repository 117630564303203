import _ from 'lodash-es';
import React, { useContext, useState } from "react";
import { apiRoutes, textAreaLength } from '../../../const';
import { Form, Space, Button, Input, Select } from 'antd';
import { post, put } from '../../../http';
import { getTodoCacheKey } from '../../../helpers';
import { Todo, TodoEvent, TodoEventType, User } from '../../../models';
import { AppContext } from '../../../../store';

const { Option } = Select;

interface TodoFormProps {
  boardID?: string;
  itemID?: string;
  teamID?: string;
  todo?: Todo;
  onFormSubmitted: (event: TodoEvent) => any;
  onCancel?: () => any;
  cancelOnCreate?: boolean;
  members?: Array<User>;
}

const TodoForm: React.FC<TodoFormProps> = ({ boardID, todo, teamID, itemID, cancelOnCreate, onFormSubmitted, onCancel, members }) => {
  const { state: { currentUser, liveEditingTodos }, dispatch } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const editCacheKey = getTodoCacheKey(itemID, todo?.id);
  const createTodo = async (values: any) => {
    const body = _.assign({}, values, {
      team: teamID ? teamID : null,
    });
    let url = apiRoutes.todosList;
    if (boardID) {
      if (itemID) {
        url = apiRoutes.boardItemTodoList(boardID, itemID);
      } else {
        url = apiRoutes.boardTodosList(boardID);
      }
    }
    await post(url, body)
    form.resetFields();
    onFormSubmitted({
      type: TodoEventType.Add,
      user: currentUser,
    });
  }

  const isEditMode = todo ? true : false;

  const onTodoContentChange = (e:any) => {
    // No need to set global state if in create mode or not in board live update mode (using socket)
    if (!boardID) {
      return;
    }
    const newContent:string = e.target.value;
    const updatedTodo = _.assign({}, liveEditingTodos[editCacheKey], {
      content: newContent,
    })
    dispatch({
      liveEditingTodos: _.assign({}, liveEditingTodos, {
        [editCacheKey]: updatedTodo,
      }) 
    })
  }

  const onFormSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      if (isEditMode) {
        await updateTodo(values);
      } else {
        await createTodo(values);
      }
      delete liveEditingTodos[editCacheKey];
      dispatch({ liveEditingTodos })
    } catch (error) {
      setIsSubmitting(false);
      throw(error);
    }
    setIsSubmitting(false);
  }

  const updateTodo = async (values: any) => {
    const body = _.assign({}, todo, {
      assignee: values.assignee,
      content: values.content,
    });
    const url = boardID ? apiRoutes.boardTodosSingle(boardID, todo?.id) : apiRoutes.todoSingle(todo?.id);
    await put(url, body);
    form.resetFields();
    onFormSubmitted({
      type: TodoEventType.Update,
      user: currentUser,
    });
  }

  if (liveEditingTodos[editCacheKey] && !liveEditingTodos[editCacheKey].assignee) {
    liveEditingTodos[editCacheKey].assignee = '';
  }

  if (todo && !todo.assignee) {
    todo.assignee = '';
  }

  return (
    <Form initialValues={liveEditingTodos[editCacheKey] ? liveEditingTodos[editCacheKey] : todo ? todo : {assignee: ''}} form={form} name={`todo-form-${todo?.id}`} onFinish={onFormSubmit}>
      <Form.Item
        name="content"
        rules={[{ required: true, message: 'Please input the content!' }]}
      >
        <Input.TextArea placeholder="Task title" maxLength={textAreaLength} rows={4} onChange={onTodoContentChange} />
      </Form.Item>
      {!isEditMode &&
        <Form.Item
          name="assignee"
        >
          <Select
            showSearch
            optionFilterProp="children"
            loading={members ? false : true}
          >
            { members && <Option value="">Unassigned</Option> }
            { _.map(members, (member:User) => {
              return (
                <Option value={member.username}>{member.username}</Option>
              );
            })}
          </Select>
        </Form.Item>}

      <Form.Item >
        {!isEditMode &&
          <Space>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Create task
            </Button>
            {cancelOnCreate && <Button onClick={onCancel ? onCancel : () => {}}>
              Cancel
            </Button>}
          </Space>}
        {isEditMode &&
          <Space>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Update task
            </Button>
            <Button onClick={onCancel ? onCancel : () => {}}>
              Cancel
            </Button>
          </Space>}
      </Form.Item>
    </Form>
  )
};

export default TodoForm;
