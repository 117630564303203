import _ from 'lodash-es';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useState, useEffect, useContext } from "react";
import { red, yellow, green, orange } from '@ant-design/colors';
import { Row, Col, Spin, Card, Space, Tooltip, Progress, Checkbox, Popconfirm, Collapse, Badge } from 'antd';
import { Comment } from '@ant-design/compatible';
import { get, put, del } from '../../http';
import CheckinForm from './CheckinForm';
import { apiRoutes } from '../../const';
import { UserAvatar, AssignmentDropdown } from '../common';
import { getCurrentTeam } from '../../helpers';
import { CheckinGroup, CheckinSession, CheckinItemType, CheckinItem, CheckinItemStatus, User, Comment as CommentModel } from '../../models';
import { CommentForm, CommentSingle} from '../comments';
import { AppContext } from '../../../store';
import { customIcons, maxMoodScore, moodTagFromScore } from './const';
import './styles.less';

const { Panel } = Collapse;


export const getStrokeColorFromMood = (moodPercent: number) => {
  const strokeColor = moodPercent <= 20 ? red.primary : moodPercent <= 40 ? orange.primary : moodPercent <= 60 ? yellow.primary : green.primary;
  return strokeColor;
}

interface CheckinGroupSummaryProps {
  checkinGroup: CheckinGroup;
  checkinSessions: CheckinSession[];
  members: Array<User>;
  hideTitle?: boolean;
}

const CheckinGroupSummary: React.FC<CheckinGroupSummaryProps> = ({ checkinGroup, members, checkinSessions, hideTitle }) => {
  const getAverageMood = () => {
    if (checkinSessions.length === 0) {
      return 0;
    }
    
    const moodSum = _.reduce(checkinSessions, (sum, session) => {
      return sum + session.mood_score;
    }, 0);

    return moodSum/checkinSessions.length;
  }

  const avgMoodScore = getAverageMood();
  const moodStrokeColor = getStrokeColorFromMood(100*getAverageMood()/maxMoodScore);
  const totalBlockerCount = _.reduce(checkinSessions, (sum, session) => {
    const blockerCount = _.reduce(session.checkinitem_set, (sum, item) => {
      return sum + (item.type === CheckinItemType.Blocker ? 1 : 0);
    }, 0);
    return sum + blockerCount;
  }, 0);
  const totalDoneBlockers = _.reduce(checkinSessions, (sum, session) => {
    const doneCount = _.reduce(session.checkinitem_set, (sum, item) => {
      return sum + (item.type === CheckinItemType.Blocker && item.status === CheckinItemStatus.Done ? 1 : 0);
    }, 0);
    return sum + doneCount;
  }, 0);
  const doneBlockersPercentage = totalBlockerCount === 0 ? 100 : 100*totalDoneBlockers/totalBlockerCount;

  return (
    <Card title={hideTitle ? undefined : <div className="teamkit-align-center">Team overview for {moment(checkinGroup.start_time).format('dddd, MMMM Do, YYYY')}</div>}>
      <Row>
        <Col span={8} className="teamkit-align-center">
          <Progress type="dashboard" percent={100*checkinSessions.length/members.length} format={() => `${checkinSessions.length}/${members.length}`} />
          <p>Members checked in</p>
        </Col>
        <Col span={8} className="teamkit-align-center">
          <Progress type="dashboard" percent={100*avgMoodScore/maxMoodScore}  format={(percent) => {
            if (checkinSessions.length === 0) {
              return '-';
            }
            if (customIcons[Math.round(avgMoodScore)]) {
              return <span style={{color: moodStrokeColor}}>{customIcons[Math.round(avgMoodScore)]}</span>;
            }
            return percent;
          }} strokeColor={moodStrokeColor} />
          <p>Average mood ({avgMoodScore.toFixed(2)})</p>
        </Col>
        <Col span={8} className="teamkit-align-center">
          <span style={{ fontSize: 42, color: getStrokeColorFromMood(doneBlockersPercentage) }}>{totalDoneBlockers}</span>
          <span style={{ fontSize: 76, color: getStrokeColorFromMood(doneBlockersPercentage) }}>/{totalBlockerCount}</span>
          <p>Blockers resolved</p>
        </Col>
      </Row>
    </Card>
  )
}

export default CheckinGroupSummary;
