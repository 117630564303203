import React from "react";
import goalCreateBg from '../../../../resources/images/goal-create-bg.jpg';
import { Card, Row, Col, Button } from 'antd';
import './styles.less';

interface FirstGoalCardProps {
  forWidget?: boolean;
  onCreateClicked: () => void;
}

const FirstGoalCard: React.FC<FirstGoalCardProps> = ({ forWidget, onCreateClicked }) => {
  const content = (
    <Row gutter={[16, 16]}>
      <Col sm={12} xl={10}>
        <div className="create-first-goal-bg-image-container">
          <img src={goalCreateBg} alt="Goal create"/>
          {!forWidget && <a
            className="card-image-attribute-link"
            href="http://www.freepik.com"
            target="_blank"
            rel="noreferrer"
          >
            Image designed by pch.vector / Freepik
          </a>}
        </div>
      </Col>
      <Col sm={12} xl={14}>
        <div>
          <p>
            Today is the perfect day to start working towards your dreams. No matter how big or small, every goal you set for yourself is a step towards the better.
          </p>
          <p>
            So, let's make the first step today!
          </p>
          <div>
            <Button type="primary" htmlType="submit" onClick={onCreateClicked}>
              Create your first goal
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );

  if (forWidget) {
    return content;
  }

  return (
    <Card className="create-first-goal-card">
      {content}
    </Card>
  );
}

export default FirstGoalCard;
