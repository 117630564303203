import _ from 'lodash-es';
import React, { useEffect, useState, useContext } from "react";
import './PromoBanner.less';
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { apiRoutes } from '../../const';
import { Plan } from '../../models';
import { get, put } from '../../http';
import { AppContext } from '../../../store';
import { appRoutes } from '../../const';
import { getBasePathWithTeam } from '../../helpers';

const PlanPromoBanner: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const [hideBanner, setHideBanner] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [configuredPlans, setConfiguredPlans] = useState<Array<Plan>>();
  const basePathWithTeam = getBasePathWithTeam();

  const fetchCurrentUser = async function() {
    const data = await get(apiRoutes.currentUser);
    dispatch({
      currentUser: data,
    });
  }

  const fetchPlans = async () => {
    const data = await get(apiRoutes.planList);
    setConfiguredPlans(data);
  }

  const onSelectPlanClicked = async (selectedPlan?: Plan) => {
    if (!selectedPlan) {
      return
    }
    try {
      setIsSubmitting(true);
      await put(apiRoutes.currentUserPlan, {
        plan: selectedPlan.id,
      });
    } finally {
      setIsSubmitting(false);
    }
    notification['info']({
      message: '',
      description: `Successfully switched to ${selectedPlan.title} plan`,
    });
    fetchCurrentUser();
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  if (hideBanner || !configuredPlans || !currentUser) {
    return null;
  }

  let currentPlanIndex = -1;
  _.each(configuredPlans, (plan:Plan, index:number) => {
    if (plan.id === currentUser.current_plan) {
      currentPlanIndex = index;
    }
  })

  let nextDiscountPlan:Plan|undefined = undefined;
  for (let i = currentPlanIndex+1; i < configuredPlans.length; i++) {
    if (configuredPlans[i].discounted_amount >= 0) {
      nextDiscountPlan = configuredPlans[i];
      break;
    }
  }

  if (!nextDiscountPlan) {
    return null;
  }
  
  return (
    <div className="promo-banner-top">
      We are running a full discount on Pro plan. <Button onClick={() => { onSelectPlanClicked(nextDiscountPlan) }} loading={isSubmitting}>Upgrade</Button> now to unlock more limits, it's free and <b>no card needed</b>, cancel anytime. Visit <Link to={appRoutes.profile(basePathWithTeam)}>here</Link> for more detail about the plans.
      <CloseOutlined className="close-icon" onClick={() => { setHideBanner(true) }}/>
    </div>
  );
}

export default withRouter(PlanPromoBanner);
