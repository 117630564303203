import _ from 'lodash-es';
import React, { useEffect } from "react";
import './Feature.less';
import { Layout, Typography, Row, Col, Image } from 'antd';
import teamImg from '../../resources/images/tutorial-team-1-members.png';
import createSessionImg from '../../resources/images/tutorial-poker-1-create.png';
import voteImg from '../../resources/images/tutorial-poker-2-vote.png';
import onlineStatusImg from '../../resources/images/tutorial-poker-3-online-status.png';
import resultsImg from '../../resources/images/tutorial-poker-4-results.png';

const { Paragraph } = Typography;

const { Content } = Layout;

interface HowToContent {
  title: string;
  img: string;
  imgClass?: string;
}
const FeatureScrumPoker: React.FC<{}> = () => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = 'RetroPlus - How it works?';
    return () => {
      document.title = currentTitle;
    }
  }, []);

  const contents: Array<HowToContent> = [
    { title: '1. Setup your team', img: teamImg },
    { title: '2. Create voting session, with different scoring strategies', img: createSessionImg },
    { title: '3. Everyone vote at the same time without seeing the vote of each other', img: voteImg },
    { title: '4. Easy tracking online status of team members', img: onlineStatusImg },
    { title: '5. Reveal results and check for the voting stats', img: resultsImg },
  ];

  return (
    <Content className="site-content">
      <div className="static-page-container" style={{ background: 'white' }}>
        <div className="site-content-inner">
          <Paragraph><h3>Scrum Poker</h3></Paragraph>
        </div>
        {_.map(contents, ((content: HowToContent) => (
          <div className="site-content-inner">
            <Row>
              <Col lg={6} md={24}>
                <h4>{content.title}</h4>
                <br/>
              </Col>
              <Col lg={18} md={24} className="howto-img-container">
                <Image
                  className={`landing-feature-img ${content.imgClass}`}
                  src={content.img}
                  alt="feature-img"
                />
              </Col>
            </Row>
          </div>
        )))}
      </div>
    </Content>
  )
}

export default FeatureScrumPoker;
