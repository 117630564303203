import { assign } from 'lodash-es';
import React from "react";
import './styles.less';
import { Input, Button, Form } from 'antd';
import { apiRoutes, mediumTextLength } from '../../const';
import { post } from '../../http';
import { VotingSession, VotingItemStatus } from '../../models';

interface VotingItemFormProps {
  votingSession: VotingSession;
  totalItems: number;
  onVotingItemChanged: (status:VotingItemStatus) => void;
  onCancelNewItem: () => void;
}

const VotingItemForm:React.FC<VotingItemFormProps> = ({ votingSession, totalItems, onVotingItemChanged, onCancelNewItem }) => {
  const createVotingItem = async function(values:any) {
    await post(apiRoutes.scrumPokerSessionItemList(votingSession.id), assign({}, values, {
      status: VotingItemStatus.Voting,
    }));
    onVotingItemChanged(VotingItemStatus.Voting);
  }

  return (
    <Form
      name="voting-item-form"
      className="voting-item-form"
      initialValues={{
        title: 'Free voting' + (totalItems > 0 ? ` ${totalItems}` : ''),
      }}
      onFinish={createVotingItem}
    >
      <Form.Item
        name="title"
        className="voting-item-title-input"
        rules={[{ required: true, message: 'Please input the voting item name!' }]}
      >
        <Input maxLength={mediumTextLength} />
      </Form.Item>
      <Button type="primary" htmlType="submit" style={{ marginRight: '6px' }}>
        Start Voting
      </Button>
      <Button onClick={onCancelNewItem}>
        Cancel
      </Button>
    </Form>
  );
}

export default VotingItemForm;
