import _ from 'lodash-es';
import moment from 'moment';
import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import './styles.less';
import { Row, Col, Button, Card, Spin, Space, Alert, Breadcrumb, Popover, Popconfirm, Collapse, Badge, Dropdown } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams as useRouterParams, withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { ItemForm, ItemCard } from './items';
import { PollCreateButton, PollCard } from '../polls';
import { KudoCreateButton, KudoMiniCard } from '../kudos';
import { InvitePopover } from '../common';
import BoardDetails from './BoardDetails';
import BoardColumnForm from './BoardColumnForm';
import BoardReportDrawer from './BoardReportDrawer';
import { apiRoutes, liteAppRoutes, colorSchemeClassMapping, MD_MIN_WIDTH } from '../../const';
import { useWindowDimensions } from '../../hooks';
import { get, post, put } from '../../http';
import { getCurrentPathWithNewTeamIndex, getBasePathWithTeam } from '../../helpers';
import { Board, BoardColumn, Item, Todo, User, Team, ItemEvent, TodoEvent, ItemEventType, InviteType, Poll, ItemType, PollEvent, Kudo, KudoEvent, KudoEventType } from '../../models';
import { AppContext } from '../../../store';
import KudoNotificationModal from '../kudos/KudoNotificationModal';
import { notifyItemEvent, notifyTodoEvent, notifyPollEvent, notifyKudoEvent } from './notification';

const { Panel } = Collapse;

const SOCKET_CONNECT_RETRY_TIMEOUT_MS = 3000;

const DEFAULT_PAGE_SIZE = 4;
const MD_MAX_WIDTH = 1200; 
const MD_PAGE_SIZE= 3;
const SM_MAX_WIDTH = 992;
const SM_PAGE_SIZE = 2;
const XS_MAX_WIDTH = 700;
const XS_PAGE_SIZE = 1;

interface ItemColumnProps {
  board: Board;
  column: BoardColumn;
  items?: Array<Item>;
  todos?: Array<Todo>;
  polls?: Array<Poll>;
  members?: Array<User>;
  currentUser: User;
  presentationMode: boolean;
  onItemRemoteChanged: (event:ItemEvent) => void;
  onPollUpdate: (event:PollEvent) => void;
  onBoardColumnChange: () => void;
  onTodoUpdate: (event:TodoEvent) => void;
}
const ItemColumn: React.FC<ItemColumnProps> = ({ board, column, items, todos, polls, members, currentUser, presentationMode, onItemRemoteChanged, onBoardColumnChange, onTodoUpdate, onPollUpdate }) => {
  const itemListRef = useRef(null)
  const [isEditingColumn, setIsEditingColumn] = useState<boolean>(false);
  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);

  useEffect(() => {
    let _items = _.sortBy(items, (i) => i.sort_index ? i.sort_index : moment(i.created_at).unix());
    setSortedItems(_items);
  }, [items]);

  const getTodosForItem = (itemID:string):Array<Todo> => {
    const itemTodos:Array<Todo> = [];
    _.each(todos, (todo:Todo) => {
      if (todo.item === itemID) {
        itemTodos.push(todo);
      }
    })
    return itemTodos;
  }

  const onColumnUpdated = () => {
    setIsEditingColumn(false);
    onBoardColumnChange();
  }

  const getListStyle = (isDraggingOver: any) => {
    return {
      background: isDraggingOver ? '#E1E3E6' : 'none',
      width: '100%',
      minHeight: (isDraggingOver && itemListRef.current) ? itemListRef.current.clientHeight : undefined,
    };
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    const styles = {
      userSelect: 'none',
      margin: '0 0 8px 0',
      ...draggableStyle
    };
    return styles;
  };

  const onDragEnd = async (result:any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    if (!sortedItems) {
      return;
    }

    let newItems = [];
    const item = sortedItems[source.index];
    for (let i = 0; i < sortedItems.length; i++) {
      if (i !== source.index) {
        newItems.push(sortedItems[i]);
      }
    }
    newItems.splice(destination.index, 0, item);

    let prevIndex = 0;
    if (destination.index > 0) {
      prevIndex = newItems[destination.index - 1].sort_index ? newItems[destination.index - 1].sort_index : moment(newItems[destination.index - 1].created_at).unix();
    }
    let nextIndex = -1;
    if (destination.index < newItems.length - 1) {
      nextIndex = newItems[destination.index+1].sort_index ? newItems[destination.index+1].sort_index : moment(newItems[destination.index+1].created_at).unix();
    }

    let newIndex = 0;
    if (nextIndex === -1) {
      newIndex = prevIndex + 1;
    } else {
      newIndex = (prevIndex + nextIndex) / 2;
    }

    sortedItems[source.index].sort_index = newIndex;
    setSortedItems(_.sortBy(sortedItems, (i) => i.sort_index ? i.sort_index : moment(i.created_at).unix()));

    await updateItemSortIndex(item, newIndex);
    
    onItemRemoteChanged({
      type: ItemEventType.Rearrange,
      user: currentUser,
    });
  }

  const updateItemSortIndex = async (item: Item, sortIndex: number) => {
    const itemModified = _.assign({}, item, {
      sort_index: sortIndex,
    });
    await put(apiRoutes.boardItemSingle(board.id, item?.id), itemModified);
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {!isEditingColumn && <Card className={classNames('column-card-title column-card-title-editable', {
        [`${colorSchemeClassMapping[column.color_scheme]}`]: column.color_scheme in colorSchemeClassMapping,
      })} onClick={() => { setIsEditingColumn(true) }}>
        {column.name}
        <EditOutlined />
      </Card>}
      {isEditingColumn && <BoardColumnForm
        column={column}
        onCancel={() => { setIsEditingColumn(false) }}
        onUpdateCompleted={onColumnUpdated}
      />}
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={column.id ? column.id : `${column.index}`}>
          {(provided:any, snapshot:any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="todo-col-droppable"
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div ref={itemListRef}>
                {sortedItems?.map((item:Item, index:number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided:any, snapshot:any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {(() => {
                          if (item.type === ItemType.Poll) {
                            const poll = polls?.find((p) => p.id === item.poll);
                            if (poll) {
                              return (
                                <PollCard
                                  poll={poll}
                                  board={board}
                                  item={item}
                                  viewOnly={presentationMode}
                                  dragHandleProps={provided.dragHandleProps}
                                  onPollUpdate={onPollUpdate}
                                />
                              );
                            }
                            return null;
                          }

                          return (
                            <ItemCard
                              key={`item-${item.id}`}
                              dragHandleProps={provided.dragHandleProps}
                              board={board}
                              item={item}
                              todos={getTodosForItem(item.id)}
                              members={members}
                              presentationMode={presentationMode}
                              onCardUpdate={onItemRemoteChanged}
                              onTodoUpdate={onTodoUpdate}
                            />
                          );
                        })()}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Space>
  );
};

interface ItemCreateCardProps {
  board: Board;
  boardColumnID: string;
  onItemRemoteChanged: (event:ItemEvent) => void;
}
const ItemCreateCard: React.FC<ItemCreateCardProps> = ({board, boardColumnID, onItemRemoteChanged}) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const onModificationCompleted = (event:ItemEvent) => {
    setShowForm(false);
    onItemRemoteChanged(event);
  }

  return (
    <div>
      {showForm &&
        <Card className="column-card-form">
          <ItemForm
            board={board}
            boardColumnID={boardColumnID}
            onModificationCompleted={onModificationCompleted}
            onCancel={() => setShowForm(false)}
          />
        </Card>}
      {!showForm && <Button style={{ width: '100%' }} onClick={() => setShowForm(true)}>Add item</Button>}
    </div>
  );
};

interface KudoRowProps {
  board: Board;
  kudos: Array<Kudo>;
  newKudoEvent?: KudoEvent;
  members: Array<User>;
  onKudoRemoteChanged: (event:KudoEvent) => void;
}

const KudoRow: React.FC<KudoRowProps> = ({ board, kudos, members, newKudoEvent, onKudoRemoteChanged }) => {
  const { width } = useWindowDimensions();

  let colCount = 1;
  let span = 24;
  if (width >= 1600) {
    colCount = 4;
    span = 6;
  } else if (width >= 1200) {
    colCount = 3;
    span = 8;
  } else if (width >= 768) {
    colCount = 2;
    span = 12;
  }

  return (
    <>
      {newKudoEvent && <KudoNotificationModal kudo={newKudoEvent.kudo} />}
      {kudos?.length ? <Card className="board-kudo-container-card">
        <Collapse ghost>
          <Panel header={<div>Kudos <Badge count={kudos?.length ?? 0} /></div>} key="1">
            <Row gutter={[16, 16]}>
              {[...Array(colCount).keys()].map((colIndex:number) => {
                return (
                  <Col span={span} key={`kudo-col-${colIndex}`}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {kudos && _.map(kudos, (kudo:Kudo,i:number) => {
                        if (i%colCount === colIndex) {
                          return (
                            <KudoMiniCard boardID={board.id} kudo={kudo} members={members} onKudoUpdate={onKudoRemoteChanged} />
                          );
                        }
                      })}
                    </Space>
                  </Col>
                );
              })}
            </Row>
          </Panel>
        </Collapse>
      </Card> : null}
    </>
  );
}

const BoardShow: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const boardID = useRouterParams<any>().id;
  const teamIndex = useRouterParams<any>().team_index;
  const { state: { currentUser, isUnAuthenticated }, dispatch } = useContext(AppContext);

  const [board, setBoard] = useState<Board>();
  const [items, setItems] = useState<Array<Item>>();
  const [todos, setTodos] = useState<Array<Todo>>();
  const [polls, setPolls] = useState<Array<Poll>>();
  const [kudos, setKudos] = useState<Array<Kudo>>();
  const [newKudoEvent, setNewKudoEvent] = useState<KudoEvent>();
  const [members, setMembers] = useState<Array<User>>();
  const [presentationMode, setPresentationMode] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);

  const { width } = useWindowDimensions();

  const [boardSessionID] = useState<number>(Math.floor( Math.random()*1000000))
  const [socket, setSocket] = useState<WebSocket>();
  const [socketHealthy, setSocketHealthy] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (isUnAuthenticated) {
      history.push(liteAppRoutes.board(boardID));
    }
  }, [isUnAuthenticated])

  const fetchBoard = async function(boardID:string) {
    const data = await get(apiRoutes.boardSingle(boardID));
    setBoard(data);
  }
  const fetchItems = async function(boardID:string) {
    const data = await get(apiRoutes.boardItemsList(boardID));
    setItems(data);
  }
  const fetchTodos = async function(boardID:string) {
    const data = await get(apiRoutes.boardTodosList(boardID));
    setTodos(data);
  }
  const fetchPolls = async function(boardID:string) {
    const data = await get(apiRoutes.pollList, {
      board: boardID,
      team: board?.team,
    });
    setPolls(data);
  }
  const fetchTeamMember = async function(board?:Board) {
    if (!board || !currentUser) {
      return;
    }
    let isInTeam = currentUser.team_membership.some((team:Team) => {
      return team.id === board.team
    });
    // If user is not in the team, no need to fetch member, he need to join first
    if (!isInTeam) {
      return
    }

    const data = await get(apiRoutes.teamMemberList(board.team));
    setMembers(data);
  }

  const connectSocket = ():WebSocket => {
    const socketProtocol = window.location.protocol.replace('http', 'ws')
    const endpoint = `${socketProtocol}//${window.location.host}/ws/board/${boardID}/`;
    const _socket = new WebSocket(endpoint);
    _socket.onopen = () => {
      setSocketHealthy(true);
      _socket.onclose = socketOnClose(_socket, true);
      setSocket(_socket);
      fetchItems(boardID);
      fetchTodos(boardID);
      fetchPolls(boardID);
      fetchKudos(boardID);
    };
    _socket.onmessage = socketOnMessage;
    _socket.onclose = socketOnClose(_socket, false);
    return _socket;
  }

  const socketOnMessage = (e:MessageEvent<any>) => {
    const data = JSON.parse(e.data);

    if (data?.board_session_id === boardSessionID) {
      // this message is emit by this component itself, no need to do anything
      return;
    }
    if (data?.change === 'item') {
      fetchItems(boardID);
      if (data?.event) {
        notifyItemEvent(data.event);
      }
    } else if (data?.change === 'todo') {
      fetchTodos(boardID);
      if (data?.event) {
        notifyTodoEvent(data.event);
      }
    } else if (data?.change === 'poll') {
      fetchItems(boardID);
      fetchPolls(boardID);
      if (data?.event) {
        notifyPollEvent(data.event);
      }
    } else if (data?.change === 'kudo') {
      fetchKudos(boardID);
      if (data?.event) {
        notifyKudoEvent(data.event);
        if (data.event.type === KudoEventType.Add && data.event.user !== currentUser?.username) {
          setNewKudoEvent(data.event);
        }
      }
    }
  }

  const socketOnClose = (_socket:WebSocket, connected:boolean) => {
    return () => {
      if (_socket) {
        _socket.onopen = null;
        _socket.onclose = null;
        _socket.onmessage = null;
      }
      setSocket(undefined);
      if (!connected) {
        // only set not healthy when the socket is never opened (connection error, not on disconnect). GCP auto disconnect socket conn every 30s, so conn close after open success is expected
        // see https://cloud.google.com/load-balancing/docs/https#websocket_support
        setSocketHealthy(false);
      }
      console.error('Web socket closed unexpectedly');
      setTimeout(() => {
        connectSocket();
      }, SOCKET_CONNECT_RETRY_TIMEOUT_MS)
    };
  };

  useEffect(() => {
    if (boardID) {
      fetchBoard(boardID);
      fetchItems(boardID);
      fetchTodos(boardID);
      fetchPolls(boardID);
      fetchKudos(boardID);

      const _socket = connectSocket();
      return () => {
        _socket.onopen = null;
        _socket.onclose = null;
        _socket.onmessage = null;
        _socket.close();
      }
    }
  }, [boardID]);

  useEffect(() => {
    fetchTeamMember(board);
  }, [currentUser, board]);

  useEffect(() => {
    dispatch({
      liveEditingItems: {},
      liveEditingTodos: {},
      liveEditingComments: {},
    });

    return () => {
      dispatch({
        liveEditingItems: {},
        liveEditingTodos: {},
        liveEditingComments: {},
      });
    }
  }, []);

  useEffect(() => {
    let newPageSize = DEFAULT_PAGE_SIZE;
    if (width < XS_MAX_WIDTH) {
      newPageSize = XS_PAGE_SIZE;
    } else if (width < SM_MAX_WIDTH) {
      newPageSize = SM_PAGE_SIZE;
    } else if (width < MD_MAX_WIDTH) {
      newPageSize = MD_PAGE_SIZE;
    }

    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setCurrentPage(1);
    }
  }, [width]);

  const onItemRemoteChanged = (event: ItemEvent) => {
    fetchItems(boardID);
    socket?.send(JSON.stringify({
      'change': "item",
      'board_session_id': boardSessionID,
      'event': event,
    }));
  }

  const onTodoRemoteChanged = (event: TodoEvent) => {
    fetchTodos(boardID);
    socket?.send(JSON.stringify({
      'change': "todo",
      'board_session_id': boardSessionID,
      'event': event,
    }));
  }

  const onPollRemoteChanged = (event: PollEvent) => {
    fetchPolls(boardID);
    fetchItems(boardID);
    socket?.send(JSON.stringify({
      'change': "poll",
      'board_session_id': boardSessionID,
      'event': event,
    }));
  }

  const fetchKudos = async function(boardID:string) {
    const data = await get(`${apiRoutes.kudoList}?board=${boardID}`);
    setKudos(data);
  }

  const onKudoRemoteChanged = (event: KudoEvent) => { 
    fetchKudos(boardID);
    socket?.send(JSON.stringify({
      'change': "kudo",
      'board_session_id': boardSessionID,
      'event': event,
    }));
  }

  if (!(board && currentUser)) {
    return (
      <Spin />
    );
  }

  const joinTeam = async function joinTeam(board:Board) {
    await post(apiRoutes.teamJoin(board.team), {
      invite_type: 'retroboard',
      invite_id: board.id,
    });
    refreshPage();
  }

  const refreshPage = () => {
    window.location.reload();
  };

  const intTeamIndex = parseInt(teamIndex);
  // Redirect if the tem index is not valid
  if (isNaN(intTeamIndex) || intTeamIndex < 0 || intTeamIndex >= currentUser.team_membership.length || currentUser.team_membership[intTeamIndex].id !== board.team) {
    let newTeamIndex = -1;
    currentUser.team_membership.forEach((team, i) => {
      if (team.id === board.team) {
        newTeamIndex = i;
      }
    });

    if (newTeamIndex >= 0) {
      history.push(getCurrentPathWithNewTeamIndex(newTeamIndex));
    } else {
      if (!board.public) {
        return (
          <div>
            Some error occurred
          </div>
        );
      }

      return (
        <div style={{textAlign: 'center'}}>
          <Card>
            <div>
              You are invited to access retrospective board  <span style={{ fontSize: '1.3em' }}>{ board.name }</span>. Join team to start collaborating in the retrospective board.
            </div>
            <br/>
            <div>
              <Button type="primary" onClick={() => { joinTeam(board) }}>Join team</Button>
            </div>
          </Card>
        </div>
      );
    }
  }

  // START RENDERING CALCULATION LOGIC, TO DETERMINE CAROUSEL PAGE RENDERING
  let totalCols = board.boardcolumn_set.length;
  let itemsByCols:{
    [boardcolumn_id:string]: Array<Item>,
  } = {};
  _.each(items, (item) => {
    if (!itemsByCols[item.board_column]) {
      itemsByCols[item.board_column] = []
    }
    itemsByCols[item.board_column].push(item);
  });

  const colSpan = 24/Math.min(pageSize, totalCols);
  const totalPages = Math.ceil(totalCols / pageSize);
  const paginatedBoardColumns:Array<Array<BoardColumn>> = [];
  for (let i = 0; i < totalPages; i++) {
    paginatedBoardColumns.push(board.boardcolumn_set.slice(i*pageSize, Math.min((i+1)*pageSize, totalCols)))
  }
  // END RENDERING CALCULATION LOGIC, TO DETERMINE CAROUSEL PAGE RENDERING

  return (
    <Fragment>
      <div>
        <Breadcrumb className="page-breadcrum">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a onClick={() => { history.push(`${getBasePathWithTeam()}/boards`) }}>Retrospective Boards</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{board.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {!socketHealthy &&
        <Row style={{ marginBottom: '15px' }}>
          <Col xs={24}>
            <Alert message="Lost connection to server, updates from other users will not be  reflected. Trying to reconect ..." type="warning" />
          </Col>
        </Row>}
      <Row style={{ marginBottom: '15px' }}>
        <Col xs={24} className="teamkit-top-action-bar">
          <Space direction="horizontal">
            {width >= MD_MIN_WIDTH && <Button onClick={() => setShowReport(true)}>AI Analysis</Button>}
            {width >= MD_MIN_WIDTH && <PollCreateButton board={board} onCompleted={onPollRemoteChanged}/>}
            {width >= MD_MIN_WIDTH && <KudoCreateButton board={board} members={members} onCompleted={onKudoRemoteChanged}/>}
            {!presentationMode &&
              <Button onClick={() => setPresentationMode(!presentationMode)}>Presentation Mode</Button>}
            {presentationMode && 
              <Popconfirm
                key="end-presentation-mode"
                placement="bottomRight"
                title={"Are you sure you want to end presentation mode? Your identidy on sensitive items will be revealed"}
                onConfirm={()=>setPresentationMode(!presentationMode)}
                okText="Yes, reveal my identity"
                cancelText="No"
              >
                <Button danger>End Presentation Mode</Button>
              </Popconfirm>}
            {board.public && <Popover placement="bottomRight" content={<InvitePopover inviteType={InviteType.Board} inviteID={board.id} inviteResource="retrospective board" inviteResourceName={board.name} />} trigger="click">
              <Button type="primary">Share & Invite</Button>
            </Popover>}
            {width < MD_MIN_WIDTH && 
              <Dropdown
                menu={{ items: [
                  {
                    key: '1',
                    label: (
                      <a target="_blank" onClick={() => setShowReport(true)}>AI Analysis</a>
                    ),
                  },
                  {
                    key: '2',
                    label: (
                      <PollCreateButton asLink board={board} onCompleted={onPollRemoteChanged} />
                    ),
                  },
                  {
                    key: '3',
                    label: (
                      <KudoCreateButton asLink board={board} members={members} onCompleted={onKudoRemoteChanged} />
                    ),
                  }
                ]}}
                placement="bottomRight"
                arrow
              >
                <Button><EllipsisOutlined/></Button>
              </Dropdown>}
          </Space>
        </Col>
      </Row>
      {presentationMode &&
        <Row style={{ marginBottom: '15px' }}>
          <Col xs={24}>
            <Alert message="You are in presentation mode. You can share your screen without revealing your identity in sensitive items" type="info" showIcon />
          </Col>
        </Row>}
      <Row style={{ marginBottom: '8px' }}>
        <Col xs={24}>
          <BoardDetails board={board} onUpdateCompleted={() => { fetchBoard(boardID) }} />
        </Col>
      </Row>
      <KudoRow board={board} kudos={kudos} newKudoEvent={newKudoEvent} members={members} onKudoRemoteChanged={onKudoRemoteChanged} />
      {totalPages > 1 &&
        <div className="teamkit-align-center" style={{ margin: '1.5em 0em 1em' }}>
          {_.map(Array(totalPages), (_, i) => (
              <Button
                key={`page-${i}`}
                className="board-pagination-button"
                type={i === currentPage-1 ? "primary" : "default"}
                style={{ marginRight: i < totalPages-1 ? '0.5em' : '0em' }}
                onClick={() => setCurrentPage(i+1)}
              >
                {i+1}
              </Button>
            ))}
        </div>}
        <Row gutter={[16, 16]}>
          {_.map(paginatedBoardColumns[currentPage-1], (boardColumn:BoardColumn) => {
            return (
              <Col key={`column-${boardColumn.id}`} xs={colSpan}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <ItemColumn
                    board={board}
                    column={boardColumn}
                    items={itemsByCols[boardColumn.id ?? '']}
                    todos={todos}
                    polls={polls}
                    members={members}
                    currentUser={currentUser}
                    presentationMode={presentationMode}
                    onItemRemoteChanged={onItemRemoteChanged}
                    onBoardColumnChange={() => { fetchBoard(boardID) }}
                    onTodoUpdate={onTodoRemoteChanged}
                    onPollUpdate={onPollRemoteChanged}
                  />
                  {!presentationMode && <ItemCreateCard
                    board={board}
                    boardColumnID={boardColumn.id ?? ''}
                    onItemRemoteChanged={onItemRemoteChanged}
                  />}
                </Space>
              </Col>
            );
          })}
        </Row>
        <BoardReportDrawer
          visible={showReport}
          board={board}
          items={items}
          onDrawerClosed={() => { setShowReport(false) }}
        />
    </Fragment>
  );
}

export default withRouter(BoardShow);
