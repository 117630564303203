import _ from 'lodash-es';
import React, { Fragment, useContext } from "react";
import '../todos/styles.less';
import { Card, Dropdown, Button, Menu, Space, Modal, Input } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { apiRoutes } from '../../../const';
import { User, Todo, TodoEvent, TodoEventType } from '../../../models';
import { AppContext } from '../../../../store';
import { del, put } from '../../../http';
import { getTodoCacheKey } from '../../../helpers';
import TodoForm from '../todos/TodoForm';
import TodoStatusTag from '../todos/TodoStatusTag';
import { AssignmentDropdown } from '../../common';

const { confirm } = Modal;
const { TextArea } = Input;

interface TodoCardProps {
  boardID?: string;
  todo: Todo;
  members?: Array<User>;
  onRemoteChanged: (event:TodoEvent) => void;
}

const TodoCard: React.FC<TodoCardProps> = ({ boardID, todo, members, onRemoteChanged }) => {
  const { state: { liveEditingTodos, currentUser }, dispatch } = useContext(AppContext);
  const editCacheKey = getTodoCacheKey(undefined, todo.id);

  // editingTodo is the todo item that is modifying, the content might be different from what is in the server
  let editingTodo = liveEditingTodos[editCacheKey]

  const setEditMode = (isEditting:boolean) => {
    if (isEditting === false) {
      if (liveEditingTodos[editCacheKey]) {
        delete liveEditingTodos[editCacheKey];
        dispatch({ liveEditingTodos });
      }
    } else {
      dispatch({
        liveEditingTodos: _.assign({}, liveEditingTodos, {
          [editCacheKey]: todo,
        }),
      });
    }
  }

  const onDeleteClicked = (todoID:string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete?',
      onOk: async () => {
        await deleteTodo(todoID);
      },
      onCancel: () => {},
    });
  }

  const deleteTodo = async function(todoID:string) {
    await del(apiRoutes.todoSingle(todoID));
    onRemoteChanged({
      type: TodoEventType.Delete,
      user: currentUser,
    });
  }

  const updateTodo = async function(todoID:string, values:any, event:TodoEvent, cb?: () => void) {
    await put(apiRoutes.todoSingle(todoID), values);
    if (cb) {
      cb();
    }
    onRemoteChanged(event);
  }

  const onEditClicked = () => {
    setEditMode(true);
  }

  const onUpdateCompleted = (event:TodoEvent) => {
    setEditMode(false);
    onRemoteChanged(event);
  }

  const changeAssignee = async (value:string) => {
    await updateTodo(todo.id, _.assign({}, todo, {
      assignee: value,
    }), {
      type: value === '' ? TodoEventType.Unassign : TodoEventType.Assign,
      user: currentUser,
    })
  }

  const menuItems = [
    <Menu.Item key="item-edit" onClick={onEditClicked}>Edit</Menu.Item>,
    <Menu.Item key="item-delete" onClick={()=>{onDeleteClicked(todo.id)}}>Delete</Menu.Item>,
  ];
  
  return (
    <Card className="todo-card">
      {editingTodo && 
        <TodoForm
          boardID={boardID}
          todo={editingTodo}
          onFormSubmitted={ onUpdateCompleted }
          onCancel={() => { setEditMode(false) }}
          members={members}
        />}
      {!editingTodo && 
        <Fragment>
          <Dropdown overlay={<Menu>{menuItems}</Menu>}>
            <Button size="small" className="todo-card-action-btn">
              <EllipsisOutlined key="ellipsis" />
            </Button>
          </Dropdown>
          <div style={{ paddingRight: 28 }}>
            <TextArea className="todo-title" bordered={false} autoSize readOnly value={todo.content} />
          </div>
          <Space>
            <AssignmentDropdown
              assignable={todo}
              members={members}
              changeAssignee={changeAssignee}
              hideUsername={true}
            />
            <TodoStatusTag status={todo.status} />
          </Space>
        </Fragment>}
    </Card>
  );
}

export default TodoCard;
