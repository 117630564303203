import _ from 'lodash-es';
import React, { Fragment, useContext, useState } from "react";
import './styles.less';
import { apiRoutes, textAreaLength } from '../../../const';
import { Form, Button, Input, Select } from 'antd';
import { post, put } from '../../../http';
import { getItemCacheKey } from '../../../helpers';
import { Item, ItemEvent, ItemEventType, Board, BoardPostMode } from '../../../models';
import { AppContext } from '../../../../store';

const { Option } = Select;

interface ItemFormProps {
  item?: Item;
  board: Board;
  boardColumnID: string;
  onModificationCompleted: (event:ItemEvent) => void;
  onCancel?: () => any;
}

const ItemForm: React.FC<ItemFormProps> = ({ board, item, boardColumnID, onModificationCompleted, onCancel }) => {
  const { state: { currentUser, liveEditingItems }, dispatch } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();
  const isEditMode = item ? true : false;

  const editCacheKey = getItemCacheKey(item?.id);
  const onItemContentChange = (e:any) => {
    if (!isEditMode) {
      return;
    }
    const newContent:string = e.target.value;
    const updatedItem = _.assign({}, liveEditingItems[editCacheKey], {
      content: newContent,
    });
    dispatch({
      liveEditingItems: _.assign({}, liveEditingItems, {
        [editCacheKey]: updatedItem,
      })
    });
  }

  const onFormSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { content, anonymous } = values;
    const body = {
      content: content,
      anonymous: anonymous ? true : false
    };
    try {
      if (isEditMode) {
        await updateItem(body);
      } else {
        await createItem(body);
      }
    } catch (error) {
      setIsSubmitting(false);
      throw(error);
    }
    setIsSubmitting(false);
  }

  const updateItem = async (values: any) => {
    const itemModified = _.assign({}, item, {
      content: values.content,
      anonymous: values.anonymous,
    });
    await put(apiRoutes.boardItemSingle(board.id, item?.id), itemModified);
    form.resetFields();
    onModificationCompleted({
      type: ItemEventType.Update,
      user: !values.anonymous ? currentUser : undefined
    });
  }

  const createItem = async (values: any) => {
    const newItem = _.assign({}, values, {
      board_column: boardColumnID,
    });
    await post(apiRoutes.boardItemsList(board.id), newItem);
    form.resetFields();
    onModificationCompleted({
      type: ItemEventType.Add,
      user: !values.anonymous ? currentUser : undefined
    });
  }

  let initialValue = {
    content: '',
    anonymous: 1,
  };
  if (item) {
    initialValue = {
      content: item.content,
      anonymous: item.anonymous ? 1 : 0
    }
  }
  return (
    <Form initialValues={initialValue} form={form} name={`item-form-${boardColumnID}-${item?.id}`} onFinish={onFormSubmit}>
      <Form.Item
        name="content"
        rules={[{ required: true, message: 'Please input the content!' }]}
      >
        <Input.TextArea maxLength={textAreaLength} rows={4} onChange={onItemContentChange}/>
      </Form.Item>

      <div className="item-form-inline-action-container">
        {board.post_mode === BoardPostMode.All &&
          <Form.Item name="anonymous" className="anonymous-selector">
            <Select defaultValue={1} onChange={() => {}}>
              <Option value={1}>Anonymous</Option>
              <Option value={0}>As myself</Option>
            </Select>
          </Form.Item>}
        <Form.Item >
          {!isEditMode &&
            <>
              <Button type="primary" htmlType="submit" className="item-form-submit-button" loading={isSubmitting}>
                Add
              </Button>
              <Button onClick={onCancel ? onCancel : () => {}} className="item-form-submit-button">
                Cancel
              </Button>
            </>}
          {isEditMode &&
            <>
              <Button type="primary" htmlType="submit" className="item-form-submit-button" loading={isSubmitting}>
                Update
              </Button>
              <Button onClick={onCancel ? onCancel : () => {}} className="item-form-submit-button">
                Cancel
              </Button>
            </>}
        </Form.Item>
      </div>
    </Form>
  )
};

export default ItemForm;
