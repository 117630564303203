import { createContext } from 'react';
import _ from 'lodash-es';
import { User, UserProfile, Item, Todo } from './components/models';

interface AppState {
    currentUser?: User;
    isUnAuthenticated?: boolean;

    // Below are cached data
    userProfiles: {
        [key: string] : UserProfile;
    };

    // Bellow are to save current eidting state for board editing. With live socket connection, maintaing editting state is a challenge
    liveEditingItems: {
        [key: string] : Item;
    };
    liveEditingTodos: {
        [key: string] : Todo;
    };
    liveEditingComments: {
        [key: string] : string;
    };
    drawerTodo?: Todo;
}

interface DispatchProps {
    currentUser?: User;
    isUnAuthenticated?: boolean;

    // Below are cached data
    userProfile?: UserProfile; // add 1 by 1 o the cache
    
    // Bellow are to save current eidting state for board editing. With live socket connection, maintaing editting state is a challenge
    liveEditingItems?: {
        [key: string] : Item;
    };
    liveEditingTodos?: {
        [key: string] : Todo;
    };
    liveEditingComments?: {
        [key: string] : string;
    };
    drawerTodo?: Todo;
}

export const initialState:AppState = {
    currentUser: undefined,
    isUnAuthenticated: undefined,
    userProfiles: {},
    liveEditingItems: {},
    liveEditingTodos: {},
    liveEditingComments: {},
    drawerTodo: undefined,
}

export const reducer = (state:AppState, dispatchProps:DispatchProps):AppState => {
    let userProfile = dispatchProps.userProfile;
    if (dispatchProps.userProfile) {
        delete dispatchProps.userProfile;
    }
    let newState = _.assign({}, state, dispatchProps);
    if (userProfile) {
        newState.userProfiles[userProfile.username] = userProfile;
    }
    if (dispatchProps.currentUser) {
        newState.userProfiles[dispatchProps.currentUser.username] = {
            username: dispatchProps.currentUser.username,
            profile_pic_url: dispatchProps.currentUser.user_config?.profile_pic_url,
        };
    }

    return newState;
}

interface AppContextProps {
    state: AppState;
    dispatch: React.Dispatch<DispatchProps>;
}
export const AppContext = createContext({} as AppContextProps);
