import _ from 'lodash-es';
import moment from 'moment-timezone';
import classNames from 'classnames';
import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Spin, Result, Card, Button, Space, Badge } from 'antd';
import { get } from '../../http';
import { getCurrentTeam, getBasePathWithTeam } from '../../helpers';
import CheckingGroupShow from './CheckingGroupShow';
import { apiRoutes } from '../../const';
import { CheckinGroup, Team, User } from '../../models';
import { useWindowDimensions } from '../../hooks';
import { AppContext } from '../../../store';
import DailyJournal from './DailyJournal';
import './styles.less';

const PAGE_SIZE = 5;
const SM_MAX_WIDTH = 992;

interface CheckinGroupSelectionCardProps {
  currentTeam: Team;
  checkinGroup: CheckinGroup;
  selected: boolean;
  style?: React.CSSProperties;
  showFullDetails?: boolean;
  onClick: (checkinGroup: CheckinGroup) => void;
}

const CheckinGroupSelectionCard: React.FC<CheckinGroupSelectionCardProps> = ({ currentTeam, checkinGroup, selected, style, showFullDetails, onClick }) => {
  let dateLocal = moment(checkinGroup.start_time).tz(currentTeam.timezone).format('YYYY-MM-DD');
  if (dateLocal === moment().tz(currentTeam.timezone).format('YYYY-MM-DD')) {
    dateLocal = 'latest';
  }
  return (
    <Card
      className={classNames('teamkit-small-listing-card checkin-group-select-card', {
        'active-checkin-group-select-card': selected,
        'checkin-group-select-card-minimized': !showFullDetails,
      })}
      style={style}
      onClick={() => onClick(checkinGroup)}
    >
      <b>{moment(checkinGroup.start_time).tz(currentTeam.timezone).format('YYYY-MM-DD')}</b>
      {showFullDetails && <p>{checkinGroup.checkin_session_count} checked in</p>}
    </Card>
  );
};

interface CheckinGroupListProps {}

const CheckinGroupList: React.FC<RouteComponentProps<CheckinGroupListProps>> = ({ history }) => {
  const location = useLocation();
  const { width } = useWindowDimensions();
  
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [members, setMembers] = useState<Array<User>>([]);

  const [checkinGroups, setCheckinGroups] = useState<Array<CheckinGroup>>();
  const [selectedCheckinGroup, setSelectedCheckinGroup] = useState<CheckinGroup>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDailyJournal, setShowDailyJournal] = useState(false);
  const queryParamDate = new URLSearchParams(location.search).get("date");
  
  useEffect(() => {
    if (currentTeam) {
      fetchCheckinGroups(currentTeam);
      fetchTeamMember(currentTeam);
    }
  }, [currentTeam]);

  useEffect(() => {
    if (currentTeam && checkinGroups && checkinGroups.length > 0) {
      if (queryParamDate) {
        let checkinGroup = checkinGroups[0]; // default to latest
        const selectedCheckinGroupIndex = _.findIndex(checkinGroups, (checkinGroup) => {
          return moment(checkinGroup.start_time).tz(currentTeam.timezone).format('YYYY-MM-DD') === queryParamDate;
        });
        if (selectedCheckinGroupIndex >= 0) {
          if (selectedCheckinGroupIndex < (currentPage-1) * PAGE_SIZE || selectedCheckinGroupIndex >= currentPage * PAGE_SIZE) {
            setCurrentPage(Math.ceil((selectedCheckinGroupIndex+1)/PAGE_SIZE));
          }
          checkinGroup = checkinGroups[selectedCheckinGroupIndex];
        } 
        setSelectedCheckinGroup(checkinGroup);
      } else {
        // no query param in filter
        history.replace({ pathname: location.pathname, search: new URLSearchParams({date: 'latest' }).toString() });       
        setSelectedCheckinGroup(checkinGroups[0]);
      }
    }
  }, [currentTeam, checkinGroups, queryParamDate]);

  const fetchTeamMember = async function(team:Team) {
    const data = await get(apiRoutes.teamMemberList(team.id));
    setMembers(data);
  }

  const fetchCheckinGroups = async (team: Team) => {
    const data: Array<CheckinGroup> = await get(apiRoutes.checkinGroupList, {
      team: team.id,
    });
    setCheckinGroups(data);
  }

  if (!currentUser || !currentTeam || !checkinGroups || !selectedCheckinGroup) {
    return <Spin />;
  }

  if (checkinGroups.length === 0) {
    return <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Button type="primary" onClick={() => {
        history.push(getBasePathWithTeam());
      }}>Back Home</Button>}
    />;
  }

  const getDateLocal = (checkinGroup: CheckinGroup):string => {
    let dateLocal = moment(checkinGroup.start_time).tz(currentTeam.timezone).format('YYYY-MM-DD');
    if (dateLocal === moment().tz(currentTeam.timezone).format('YYYY-MM-DD')) {
      dateLocal = 'latest';
    }

    return dateLocal;
  }

  const goToCheckinGroup = (checkinGroup: CheckinGroup) => {
    history.replace({ pathname: location.pathname, search: new URLSearchParams({date: getDateLocal(checkinGroup) }).toString() });   
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row gutter={[16, 16]} style={{ minWidth: 450, justifyContent: 'right' }}>
        <Button onClick={() => setShowDailyJournal(true)}>My daily journey</Button>
      </Row>
      <DailyJournal
        currentTeam={currentTeam}
        show={showDailyJournal}
        onClose={() => setShowDailyJournal(false)}
      />
      <Row gutter={[16, 16]} style={{ minWidth: 450 }}>
        {width > SM_MAX_WIDTH &&
          <Col xl={4} span={5}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {currentPage > 1 && <Button style={{ width: '100%' }} onClick={() => setCurrentPage(currentPage-1)}>Load previous&nbsp;<Badge count={(currentPage-1)*PAGE_SIZE} /></Button>}
              {checkinGroups.map((checkinGroup:CheckinGroup, i:number) => {
                if (i >= (currentPage-1)*PAGE_SIZE && i < currentPage*PAGE_SIZE) {
                  return (
                    <CheckinGroupSelectionCard
                      key={checkinGroup.id}
                      currentTeam={currentTeam}
                      checkinGroup={checkinGroup}
                      selected={(queryParamDate==='latest' && i === 0) || (getDateLocal(checkinGroup) === queryParamDate)}
                      onClick={() => goToCheckinGroup(checkinGroup)}
                      showFullDetails
                    />
                  );
                }
                return null
              })}
              {checkinGroups.length > currentPage*PAGE_SIZE && <Button style={{ width: '100%' }} onClick={() => setCurrentPage(currentPage+1)}>Load next&nbsp;<Badge count={checkinGroups.length - currentPage*PAGE_SIZE} /></Button>}
            </Space>
          </Col>
        }
        {width <= SM_MAX_WIDTH &&
          <Col span={24}>
            <Row className="teamkit-display-flex" gutter={[16, 16]}>
                {currentPage > 1 &&
                  <Card className="teamkit-small-listing-card checkin-group-select-card" style={{ marginRight: 6 }} onClick={() => setCurrentPage(currentPage-1)}>
                    {'<'}
                  </Card>}
                {checkinGroups.map((checkinGroup:CheckinGroup, i:number) => {
                  if (i >= (currentPage-1)*PAGE_SIZE && i < currentPage*PAGE_SIZE) {
                    return (
                      <CheckinGroupSelectionCard
                        key={checkinGroup.id}
                        currentTeam={currentTeam}
                        checkinGroup={checkinGroup}
                        selected={(queryParamDate==='latest' && i === 0) || (getDateLocal(checkinGroup) === queryParamDate)}
                        style={{ flex: 1, marginRight: 6}}
                        onClick={() => goToCheckinGroup(checkinGroup)}
                      />
                    );
                  }
                  return null
                })}
                {checkinGroups.length < currentPage*PAGE_SIZE &&
                  _.map(_.range(checkinGroups.length, currentPage*PAGE_SIZE), (i:number) => <div style={{ flex: 1 }}></div>)
                }
                {checkinGroups.length > currentPage*PAGE_SIZE &&
                  <Card className="teamkit-small-listing-card checkin-group-select-card" style={{ marginRight: checkinGroups.length > currentPage*PAGE_SIZE ? 6 : 0 }}onClick={() => setCurrentPage(currentPage+1)}>
                    {'>'}
                  </Card>}
            </Row>
          </Col>}
        <Col span={width > SM_MAX_WIDTH ? 19 : 24} xl={20}>
          <CheckingGroupShow
            members={members}
            checkinGroup={selectedCheckinGroup}
          />
        </Col>
      </Row>
    </Space>
  );
}

export default withRouter(CheckinGroupList);
