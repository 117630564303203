import _ from 'lodash-es';
import React, { useContext, useState } from "react";
import './styles.less';
import { apiRoutes, longTextLength } from '../../../const';
import { Form, Button, Input, Select, Space, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Comment } from '@ant-design/compatible';
import { Item, ItemEvent, ItemEventType, Board, Comment as CommentModel } from '../../../models';
import { AppContext } from '../../../../store';
import { UserAvatar } from '../../common';
import { put, del } from '../../../http';

const { TextArea } = Input;

interface EditableCommentProps {
  comment: CommentModel;
  board: Board;
  item: Item;
  presentationMode: boolean;
  onCommentDeleted: (event:ItemEvent) => void;
  onCommentEdited: (event:ItemEvent) => void;
}

const EditableComment: React.FC<EditableCommentProps> = ({ board, item, comment, presentationMode, onCommentDeleted, onCommentEdited}) => {
  const { state: { currentUser, liveEditingComments }, dispatch } = useContext(AppContext);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editDebounceTimer, setEditDebounceTimer] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const editCacheKey = `comment-${comment.id}`;

  const onFormSubmit = async (values:any) => {
    setIsSubmitting(true);
    let putUrl = apiRoutes.boardItemCommentSingle(board.id, item.id, comment.id);

    try {
      await put(putUrl, values);
    } catch (error) {
      setIsSubmitting(false);
      throw(error);
    }
    setIsSubmitting(false);
    form.resetFields();
    delete liveEditingComments[editCacheKey];
    dispatch({ liveEditingComments });
    setIsEditMode(false);
    onCommentEdited({
      type: ItemEventType.UpdateComment,
      user: currentUser
    });
  }

  const deleteComment = async (boardID: string, itemID: string, commentID: string) => {
    await del(apiRoutes.boardItemCommentSingle(boardID, itemID, commentID));
    onCommentDeleted({
      type: ItemEventType.RemoveComment,
      user: currentUser
    });
  }

  const onContentChange = (e:any) => {
    const newContent:string = e.target.value;
    dispatch({
      liveEditingComments: _.assign({}, liveEditingComments, {
        [editCacheKey]: newContent,
      })
    })
  }

  const debounce = (func: any, timeout = 1000) => {
    return (...args: any) => {
      if (editDebounceTimer) {
        clearTimeout(editDebounceTimer);
      };
      setEditDebounceTimer(setTimeout(() => { func.apply(this, args); }, timeout));
    };
  }

  const onCancelClicked = () => {
    form.resetFields();
    delete liveEditingComments[editCacheKey];
    dispatch({ liveEditingComments });
    setIsEditMode(false);
  }

  const onEditClicked = () => {
    liveEditingComments[editCacheKey] = comment.content;
    dispatch({ liveEditingComments });
    setIsEditMode(true);
  }

  const initialVal = {
    content: liveEditingComments[editCacheKey]
  };

  return (
    <>
      {isEditMode && <Comment
        style={{ marginTop: '8px' }}
        className="item-comment-form"
        avatar={
          <UserAvatar username={currentUser?.username} />
        }
        content={
          <Form initialValues={initialVal} form={form} name={`comment-form-${comment?.id}`} onFinish={onFormSubmit}>
            <Form.Item
              name="content"
              rules={[{ required: true, message: 'Please input the content!' }]}
            >
              <TextArea rows={2} maxLength={longTextLength} onChange={debounce(onContentChange)} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button htmlType="submit" loading={isSubmitting} onClick={() => {}} type="primary">
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
                <Button htmlType="submit" onClick={onCancelClicked}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        }
      />}
      {!isEditMode &&
        <Comment
          className="item-comment"
          avatar={<UserAvatar username={comment.owner} />}
          content={
            <div className="teamkit-display-flex">
              <p className="teamkit-flex-full">{comment.content}</p>
              {!presentationMode && (!comment.owner || comment.owner === currentUser?.username) &&
                <Space>
                  <Tooltip placement="bottom" title="Edit">
                    <span className="comment-reply-link" onClick={onEditClicked}><EditOutlined /></span>
                  </Tooltip>
                  <Popconfirm
                    key="delete"
                    placement="top"
                    title={"Are you sure you want to delete?"}
                    onConfirm={() => { deleteComment(board.id, item.id, comment.id) }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip placement="bottom" title="Delete">
                      <span className="comment-reply-link"><DeleteOutlined /></span>
                    </Tooltip>
                  </Popconfirm>
                </Space>}
            </div>
          }
        />}
    </>

  )
};

export default EditableComment;


