import { map } from 'lodash-es';
import React, { Fragment, useContext } from "react";
import './styles.less';
import classNames from 'classnames';
import { Card, Tag, Dropdown } from 'antd';
import { User, Todo, TodoLabel } from '../../../models';
import { AppContext } from '../../../../store';
import { AssignmentDropdown } from '../../common';
import TodoActionMenu from './TodoActionMenu';

interface TodoCardProps {
  boardID?: string;
  todo: Todo;
  members?: Array<User>;
  horizontal?:boolean;
  onRemoteChanged: () => void;
}

const TodoCard: React.FC<TodoCardProps> = ({ todo, members, horizontal, onRemoteChanged }) => {
  const { dispatch } = useContext(AppContext);

  const onCardClicked = () => {
    dispatch({
      drawerTodo: todo,
    });
  }

  return (
    <Dropdown overlay={<TodoActionMenu todo={todo} onRemoteChanged={onRemoteChanged} onDeleted={onRemoteChanged} />} trigger={['contextMenu']}>
      <Card 
        className={classNames('todo-card todo-card-clickable site-dropdown-context-menu', {
          'todo-card-horizontal': horizontal,
        })}
        onClick={onCardClicked}
      >
        {horizontal ? 
          <div className="teamkit-display-flex">
            <p className="todo-title teamkit-flex-full">{ todo.content }</p>
            <AssignmentDropdown
              hideUsername={true}
              assignable={todo}
              members={members}
            />
          </div> : 
          <Fragment>
            <p className="todo-title">{ todo.content }</p>
            <div style={{ marginBottom: 8 }}>
              {map(todo.todolabel_set, (label: TodoLabel) => <Tag className="todo-label-tag">{label.label}</Tag>)}
            </div>
            <div className="todo-assignee-status-row">
              <AssignmentDropdown
                assignable={todo}
                members={members}
              />
            </div>
          </Fragment>}
      </Card>
    </Dropdown>
  );
}

export default TodoCard;
