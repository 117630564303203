import _ from 'lodash-es';
import './Feature.less';
import React, { useEffect } from "react";
import { djangoPageRoutes } from '../const';
import { Header, Footer, PricingSection } from './LandingPageTailwinds';
import { FeatureDefinition, HowToContent, Feature } from './features';

interface ProductHighlightProps {
  featureDefinition: FeatureDefinition;
}

const ProductHighlight: React.FC<ProductHighlightProps> = ({ featureDefinition }) => {
  return (
    <div className="overflow-hidden py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {featureDefinition.title}
              </p>
              {featureDefinition.longDescription && <p className="mt-6 text-lg leading-8 text-gray-600">
                {featureDefinition.longDescription}
              </p>}
              <div className="mt-6 flex gap-x-6">
                <a
                  href={`${window.location.origin}${djangoPageRoutes.signup}`}
                  className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
          <img
            src={featureDefinition.image}
            alt="Product screenshot"
            className="w-[48rem] max-w-xl sm:max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
};

interface FeaturesProps {
  features: Array<Feature>;
}

const Features: React.FC<FeaturesProps> = ({ features }) => {
  return (
    <div className="py-24 px-6 m-auto max-w-7xl">
      <div className="text-center py-12 decoration-gray-800">
        <h3>FEATURES</h3>
      </div>
      <div className="grid lg:grid-cols-3 lg:gap-3 sm:grid-cols-2 sm:gap-2 grid-cols-1 gap-1">
          {features.map((feature) => (
            <div key={feature.name} className="text-base leading-7 text-gray-600">
              {feature.icon && <div>
                <feature.icon
                  className="m-auto h-10 w-10 text-indigo-600"
                  aria-hidden="true"
                />
              </div>}
              <div className="mt-4">
                <dt className="inline font-semibold text-gray-900">
                  {feature.name && feature.name}
                </dt>{" "}
                <dd className="inline">{feature.description}</dd>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export interface HowToProps {
  content: HowToContent;
}

export const HowTo: React.FC<HowToProps> = ({ content }) => {
  return(
    <div className="overflow-hidden py-12">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <div className="lg:pr-8 lg:pt-4 lg:col-span-2">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-lg font-bold tracking-tight text-gray-900">
                {content.title}
              </p>
              {content.description && <p className="mt-6 text-lg leading-8 text-gray-600">
                {content.description}
              </p>}
            </div>
          </div>
          <img
            src={content.img}
            alt="Product screenshot"
            className="sm:max-w-3xl rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
    </div>
  );
}

interface SignUpCTAProps {
  feature: FeatureDefinition;
}

const SignUpCTA: React.FC<SignUpCTAProps> = ({ feature }) => {
  return (
    <div className="mx-auto bg-gray-900">
        <div className="relative isolate overflow-hidden px-6 pt-16 sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0 m-auto max-w-7xl">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to dive in?
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Register now, it's free, no credit card needed!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href={`${window.location.origin}${djangoPageRoutes.signup}`}
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Sign Up
              </a>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src={feature.image}
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
  );
}

interface FeaturePageTailwindProps {
  pageTitle?: string;
  featureDefinition: FeatureDefinition;
}

const FeaturePageTailwind: React.FC<FeaturePageTailwindProps> = ({ featureDefinition }) => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = featureDefinition.title;
    return () => {
      document.title = currentTitle;
    }
  }, [featureDefinition]);

  return (
    <div className="bg-white font-sans">
      <Header />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <ProductHighlight featureDefinition={featureDefinition} />
      </div>
      <Features features={featureDefinition.features} />
      <div className="py-24 px-6 m-auto max-w-7xl">
        <div className="overflow-hidden text-center decoration-gray-800">
          <h3>SIMPLE & INTUITIVE</h3>
        </div>
        {_.map(featureDefinition.howtos, ((content: HowToContent) => (
          <HowTo content={content} />
        )))}
      </div>
      <PricingSection />
      <SignUpCTA feature={featureDefinition}/>
      <Footer />
    </div>
  );
}

export default FeaturePageTailwind;
