import React, { useState } from "react";
import './styles.less';
import { Form, Button, Space, Input, Popover } from 'antd';
import { Team, InviteType } from '../../../models';
import { InvitePopover } from '../../common';
import { apiRoutes, mediumTextLength } from '../../../const';
import { post } from '../../../http';

interface MemberFormProps {
  team: Team;
  onCreateComplete: () => void;
  showButtonNextRow?: boolean;
}
const MemberForm: React.FC<MemberFormProps> = ({ team, showButtonNextRow, onCreateComplete }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();
  const addMember = async (values:any) => {
    setIsSubmitting(true);
    try {
      await post(apiRoutes.teamMemberList(team.id), values);
    } finally {
      setIsSubmitting(false);
    }
    form.resetFields();
    onCreateComplete();
  };

  return (
    <Form
      form={form}
      name="member-form"
      className={showButtonNextRow ? "" : "teamkit-display-flex"}
      onFinish={addMember}
    >
      <Form.Item
        label="Email"
        name="email"
        className="teamkit-flex-full add-member-input"
        style={{ marginRight: '0px 15px 0px 0px' }}
        rules={[
          { required: true, message: 'Please input the email!' },
          { required: true, type: "email", message: "The input is not valid email!" },
        ]}
      >
        <Input placeholder="Enter new user email to add to team" maxLength={mediumTextLength} />
      </Form.Item>
      
      <Form.Item >
        <Space>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Add member
          </Button>
          <Popover placement="bottomRight" content={<InvitePopover inviteType={InviteType.Team} inviteID={team.id} inviteResource="team" inviteResourceName={team.name} />} trigger="click">
            <Button>Invite via link</Button>
          </Popover>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default MemberForm;
