import _ from 'lodash-es';
import './styles.less';
import React, { useContext, useEffect, useState } from "react";
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import goalGroupFormBg from '../../../resources/images/goal-group-create-bg.jpg';
import { Form, Input, Button, Card, Space, Spin, DatePicker, Select } from 'antd';
import { LockOutlined, TeamOutlined } from '@ant-design/icons';
import { apiRoutes, mediumTextLength } from '../../const';
import { GoalGroup, GoalGroupVisibility, User, Team, GoalGroupShare } from '../../models';
import { AppContext } from '../../../store';
import { get, post, put, del } from '../../http';
import { getCurrentTeam } from '../../helpers';
import { TimeStampFromPeriod } from './helpers';
import { useForceUpdate } from '../../hooks';

const MmomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = MmomentDatePicker;

interface GoalGroupFormProps {
  goalGroup?: GoalGroup;
  onCreateCompleted: (newID:string) => void;
  onCreateCancelled: () => void;
}

const GoalGroupForm: React.FC<GoalGroupFormProps> = ({ goalGroup, onCreateCompleted, onCreateCancelled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [currentTeamDetails, setCurrentTeamDetails] = useState<Team>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const forceUpdate = useForceUpdate();
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (currentTeam) {
      fetchTeamDetails(currentTeam.id)
    }
  }, [currentTeam]);

  const fetchTeamDetails = async (teamID:string) => {
    const data = await get(apiRoutes.teamSingle(teamID));
    setCurrentTeamDetails(data);
  }

  const isEditMode = () => {
    return goalGroup ? true : false;
  }

  const formSubmit = async (values: any) => {
    console.log(values);
    setIsSubmitting(true);
    let period = values.period;
    let shares = values.shares;
    values = _.omit(values, ['period', 'shares']);
    
    values =  _.assign({}, values, TimeStampFromPeriod(period));
    let updatedGoalGroup:GoalGroup;
    if (goalGroup) {
      updatedGoalGroup = await updateGoalGroup(goalGroup.id, _.assign({}, goalGroup, values));
    } else {
      updatedGoalGroup = await createGoalGroup(values);
    }
    await updateShareList(updatedGoalGroup, shares);
    setIsSubmitting(false);
    onCreateCompleted(updatedGoalGroup.id);
  }

  const createGoalGroup = async (values: any): Promise<GoalGroup> => {
    const data = await post(apiRoutes.goalGroupList, _.assign({}, values, {
      team: currentTeam?.id,
    }));
    return data;
  }

  const updateGoalGroup = async (goalGroupID:string, values: any): Promise<GoalGroup> => {
    const data = await put(apiRoutes.goalGroupSingle(goalGroupID), values)
    return data;
  }

  const updateShareList = async (updatedGoalGroup: GoalGroup, usernames: Array<string>) => {
    if (!usernames) {
      usernames = [];
    }
    if (goalGroup) {
      const currentShares = goalGroup.goalgroupshare_set;
      let toRemove: Array<string> = [];
      let toAdd: Array<string> = [];
      for (let share of currentShares) {
        if (!_.find(usernames, (username: string) => username === share.user)) {
          toRemove.push(share.id);
        }
      }
      for (let username of usernames) {
        if (!_.find(currentShares, (share: GoalGroupShare) => username === share.user)) {
          toAdd.push(username);
        }
      }
      await addShares(updatedGoalGroup.id, _.map(toAdd, (username:string) => {
        return {
          goalGroup: updatedGoalGroup.id,
          username,
        }
      }));
      await deleteShares(updatedGoalGroup.id, toRemove);
      return;
    }

    await addShares(updatedGoalGroup.id, _.map(usernames, (username) => {
      return {
        goalGroup: updatedGoalGroup.id,
        username,
      }
    }));
  }

  const addShares = async (goalGroupID: string, shares: Array<{goalGroup: string, username: string}>) => {
    for (let share of shares) {
      await post(apiRoutes.goalGroupShareList(goalGroupID), {
        goal_group: share.goalGroup,
        user: share.username,
      });
    }
  }

  const deleteShares= async (goalGroupID: string, ids: Array<string>) => {
    for (let id of ids) {
      await del(apiRoutes.goalGroupShareSingle(goalGroupID, id));
    }
  }

  const periodFromGoalGroup = (goalGroup: GoalGroup) => {
    if (!goalGroup.start_time || !goalGroup.end_time) {
      return null;
    }
    
    return [moment(goalGroup.start_time), moment(goalGroup.end_time)];
  }

  if (!currentUser) {
    return <Spin />
  }

  return(
    <Card>
      <Form
        form={form}
        name="goal-group-form"
        initialValues={_.assign({}, goalGroup, {
          period: goalGroup ? periodFromGoalGroup(goalGroup) : null,
          shares: goalGroup ? _.map(goalGroup.goalgroupshare_set, (share: GoalGroupShare) => share.user) : [],
        })}
        onFinish={formSubmit}
      >
        {!isEditMode() &&
          <div className="create-board-bg-image-container">
            <img src={goalGroupFormBg} className="create-board-bg-image" alt="Goal Group create"/>
            <a
              className="card-image-attribute-link"
              href="http://www.freepik.com"
              target="_blank"
              rel="noreferrer"
            >
              Image designed by pch.vector / Freepik
            </a>
          </div>}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the goal group name!' }]}
        >
          <Input placeholder="Eg: Engineering Goals 2023-H1, Personal Goals 2023 ..." maxLength={mediumTextLength} />
        </Form.Item>

        <Form.Item
          label="Visibility"
          name="visibility"
          rules={[{ required: true, message: 'Please input the visibility!' }]}
        >
          <Select
            options={[
              { value: GoalGroupVisibility.Private, label: <span><LockOutlined /> Only you and the people you share</span> },
              { value: GoalGroupVisibility.Public, label: <span><TeamOutlined /> Everyone in the team</span> },
            ]}
            onChange={forceUpdate}
          />
        </Form.Item>

        {form.getFieldValue('visibility') && form.getFieldValue('visibility') === GoalGroupVisibility.Private &&
          <Form.Item
            label="Shares"
            name="shares"
          >
            <Select
              mode="multiple"
              placeholder="Select members to share with"
              options={_.map(_.filter(currentTeamDetails?.members, (member:User) => member.username !== currentUser.username), (member: User) => {
                return { value: member.username, label: member.username };
              })}
            />
          </Form.Item>}

        <Form.Item
          label="Period"
          name="period"
        >
          <RangePicker picker="quarter" placeholder={["Start Quarter", "End Quarter"]} />
        </Form.Item>

        <Form.Item >
          <Space>
            <Button type="primary" disabled={isSubmitting} loading={isSubmitting} htmlType="submit">
              { isEditMode() ? 'Update' : 'Create'}
            </Button>
            <Button onClick={onCreateCancelled}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default GoalGroupForm;
