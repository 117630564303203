import React, { useContext } from "react";
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { Spin, Breadcrumb } from 'antd';
import { getBasePathWithTeam } from './helpers';
import { APP_BASE_PATH } from './const';
import { AppContext } from '../store';

import { VotingSessionList, VotingSessionCreate, VotingSessionShow } from './modules/scrum-pokers';

const ScrumPokerPage: React.FC<any> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);

  const onCreateCompleted = (newScumPokerID:string) => {
    history.push(`${getBasePathWithTeam()}/scrum-poker-sessions/${newScumPokerID}`);
  };

  return (
    <Switch>
      <Route path={[`${APP_BASE_PATH}t/:team_index/scrum-poker-sessions/:id`, `${APP_BASE_PATH}scrum-poker-sessions/:id`]}>
        { !currentUser && <Spin /> }
        { currentUser && <VotingSessionShow currentUser={currentUser} /> }
      </Route>
      <Route>
        <div>
          <Breadcrumb className="page-breadcrum">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Scrum Poker</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <VotingSessionCreate onCreateCompleted={onCreateCompleted}/>
        <VotingSessionList />
      </Route>
    </Switch>
  );
}

export default withRouter(ScrumPokerPage);
