import _ from 'lodash-es';
import React, { useEffect, useState } from "react";
import './styles.less';
import './loading-animation.less';
import { Row, Col, Card, Space, Button, Typography, Empty } from 'antd';
import { apiRoutes } from '../../const';
import { post, del, put } from '../../http';
import { UserAvatar } from '../common';
import { VotingSession, User, VotingItem, Vote, VotingItemStatus, ScoreCardOption, StoryPointOptions, EstimationUnit, GetHourOptions } from '../../models';
import VotingItemForm from './VotingItemForm';
import VotingMemberStatus from './VotingMemberStatus';
import { getAverageScoreFormatted } from './helpers';

const { Title } = Typography;

interface VotingPanelProps {
  currentUser: User;
  onlineMembers: { [key: string]: boolean };
  votingSession: VotingSession;
  votingItem?: VotingItem;
  totalItems: number;
  onVoteSubmitted: (votingItemID:string) => void;
  onVotingItemChanged: (status:VotingItemStatus) => void;
  cancelNewItem: () => void;
  members?: Array<User>;
}
const VotingPanel:React.FC<VotingPanelProps> = ({ currentUser, onlineMembers, votingSession, votingItem, totalItems, members, onVoteSubmitted, cancelNewItem, onVotingItemChanged }) => {
  const [selectingCard, setSelectingCard] = useState<number|undefined>(undefined);
  const [currentSubmittedVote, setCurrentSubmittedVote] = useState<Vote>();

  useEffect(() => {
    if (votingItem) {
      let submittedVote:Vote|undefined;
      for (let vote of votingItem.vote_set) {
        if (vote.owner === currentUser.username) {
          submittedVote = vote;
        }
      }
      setCurrentSubmittedVote(submittedVote);
    }
  }, [votingItem, currentUser]);

  let votedMembers:{ [key: string]: boolean } = {};
  if (votingItem) {
    for (let vote of votingItem.vote_set) {
      votedMembers[vote.owner] = true;
    }
  }
  let sortedMembers:Array<User> = members ? _.clone(members) : [];
  sortedMembers.sort((a:User, b:User):number => {
    if (votedMembers[a.username] !== votedMembers[b.username]) {
      return votedMembers[a.username] ? -1 : 1;
    }
    if (onlineMembers[a.username] !== onlineMembers[b.username]) {
      return onlineMembers[a.username] ? -1 : 1;
    }

    return a.username < b.username ? -1 : 1;
  });

  if (!votingItem) {
    return (
      <>
        <div className="voting-item-panel-header">
          <VotingItemForm
            totalItems={totalItems}
            votingSession={votingSession}
            onVotingItemChanged={onVotingItemChanged}
            onCancelNewItem={cancelNewItem}
          />
        </div>
        <Row className="voting-panel-member-status-row">
          <Space>
            {_.map(sortedMembers, (member:User) => {
              return (
                <VotingMemberStatus
                  key={`vote-status-${member.username}`}
                  isOnline={onlineMembers[member.username]}
                  username={member.username}
                />
              );
            })}
          </Space>
        </Row>
      </>
    );
  }

  const createVote = async function(score:number) {
    await post(apiRoutes.scrumPokerSessionItemVoteList(votingItem.id), {
      score: score,
    });
    setSelectingCard(undefined);
    onVoteSubmitted(votingItem.id);
  }

  const deleteVote = async function(id:string) {
    await del(apiRoutes.scrumPokerSessionItemVoteSingle(votingItem.id, id));
    onVoteSubmitted(votingItem.id);
  }

  const updateVotingItemStatus = async function(status:VotingItemStatus) {
    await put(apiRoutes.scrumPokerSessionItemSingle(votingItem.voting_session, votingItem.id), _.assign(votingItem, {
      status,
    }));
    onVotingItemChanged(status);
  }

  let scoreOptions = StoryPointOptions;
  if (votingSession.estimation_unit === EstimationUnit.Hour) {
    scoreOptions = GetHourOptions(votingSession.hours_per_day, votingSession.days_per_week);
  } else if (votingSession.estimation_unit === EstimationUnit.Freestyle) {
    let optionArr = JSON.parse(votingSession.freestyle_options);
    scoreOptions = _.map(optionArr, (str:string,idx:number) => ({ displayName: str, val: idx }))
  }

  if (votingItem.status === VotingItemStatus.Ended) {
    return (
      <>
        <div className="voting-item-panel-header">
          <span className="voting-item-title">{votingItem.title}</span>
        </div>
        <div style={{ marginTop: '12px' }}>
          <Row gutter={[16, 16]}>
            <Col xs={8} md={6} xl={4} >
              <Card className="voting-score-card voting-score-card-result">
                <span>AVG SCORE</span>
                <div className="voting-score-card-number">{getAverageScoreFormatted(votingSession, votingItem)}</div>
              </Card>
            </Col>
            <Col xs={16} md={18} xl={20} >
              {votingItem.vote_set.length === 0 && <Empty description={<span>No vote</span>}/>}
              <Space direction="vertical" style={{width: '100%'}}>
                {_.map(votingItem.vote_set, (vote:Vote) => {
                  let scoreCard:ScoreCardOption|undefined = _.find(scoreOptions, function(option:ScoreCardOption) { return option.val === vote.score });
                  return (
                    <div key={`voting-result-${vote.id}`} className="voting-result-row">
                      <div className="voting-result-names"><UserAvatar username={vote.owner} />&nbsp;&nbsp;{vote.owner}</div>
                      <div className="voting-result-score"><Title level={4} style={{ marginBottom: 0 }}>{scoreCard ? scoreCard.displayName : vote.score}</Title></div>
                    </div>
                  );
                })}
              </Space>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="voting-item-panel-header" style={{ display: 'flex' }}>
        <span className="voting-item-title" style={{ flex: 1}}>{votingItem.title}</span>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <Button type="primary" onClick={() => updateVotingItemStatus(VotingItemStatus.Ended)}>End</Button>
      </div>
      <Row className="voting-panel-member-status-row">
        <Space>
          {_.map(sortedMembers, (member:User) => {
            let hasVoted = false;
            for (let vote of votingItem.vote_set) {
              if (vote.owner === member.username) {
                hasVoted = true;
              }
            }

            return (
              <VotingMemberStatus
                key={`voting-status-active-${member.username}`}
                isOnline={onlineMembers[member.username]}
                username={member.username}
                isVoted={hasVoted}
              />
            );
          })}
        </Space>
      </Row>
      <Row gutter={[6, 6]} style={{ marginTop: 12 }}>
        {_.map(scoreOptions, (option:ScoreCardOption, index:number) => {
          if (!currentSubmittedVote && selectingCard === undefined) { // case no selecting card
            return (
              <Col key={`voting-card-${index}`} xs={12} sm={8} md={6} xxl={4}>
                <Card className="voting-score-card voting-score-card-clickable" onClick={() => setSelectingCard(option.val)}>
                  <div className="voting-score-card-number">{option.displayName}</div>
                </Card>
              </Col>
            );
          }

          if (currentSubmittedVote?.score !== option.val && selectingCard !== option.val) {
            const onClick = currentSubmittedVote ? () => {} : () => setSelectingCard(option.val);
            return (
              <Col key={`voting-card-${index}`} xs={12} sm={8} md={6} xxl={4}>
                <Card className="voting-score-card voting-score-card-inactive" onClick={onClick}>
                  <div className="voting-score-card-number">{option.displayName}</div>
                </Card>
              </Col>
            );
          }
          
          return (
            <Col key={`voting-card-${index}`} xs={12} sm={8} md={6} xxl={4}>
              <Card className="voting-score-card voting-score-card-active">
                <div className="voting-score-card-number">{option.displayName}</div>
                {!currentSubmittedVote && <Space>
                  <Button type="primary" size="small" className="vote-card-btn vote-confirm-btn" onClick={() => createVote(option.val)}>Vote</Button>
                  <Button size="small" onClick={() => setSelectingCard(undefined)}>Cancel</Button>
                </Space>}
                {currentSubmittedVote &&
                  <div>
                    <Button size="small" onClick={() => deleteVote(currentSubmittedVote?.id)}>Revoke vote</Button>
                  </div>}
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default VotingPanel;
