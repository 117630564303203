import _ from 'lodash-es';
import './styles.less';
import queryString from 'query-string';
import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Space, Card, Row, Col, Button, Empty, Breadcrumb, Spin, Popover, Badge } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import {
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import confetti from 'canvas-confetti';
import { apiRoutes } from './const';
import { get, put } from './http';
import { getCurrentTeam, getBasePathWithTeam } from './helpers';
import { TodoCard, TodoForm, TodoFilter } from './modules/boards/todos';
import { Team, Todo, TodoStatus, User, TodoLabel } from './models';
import { AppContext } from '../store';
import TodoDrawer from './modules/boards/todos/TodoDrawer';
import TodoCreateDrawer from './modules/boards/todos/TodoCreateDrawer';


const MIN_WIDTH = 1000;
const TodoPage: React.FC<RouteComponentProps<{}>> = ({ history, location }) => {
  const { state: { currentUser, drawerTodo }, dispatch } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [todoLoaded, setTodoLoaded] = useState<boolean>(false);
  const [backlogTodos, setBacklogTodos] = useState<Array<Todo>>([]);
  const [inProgressTodos, setInProgressTodos] = useState<Array<Todo>>([]);
  const [doneTodos, setDoneTodos] = useState<Array<Todo>>([]);
  const [canceledTodos, setCanceledTodos] = useState<Array<Todo>>([]);
  const [members, setMembers] = useState<Array<User>>();
  const [showCreateTask, setShowCreateTask] = useState<boolean>(false);

  const fetchTodos = async function(team:Team) {
    // get filter param from path
    let { labels, assignees } = queryString.parse(location.search);
    const data = await get(apiRoutes.todosList, {
      team: team.id,
      archived: false,
      label: labels,
      assignee: assignees,
    });

    const _backlogTodos: Todo[] = [];
    const _inProgressTodos: Todo[] = [];
    let _doneTodos: Todo[] = [];
    let _canceledTodos: Todo[] = [];
    for (let todo of data) {
      if (drawerTodo && drawerTodo.id === todo.id) {
        dispatch({
          drawerTodo: todo,
        });
      }
      if (todo.status === TodoStatus.Done) {
        _doneTodos.push(todo);
      } else if (todo.status === TodoStatus.InProgress) {
        _inProgressTodos.push(todo);
      } else if (todo.status === TodoStatus.Canceled) {
        _canceledTodos.push(todo);
      } else {
        _backlogTodos.push(todo);
      }
    }
    setBacklogTodos(_backlogTodos);
    setInProgressTodos(_inProgressTodos);
    setDoneTodos(_doneTodos);
    setCanceledTodos(_canceledTodos);
    setTodoLoaded(true);
  }

  const fetchTeamMember = async function(team:Team) {
    const data = await get(apiRoutes.teamMemberList(team.id));
    setMembers(data);
  }

  useEffect(() => {
    if (currentTeam) {
      fetchTodos(currentTeam);
      fetchTeamMember(currentTeam);
    }
  }, [currentTeam]);

  useEffect(() => {
    if (currentTeam) {
      fetchTodos(currentTeam);
    }
  }, [location, currentTeam]);

  if (!currentTeam || !todoLoaded) {
    return (
      <Spin />
    );
  }

  const markAsDone = async (todo: Todo, newSortScore?: number) => {
    const newToDo = _.assign({}, todo, {
      status: TodoStatus.Done,
    });
    if (newSortScore) {
      newToDo.sort_score = newSortScore;
    }
    await updateTodo(todo.id, newToDo, () => {
      confetti({
        particleCount: 60,
        spread: 100
      });
    });
  }

  // updateStatus does not have confetti, if mark to Done, use markAsDone
  const updateStatus = async (todo:Todo, newStatus:TodoStatus, newSortScore?: number) => {
    const newToDo = _.assign({}, todo, {
      status: newStatus,
    });
    if (newSortScore) {
      newToDo.sort_score = newSortScore;
    }
    await updateTodo(todo.id, newToDo);
  }

  const updateSortScore = async (todo:Todo, newSortScore: number) => {
    await updateTodo(todo.id, _.assign({}, todo, {
      sort_score: newSortScore,
    }));
  }

  const updateTodo = async function(todoID:string, values:any, cb?: () => void) {
    await put(apiRoutes.todoSingle(todoID), values);
    if (cb) {
      cb();
    }
  }

  const calculateScoreInsert = (todo: Todo, insertList: Array<Todo>, insertIndex: number): number => {
    if (insertIndex === 0) {
      if (insertList.length === 1) {
        return 1; // anything would do
      }
      return insertList[1].sort_score - 1;
    }

    if (insertIndex === insertList.length-1) {
      if (insertList.length === 1) {
        return 1; // anything would do, this won't happen anw
      }
      return insertList[insertIndex-1].sort_score + 1;
    }

    const nextTodoSortScore = insertList[insertIndex+1].sort_score;
    const prevTodoSortScore = insertList[insertIndex-1].sort_score;
    return (prevTodoSortScore+nextTodoSortScore)/2;
  }

  const insertToList = (todo:Todo, destination:{droppableId:string,index:number}, todoList:Array<Todo>): Todo[] => {
    const newTodoList: Array<Todo> = [];
    for (let i = 0; i < destination.index; i++) {
      newTodoList.push(todoList[i]);
    }
    newTodoList.push(todo);
    for (let i = destination.index; i < todoList.length; i++) {
      newTodoList.push(todoList[i]);
    }

    if (destination.droppableId === TodoStatus.Todo) {
      setBacklogTodos(newTodoList);
    } else if (destination.droppableId === TodoStatus.InProgress) {
      setInProgressTodos(newTodoList);
    } else if (destination.droppableId === TodoStatus.Done) {
      setDoneTodos(newTodoList);
    }

    return newTodoList;
  }

  const removeFromList = (source:{droppableId:string,index:number}, todoList:Array<Todo>): Todo[] => {
    const newTodoList = _.filter(todoList, function(todo: Todo, index: number) {
      return index !== source.index;
    });

    if (source.droppableId === TodoStatus.Todo) {
      setBacklogTodos(newTodoList);
    } else if (source.droppableId === TodoStatus.InProgress) {
      setInProgressTodos(newTodoList);
    } else if (source.droppableId === TodoStatus.Done) {
      setDoneTodos(newTodoList);
    }

    return newTodoList;
  }

  const onDragEnd = async (result:any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    let sourceTodo: Todo;
    let sourceTodoList:Array<Todo> = [];
    if (source.droppableId === TodoStatus.Todo) {
      sourceTodo = backlogTodos[source.index];
      sourceTodoList = backlogTodos;
    } else if (source.droppableId === TodoStatus.InProgress) {
      sourceTodo = inProgressTodos[source.index];
      sourceTodoList = inProgressTodos;
    } else if (source.droppableId === TodoStatus.Canceled) {
      sourceTodo = canceledTodos[source.index];
      sourceTodoList = canceledTodos;
    } else {
      sourceTodo = doneTodos[source.index];
      sourceTodoList = doneTodos;
    }

    let destinationTodoList = doneTodos;
    if (destination.droppableId === TodoStatus.Todo) {
      destinationTodoList = backlogTodos;
    } else if (destination.droppableId === TodoStatus.InProgress) {
      destinationTodoList = inProgressTodos;
    } else if (destination.droppableId === TodoStatus.Canceled) {
      destinationTodoList = canceledTodos;
    }
    
    // Case move between columns
    if (source.droppableId !== destination.droppableId) {
      let newStatus = TodoStatus.Todo;
      if (destination.droppableId === TodoStatus.InProgress) {
        newStatus = TodoStatus.InProgress;
      } else if (destination.droppableId === TodoStatus.Done) {
        newStatus = TodoStatus.Done;
      } else if (destination.droppableId === TodoStatus.Canceled) {
        newStatus = TodoStatus.Canceled;
      }
      
      sourceTodoList = removeFromList(source, sourceTodoList);
      destinationTodoList = insertToList(sourceTodo, destination, destinationTodoList);
      
      const newScore = calculateScoreInsert(sourceTodo, destinationTodoList, destination.index);
      try {
        if (newStatus === TodoStatus.Done) {
          await markAsDone(sourceTodo, newScore);
        } else {
          await updateStatus(sourceTodo, newStatus, newScore);
        }
      } finally {
        fetchTodos(currentTeam);
      }
    } else if (source.index !== destination.index) {
      
      const todoListWithElementRemoved = _.filter(destinationTodoList, function(todo: Todo, index: number) {
        return index !== source.index;
      });
      destinationTodoList = insertToList(sourceTodo, destination, todoListWithElementRemoved);
      const newScore = calculateScoreInsert(sourceTodo, destinationTodoList, destination.index);
      try {
        await updateSortScore(sourceTodo, newScore);
      } finally {
        fetchTodos(currentTeam);
      }
    }
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) =>({
    userSelect: 'none',
    margin: '0 0 8px 0',
    ...draggableStyle
  });

  const onTodoCreated = async () => {
    await fetchTodos(currentTeam);
    setShowCreateTask(false);
  }

  const getFilterCount = (): number => {
    let filterCount = 0;
    let { labels, assignees } = queryString.parse(location.search);
    if (labels) {
      filterCount++;
    }
    if (assignees) {
      filterCount++;
    }
    return filterCount
  }
  
  const getListStyle = (isDraggingOver: any) => {
    const maxListLength = _.max([0, backlogTodos.length, inProgressTodos.length, doneTodos.length]) || 0;
    const TEXT_LINE_PER_TODO = 2.5;
    return {
      background: isDraggingOver ? '#E1E3E6' : 'none',
      width: '100%',
      minHeight: `${maxListLength*TEXT_LINE_PER_TODO*3}em`
    };
  };

  const getDropableCol = (droppableId:string, todoList:Array<Todo>) => {
    return (
      <Droppable droppableId={droppableId}>
        {(provided:any, snapshot:any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="todo-col-droppable"
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {todoList.length === 0 && <Card className="todo-col-empty-card"><Empty /></Card>}
            {todoList.map((todo, index) => {
              return (
                <Draggable key={todo.id} draggableId={todo.id} index={index}>
                  {(provided:any, snapshot:any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <TodoCard
                        key={`todo-${todo.id}`}
                        todo={todo}
                        members={members}
                        onRemoteChanged={() => { fetchTodos(currentTeam) }}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  return (
    <>
      <div>
        <Breadcrumb className="page-breadcrum">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Tasks</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row style={{ marginBottom: '15px' }}>
        <Col xs={24} className="teamkit-top-action-bar">
          <Space direction='horizontal'>
            <Button type="primary" onClick={() => { setShowCreateTask(true) }}>Create task</Button>
            <Popover
              placement="bottomRight"
              content={
                <TodoFilter
                  visible={true}
                  members={members}
                />
              }
              trigger="click">
              <Badge count={getFilterCount()}>
                <Button>Filter</Button>
              </Badge>
            </Popover>
            <Button onClick={() => {history.push(`${getBasePathWithTeam()}/tasks/archived`)}}>
              <FieldTimeOutlined /> View Archived
            </Button>
          </Space>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row gutter={[16,16]} style={{ minWidth: `${MIN_WIDTH}px` }}>
          <Col xs={6}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="todo-lane-header todo-lane-header-not-started">
                <b>Not Started</b>
              </div>
              {getDropableCol(TodoStatus.Todo, backlogTodos)}
            </Space>
          </Col>
          <Col xs={6}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="todo-lane-header todo-lane-header-in-progress">
                <b>In Progress</b>
              </div>
              {getDropableCol(TodoStatus.InProgress, inProgressTodos)}
            </Space>
          </Col>
          <Col xs={6}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="todo-lane-header todo-lane-header-done">
                <b>Done</b>
              </div>
              {getDropableCol(TodoStatus.Done, doneTodos)}
            </Space>
          </Col>
          <Col xs={6}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="todo-lane-header todo-lane-header-canceled">
                <b>Canceled</b>
              </div>
              {getDropableCol(TodoStatus.Canceled, canceledTodos)}
            </Space>
          </Col>
        </Row>
        {drawerTodo && <TodoDrawer
          todo={drawerTodo}
          members={members}
          onRemoteChanged={() => { fetchTodos(currentTeam) }}
          visible={drawerTodo ? true : false}
          onHide={() => {}}
        />}
        {showCreateTask && <TodoCreateDrawer
          members={members}
          onFormSubmitted={() => { fetchTodos(currentTeam) }}
          visible={showCreateTask}
          onHide={() => setShowCreateTask(false)}
        />}
      </DragDropContext>
    </>
  );
};

export default withRouter(TodoPage);
