import React from "react";
import './Feature.less';
import FeaturePageTailwind from './FeaturePageTailwind';
import { featureDailyCheckin } from "./features";

const FeatureDailyCheckinTailwind: React.FC<{}> = () => {
  return (
    <FeaturePageTailwind featureDefinition={featureDailyCheckin} />
  );
}

export default FeatureDailyCheckinTailwind;
