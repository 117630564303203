import { BoardColumnColorScheme } from '../models';



export const publicApiRoutes:any =  {
  boardList: '/retroboard/public/boards/',
  boardSingle: (id:string) => `/retroboard/public/boards/${id}/`,
  boardColumnTemplatesList: '/retroboard/public/boardcolumn-templates/',
  boardItemsList: (boardID:string) => `/retroboard/public/boards/${boardID}/items/`,
  boardItemSingle: (boardID:string, itemID:string) => `/retroboard/public/boards/${boardID}/items/${itemID}/`,
  boardItemTodoList: (boardID:string, itemID:string) => `/retroboard/public/boards/${boardID}/items/${itemID}/todos/`,
  boardItemCommentList: (boardID:string, itemID:string) => `/retroboard/public/boards/${boardID}/items/${itemID}/comments/`,
  boardItemCommentSingle: (boardID:string, itemID:string, commentID: string) => `/retroboard/public/boards/${boardID}/items/${itemID}/comments/${commentID}/`,
  boardTodosList: (boardID:string) => `/retroboard/public/boards/${boardID}/todos/`,
  boardTodosSingle: (boardID:string,todoID:string) => `/retroboard/public/boards/${boardID}/todos/${todoID}/`,
  boardColumnSingle: (boardColumnID:string) => `/retroboard/public/boardcolumns/${boardColumnID}/`,
  boardPollsList: (boardID:string) => `/retroboard/public/boards/${boardID}/polls/`,
  inviteSingle: (id:string) => `/common/public/invites/${id}/`,
  kudoList: '/kudo/public/kudos/',
}

export const apiRoutes:any =  {
  boardList: '/retroboard/boards/',
  boardSingle: (id:string) => `/retroboard/boards/${id}/`,
  boardColumnTemplatesList: '/retroboard/boardcolumn-templates/',
  boardItemsList: (boardID:string) => `/retroboard/boards/${boardID}/items/`,
  boardItemSingle: (boardID:string, itemID:string) => `/retroboard/boards/${boardID}/items/${itemID}/`,
  boardItemTodoList: (boardID:string, itemID:string) => `/retroboard/boards/${boardID}/items/${itemID}/todos/`,
  boardItemCommentList: (boardID:string, itemID:string) => `/retroboard/boards/${boardID}/items/${itemID}/comments/`,
  boardItemCommentSingle: (boardID:string, itemID:string, commentID: string) => `/retroboard/boards/${boardID}/items/${itemID}/comments/${commentID}/`,
  boardTodosList: (boardID:string) => `/retroboard/boards/${boardID}/todos/`,
  boardTodosSingle: (boardID:string,todoID:string) => `/retroboard/boards/${boardID}/todos/${todoID}/`,
  boardReportList: (boardID:string) => `/retroboard/boards/${boardID}/reports/`,
  boardColumnSingle: (boardColumnID:string) => `/retroboard/boardcolumns/${boardColumnID}/`,
  todosList: '/retroboard/todos/',
  todoSingle: (todoID:string) => `/retroboard/todos/${todoID}/`,
  todoCommentList: (todoID:string) => `/retroboard/todos/${todoID}/comments/`,
  todoCommentSingle: (todoID:string, commentID: string) => `/retroboard/todos/${todoID}/comments/${commentID}/`,
  todoLabelList: (todoID:string) => `/retroboard/todos/${todoID}/labels/`,
  todoLabelSingle: (todoID:string, labelID: string) => `/retroboard/todos/${todoID}/labels/${labelID}/`,
  todoLabelListStandAlone: '/retroboard/todolabels/',
  itemLike: (itemID:string) => `/retroboard/items/${itemID}/like/`,
  itemDislike: (itemID:string) => `/retroboard/items/${itemID}/dislike/`,
  currentUser: '/common/users/current/',
  userSingle: (username:string) => `/common/users/${username}/`,
  teamList: '/common/teams/',
  teamSingle: (id:string) => `/common/teams/${id}/`,
  teamJoin: (id:string) => `/common/teams/${id}/join/`,
  teamMemberList: (id:string) => `/common/teams/${id}/members/`,
  teamRemoveMember: (id:string) => `/common/teams/${id}/remove/`,
  teamRoleChange: (id:string) => `/common/teams/${id}/role_change/`,
  teamLeave: '/common/teams/leave/',
  planList: '/plan/plans/',
  currentUserPlan: '/plan/userplans/current/',
  scrumPokerSessionList: '/scrum-poker/voting_sessions/',
  scrumPokerSessionSingle: (id:string) => `/scrum-poker/voting_sessions/${id}/`,
  scrumPokerSessionItemList: (votingSessionID:string) => `/scrum-poker/voting_sessions/${votingSessionID}/voting_items/`,
  scrumPokerSessionItemSingle: (votingSessionID:string, itemID:string) => `/scrum-poker/voting_sessions/${votingSessionID}/voting_items/${itemID}/`,
  scrumPokerSessionItemVoteList: (itemID:string) => `/scrum-poker/voting_items/${itemID}/votes/`,
  scrumPokerSessionItemVoteSingle: (itemID:string, voteID:string) => `/scrum-poker/voting_items/${itemID}/votes/${voteID}/`,
  goalGroupList: '/goal/goal_groups/',
  goalGroupSingle: (id:string) => `/goal/goal_groups/${id}/`,
  goalGroupShareList: (goalGroupID:string) => `/goal/goal_groups/${goalGroupID}/shares/`,
  goalGroupShareSingle: (goalGroupID:string,shareID:string) => `/goal/goal_groups/${goalGroupID}/shares/${shareID}/`,
  goalList: (goalGroupID:string) => `/goal/goal_groups/${goalGroupID}/goals/`,
  goalSingle: (goalGroupID:string, goalID:string) => `/goal/goal_groups/${goalGroupID}/goals/${goalID}/`,
  goalCommentList: (goalID:string) => `/goal/goals/${goalID}/comments/`,
  goalCommentSingle: (goalID:string, commentID:string) => `/goal/goals/${goalID}/comments/${commentID}/`,
  goalTargetList: (goalID:string) => `/goal/goals/${goalID}/targets/`,
  goalTargetSingle: (goalID:string, targetID:string) => `/goal/goals/${goalID}/targets/${targetID}/`,
  targetCommentList: (targetID:string) => `/goal/targets/${targetID}/comments/`,
  targetCommentSingle: (targetID:string, commentID:string) => `/goal/targets/${targetID}/comments/${commentID}/`,
  inviteList: '/common/invites/',
  inviteSingle: (id:string) => `/common/invites/${id}/`,
  inviteAccept: (id:string) => `/common/invites/${id}/accept/`,
  checkinGroupList: '/checkin/checkin-groups/',
  checkinSessionLatest: '/checkin/checkin-sessions/latest/',
  checkinSessionList: (checkinGroupID:string) => `/checkin/checkin-groups/${checkinGroupID}/checkin-sessions/`,
  checkinSessionSingle: (checkinGroupID:string, id:string) => `/checkin/checkin-groups/${checkinGroupID}/checkin-sessions/${id}/`,
  checkinSessionListMine: '/checkin/checkin-sessions/mine/',
  checkinItemList: (checkinSessionID:string) => `/checkin/checkin-sessions/${checkinSessionID}/checkin-items/`,
  checkinItemSingle: (checkinSessionID:string, id:string) => `/checkin/checkin-sessions/${checkinSessionID}/checkin-items/${id}/`,
  checkinSessionCommentList: (checkinSessionID:string) => `/checkin/checkin-sessions/${checkinSessionID}/comments/`,
  checkinSessionCommentSingle: (checkinSessionID:string, commentID:string) => `/checkin/checkin-sessions/${checkinSessionID}/comments/${commentID}/`,
  fileUploadPreSign: '/teamkit-go/api/v1/fileupload/pre-sign',
  pollList: '/engagement/polls/',
  pollSingle: (pollID: string) => `/engagement/polls/${pollID}/`,
  pollResponseList: (pollID: string) =>  `/engagement/polls/${pollID}/responses/`,
  pollResponseSingle: (pollID: string, responseID: string) =>  `/engagement/polls/${pollID}/responses/${responseID}/`,
  kudoList: '/kudo/kudos/',
  kudoSingle: (kudoID: string) => `/kudo/kudos/${kudoID}/`,
};

export const APP_BASE_PATH = '/app/';

export const appRoutes:any =  {
  boards: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/boards/`,
  board: (teamID:string, boardID: string) => `${APP_BASE_PATH}t/${teamID}/boards/${boardID}/`,
  home: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/home/`,
  goals: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/goals/`,
  teams: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/teams/`,
  checkins: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/checkins/`,
  scrumPokerSessions: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/scrum-poker-sessions/`,
  tasks: (teamID:string) => `${APP_BASE_PATH}t/${teamID}/tasks/`,
  teamsWithoutCurrentTeamID: `${APP_BASE_PATH}teams/`,
  profile: (basePath?:string) => basePath ? `${basePath}/profile/` : `${APP_BASE_PATH}profile/`,
  invite: (id:string) => `${APP_BASE_PATH}invites/${id}`,
};

export const liteAppRoutes:any =  {
  board: (boardID:string) => `${APP_BASE_PATH}lite/boards/${boardID}/`,
};

export const djangoPageRoutes:any = {
    login: '/accounts/login/',
    logout: '/accounts/logout/',
    changePassword: '/accounts/password/change/',
    signup: '/accounts/signup/',
};

export const colorSchemeClassMapping:any = {
  [BoardColumnColorScheme.Positive]: 'column-card-positive',
  [BoardColumnColorScheme.Positive1]: 'column-card-positive-1',
  [BoardColumnColorScheme.Negative]: 'column-card-negative',
  [BoardColumnColorScheme.Negative1]: 'column-card-negative-1',
  [BoardColumnColorScheme.Neutral]: 'column-card-neutral',
  [BoardColumnColorScheme.Neutral1]: 'column-card-neutral-1',
}

export const shortTextLength = 50;
export const mediumTextLength = 100;
export const longTextLength = 200;
export const textAreaLength = 500;

export const localStorageKeys = {
  loginRedirect: 'login_redirect',
  shownGoalReleaseAnnouncement: 'goal-release-announcement',
}


export { momentTimezones } from './timezone';

export const XS_MAX_WIDTH = 575; 
export const SM_MIN_WIDTH = 576;
export const SM_MAX_WIDTH = 767;
export const MD_MIN_WIDTH = 768;
export const MD_MAX_WIDTH = 991;
export const LG_MIN_WIDTH = 992;
export const LG_MAX_WIDTH = 1199;
export const XL_MIN_WIDTH = 1200;
export const XL_MAX_WIDTH = 1599;
export const XXL_MIN_WIDTH = 1600;
