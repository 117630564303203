import { VotingSession, VotingItem, EstimationUnit } from '../../models';

const formatNumber = (value:number,decimalPlace:number):number => {
  return +value.toFixed(decimalPlace);
}

export const getAverageScoreFormatted = (votingSession:VotingSession, votingItem:VotingItem):string => {
  if (votingSession.estimation_unit === EstimationUnit.Freestyle) {
    return '-';
  }
  let avgScore = -1;
  if (votingItem.vote_set.length > 0) {
    let sumScore = 0;
    let sumValidVotes = 0;
    for (let vote of votingItem.vote_set) {
      // Only count valid score
      if (vote.score >= 0 ) {
        sumScore += vote.score;
        sumValidVotes++;
      }
    }
    if (sumValidVotes > 0) {
      avgScore = sumScore / sumValidVotes;
    }
  }

  return getScoreFormatted(avgScore, votingSession);
}

export const getScoreFormatted = (score: number, votingSession:VotingSession):string => {
  if (score < 0) {
    return '?';
  }

  let scoreUnit = '';
  if (votingSession.estimation_unit === EstimationUnit.Hour) {
    let hours = score;
    if (hours < votingSession.hours_per_day) {
      scoreUnit = 'h';
    } else {
      let days = hours / votingSession.hours_per_day;
      if (days <= votingSession.days_per_week*2) {
        score = days;
        scoreUnit = 'd';
      } else {
        let weeks = days/votingSession.days_per_week;
        score = weeks;
        scoreUnit = 'w';
      }
    }
  } else if (votingSession.estimation_unit === EstimationUnit.Freestyle) {
    let options = JSON.parse(votingSession.freestyle_options)
    return options.length > score ? options[score] : '?';

  }

  return score >= 0 ? formatNumber(score,2)+scoreUnit : '?';
}
