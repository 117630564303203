import React, { useContext } from "react";
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { Spin, Breadcrumb } from 'antd';
import { getBasePathWithTeam } from './helpers';
import { APP_BASE_PATH } from './const';
import { AppContext } from '../store';

import { GoalGroupList, GoalGroupCreate, GoalGroupShow } from './modules/goals';
import { GoalShow, GoalCreate } from './modules/goals/goal';

const GoalPage: React.FC<any> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);

  const onCreateCompleted = (goalGroupID:string) => {
    history.push(`${getBasePathWithTeam()}/goals/${goalGroupID}`);
  };

  return (
    <Switch>
      <Route path={[`${APP_BASE_PATH}t/:team_index/goals/:id/create`, `${APP_BASE_PATH}goals/:id`]}>
        { !currentUser && <Spin /> }
        { currentUser && <GoalCreate currentUser={currentUser} /> }
      </Route>
      <Route path={[`${APP_BASE_PATH}t/:team_index/goals/:id/goals/:goal_id`, `${APP_BASE_PATH}goals/:id`]}>
        { !currentUser && <Spin /> }
        { currentUser && <GoalShow currentUser={currentUser} /> }
      </Route>
      <Route path={[`${APP_BASE_PATH}t/:team_index/goals/:id`, `${APP_BASE_PATH}goals/:id`]}>
        { !currentUser && <Spin /> }
        { currentUser && <GoalGroupShow currentUser={currentUser} /> }
      </Route>
      <Route>
        <div>
          <Breadcrumb className="page-breadcrum">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Goal Group</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <GoalGroupCreate onCreateCompleted={onCreateCompleted}/>
        <GoalGroupList />
      </Route>
    </Switch>
  );
}

export default withRouter(GoalPage);
