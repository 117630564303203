import React, { useState } from "react";
import { Card, Row, Col } from 'antd';
import { withRouter, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import GoalGroupForm from './GoalGroupForm';

interface GoalGroupCreateProps extends RouteComponentProps {
  onCreateCompleted: (newID:string) => void;
}
const GoalGroupCreate: React.FC<GoalGroupCreateProps> = ({ location, onCreateCompleted }) => {
  const { show_create } = queryString.parse(location.search);
  const [showForm, setShowForm] = useState<boolean>(show_create==='true');

  return (
    <Row gutter={[16, 16]}>
      {!showForm &&
        <Col className="teamkit-create-card-container" xl={8} md={12} xs={24}>
          <Card className="teamkit-small-listing-card teamkit-create-card" onClick={() => { setShowForm(true) }}>
            Create new goal group
          </Card>
          <a  className="teamkit-create-card-help" href="https://retroplus.io/features/goal" target="_blank" ><QuestionCircleOutlined /></a>
        </Col>}
      {showForm &&
        <Col xxl={12} xs={24}>
          <GoalGroupForm
            onCreateCancelled={() => { setShowForm(false) }}
            onCreateCompleted={(newID:string) => {
              onCreateCompleted(newID);
              setShowForm(false);
            }}
          />
        </Col>}
    </Row>
  );
}

export default withRouter(GoalGroupCreate);
