import _ from 'lodash-es';
import React, { useContext, useEffect, useState } from "react";
import { Space, Button, Drawer, Form, Select, Input, Spin } from 'antd';
import { post } from '../../../http';
import { apiRoutes, textAreaLength } from '../../../const';
import { User,TodoLabel, Team } from '../../../models';
import { AppContext } from '../../../../store';
import { get } from '../../../http';
import { getCurrentTeam } from '../../../helpers';
import './styles.less';

const { Option } = Select;

interface TodoDrawerProps {
  visible: boolean;
  members?: Array<User>;
  onFormSubmitted: () => void;
  onHide: () => void;
}

const TodoCreateDrawer: React.FC<TodoDrawerProps> = ({ members, visible, onFormSubmitted, onHide }) => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [existingTodoLabels, setExistingTodoLabels] = useState<Array<TodoLabel>>([]);
  const [innerWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const updateWindowSize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    }
  }, []);

  useEffect(() => {
    if (currentTeam) {
      fetchExistingTodoLabels(currentTeam);
    }
  }, [currentTeam]);

  const fetchExistingTodoLabels = async function(team:Team) {
    const data = await get(apiRoutes.todoLabelListStandAlone, {
      team: team.id
    });
    setExistingTodoLabels(data);
  }

  const onFormSubmit = async (values: any) => {
    console.log(values);
    setIsSubmitting(true);
    await createTodo(values);
    setIsSubmitting(false);
    onDrawerClosed();
  }

  const createTodo = async (values: any) => {
    const body = _.assign({}, values, {
      team: currentTeam?.id,
    });
    let url = apiRoutes.todosList;
    let resp = await post(url, body)
    await Promise.all(_.map(values.todolabel_set, async (label:string) => {
      await post(apiRoutes.todoLabelList(resp.id), {
        label,
      });
    }));

    form.resetFields();
    onFormSubmitted();
  }

  const onDrawerClosed = () => {
    dispatch({
      drawerTodo: undefined,
    })
    onHide();
  }

  const onCancel = () => {
    form.resetFields();
    onDrawerClosed();
  }


  let labelOptions = [];
  labelOptions =  _.map(existingTodoLabels, (label:string,i:number) => {
    return <Option key={`label-${i}`} value={label}>{label}</Option>;
  })

  return (
    <Drawer
      title="New Task"
      placement="right"
      closable={true}
      onClose={onDrawerClosed}
      visible={visible}
      className="todo-drawer"
      width={innerWidth < 600 ? innerWidth : 600}
    >
      {!currentTeam && <Spin />}
      {currentTeam && <Form form={form} onFinish={onFormSubmit}>
        <Form.Item
          name="content"
          label="Title"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input the content!' }]}
        >
          <Input.TextArea maxLength={textAreaLength} rows={2} />
        </Form.Item>
        <Form.Item
          name="todolabel_set"
          label="Labels"
          labelCol={{ span: 24 }}
          style={{ marginBottom: 8 }}
        >
          <Select mode="tags" style={{ width: '100%' }}>
            {labelOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name="assignee"
          label="Assignee"
          labelCol={{ span: 24 }}
        >
          <Select
            showSearch
            optionFilterProp="children"
            loading={members ? false : true}
          >
            { members && <Option value="">Unassigned</Option> }
            { _.map(members, (member:User) => {
              return (
                <Option value={member.username}>{member.username}</Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          labelCol={{ span: 24 }}
        >
          <Input.TextArea maxLength={textAreaLength} rows={4} />
        </Form.Item>

        <Form.Item >
          <Space>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Create task
            </Button>
            <Button onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>}
    </Drawer>
  );
}

export default TodoCreateDrawer;
