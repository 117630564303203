import React from "react";
import './Feature.less';
import FeaturePageTailwind from './FeaturePageTailwind';
import { featureGoalTracking } from './features';

const FeatureGoalTailwind: React.FC<{}> = () => {
  return (
    <FeaturePageTailwind featureDefinition={featureGoalTracking} />
  );
}

export default FeatureGoalTailwind;
