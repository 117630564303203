import React, { useState } from "react";
import { Button } from 'antd';
import PollEditModal from './PollEditModal';
import { Board, PollEvent } from '../../models';

interface PollCreateButtonProps {
  board?: Board;
  asLink?: boolean;
  type?: "default" | "link" | "text" | "primary" | "dashed";
  onCompleted: (event:PollEvent) => void;
}

const PollCreateButton: React.FC<PollCreateButtonProps> = ({ board, asLink, type, onCompleted }) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [formId, setFormId] = useState<number>(0);

  const createButton = asLink ? <a target="_blank" onClick={() => setIsCreating(true)}>Create Poll</a> : <Button type={type ?? "default"} onClick={() => setIsCreating(true)}>Create Poll</Button>;

  if (!isCreating) {
    return createButton;
  }

  const onPollCompleted = (event: PollEvent) => {
    setIsCreating(false);
    setFormId(formId + 1);
    onCompleted(event);
  };

  const onPollCanceled = () => {
    setIsCreating(false);
    setFormId(formId + 1);
  };

  return (
    <>
      {createButton}
      <PollEditModal
        key={formId}
        board={board}
        onCompleted={onPollCompleted}
        onCanceled={onPollCanceled}
      />
    </>
  );
}

export default PollCreateButton;
