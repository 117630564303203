import React, { useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Spin, Button } from 'antd';
import { AppContext } from '../store';
import { appRoutes } from './const';
import { getCurrentTeamIndex, getBasePathWithTeam } from './helpers';

const AppBasePage: React.FC<RouteComponentProps<any>> = ({ history }) => {
    const { state: { currentUser } } = useContext(AppContext);

    if (!currentUser) {
        return <Spin />;
    }

    if (currentUser.team_membership.length === 0) {
        return (
            <div className="teamkit-align-center">
                You don't have a team.&nbsp;
                <Button onClick={() => { history.push(`${getBasePathWithTeam()}/teams/create`)}}>Create</Button>
                &nbsp;one or ask your team admin to add you to a team to start.
            </div>
        );
    }

    const currentTeamIndex = getCurrentTeamIndex();
    if (currentTeamIndex >= 0 && currentTeamIndex < currentUser.team_membership.length) {
        history.push(appRoutes.home(currentTeamIndex));
    } else {
        history.push(appRoutes.home(0));
    }

    return (
        <div className="teamkit-align-center">
            Have a good day!
        </div>
    );
}

export default withRouter(AppBasePage);
