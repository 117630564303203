import React from "react";
import './styles.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Divider, Button } from 'antd';
import { getCurrentTeamIndex } from '../../helpers';

interface WidgetContainerProps extends RouteComponentProps {
    title: string;
    className?: string;
    route: (teamID: number) => string;
    children: React.ReactNode;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({ history, title, className, route, children }) => {
  const onArrowButtonClick = () => {
    const currentTeamIndex = getCurrentTeamIndex();
    history.push(route(currentTeamIndex));
  }

  return (
    <div className={className}>
      <div className="teamkit-display-flex">
        <b className="teamkit-flex-full">{title}</b>
        <Button shape="circle" onClick={onArrowButtonClick} icon={<ArrowRightOutlined />} />
      </div>
      <Divider className="widget-divider" />
      {children}
    </div>
  );
}

export default withRouter(WidgetContainer);
