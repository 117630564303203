import _ from 'lodash-es';
import React, { Fragment, useContext, useState } from "react";
import { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { Form, FormInstance, Input, InputNumber, Button, Card, Space, Spin, Select, DatePicker, Row, Col } from 'antd';
import { PlusOutlined, MinusOutlined, PercentageOutlined, NumberOutlined, CheckCircleOutlined, DollarOutlined } from '@ant-design/icons';
import { apiRoutes, mediumTextLength, longTextLength, textAreaLength } from '../../../const';
import { Goal, Target, TargetCreate, TargetProgressType, TargetProgressCompareDirection, isNumericProgressType, TargetProgress, formatNumberProgress, parseNumberProgress, TargetProgressCreate } from '../../../models';
import { AppContext } from '../../../../store';
import { post } from '../../../http';
import { getCurrentTeam } from '../../../helpers';
import { TimeStampFromPeriod } from '../helpers';
import { useForceUpdate } from '../../../hooks';
import './styles.less';

const MmomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = MmomentDatePicker;
const { Option } = Select;
const { TextArea } = Input;

export interface GoalDetailFormValues {
  'goal-title'?: string;
  'goal-content'?: string;
  'period'?: Array<Moment>;
}
interface GoalDetailFormItemsProps {
  form: FormInstance;
}

export const GoalDetailFormItems: React.FC<GoalDetailFormItemsProps> = ({ form }) => {
  return (
    <Fragment>
      <Form.Item
        name="goal-title"
        rules={[{ required: true, message: 'Please input the goal title!' }]}
      >
        <Input bordered={false} size="large" placeholder="What are you going to achieve?" maxLength={mediumTextLength} />
      </Form.Item>
      <Form.Item name="goal-content">
        <TextArea bordered={false} rows={2} placeholder="Give the goal a detailed description. Why are we doing this? What success looks like?" maxLength={textAreaLength} />
      </Form.Item>

      <Form.Item
        name="period"
        rules={[{ required: true, message: 'Please select the goal period!' }]}
        className="teamkit-no-margin-bottom"
      >
        <RangePicker bordered={false} picker="quarter" placeholder={["Start Quarter", "End Quarter"]} />
      </Form.Item>
    </Fragment>
  );
}

interface TargetFormItemsProps {
  form: FormInstance
  id: number | string
  onRemoveClicked: (id: string|number) => void
  hideRemoveButton?: boolean;
  isEditMode?: boolean;
  onRecordUpdateClicked?: () => void;
}

export const TargetFormItems: React.FC<TargetFormItemsProps> = ({ form, id, hideRemoveButton, isEditMode, onRemoveClicked, onRecordUpdateClicked }) => {
  const forceUpdate = useForceUpdate();

  let addonAfter = undefined;
  if (form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Currency) {
    addonAfter = (
      <Form.Item
        className="teamkit-no-margin-bottom"
        initialValue={'USD'}
        name={`target-${id}-progress-unit`}
        rules={[{ required: true, message: 'Please select the unit!' }]}
      >
        <Select style={{ width: 60 }}>
          <Option value="USD">$</Option>
          <Option value="EUR">€</Option>
          <Option value="GBP">£</Option>
          <Option value="VND">đ</Option>
          <Option value="IDR">Rp</Option>
          <Option value="INR">₹</Option>
        </Select>
      </Form.Item>
    );
  } else if (form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage) {
    addonAfter = '%';
  }

  return (
    <>
      {!hideRemoveButton && <Button type="primary" shape="circle" icon={<MinusOutlined />} size="small" danger className="remove-target-button" onClick={() => onRemoveClicked(id)} />}
      <Form.Item
        name={`target-${id}-title`}
        rules={[{ required: true, message: 'Please input the milestone title!' }]}
      >
        <Input bordered={false} placeholder="Describe the measurable milestone" maxLength={longTextLength} />
      </Form.Item>
      <Form.Item
        name={`target-${id}-progress-type`}
        rules={[{ required: true, message: 'Please select the target measurement method!' }]}
      >
        <Select bordered={false} disabled={isEditMode} placeholder="Select measurement method" onChange={forceUpdate}>
          <Option value={TargetProgressType.Num}><NumberOutlined /> Numeric</Option>
          <Option value={TargetProgressType.Currency}><DollarOutlined /> Currency</Option>
          <Option value={TargetProgressType.Percentage}><PercentageOutlined /> Percentage</Option>
          <Option value={TargetProgressType.Bool}><CheckCircleOutlined /> Complete/ Not Complete</Option>
        </Select>
      </Form.Item> 
      {form.getFieldValue(`target-${id}-progress-type`) && isNumericProgressType(form.getFieldValue(`target-${id}-progress-type`)) &&
        <>
        <Row>
          <Col span={24}>
            <Form.Item
              name={`target-${id}-progress-start-value`}
              initialValue={0}
              label="Initial Value"
              rules={[
                { required: true, message: 'Initial Value is required!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const currentValue = getFieldValue(`target-${id}-progress-current-value`);
                    if (currentValue !== null && currentValue !== undefined && value > currentValue) {
                      return Promise.reject("Start Value must be smaller than or equal to Current Value"); 
                    } else {
                      return Promise.resolve();
                    }
                  }
                })
              ]}
            >
              <InputNumber
                placeholder="Start Value"
                style={{width: '100%'}}
                formatter={formatNumberProgress}
                parser={parseNumberProgress}
                min={form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage ? 0 : undefined}
                max={form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage ? 100 : undefined}
                addonAfter={addonAfter}
                onChange={(values) => {
                  if (!isEditMode) {
                    form.setFieldsValue({
                      [`target-${id}-progress-current-value`]: values,
                    });
                  }
                  const targetValue = form.getFieldValue(`target-${id}-progress-end-value`);
                  if (targetValue !== null && targetValue !== undefined) {
                    form.validateFields();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{display: isEditMode ? 'block' : 'none'}}>
          <Col span={24}>
          <Form.Item
            name={`target-${id}-progress-current-value`}
            initialValue={0}
            label="Current Value"
            rules={[
              { required: true, message: 'Current Value is required!' },
            ]}
          >
            <InputNumber
              placeholder="Current Value"
              disabled={true}
              style={{width: '100%'}}
              formatter={formatNumberProgress}
              parser={parseNumberProgress}
              min={form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage ? 0 : undefined}
              max={form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage ? 100 : undefined}
              addonAfter={addonAfter}
            />
          </Form.Item>
        </Col>
        </Row>
        <Row>
          <Col span={24}>
          <Form.Item
            name={`target-${id}-progress-end-value`}
            label="Target Value"
            rules={[
              { required: true, message: 'Target Value is required!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const startValue = getFieldValue(`target-${id}-progress-start-value`);
                  if (startValue !== null && startValue !== undefined && startValue >= value) {
                    return Promise.reject("Target Value must be greater than Initial Value"); 
                  } else {
                    return Promise.resolve();
                  }
                }
              })
            ]}
          >
            <InputNumber
              placeholder="Target Value"
              style={{width: '100%'}}
              formatter={formatNumberProgress}
              parser={parseNumberProgress}
              min={form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage ? 0 : undefined}
              max={form.getFieldValue(`target-${id}-progress-type`) === TargetProgressType.Percentage ? 100 : undefined}
              addonAfter={addonAfter}
              onChange={() => {
                form.validateFields();
              }}
            />
          </Form.Item>
        </Col>
      </Row>
        </>
      }
      {isEditMode && <>
        <br/>
        <Row className="target-update-update-progress-text">
          <Col span={24}>
            <i>Not what you are looking for? <Button type="link" onClick={() => {
              onRecordUpdateClicked && onRecordUpdateClicked();
            }}>Record a progress</Button> update instead</i>
          </Col>
        </Row>
      </>}
    </>
  );
}

export const targetFromTargetFormValues = (id: string | number, values: any): TargetCreate => {
  let targetProgress: TargetProgressCreate = {
    type: values[`target-${id}-progress-type`],
    unit: values[`target-${id}-progress-unit`],
    compare_direction: TargetProgressCompareDirection.Asc,
  };
  if (isNumericProgressType(values[`target-${id}-progress-type`])) {
    targetProgress.initial_value_float = values[`target-${id}-progress-start-value`];
    targetProgress.current_value_float = values[`target-${id}-progress-current-value`];
    targetProgress.target_value_float = values[`target-${id}-progress-end-value`];
  } else if (values[`target-${id}-progress-type`] === TargetProgressType.Bool) {
    targetProgress.current_value_bool = false;
    targetProgress.target_value_bool = true;
  }
  let targetVal: TargetCreate = {
    title: values[`target-${id}-title`],
    target_progress: targetProgress
  };

  return targetVal;
}; 

export const targetToTargetFormValues = (target: Target): any => {
  let formValues: any = {
    [`target-${target.id}-title`]: target.title,
    [`target-${target.id}-progress-type`]: target.target_progress.type,
    [`target-${target.id}-progress-unit`]: target.target_progress.unit,
    [`target-${target.id}-progress-start-value`]: isNumericProgressType(target.target_progress.type) ? target.target_progress.initial_value_float : undefined,
    [`target-${target.id}-progress-current-value`]: isNumericProgressType(target.target_progress.type) ? target.target_progress.current_value_float : undefined,
    [`target-${target.id}-progress-end-value`]: isNumericProgressType(target.target_progress.type) ? target.target_progress.target_value_float : undefined,
  };

  return formValues;
}; 

interface GoalFormProps {
  goalGroupID: string;
  onCreateCompleted: (newID:string) => void;
  onCreateCancelled: () => void;
}

const GoalForm: React.FC<GoalFormProps> = ({ goalGroupID, onCreateCompleted, onCreateCancelled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const [curTargetID, setCurTargetID] = useState(1); // id for the target
  const [ targetIDs, setTargetIDs ] = useState<Array<number>>([]);
  const [form] = Form.useForm();

  if (!currentUser) {
    return <Spin />
  }
  
  const currentTeam = getCurrentTeam(currentUser);

  const newTarget = () => {
    let cloned = _.clone(targetIDs);
    cloned.push(curTargetID);
    setCurTargetID(curTargetID + 1);
    setTargetIDs(cloned);
  }

  const removeTarget = (id: number) => {
    let newArr = [];
    for (let i = 0; i < targetIDs.length; i++) {
      if (targetIDs[i] !== id) {
        newArr.push(targetIDs[i]);
      }
    }
    form.setFields([
      {
        name: `target-${id}-title`,
        value: "deleted",
      },
      {
        name: `target-${id}-progress-type`,
        value: "deleted",
      },
    ])
    setTargetIDs(newArr);
  }

  const formSubmit = async (values: any) => {
    console.log(values);

    let periodTimestamps = TimeStampFromPeriod(values['period']);
    let goalVal: any = {
      title: values['goal-title'],
      content: values['goal-content'],
      start_time: periodTimestamps.start_time,
      end_time: periodTimestamps.end_time,
    };

    let updatedGoal: Goal;
    updatedGoal = await createGoal(goalVal);

    for (let i = 0; i < targetIDs.length; i++) {
      let targetID = targetIDs[i];
      let targetProgress: any = {
        type: values[`target-${targetID}-progress-type`],
        unit: values[`target-${targetID}-progress-unit`],
        compare_direction: TargetProgressCompareDirection.Asc,
      };
      if (isNumericProgressType(values[`target-${targetID}-progress-type`])) {
        targetProgress.initial_value_float = values[`target-${targetID}-progress-start-value`];
        targetProgress.current_value_float = values[`target-${targetID}-progress-current-value`];
        targetProgress.target_value_float = values[`target-${targetID}-progress-end-value`];
      } else if (values[`target-${targetID}-progress-type`] === TargetProgressType.Bool) {
        targetProgress.current_value_bool = false;
        targetProgress.target_value_bool = true;
      }
      let targetVal: any = {
        title: values[`target-${targetID}-title`],
        target_progress: targetProgress
      };
      await createTarget(updatedGoal.id, targetVal);
    }
    onCreateCompleted(updatedGoal.id);
  }

  const createGoal = async (values: any): Promise<Goal> => {
    const data = await post(apiRoutes.goalList(goalGroupID), _.assign({}, values, {
      team: currentTeam?.id,
      goal_group: goalGroupID,
    }));
    return data;
  }

  const createTarget = async (goalID: string, values: any): Promise<Target> => {
    const data = await post(apiRoutes.goalTargetList(goalID), _.assign({}, values, {
      team: currentTeam?.id,
      goal: goalID,
    }));
    return data;
  }

  return(
    <Form
      form={form}
      name="goal-form"
      layout="vertical"
      onFinish={formSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="goal-create-form-container">
          <Form.Item>
            <div className="teamkit-display-flex">
                <div className="teamkit-flex-full" />
              <Space>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
                <Button onClick={onCreateCancelled}>
                  Cancel
                </Button>
              </Space>
            </div>
          </Form.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Card title="Goal">
                <GoalDetailFormItems form={form}/>
              </Card>
              <Card title="Milestones">
                <Space direction="vertical" size="middle" style={{width: '100%'}}>
                  <Row className="target-description-text">
                    <i>Create one or more milestones to measure the success of the goal. Goals are more about objective. Milestones are more measurable.</i>
                  </Row>
                  {targetIDs.map((id) => {
                    return (
                      <Row>
                        <Col span={24}>
                          <Card>
                            <TargetFormItems
                              form={form}
                              id={id}
                              onRemoveClicked={removeTarget}
                            />
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" style={{margin: "auto"}} onClick={newTarget}/>
                  </Row>
                </Space>
              </Card>
            </Space>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default GoalForm;
