import React, { useContext } from "react";
import { Spin, Space, Card } from 'antd';
import { AppContext } from '../store';
import { PlanList } from './modules/common';
import { UserProfile } from './modules/profile';


const ProfilePage: React.FC<{}> = () => {
  const { state: { currentUser } } = useContext(AppContext);
  
  if (!currentUser) {
    return <Spin />
  }
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <UserProfile />
      <div className="teamkit-section-title">
        SUBSCRIPTION
      </div>
      <Card>
        <PlanList />
      </Card>
    </Space>
  );
}

export default ProfilePage;
