import _ from 'lodash-es';
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row, Col, Spin, Empty, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import GoalGroupCard from './GoalGroupCard';
import { get } from '../../http';
import { getCurrentTeam } from '../../helpers';
import { apiRoutes } from '../../const';
import { GoalGroup, Team } from '../../models';
import { AppContext } from '../../../store';
import './styles.less';

interface GoalGroupTabProps {
  goalGroups: Array<GoalGroup>;
  editable?: boolean;
  onRemoteChanged: () => void;
}

const GoalGroupTab: React.FC<GoalGroupTabProps> = ({ goalGroups, editable, onRemoteChanged }) => {
  return (
    <Row gutter={[16, 16]}>
      {Array.isArray(goalGroups) && goalGroups.length === 0 && <Empty />}
      {Array.isArray(goalGroups) && goalGroups.length > 0 && goalGroups.map((goalGroup:any) => (
        <Col key={goalGroup.id} xl={8} md={12} xs={24}>
          <GoalGroupCard
            goalGroup={goalGroup}
            onRemoteChanged={onRemoteChanged}
            editable={editable}
          />
        </Col>
      ))}
    </Row>
  );
}

const GoalGroupList: React.FC<{}> = () => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [goalGroups, setGoalGroups] = useState<Array<GoalGroup>>();
  const [myGoalGroups, setMyGoalGroups] = useState<Array<GoalGroup>>();
  const [sharedGoalGroups, setSharedGoalGroups] = useState<Array<GoalGroup>>();
  const [archivedGoalGroups, setArchivedGoalGroups] = useState<Array<GoalGroup>>();

  useEffect(() => {
    if (currentTeam) {
      fetchAllGoalGroups();
    }
  }, [currentTeam]);

  if (!currentUser) {
    return <Spin />;
  }

  const fetchAllGoalGroups = async function() {
    fetchGoalGroups(currentTeam);
    fetchArchivedGoalGroups(currentTeam);
  }

  const fetchGoalGroups = async function(team:Team) {
    const data: Array<GoalGroup> = await get(apiRoutes.goalGroupList, {
      team: team.id,
      archived: false,
    });
    
    const _myGoalGroups:Array<GoalGroup> = _.filter(data, (goalGroup:GoalGroup) => {
      return goalGroup.owner === currentUser?.username;
    });
    const _sharedGoalGroups:Array<GoalGroup> = _.filter(data, (goalGroup:GoalGroup) => {
      return goalGroup.owner !== currentUser?.username;
    });

    setGoalGroups(data);
    setMyGoalGroups(_myGoalGroups);
    setSharedGoalGroups(_sharedGoalGroups);
  }

  const fetchArchivedGoalGroups = async function(team:Team) {
    const data: Array<GoalGroup> = await get(apiRoutes.goalGroupList, {
      team: team.id,
      archived: true,
    });

    setArchivedGoalGroups(data);
  }

if (!currentTeam || !goalGroups || !myGoalGroups || !sharedGoalGroups) {
    return <Spin />;
  }

  const items: TabsProps['items'] = [
    {
      key: 'my_goals',
      label: `My goals (${myGoalGroups.length})`,
      children: <GoalGroupTab
        editable
        goalGroups={myGoalGroups}
        onRemoteChanged={fetchAllGoalGroups}
      />,
    },
    {
      key: 'shared_goals',
      label: `Share with me (${sharedGoalGroups.length})`,
      children: <GoalGroupTab
        goalGroups={sharedGoalGroups}
        onRemoteChanged={fetchAllGoalGroups}
      />,
    },
  ];

  if (archivedGoalGroups?.length) {
    items.push({
      key: 'archived_goals',
      label: `Archived (${archivedGoalGroups?.length ?? 0})`,
      children: <GoalGroupTab
        editable
        goalGroups={archivedGoalGroups}
        onRemoteChanged={fetchAllGoalGroups}
      />,
    });
  }

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Tabs defaultActiveKey="my_goals" items={items} onChange={() => {}} />
        </Col>
      </Row>
    </Fragment>
  );
}

export default GoalGroupList;
