import _ from 'lodash-es';
import moment, { Moment } from 'moment-timezone';
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, Select, Spin, Space, DatePicker } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { get } from '../../http';
import { apiRoutes } from '../../const';
import { Kudo, Team, User, KudoEvent } from '../../models';
import KudoMiniCard from './KudoMiniCard';
import { useWindowDimensions } from '../../hooks';
import { getCurrentTeam } from '../../helpers';
import { AppContext } from '../../../store';
import KudoCreateButton from './KudoCreateButton';

const MmomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = MmomentDatePicker;
const backendDateFormat = 'YYYY-MM-DD hh:mm:ss';

const KudoListWrapper: React.FC<{}> = () => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);

  if (!currentUser || !currentTeam) {
    return <Spin />;
  }

  return (
    <KudoList currentUser={currentUser} currentTeam={currentTeam}/>
  )
}

interface KudoListProps {
  currentUser: User;
  currentTeam: Team;
}

const KudoList: React.FC<KudoListProps> = ({ currentUser, currentTeam }) => {
  const [kudos, setKudos] = useState<Kudo[]>();
  const [members, setMembers] = useState<User[]>();
  const { width } = useWindowDimensions();
  const [selectedMembers, setSelectedMembers] = useState<string[]|undefined>(undefined);
  const [fromDate, setFromDate] = useState<Moment|null>(moment().tz(currentTeam.timezone).startOf('day').subtract(14, "days"));
  const [toDate, setToDate] = useState<Moment|null>(moment().tz(currentTeam.timezone).endOf('day'));

  let colCount = 1;
  let span = 24;
  if (width >= 1600) {
    colCount = 4;
    span = 6;
  } else if (width >= 1200) {
    colCount = 3;
    span = 8;
  } else if (width >= 768) {
    colCount = 2;
    span = 12;
  }

  useEffect(() => {
    if (currentUser && !selectedMembers) {
      setSelectedMembers([currentUser.username]);
    }
    if (currentUser && currentTeam) {
      fetchTeamMembers();
    }
  }, [currentUser, currentTeam, selectedMembers]);

  useEffect(() => {
    fetchKudos();
  }, [selectedMembers, fromDate, toDate]);

  const fetchKudos = async () => {
    if (!selectedMembers) {
      return;
    }

    let urls = [];
    let commonFilters = [
      `team=${currentTeam.id}`,
    ];
    if (fromDate) {
      commonFilters.push(`created_at__gte=${fromDate?.format(backendDateFormat)}`);
    }
    if (toDate) {
      commonFilters.push(`created_at__lte=${toDate?.format(backendDateFormat)}`);
    }

    if (selectedMembers.length === 0) {
      urls.push(`${apiRoutes.kudoList}?${commonFilters.join('&')}`);
    } else {
      for (let username of selectedMembers) {
        urls.push(`${apiRoutes.kudoList}?receiver__username=${username}&${commonFilters.join('&')}`);
      }
    }
    const results = await Promise.all(urls.map((url) => get(url)));
    const data = _.flatten(results);
    setKudos(data);
  }

  const fetchTeamMembers = async function() {
    if (!currentTeam) {
      return;
    }

    const data = await get(apiRoutes.teamMemberList(currentTeam.id));
    setMembers(data);
  }

  const onKudoUpdate = (event:KudoEvent) => {
    fetchKudos();
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <KudoCreateButton type="primary" members={members} onCompleted={fetchKudos}/>
        </Col>
      </Row>
      <Card style={{ width: "100%" }}>
        <Row gutter={[16,16]}>
          <Col md={12} xs={24}>
            <div className="teamkit-display-flex">
              <span className="kudo-list-filter-label">Team Members</span>
              <Select
                mode="multiple"
                allowClear
                className="teamkit-flex-full"
                placeholder="Select a team member"
                defaultValue={[currentUser.username]}
                onChange={(values) => setSelectedMembers(values)}
                options={_.map(members, (member: User) => {
                  return {
                    label:
                      member.username === currentUser.username
                        ? `${member.username} (me)`
                        : member.username,
                    value: member.username,
                  };
                })}
              />
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="teamkit-display-flex">
              <span className="kudo-list-filter-label">Date Time</span>
              <RangePicker
                className="teamkit-flex-full"
                defaultValue={[fromDate, toDate]}
                allowClear={false}
                onChange={(dates) => {
                  if (dates && dates.length > 0) {
                    setFromDate(dates[0].startOf("day"));
                  }
                  if (dates && dates.length > 1) {
                    setToDate(dates[1].endOf("day"));
                  }
                }}
              />
            </div>
          </Col>
        </Row>
      </Card>
      <Row gutter={[16, 16]}>
        {[...Array(colCount).keys()].map((colIndex: number) => {
          return (
            <Col span={span} key={`kudo-col-${colIndex}`}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {kudos &&
                  _.map(kudos, (kudo: Kudo, i: number) => {
                    if (i % colCount === colIndex) {
                      return <KudoMiniCard kudo={kudo} onKudoUpdate={onKudoUpdate}/>;
                    }
                  })}
              </Space>
            </Col>
          );
        })}
      </Row>
    </Space>
  );
}

export default KudoListWrapper;
