import _ from 'lodash-es';
import React, { Fragment, useEffect, useState } from "react";
import { Form, Row, Col, Spin, Button, Space, Breadcrumb, Card, Tooltip, Dropdown, Menu, Modal, Result, Tag } from 'antd';
import { Comment } from '@ant-design/compatible';
import { CalendarOutlined, EditOutlined, PlusOutlined, ArrowLeftOutlined, EllipsisOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useParams as useRouterParams, withRouter, RouteComponentProps } from 'react-router-dom';
import { apiRoutes } from '../../../const';
import { UserAvatar } from '../../common';
import { CommentSingle } from '../../comments';
import { post, get, put, del } from '../../../http';
import { getBasePathWithTeam, getCurrentTeam } from '../../../helpers';
import { GoalGroup, GoalWithTarget, Goal, User, GoalComment, Target } from '../../../models';
import { formatPeriod, TimeStampFromPeriod, periodFromTimestamp } from '../helpers';
import { GoalDetailFormItems, GoalDetailFormValues, TargetFormItems, targetFromTargetFormValues } from './GoalForm';
import { useWindowDimensions } from '../../../hooks';
import TargetCard from './TargetCard';
import GoalProgressBar from './GoalProgressBar';
import GoalCommentForm from './GoalCommentForm';
import './styles.less';

const { confirm } = Modal;

interface GoalCommentListProps {
  goalComments: Array<GoalComment>;
  goal: Goal;
  currentUser: User;
  onCommentDeleted: () => void;
  onCommentUpdated: () => void;
}

const GoalCommentList: React.FC<GoalCommentListProps> = ({ goal, goalComments, currentUser, onCommentDeleted, onCommentUpdated}) => {
  const [pageSize, setPageSize] = useState(2);

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {_.map(goalComments, (comment:GoalComment, i: number) => {
        if (i < pageSize) {
          return (
            <Card className="goal-comment-cards" >
              <CommentSingle
                comment={comment}
                goal={goal}
                currentUser={currentUser}
                onDeleted={onCommentDeleted}
                onUpdated={onCommentUpdated}
              />
            </Card>
          );
        }

        return null
      })}

      {_.size(goalComments) > pageSize && <div style={{textAlign: 'center'}}>
        <Button onClick={() => { setPageSize(pageSize+8) }}>Show more</Button>
      </div>}
    </Space>
  );
};

interface GoalEditProps {
  goalGroupID: string;
  goalWithTargets: GoalWithTarget;
  onUpdateCompleted: () => void;
  onUpdateCanceled: () => void;
}

const GoalEdit: React.FC<GoalEditProps> = ({ goalGroupID, goalWithTargets, onUpdateCompleted, onUpdateCanceled }) => {
  const [goalDetailsForm] = Form.useForm();

  const goalDetailsFormSubmit = async (values: GoalDetailFormValues) => {
    console.log(values);

    let periodTimestamps = TimeStampFromPeriod(values['period']);
    let goalVal: any = {
      title: values['goal-title'],
      content: values['goal-content'],
      start_time: periodTimestamps.start_time,
      end_time: periodTimestamps.end_time,
    };

    await updateGoal(_.assign({}, goalWithTargets.goal, goalVal));
    onUpdateCompleted();
  };


  const updateGoal = async (values: any): Promise<Goal> => {
    const data = await put(apiRoutes.goalSingle(goalGroupID, goalWithTargets.goal.id), values);
    return data;
  }

  return (
    <Card>
      <div>
        <Form
          form={goalDetailsForm}
          initialValues={{
            'goal-title': goalWithTargets.goal.title,
            'goal-content': goalWithTargets.goal.content,
            'period': periodFromTimestamp(goalWithTargets.goal.start_time, goalWithTargets.goal.end_time),
          } as GoalDetailFormValues}
          onFinish={goalDetailsFormSubmit}
        >
          <GoalDetailFormItems form={goalDetailsForm} />
          <br />
          <Space>
            <Button type="primary" htmlType="submit">Save</Button>
            <Button onClick={onUpdateCanceled}>Cancel</Button>
          </Space>
          <br />
          <br />
          <br />
        </Form>
      </div>
    </Card>
  );
}

interface GoalCommentSectionProps {
  currentUser: User;
  goalComments: Array<GoalComment>;
  newCommentID: number;
  goalWithTargets: GoalWithTarget;
  onCommentCreated: () => void;
  onCommentUpdated: () => void;
}

const GoalCommentSection: React.FC<GoalCommentSectionProps> = ({ currentUser, goalComments, newCommentID, goalWithTargets, onCommentCreated, onCommentUpdated }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="teamkit-section-title">
          COMMENTS ({_.size(goalComments)})
        </Col>
      </Row>
      <Card className="goal-comment-form-cards">
        <Comment
          className="item-comment-form"
          avatar={
            <UserAvatar username={currentUser?.username} />
          }
          content={
            <GoalCommentForm
              key={`comment-form-${newCommentID}`}
              goal={goalWithTargets.goal}
              hideCancel
              onCancel={() => {  }}
              onCommentCreated = {onCommentCreated}
            />
          }
        />
      </Card>
      <GoalCommentList
        goal={goalWithTargets.goal}
        goalComments={goalComments}
        currentUser={currentUser}
        onCommentDeleted={onCommentUpdated}
        onCommentUpdated={onCommentUpdated}
      />
    </>
  );
}

interface GoalShowProps extends RouteComponentProps<any> {
  currentUser: User;
}

const GoalShow: React.FC<GoalShowProps> = ({ history, currentUser }) => {
  const goalGroupID = useRouterParams<any>().id;
  const goalID = useRouterParams<any>().goal_id;
  const currentTeam = getCurrentTeam(currentUser);
  // const teamIndex = useRouterParams<any>().team_index;
  const [targetForm] = Form.useForm();
  const { width } = useWindowDimensions();

  const [goalGroup, setGoalGroup] = useState<GoalGroup>();
  const [isGoalDetailEditing, setIsGoalDetailEditing] = useState<boolean>(false);
  const [isAddingTarget, setIsAddingTarget] = useState<boolean>(false);

  const [goalWithTargets, setGoalWithTargets] = useState<GoalWithTarget>();
  const [goalComments, setGoalComments] = useState<Array<GoalComment>>([]);
  const [newCommentID, setNewCommentID] = useState<number>(1);
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (goalGroupID) {
      fetchGoalGroup(goalGroupID);
    }
    if (goalGroupID && goalID) {
      fetchGoalAndTargets(goalGroupID, goalID);
      fetchGoalComments(goalID);
    }
  }, [goalGroupID, goalID]);

  const fetchGoalGroup = async function(goalGroupID:string) {
    const data = await get(apiRoutes.goalGroupSingle(goalGroupID));
    setGoalGroup(data);
  };

  const fetchGoalAndTargets = async function(goalGroupID:string, goalID:string) {
    try {
      const goalData = await get(apiRoutes.goalSingle(goalGroupID, goalID));
      const targetData = await get(apiRoutes.goalTargetList(goalData.id));
      setGoalWithTargets({
        goal: goalData,
        targets: targetData
      });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        setNotFound(true);
        return;
      }
    }
  };

  const fetchTargets = async function() {
    if (goalWithTargets) {
      const targetData = await get(apiRoutes.goalTargetList(goalWithTargets.goal.id));
      setGoalWithTargets({
        goal: goalWithTargets.goal,
        targets: targetData
      });
    }
  };

  const fetchGoalComments = async function(goalID:string) {
    const data = await get(apiRoutes.goalCommentList(goalID));
    setGoalComments(data);
  };

  if (notFound) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => {
          history.push(`${getBasePathWithTeam()}/goals/${goalGroupID}`);
        }}>Back to Goals</Button>}
      />
    );
  }

  if (!currentUser || !goalGroup || !goalWithTargets) {
    return (
      <Spin />
    );
  }

  const newTargetFormSubmit = async (values: any) => {
    console.log(values);

    let targetID = 1; // always 1 for new target
    let targetVal = targetFromTargetFormValues(targetID, values);
    await createTarget(goalWithTargets.goal.id, targetVal);
    targetForm.resetFields();
    setIsAddingTarget(false);
    fetchTargets();
  };

  const createTarget = async (goalID: string, values: any): Promise<Target> => {
    const data = await post(apiRoutes.goalTargetList(goalID), _.assign({}, values, {
      team: currentTeam?.id,
      goal: goalID,
    }));
    return data;
  }

  const deleteGoal = async () => {
    await del(apiRoutes.goalSingle(goalGroupID, goalWithTargets.goal.id));
  }

  const showDeleteGoalConfirm = () => {
    confirm({
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure delete this goal? The action is not reversible and all data will be lost.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        await deleteGoal();
        history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}`);
      },
      onCancel() {},
    });
  };

  return(
    <Fragment>
      <Breadcrumb className="page-breadcrum">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a onClick={() => history.push(`${getBasePathWithTeam()}/goals`)}>Goal Group</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a onClick={() => history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}`)}>{goalGroup.name}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {goalWithTargets.goal.title.length > 20 ? goalWithTargets.goal.title.substring(0, 20) + '...' : goalWithTargets.goal.title}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row style={{marginBottom: 8}}>
        <Col span={24}>
          <Button onClick={() => history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}`)}><ArrowLeftOutlined /> Back to goals</Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Space direction="vertical" style={{width: '100%'}}>
            {isGoalDetailEditing ?
              <GoalEdit
                goalGroupID={goalGroupID}
                goalWithTargets={goalWithTargets}
                onUpdateCompleted={async () => {
                  await fetchGoalAndTargets(goalGroupID, goalID);
                  setIsGoalDetailEditing(false);
                }}
                onUpdateCanceled={() => {setIsGoalDetailEditing(false)}}
              />
             :
              <Card className="goal-detail-card">
                <div className="teamkit-display-flex">
                  <div className="teamkit-flex-full">
                    {!goalGroup.archived && goalGroup.owner === currentUser.username && <Dropdown overlay={<Menu>
                      <Menu.Item key="target-edit" onClick={() => setIsGoalDetailEditing(true)}><EditOutlined /> Edit</Menu.Item>
                      <Menu.Item key="target-delete" onClick={showDeleteGoalConfirm}><DeleteOutlined /> Delete</Menu.Item>
                    </Menu>} placement="bottomRight" arrow={{ pointAtCenter: true }}>
                      <Button className="goal-details-edit">
                        <EllipsisOutlined key="ellipsis" />
                      </Button>
                    </Dropdown>}
                    <h2>{goalGroup.archived && <><Tag color="grey">Archived</Tag></>}{goalWithTargets.goal.title}</h2>
                    <div className="goal-period">
                      <CalendarOutlined /> {formatPeriod(goalWithTargets.goal)}
                    </div>
                    <br />
                    <b>Descriptions</b>
                    <div>
                      {goalWithTargets.goal.content ? <span className="teamkit-show-line-break">{goalWithTargets.goal.content}</span> : <i>No description</i>}
                    </div>
                    <br />
                    <br />
                  </div>
                  <GoalProgressBar goal={goalWithTargets.goal} targets={goalWithTargets.targets} />
                </div>
              </Card>}
            {width >= 992 && <GoalCommentSection
              currentUser={currentUser}
              goalComments={goalComments}
              newCommentID={newCommentID}
              goalWithTargets={goalWithTargets}
              onCommentCreated = { () => {
                setNewCommentID(newCommentID + 1);
                fetchGoalComments(goalWithTargets.goal.id);
              }}
              onCommentUpdated = {() => fetchGoalComments(goalID)}
            />}
          </Space>
        </Col>
        <Col lg={12} xs={24}>
          {!isAddingTarget && _.size(goalWithTargets.targets) === 0 && 
            <Card title="Milestones">
              <Row className="target-description-text">
                <i>Create one or more milestones to measure the success of the goal. Goals are more about objective. Milestones are more measurable.</i>
                {!goalGroup.archived && goalWithTargets.goal.owner === currentUser.username && <Tooltip placement="bottom" title="Add new milestone">
                  <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" style={{margin: "auto"}} onClick={() => setIsAddingTarget(true)}/>
                </Tooltip>}
              </Row>
            </Card>}
          <Space direction="vertical" size="middle" style={{width: '100%'}}>
            {width < 992 && _.size(goalWithTargets.targets) > 0 &&
              <Row gutter={[16, 16]}>
                <Col xs={24} className="teamkit-section-title">
                  TARGETS ({_.size(goalWithTargets.targets)})
                </Col>
              </Row>}
            {goalWithTargets.targets.map((target: Target) => {
              return (
                <Row>
                  <Col span={24}>
                    <TargetCard
                      currentUser={currentUser}
                      goal={goalWithTargets.goal}
                      target={target}
                      isArchived={goalGroup.archived}
                      onDeleteCompleted={fetchTargets}
                      onUpdateCompleted={() => {
                        fetchTargets();
                        fetchGoalComments(goalWithTargets.goal.id);
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row>
              {!goalGroup.archived && goalWithTargets.goal.owner === currentUser.username && !isAddingTarget && _.size(goalWithTargets.targets) > 0 && <Tooltip placement="bottom" title="Add new milestone">
                <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" style={{margin: "auto"}} onClick={() => setIsAddingTarget(true)}/>
              </Tooltip>}
              {isAddingTarget && 
                <Col span={24}>
                  <Card>
                    <Form
                      form={targetForm}
                      initialValues={{}}
                      onFinish={newTargetFormSubmit}
                    >
                      <TargetFormItems
                        form={targetForm}
                        id={1}
                        onRemoveClicked={() => {}}
                        hideRemoveButton={true}
                      />
                      <br/>
                      <Space>
                        <Button type="primary" htmlType="submit">Save</Button>
                        <Button onClick={() => setIsAddingTarget(false)}>Cancel</Button>
                      </Space>
                    </Form>
                  </Card>
                </Col>}
            </Row>
            {width < 992 && <GoalCommentSection
              currentUser={currentUser}
              goalComments={goalComments}
              newCommentID={newCommentID}
              goalWithTargets={goalWithTargets}
              onCommentCreated = { () => {
                setNewCommentID(newCommentID + 1);
                fetchGoalComments(goalWithTargets.goal.id);
              }}
              onCommentUpdated = {() => fetchGoalComments(goalID)}
            />}
          </Space>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withRouter(GoalShow);
