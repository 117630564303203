import React, { useState, useContext, Fragment } from "react";
import "./AppSider.less";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  TeamOutlined,
  ProjectOutlined,
  CheckCircleOutlined,
  FieldBinaryOutlined,
  TrophyOutlined,
  FileDoneOutlined,
  HomeOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { getCurrentTeamIndex, getBasePathWithTeam } from "./components/helpers";
import { AppContext } from "./store";
import { useWindowDimensions } from "./components/hooks";
import { APP_BASE_PATH } from "./components/const";
import iconImg from "./resources/images/logo192.png";

const { Sider } = Layout;

interface RouteConfig {
  path: string;
  label: string;
  icon: any;
  requiredTeamSelection: boolean;
}

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    label: "Home",
    icon: <HomeOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/boards",
    label: "Retrospective",
    icon: <ProjectOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/scrum-poker-sessions",
    label: "Scrum Poker",
    icon: <FieldBinaryOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/tasks",
    label: "Task",
    icon: <FileDoneOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/checkins",
    label: "Check-in",
    icon: <CheckCircleOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/goals",
    label: "Goal",
    icon: <TrophyOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/kudos",
    label: "Kudo",
    icon: <StarOutlined />,
    requiredTeamSelection: true,
  },
  {
    path: "/teams",
    label: "Team",
    icon: <TeamOutlined />,
    requiredTeamSelection: false,
  },
];

const AppSider: React.FC<RouteComponentProps<any>> = ({
  history,
  location,
}) => {
  const {
    state: { currentUser, isUnAuthenticated },
  } = useContext(AppContext);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { width } = useWindowDimensions();
  const basePathWithTeam = getBasePathWithTeam();

  const currentTeamIndex = getCurrentTeamIndex();
  const isRouteDisabled = (route: RouteConfig) => {
    // we only disable for routes that require team selection
    if (!route.requiredTeamSelection) {
      return false;
    }

    return (
      !currentUser ||
      Number.isNaN(currentTeamIndex) ||
      currentTeamIndex < 0 ||
      currentTeamIndex >= currentUser.team_membership.length
    );
  };

  // hacky way for higlighting the menu, since 1 menu item can be mapped to multiple routes
  const getMenuKeyHacky = (key: string) => {
    if (location.pathname.search(key) >= 0) {
      return location.pathname;
    } else {
      return key;
    }
  };

  const navigate = (url: string) => {
    history.push(url);
  };

  if (isUnAuthenticated) {
    return null;
  }
  const siderContent = (
    <>
      <div className="sider-logo">
        <img
          src={iconImg}
          alt="main-icon"
          onClick={() => {
            history.push(APP_BASE_PATH);
          }}
        />
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        {routes.map((route) => (
          <Menu.Item
            key={getMenuKeyHacky(route.path)}
            icon={route.icon}
            disabled={isRouteDisabled(route)}
            onClick={() => {
              navigate(basePathWithTeam + route.path);
            }}
          >
            {route.label}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );

  return (
    <Fragment>
      {width < 992 ? (
        <Sider className="main-sider" breakpoint="lg" collapsedWidth="0">
          {siderContent}
        </Sider>
      ) : (
        <Sider
          className="main-sider"
          collapsible
          collapsed={isCollapsed}
          onCollapse={setIsCollapsed}
        >
          {siderContent}
        </Sider>
      )}
    </Fragment>
  );
};

export default withRouter(AppSider);
