import React, { useContext, useEffect, useState } from "react";
import { withRouter,  useParams as useRouterParams } from "react-router-dom";
import { Spin, Card, Button, Result, Tag } from 'antd';
import { getBasePathWithTeam, getPathPrefix } from './helpers';
import { apiRoutes, publicApiRoutes, liteAppRoutes, djangoPageRoutes, localStorageKeys } from './const';
import { Invite, InviteType } from './models';
import { get, post } from './http';
import { AppContext } from '../store';

const InvitePage: React.FC<any> = ({ history }) => {
  const inviteID = useRouterParams<any>().id;
  const { state: { currentUser, isUnAuthenticated } } = useContext(AppContext);
  const [invite, setInvite] = useState<Invite|undefined>(undefined);

  useEffect(() => {
    if (currentUser) {
      getInvite();
    } else if (isUnAuthenticated) {
      getInvitePublic();
    }
  }, [currentUser, isUnAuthenticated]);

  const getInvite = async () => {
    const data:Invite = await get(apiRoutes.inviteSingle(inviteID));

    if (currentUser) {
      let newTeamIndex = -1;
      currentUser.team_membership.forEach((team, i) => {
        if (team.id === data.team) {
          // user is already a member of the team
          newTeamIndex = i;
        }
      });
      if (newTeamIndex >= 0) {
        const basePathWithTeam = getPathPrefix(newTeamIndex);
        if (data.invite_type === InviteType.Board) {
          console.log(`${basePathWithTeam}/boards/${data.invite_id}`);
          window.location.href = `${basePathWithTeam}/boards/${data.invite_id}`;
        } else if (data.invite_type === InviteType.ScrumPoker) {
          window.location.href = `${basePathWithTeam}/scrum-poker-sessions/${data.invite_id}`;
        } else {
          window.location.href = basePathWithTeam;
        }
        return;
      }
    }
    setInvite(data);
  }

  const getInvitePublic = async () => {
    const data:Invite = await get(publicApiRoutes.inviteSingle(inviteID));
    setInvite(data);
    if (isUnAuthenticated && (!data || data.invite_type !== InviteType.Board)) {
      login();
    }
  }

  const acceptInvite = async () => {
    await post(apiRoutes.inviteAccept(invite?.id), {});
    const basePathWithTeam = getBasePathWithTeam();
    if (invite?.invite_type === InviteType.Board) {
      window.location.href = `${basePathWithTeam}/boards/${invite?.invite_id}`;
    } else if (invite?.invite_type === InviteType.ScrumPoker) {
      window.location.href = `${basePathWithTeam}/scrum-poker-sessions/${invite?.invite_id}`;
    } else {
      window.location.href = basePathWithTeam;
    }
  }

  const login = () => {
    localStorage.setItem(localStorageKeys.loginRedirect, window.location.pathname);
    window.location.href = djangoPageRoutes.login;
  }

  if (!invite) {
    return <Spin />;
  }

  if (invite.invite_type === InviteType.Board) {
    if (currentUser) {
      return (
        <div style={{textAlign: 'center'}}>
          <Card>
            <div>
              You are invited to access retrospective board <span style={{ fontSize: '1.3em' }}>{ invite.display_name }</span>. Join team now to start collaborating with your teammates.
            </div>
            <br/>
            <div>
              <Button type="primary" onClick={acceptInvite}>Accept invite</Button>
            </div>
          </Card>
        </div>
      );
    } else {
      return (
        <div style={{textAlign: 'center'}}>
          <Card>
            <div>
              You are invited to access retrospective board <span style={{ fontSize: '1.3em' }}>{ invite.display_name }</span>
            </div>
            <br/>
            <div>
              <Button type="link" style={{ padding: 0 }} onClick={login}>Login/ Sign Up</Button> now to accept the invite <Tag>Recommended</Tag>
            </div>
            <div>
              <Button type="link" style={{ padding: 0 }} onClick={() => history.push(`${liteAppRoutes.board(invite?.invite_id)}?invite_id=${invite.id}`)}>Use Non-Login mode</Button> and accept the invite later
            </div>
          </Card>
        </div>
      );
    }
  } else if (invite.invite_type === InviteType.ScrumPoker) {
    return (
      <div style={{textAlign: 'center'}}>
        <Card>
          <div>
            You are invited to access scrum poker voting session <span style={{ fontSize: '1.3em' }}>{ invite.display_name }</span>. Join team now to start collaborating with your teammates.
          </div>
          <br/>
          <div>
            <Button type="primary" onClick={acceptInvite}>Accept invite</Button>
          </div>
        </Card>
      </div>
    );
  } else if (invite.invite_type === InviteType.Team) {
  return (
    <div style={{textAlign: 'center'}}>
      <Card>
        <div>
          You are invited to join team <span style={{ fontSize: '1.3em' }}>{ invite.display_name }</span>. Join now to start collaborating with your teammates.
        </div>
        <br/>
        <div>
          <Button type="primary" onClick={acceptInvite}>Accept invite</Button>
        </div>
      </Card>
    </div>
  );
}

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={() => {
        history.push(getBasePathWithTeam());
      }}>Back to Home</Button>}
    />
  );
}

export default withRouter(InvitePage);
