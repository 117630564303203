import _ from 'lodash-es';
import './styles.less';
import React, { Fragment, ReactElement, useContext, useEffect, useState } from "react";
import boardFormBg from '../../../resources/images/board-create-bg.png';
import { Form, Input, Button, Card, Space, Spin, Select, Switch} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { apiRoutes, shortTextLength, mediumTextLength, colorSchemeClassMapping } from '../../const';
import { Board, BoardPostMode, Template } from '../../models';
import { AppContext } from '../../../store';
import { get, post, put } from '../../http';
import { getCurrentTeam } from '../../helpers';

const { Option } = Select;

const BoardColumnForm: React.FC<{}> = () => {
  return (
    <Fragment>
      <p>Columns</p>
      <Form.List
        name="boardcolumn_set"
        rules={[
          {
            validator: async (_, boardcolumn_set) => {
              if (!boardcolumn_set || boardcolumn_set.length < 2) {
                return Promise.reject(new Error('At least 2 columns'));
              }
              if (!boardcolumn_set || boardcolumn_set.length > 5) {
                return Promise.reject(new Error('Max 5 columns'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
          <Fragment>
            {fields.map((field, index) => (
              <Space key={field.key} align="baseline" className="custom-column-row">
                <Form.Item
                  name={[field.name, 'index']}
                  fieldKey={[field.fieldKey, 'index']}
                  initialValue={index}
                >
                  <Input type="hidden"/>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Color"
                  name={[field.name, 'color_scheme']}
                  fieldKey={[field.fieldKey, 'color_scheme']}
                  rules={[{ required: true, message: 'Missing color' }]}
                >
                  <Select style={{ width: '70px' }}>
                    {function() {
                      const options:Array<ReactElement> = [];
                      _.forIn(colorSchemeClassMapping, function(value, key) {
                        options.push(
                          <Option key={key} value={key}><span style={{ color: '#ffffff00' }} className={value}>OO</span></Option>
                        );
                      });
                      return options;
                    }()}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Name"
                  name={[field.name, 'name']}
                  fieldKey={[field.fieldKey, 'name']}
                  rules={[{ required: true, message: 'Missing name' }]}
                >
                  <Input maxLength={shortTextLength} />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            {fields.length < 5 && <Form.Item style={{ marginLeft: '26px' }}>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Add column
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>}
          </Fragment>
        )}}
      </Form.List>
    </Fragment>
  );
}

interface BoardFormProps {
  board?: Board;
  onCreateCompleted: (newID:string) => void;
  onCreateCancelled: () => void;
}

const BoardForm: React.FC<BoardFormProps> = ({ board, onCreateCompleted, onCreateCancelled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const [builtinTemplates, setBuiltinTemplates] = useState<any>();
  const [currentTemplate, setCurrentTemplate] = useState<number>();
  const [form] = Form.useForm();
  
  const fetchBuiltInTemplate = async function() {
    const data = await get(apiRoutes.boardColumnTemplatesList);
    setBuiltinTemplates(data);
  }

  useEffect(() => {
    fetchBuiltInTemplate();
  }, []);

  if (!currentUser) {
    return <Spin />
  }
  
  const currentTeam = getCurrentTeam(currentUser);
  const isEditMode = () => {
    return board ? true : false;
  }

  const formSubmit = async (values: any) => {
    if (board) {
      await updateBoard(board.id, _.assign({}, board, values));
      return;
    }
    await createBoard(values);
  }

  const createBoard = async (values: any) => {
    const data = await post(apiRoutes.boardList, _.assign({}, values, {
      team: currentTeam?.id,
    }));
    onCreateCompleted(data.id);
  }

  const updateBoard = async (boardID:string, values: any) => {
    const data = await put(apiRoutes.boardSingle(boardID), values)
    onCreateCompleted(data.id);
  }

  const onTemplateChanged = (value:number) => {
    setCurrentTemplate(value);
  }

  return(
    <Card>
      <Form
        form={form}
        name="board-form"
        initialValues={{
          name: board ? board.name : '',
          template: 0,
        }}
        onFinish={formSubmit}
      >
        {!isEditMode() &&
          <div className="create-board-bg-image-container">
            <img src={boardFormBg} className="create-board-bg-image teamkit-margin-auto" alt="Board create"/>
            <a
              className="card-image-attribute-link"
              href="http://www.freepik.com"
              target="_blank"
              rel="noreferrer"
            >
              Image designed by pch.vector / Freepik
            </a>
          </div>}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the board name!' }]}
        >
          <Input maxLength={mediumTextLength} />
        </Form.Item>
        
        {!isEditMode() &&
        <Fragment>
          <Form.Item
            label="Template"
            name="template"
            rules={[{ required: true, message: 'Please select template!' }]}
          >
            <Select onChange={onTemplateChanged}>
              {_.map(builtinTemplates, (template:Template, index:number) => {
                return <Option value={index}>{ template.title }</Option>;
              })}
              <Option value={-1}>Custom</Option>
            </Select>
          </Form.Item>
          {currentTemplate === -1 && <BoardColumnForm />}
          <Form.Item
            label="Anonymous posting"
            name="post_mode"
            initialValue={BoardPostMode.All}
          >
            <Switch
              checkedChildren="Allow"
              unCheckedChildren="Disallowed"
              defaultChecked
              onChange={(checked:boolean) => { form.setFieldsValue({ post_mode: checked ? BoardPostMode.All : BoardPostMode.Identified }) }}
            />
          </Form.Item>
          <Form.Item
            label="Public"
            name="public"
            initialValue={true}
            extra="Public board can be accessed by anyone with the link"
          >
            <div>
              <Switch
                checkedChildren="Public"
                unCheckedChildren="Private"
                defaultChecked
                onChange={(checked:boolean) => { form.setFieldsValue({ public: checked }) }}
              />
            </div>
          </Form.Item>
        </Fragment>}

        <Form.Item >
          <Space>
            <Button type="primary" htmlType="submit">
              { isEditMode() ? 'Update' : 'Create'}
            </Button>
            <Button onClick={onCreateCancelled}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default BoardForm;
