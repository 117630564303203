import React from "react";
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { Breadcrumb } from 'antd';

import { CheckinGroupList } from './modules/checkins';

const CheckinPage: React.FC<any> = () => {
  return (
    <Switch>
      <Route>
        <div>
          <Breadcrumb className="page-breadcrum">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Check-ins</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <CheckinGroupList />
      </Route>
    </Switch>
  );
}

export default withRouter(CheckinPage);
