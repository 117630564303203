import _ from 'lodash-es';
import moment, { Moment } from 'moment-timezone';
import React, { useState, useEffect } from "react";
import { Spin, Space, Drawer, Timeline, DatePicker } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { get } from '../../http';
import { apiRoutes } from '../../const';
import { CheckinSession, Team } from '../../models';
import { useWindowDimensions } from '../../hooks';
import './styles.less';

const MmomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = MmomentDatePicker;

interface DailyJournalProps {
  currentTeam: Team;
  show: boolean;
  onClose: () => void;
}

const DailyJournal: React.FC<DailyJournalProps> = ({ currentTeam, show, onClose}) => {
  const { width } = useWindowDimensions();
  const [checkinSessions, setCheckinSessions] = useState<CheckinSession[]>();
  const [fromDate, setFromDate] = useState<Moment|null>(moment().tz(currentTeam.timezone).startOf('day').subtract(14, "days"));
  const [toDate, setToDate] = useState<Moment|null>(moment().tz(currentTeam.timezone).endOf('day'));

  useEffect(() => {
    if (currentTeam) {
      fetchMyCheckinSessions(currentTeam);
    }
  }, [currentTeam]);

  const fetchMyCheckinSessions = async (team: Team) => {
    const response: CheckinSession[] = await get(apiRoutes.checkinSessionListMine, {
      team: team.id,
    });

    if (response.length > 0) {
      if (response.length <= 5) {
        setFromDate(moment(moment(response[response.length-1].created_at).format('YYYY-MM-DD')).startOf('day').tz(currentTeam.timezone));
      } else {
        setFromDate(moment(moment(response[4].created_at).format('YYYY-MM-DD')).startOf('day').tz(currentTeam.timezone));
      }
    }
    setCheckinSessions(response);
  };

  const checkinSessionsToDisplay = _.filter(checkinSessions, (checkinSession:CheckinSession) => {
    const checkinTime = moment(checkinSession.created_at).tz(currentTeam.timezone);
    return checkinTime.isBetween(fromDate, toDate);
  });

  return (
    <Drawer title="My Daily Journey" width={width > 500 ? 500 : width} placement="right" onClose={onClose} open={show}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <RangePicker
          defaultValue={[fromDate, toDate]}
          onChange={(dates) => {
            if (dates && dates.length > 0) {
              setFromDate(dates[0].startOf('day'));
            }
            if (dates && dates.length > 1) {
              setToDate(dates[1].endOf('day'));
            }
          }}
        />
        {!checkinSessions && <Spin/>}
        {checkinSessions &&
          <Timeline style={{ marginTop: '1em' }}>
            {checkinSessionsToDisplay.map((checkinSession:CheckinSession) => {
              return (
                <Timeline.Item>
                  <b>{moment(checkinSession.created_at).tz(currentTeam.timezone).format('YYYY-MM-DD')}</b>
                  <p className="teamkit-show-line-break">{checkinSession.checkinitem_set[0].content}</p>
                </Timeline.Item>
              );
            })}
          </Timeline>}
      </Space>
    </Drawer>
  );
};

export default DailyJournal;
