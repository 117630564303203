import _ from 'lodash-es';
import React, { useContext, useState } from "react";
import './styles.less';
import { Space, Button, Form, Select } from 'antd';
import { apiRoutes } from '../../../../const';
import { Todo, TodoStatus, TodoEvent, TodoEventType, TodoStatusTitle } from '../../../../models';
import { AppContext } from '../../../../../store';
import { put } from '../../../../http';
import TodoStatusTag from '../TodoStatusTag';

const { Option } = Select;

interface TodoStatusDisplayProps {
  todo: Todo;
  onRemoteChanged: (event:TodoEvent) => void;
}
const TodoStatusDisplay:React.FC<TodoStatusDisplayProps> = ({ todo, onRemoteChanged }) => {
  const [form] = Form.useForm();
  const { state: { currentUser } } = useContext(AppContext);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onEditClicked = () => {
    setIsEditting(true);
  }
  
  if (!isEditting) {
    return (
      <div className="todo-editable-content" onClick={onEditClicked}>
        <TodoStatusTag status={todo.status} />
      </div>
    );
  }

  const onFormSubmit = async (values:any) => {
    setIsSubmitting(true);
    try {
      await put(apiRoutes.todoSingle(todo.id), _.assign({}, todo, {
        status: values.status,
      }));
      form.resetFields();
      setIsEditting(false);
      onRemoteChanged({
        type: TodoEventType.Update,
        user: currentUser,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  const cancelEdit = () => {
    form.resetFields();
    setIsEditting(false);
  }

  return (
    <Form
      initialValues={{ status: todo.status }}
      form={form}
      name={`todo-status-form-${todo?.id}`}
      onFinish={onFormSubmit}
      style={{ marginBottom: '1em' }}
    >
      <Form.Item
        name="status"
        rules={[{ required: true, message: 'Please select the status!' }]}
        style={{ marginBottom: 8 }}
      >
        <Select>
          <Option value={TodoStatus.Todo}>{TodoStatusTitle[TodoStatus.Todo]}</Option>
          <Option value={TodoStatus.InProgress}>{TodoStatusTitle[TodoStatus.InProgress]}</Option>
          <Option value={TodoStatus.Done}>{TodoStatusTitle[TodoStatus.Done]}</Option>
        </Select>
      </Form.Item>

      <div>
        <Space>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>Save</Button>
          <Button onClick={cancelEdit}>Cancel</Button>
        </Space>
      </div>
    </Form>
  )
}

export default TodoStatusDisplay;
