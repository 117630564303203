import _ from "lodash-es";
import moment from "moment-timezone";
import React, { useState, useEffect, useContext } from "react";
import { Form, Select, Rate, Input, Button, Space, Tag } from "antd";
import { put, post, del } from "../../http";
import { apiRoutes } from "../../const";
import { getCurrentTeam } from "../../helpers";
import { AppContext } from "../../../store";
import {
  CheckinGroup,
  CheckinSession,
  CheckinItem,
  CheckinItemType,
  CheckinItemStatus,
  User,
  CheckinItemCreate,
} from "../../models";
import { useForceUpdate } from "../../hooks";
import { customIcons, moodTagFromScore } from "./const";
import "./styles.less";

const { TextArea } = Input;

interface CheckinFormProps {
  checkinGroup: CheckinGroup;
  checkinSession?: CheckinSession;
  latestCheckinSession?: CheckinSession;
  members: Array<User>;
  allowCancel?: boolean;
  onSaved: () => void;
  onCanceled?: () => void;
}

const CheckinForm: React.FC<CheckinFormProps> = ({
  members,
  checkinGroup,
  checkinSession,
  latestCheckinSession,
  allowCancel,
  onSaved,
  onCanceled,
}) => {
  const forceUpdate = useForceUpdate();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [form] = Form.useForm();
  const [blockerIDs, setBlockerIDs] = useState<Array<number | string>>([1]);
  const [newBlockerID, setNewBlockerID] = useState<number>(2); // Start from 2 because 1 is already used
  const [futureIDs, setFutureIDs] = useState<Array<number | string>>([1]);
  const [newFutureID, setNewFutureID] = useState<number>(2); // Start from 2 because 1 is already used

  useEffect(() => {
    if (checkinSession) {
      const blockers = _.filter(
        checkinSession.checkinitem_set,
        (item) => item.type === CheckinItemType.Blocker
      );
      setBlockerIDs(_.map(blockers, (blocker) => blocker.id));
      const futureItems = _.filter(
        checkinSession.checkinitem_set,
        (item) => item.type === CheckinItemType.Future
      );
      setFutureIDs(_.map(futureItems, (futureItem) => futureItem.id));
    }
  }, [checkinSession]);

  let assigneeOptions = [];
  if (members) {
    assigneeOptions.push({
      value: null,
      label: "Unassigned",
    });

    _.each(members, (member: User) => {
      assigneeOptions.push({
        value: member.username,
        label: member.username,
      });
    });
  }

  const onFormSubmit = async (values: any) => {
    if (!checkinSession) {
      await handleCreateSession(values);
    } else {
      await handleUpdateSession(values);
    }
    onSaved();
  };

  const handleCreateSession = async (values: any) => {
    let checkinItems: Array<CheckinItemCreate> = [
      {
        type: CheckinItemType.Past,
        status: CheckinItemStatus.Done,
        content: values.past,
      },
    ];
    _.each(futureIDs, (futureID: number | string) => {
      if (!values[`future-${futureID}`]) {
        return;
      }
      checkinItems.push({
        type: CheckinItemType.Future,
        status: CheckinItemStatus.Todo,
        content: values[`future-${futureID}`],
      });
    });
    _.each(blockerIDs, (blockerID: number | string) => {
      if (!values[`blocker-${blockerID}`]) {
        return;
      }
      checkinItems.push({
        type: CheckinItemType.Blocker,
        status: CheckinItemStatus.Todo,
        content: values[`blocker-${blockerID}`],
        assignee: values[`assignee-${blockerID}`],
      });
    });
    let newCheckinSession = {
      mood_score: values.mood_score,
      checkin_group: checkinGroup.id,
      checkinitem_set: checkinItems,
    };
    await createCheckingSession(newCheckinSession);
  };

  const createCheckingSession = async (checkinSession: any) => {
    const data = await post(
      apiRoutes.checkinSessionList(checkinGroup.id),
      checkinSession
    );
    return data;
  };

  const handleUpdateSession = async (values: any) => {
    if (!checkinSession) {
      return;
    }

    let updatedSession = _.assign({}, checkinSession, {
      mood_score: values.mood_score,
    });
    await updateCheckingSession(checkinSession.id, updatedSession);

    const pastItem = _.find(
      checkinSession.checkinitem_set,
      (item) => item.type === CheckinItemType.Past
    );
    const futureItems = _.filter(
      checkinSession.checkinitem_set,
      (item) => item.type === CheckinItemType.Future
    );
    const blockers = _.filter(
      checkinSession.checkinitem_set,
      (item) => item.type === CheckinItemType.Blocker
    );
    if (pastItem && pastItem.content !== values.past) {
      await updateCheckinItem(
        pastItem.id,
        _.assign({}, pastItem, {
          content: values.past,
        })
      );
    }

    await updateCheckinItems(
      values,
      "future",
      futureIDs,
      CheckinItemType.Future,
      futureItems
    );
    await updateCheckinItems(
      values,
      "blocker",
      blockerIDs,
      CheckinItemType.Blocker,
      blockers
    );
  };

  const updateCheckinItems = async (
    values: any,
    prefix: string,
    newItemIDs: any[],
    type: CheckinItemType,
    checkinItems: CheckinItem[]
  ) => {
    let toDeleteItemIDs: string[] = [];

    for (let i = 0; i < checkinItems.length; i++) {
      const item: CheckinItem = checkinItems[i];
      if (!values[`${prefix}-${item.id}`]) {
        toDeleteItemIDs.push(item.id);
      }
    }
    await deleteCheckinItems(toDeleteItemIDs);

    for (let i = 0; i < newItemIDs.length; i++) {
      let itemID = newItemIDs[i];
      if (!values[`${prefix}-${itemID}`]) {
        continue;
      }
      if (typeof itemID === "number") {
        await createCheckinItem({
          type: type,
          status: CheckinItemStatus.Todo,
          content: values[`${prefix}-${itemID}`],
          assignee: values[`assignee-${itemID}`],
        });
      } else {
        await updateCheckinItem(itemID, {
          content: values[`${prefix}-${itemID}`],
          assignee: values[`assignee-${itemID}`],
        });
      }
    }
  };

  const createCheckinItem = async (checkinItem: any) => {
    const data = await post(
      apiRoutes.checkinItemList(checkinSession?.id),
      checkinItem
    );
    return data;
  };

  const updateCheckinItem = async (checkinItemID: string, values: any) => {
    const data = await put(
      apiRoutes.checkinItemSingle(checkinSession?.id, checkinItemID),
      values
    );
    return data;
  };

  const deleteCheckinItems = async (checkinItemIDs: string[]) => {
    for (let i = 0; i < checkinItemIDs.length; i++) {
      await deleteCheckinItem(checkinItemIDs[i]);
    }
    return;
  };

  const deleteCheckinItem = async (checkinItemID: string) => {
    const data = await del(
      apiRoutes.checkinItemSingle(checkinSession?.id, checkinItemID)
    );
    return data;
  };

  const updateCheckingSession = async (
    checkinSessionID: string,
    values: any
  ) => {
    const data = await put(
      apiRoutes.checkinSessionSingle(checkinGroup.id, checkinSessionID),
      values
    );
    return data;
  };

  const setPastItemFromLatestSessions = (
    latestCheckinSession: CheckinSession
  ) => {
    const latestFutureItems = latestCheckinSession?.checkinitem_set.filter(
      (item) => item.type === CheckinItemType.Future
    );
    form.setFieldsValue({
      past: _.map(latestFutureItems, (item) => item.content).join("\n"),
    });
  };

  const onPrefillBlockersClicked = () => {
    const latestBlockers = latestCheckinSession?.checkinitem_set.filter(
      (item) =>
        item.type === CheckinItemType.Blocker &&
        item.status === CheckinItemStatus.Todo
    );
    if (latestBlockers) {
      setBlockerIDs(_.map(latestBlockers, (item) => item.id));
      _.each(latestBlockers, (blocker) => {
        form.setFieldsValue({
          [`blocker-${blocker.id}`]: blocker.content,
          [`assignee-${blocker.id}`]: blocker.assignee,
        });
      });
    }
  };

  let initialValues = {};
  if (checkinSession) {
    const passItem = _.find(
      checkinSession.checkinitem_set,
      (item) => item.type === CheckinItemType.Past
    );
    const futureItems = _.filter(
      checkinSession.checkinitem_set,
      (item) => item.type === CheckinItemType.Future
    );
    const blockers = _.filter(
      checkinSession.checkinitem_set,
      (item) => item.type === CheckinItemType.Blocker
    );

    initialValues = {
      mood_score: checkinSession.mood_score,
      past: passItem?.content,
    };
    _.each(futureItems, (item: CheckinItem) => {
      initialValues[`future-${item.id}`] = item.content;
    });
    _.each(blockers, (blocker: CheckinItem) => {
      initialValues[`blocker-${blocker.id}`] = blocker.content;
      initialValues[`assignee-${blocker.id}`] = blocker.assignee;
    });
  }

  return (
    <Form form={form} onFinish={onFormSubmit} initialValues={initialValues}>
      <div>
        <b>How do you feel today?</b>
      </div>
      <Form.Item
        name="mood_score"
        rules={[{ required: true, message: "Please rate your feeling!" }]}
        style={{ display: "inline-block" }}
      >
        <Rate
          onChange={() => {
            forceUpdate();
          }}
          character={({ index }: { index: number }) => customIcons[index + 1]}
        />
      </Form.Item>
      &nbsp;&nbsp;
      {form.getFieldValue("mood_score") &&
        moodTagFromScore(form.getFieldValue("mood_score"))}
      <div>
        <b>What did you do yesterday?</b>
        {!checkinSession &&
          latestCheckinSession &&
          latestCheckinSession.checkin_group &&
          latestCheckinSession.checkin_group !== checkinGroup.id && (
            <Button
              type="link"
              onClick={() =>
                setPastItemFromLatestSessions(latestCheckinSession)
              }
            >
              Prefill from latest check-in on{" "}
              {moment(latestCheckinSession.created_at)
                .tz(currentTeam.timezone)
                .format("YYYY-MM-DD")}
            </Button>
          )}
      </div>
      <Form.Item name="past">
        <TextArea rows={4} />
      </Form.Item>
      <div>
        <b>What do you plan to work on today?</b>
      </div>
      <div className="blockers-container">
        {futureIDs.map((i) => {
          return (
            <div className="teamkit-display-flex">
              <div className="teamkit-flex-full">
                <Form.Item name={`future-${i}`}>
                  <TextArea rows={2} />
                </Form.Item>
              </div>
              <div style={{ marginLeft: 8 }}>
                <Button
                  danger
                  disabled={futureIDs.length <= 1}
                  onClick={() => {
                    setFutureIDs(_.filter(futureIDs, (id) => id !== i));
                  }}
                >
                  x
                </Button>
              </div>
            </div>
          );
        })}
        <Button
          onClick={() => {
            setFutureIDs([...futureIDs, newFutureID]);
            setNewFutureID(newFutureID + 1);
          }}
        >
          More item
        </Button>
      </div>
      <div>
        <b>Any blockers?</b>
        {!checkinSession && latestCheckinSession && (
          <Button type="link" onClick={onPrefillBlockersClicked}>
            Prefill un-resolved blockers from latest check-in on{" "}
            {moment(latestCheckinSession.created_at)
              .tz(currentTeam.timezone)
              .format("YYYY-MM-DD")}
          </Button>
        )}
      </div>
      <div className="blockers-container">
        {blockerIDs.map((i) => {
          return (
            <div className="teamkit-display-flex">
              <div className="teamkit-flex-full">
                <Form.Item name={`blocker-${i}`}>
                  <TextArea rows={2} />
                </Form.Item>
              </div>
              <div style={{ marginLeft: 8 }}>
                <Form.Item name={`assignee-${i}`}>
                  <Select
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={assigneeOptions}
                    defaultValue={null}
                  />
                </Form.Item>
              </div>
              <div style={{ marginLeft: 8 }}>
                <Button
                  danger
                  onClick={() => {
                    setBlockerIDs(_.filter(blockerIDs, (id) => id !== i));
                  }}
                >
                  x
                </Button>
              </div>
            </div>
          );
        })}
        <Button
          onClick={() => {
            setBlockerIDs([...blockerIDs, newBlockerID]);
            setNewBlockerID(newBlockerID + 1);
          }}
        >
          More blockers
        </Button>
      </div>
      <br />
      <div>
        <Space>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          {allowCancel && (
            <Button
              onClick={() => {
                onCanceled && onCanceled();
              }}
            >
              Cancel
            </Button>
          )}
        </Space>
      </div>
    </Form>
  );
};

export default CheckinForm;
