import _ from 'lodash-es';
import React, { Fragment, useEffect, useState } from "react";
import './styles.less';
import { Row, Col, Spin, Alert, Breadcrumb, Card, Modal, Drawer, Button, Popover } from 'antd';
import { CoffeeOutlined } from '@ant-design/icons';
import { useParams as useRouterParams, withRouter, RouteComponentProps } from 'react-router-dom';
import { apiRoutes } from '../../const';
import { InvitePopover } from '../common';
import { get } from '../../http';
import { getCurrentPathWithNewTeamIndex, getBasePathWithTeam } from '../../helpers';
import { VotingSession, User, VotingItem, VotingItemStatus, InviteType } from '../../models';
import VotingPanel from './VotingPanel';
import VotingSessionHistory from './VotingSessionHistory';

const SOCKET_CONNECT_RETRY_TIMEOUT_MS = 3000;

interface VotingSessionShowpProps extends RouteComponentProps<any> {
  currentUser: User;
}
const VotingSessionShow: React.FC<VotingSessionShowpProps> = ({ history, currentUser }) => {
  const votingSessionID = useRouterParams<any>().id;
  const teamIndex = useRouterParams<any>().team_index;

  const [votingSession, setVotingSession] = useState<VotingSession>();
  const [votingItems, setVotingItems] = useState<Array<VotingItem|undefined>>();
  const [members, setMembers] = useState<Array<User>>();
  const [onlineMembers, setOnlineMembers] = useState<{ [key: string]: boolean }>({[currentUser.username]: true});

  const [socket, setSocket] = useState<WebSocket>();
  const [socketHealthy, setSocketHealthy] = useState<boolean>(false);
  
  const [coffeeRequestor, setCoffeeRequestor] = useState<string|undefined>(undefined);
  const [showHistory, setShowHistory] = useState<boolean>(false);

  useEffect(() => {
    if (votingSessionID) {
      fetchVotingSession(votingSessionID);
      fetchVotingItems(votingSessionID);

      const _socket = connectSocket();
      return () => {
        _socket.onopen = null;
        _socket.onclose = null;
        _socket.onmessage = null;
        _socket.close();
      }
    }
  }, [votingSessionID]);

  useEffect(() => {
    fetchTeamMember(votingSession);
  }, [votingSession]);

  let coffeeRequestOverlay = null;
  if (coffeeRequestor) {
    coffeeRequestOverlay = (
      <Modal
          className="coffee-break-modal"
          visible={coffeeRequestor ? true : false}
          onCancel={() => setCoffeeRequestor(undefined)}
          closable={false}
        >
        <Card className="voting-score-card coffee-break-card">
          <CoffeeOutlined />
          <br />
          <br />
          <div className="username-text">{ coffeeRequestor }</div>
        </Card>
      </Modal>
    );
  }

  const fetchVotingSession = async function(votingSessionID:string) {
    const data = await get(apiRoutes.scrumPokerSessionSingle(votingSessionID));
    setVotingSession(data);
  }
  const fetchVotingItems = async function(votingSessionID:string) {
    const data = await get(apiRoutes.scrumPokerSessionItemList(votingSessionID));
    setVotingItems(data);
  }
  const fetchTeamMember = async function(votingSession?:VotingSession) {
    if (!votingSession) {
      return;
    }
    const data = await get(apiRoutes.teamMemberList(votingSession.team));
    setMembers(data);
  }

  const connectSocket = ():WebSocket => {
    const socketProtocol = window.location.protocol.replace('http', 'ws')
    const endpoint = `${socketProtocol}//${window.location.host}/ws/scrum-poker-session/${votingSessionID}/`;
    const _socket = new WebSocket(endpoint);
    _socket.onopen = () => {
      setSocketHealthy(true);
      _socket.onclose = socketOnClose(_socket, true);
      setSocket(_socket);
      _socket.send(JSON.stringify({
        change: 'ping',
        voting_session_id: votingSessionID,
        event: {
          owner: currentUser.username
        },
      }));
      fetchVotingItems(votingSessionID);
    };
    _socket.onmessage = getSocketOnMessageHandler(_socket);
    _socket.onclose = socketOnClose(_socket, false);
    return _socket;
  }

  const getSocketOnMessageHandler = (_socket:any) => {
    return (e:MessageEvent<any>) => {
      const data = JSON.parse(e.data);
      if (data?.event?.owner === currentUser?.username) {
        // this message is emit by this component itself, no need to do anything
        return;
      }

      if (data?.change === 'voting_item') {
        fetchVotingItems(votingSessionID);
      } else if (data?.change === 'vote') {
        fetchVotingItems(votingSessionID);
      } else if (data?.change === 'coffee' && data?.event?.owner) {
        setCoffeeRequestor(data.event.owner);
      } else if (data?.change === 'ping' && data?.event?.owner) {
        if (data?.event?.owner && data?.event?.owner !== currentUser?.username) {
          setOnlineMembers(_.assign({}, onlineMembers, {
            [data.event.owner]: true,
          }));
          pong(_socket);
        }
      } else if (data?.change === 'pong' && data?.event?.owner) {
        setOnlineMembers(_.assign({}, onlineMembers, {
          [data.event.owner]: true,
        }));
      } else if (data?.change === 'disconnected' && data?.event?.owner) {
        setOnlineMembers(_.assign({}, onlineMembers, {
          [data.event.owner]: false,
        }));
      }
    }
  }

  const socketOnClose = (_socket:WebSocket, connected:boolean) => {
    return () => {
      if (_socket) {
        _socket.onopen = null;
        _socket.onclose = null;
        _socket.onmessage = null;
      }
      setSocket(undefined);
      if (!connected) {
        // only set not healthy when the socket is never opened (connection error, not on disconnect). GCP auto disconnect socket conn every 30s, so conn close after open success is expected
        // see https://cloud.google.com/load-balancing/docs/https#websocket_support
        setSocketHealthy(false);
      }
      console.error('Web socket closed unexpectedly');
      setTimeout(() => {
        connectSocket();
      }, SOCKET_CONNECT_RETRY_TIMEOUT_MS)
    };
  };

  const onVotingItemChanged = (status:VotingItemStatus) => {
    socket?.send(JSON.stringify({
      change: 'voting_item',
      voting_session_id: votingSessionID,
      event: {
        status: status,
        owner: currentUser?.username
      },
    }));
    fetchVotingItems(votingSessionID);
  }

  const onVoteSubmitted = (votingItemID:string) => {
    socket?.send(JSON.stringify({
      change: 'vote',
      voting_session_id: votingSessionID,
      voting_item_id: votingItemID,
      event: {
        owner: currentUser?.username
      },
    }));
    fetchVotingItems(votingSessionID);
  }

  const emitCoffeeTime = () => {
    socket?.send(JSON.stringify({
      change: 'coffee',
      voting_session_id: votingSessionID,
      event: {
        owner: currentUser?.username
      },
    }));
  }

  const pong = (_socket:any) => {
    _socket?.send(JSON.stringify({
      change: 'pong',
      voting_session_id: votingSessionID,
      event: {
        owner: currentUser?.username
      },
    }));
  }

  if (!(votingSession && currentUser)) {
    return (
      <Spin />
    );
  }

  const intTeamIndex = parseInt(teamIndex);
  // Redirect if the tem index is not valid
  if (isNaN(intTeamIndex) || intTeamIndex < 0 || intTeamIndex >= currentUser.team_membership.length || currentUser.team_membership[intTeamIndex].id !== votingSession.team) {
    let newTeamIndex = -1;
    currentUser.team_membership.forEach((team, i) => {
      if (team.id === votingSession.team) {
        newTeamIndex = i;
      }
    });

    if (newTeamIndex >= 0) {
      history.push(getCurrentPathWithNewTeamIndex(newTeamIndex));
    } else {
      return (
        <div>
          Not found
        </div>
      );
    }
  }

  const addUndefinedNewItem = () => {
    setVotingItems(_.concat(votingItems, undefined));
  }

  const removeLastUndefinedNewItem = () => {
    if (!votingItems || votingItems.length === 0 || votingItems[votingItems.length-1]) {
      return;
    }
    setVotingItems(_.dropRight(votingItems));
  }

  const showCreateNewItem = () => {
    if (!votingItems || votingItems.length === 0) {
      // when there is no item, the voting item form will be shown by default
      return false;
    }
    const lastVotingItem = votingItems[votingItems.length-1];
    if (!lastVotingItem) {
      // if last voting item is undefiend, that me the voting item form is showned, no need to show create button
      return false;
    }
    if (lastVotingItem.status !== VotingItemStatus.Ended) {
      return false;
    }

    return true;
  }

  return (
    <Fragment>
      {coffeeRequestOverlay}
      {votingSession &&
        <Breadcrumb className="page-breadcrum">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a onClick={() => history.push(`${getBasePathWithTeam()}/scrum-poker-sessions`)}>Scrum Poker</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{votingSession.name}</Breadcrumb.Item>
        </Breadcrumb>}
      {!socketHealthy &&
        <Row style={{ marginBottom: '15px' }}>
          <Col xs={24}>
            <Alert message="Lost connection to server, updates from other users will not be  reflected. Trying to reconect ..." type="warning" />
          </Col>
        </Row>}
      <div>
        <div className="voting-session-show-control-panel">
          <div style={{ flex: 1 }}></div>
          {showCreateNewItem() && <Button type="primary" onClick={() => addUndefinedNewItem()}>New Vote Item</Button>}
          <Button onClick={() => setShowHistory(true)}>Show History</Button>
          <Button onClick={emitCoffeeTime}><CoffeeOutlined /></Button>
          <Popover placement="bottomRight" content={<InvitePopover inviteType={InviteType.ScrumPoker} inviteID={votingSession.id} inviteResource="scrum poker session"  inviteResourceName={votingSession.name} />} trigger="click">
            <Button>Share & Invite</Button>
          </Popover>
        </div>
        <Drawer
          title="Voting history"
          width={350}
          placement="right"
          closable={true}
          onClose={() => setShowHistory(false)}
          visible={showHistory}
        >
          <VotingSessionHistory votingSession={votingSession} votingItems={votingItems} />
        </Drawer>
      </div>
      <VotingPanel
        currentUser={currentUser}
        onlineMembers={onlineMembers}
        votingSession={votingSession}
        totalItems={votingItems ? votingItems.length : 0}
        votingItem={(!votingItems || votingItems.length === 0) ? undefined : votingItems[votingItems.length-1]}
        onVoteSubmitted={onVoteSubmitted}
        cancelNewItem={removeLastUndefinedNewItem}
        onVotingItemChanged={onVotingItemChanged}
        members={members}
      />
    </Fragment>
  );
}

export default withRouter(VotingSessionShow);
