import React from "react";
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { Breadcrumb } from 'antd';
import { getBasePathWithTeam } from './helpers';
import { APP_BASE_PATH } from './const';

import { BoardList, BoardShow } from './modules/boards';

const BoardPage: React.FC<any> = ({ history }) => {
  return (
    <Switch>
      <Route path={[`${APP_BASE_PATH}t/:team_index/boards/:id`, `${APP_BASE_PATH}boards/:id`]}>
        <BoardShow />
      </Route>
      <Route>
        <div>
          <Breadcrumb className="page-breadcrum">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Retrospective Boards</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <BoardList />
      </Route>
    </Switch>
  );
}

export default withRouter(BoardPage);
