import _ from 'lodash-es';
import React from "react";
import './styles.less';
import { Tag } from 'antd';
import { TargetStatus, TargetStatusTitle } from '../../../models';

interface TargetStatusTagProps {
  status: TargetStatus;
}
const TargetStatusTag: React.FC<TargetStatusTagProps> = ({ status }) => {
  let color = 'grey';
  if (status === TargetStatus.OnTrack) {
    color = 'blue';
  } else if (status === TargetStatus.AtRisk) {
    color = 'red';
  } else if (status === TargetStatus.Done) {
    color = 'green';
  }
  
  return (
    <Tag className="status-tag" color={color}>{_.toUpper(TargetStatusTitle[status] ? TargetStatusTitle[status] : status)}</Tag>
  );
}

export default TargetStatusTag;
