import _ from "lodash-es";
import React, { useEffect, useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Spin, Carousel, Empty } from "antd";
import { get } from "../../http";
import { apiRoutes, appRoutes } from "../../const";
import { AppContext } from "../../../store";
import { getCurrentTeam, getBasePathWithTeam } from "../../helpers";
import { GoalWithTarget, Team, GoalGroup, Goal } from "../../models";
import { FirstGoalCard, GoalProgressBar } from "../goals/goal";
import WidgetContainer from "./WidgetContainer";

interface GoalGroupSummaryProps {
  goalGroup: GoalGroup;
}

const GoalGroupSummary: React.FC<GoalGroupSummaryProps> = ({ goalGroup }) => {
  const [goals, setGoals] = useState<Array<Goal>>();
  const [goalWithTargets, setGoalWithTargets] =
    useState<Array<GoalWithTarget>>();

  useEffect(() => {
    if (goalGroup) {
      fetchGoals(goalGroup.id);
    }
  }, [goalGroup]);

  useEffect(() => {
    if (goals) {
      fetchTargets(goals);
    }
  }, [goals]);

  const fetchGoals = async function (goalGroupID: string) {
    const data = await get(apiRoutes.goalList(goalGroupID));
    setGoals(data);
  };

  const fetchTargets = async function (goals: Array<Goal>) {
    if (!goals) {
      return;
    }

    let _goalWithTargets: Array<GoalWithTarget> = [];
    for (let goal of goals) {
      const data = await get(apiRoutes.goalTargetList(goal.id));
      _goalWithTargets.push({
        goal: goal,
        targets: data,
      });
    }
    setGoalWithTargets(_goalWithTargets);
  };

  if (!goalWithTargets) {
    return <Spin />;
  }

  return (
    <>
      <h3>{goalGroup.name}</h3>
      {goalWithTargets.length === 0 && <i>No target</i>}
      {goalWithTargets.length > 0 &&
        _.map(goalWithTargets, (goalWithTarget: GoalWithTarget) => {
          return (
            <div style={{ paddingRight: 20, overflow: "hidden" }}>
              <div>{goalWithTarget.goal.title}</div>
              <GoalProgressBar
                asProgressBar
                goal={goalWithTarget.goal}
                targets={goalWithTarget.targets}
              />
            </div>
          );
        })}
    </>
  );
};

interface GoalGroupDetailsProps {
  goalGroups: GoalGroup[];
}

const GoalGroupDetails: React.FC<GoalGroupDetailsProps> = ({ goalGroups }) => {
  if (!goalGroups || goalGroups.length === 0) {
    return <Empty />;
  }

  if (goalGroups.length === 1) {
    return <GoalGroupSummary goalGroup={goalGroups[0]} />;
  }

  return (
    <Carousel dotPosition="top" style={{ paddingTop: 45 }}>
      {_.map(goalGroups, (goalGroup: GoalGroup) => {
        return (
          <div>
            <GoalGroupSummary goalGroup={goalGroup} />
          </div>
        );
      })}
    </Carousel>
  );
};

const GoalWidget: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [myGoalGroups, setMyGoalGroups] = useState<
    Array<GoalGroup> | undefined
  >(undefined);

  useEffect(() => {
    if (currentTeam) {
      fetchGoalGroups(currentTeam);
    }
  }, [currentTeam]);

  const fetchGoalGroups = async function (team: Team) {
    const data: Array<GoalGroup> = await get(apiRoutes.goalGroupList, {
      team: team.id,
      archived: false,
    });

    const _myGoalGroups: Array<GoalGroup> = _.filter(
      data,
      (goalGroup: GoalGroup) => {
        return goalGroup.owner === currentUser?.username;
      }
    );

    setMyGoalGroups(_myGoalGroups);
  };

  const onCreateGoalClick = () => {
    history.push(`${getBasePathWithTeam()}/goals/?show_create=true`);
  };

  return (
    <WidgetContainer route={appRoutes.goals} title="Goals">
      {!myGoalGroups && <Spin />}
      {myGoalGroups && myGoalGroups.length === 0 && (
        <FirstGoalCard forWidget onCreateClicked={onCreateGoalClick} />
      )}
      {myGoalGroups && myGoalGroups.length > 0 && <GoalGroupDetails goalGroups={myGoalGroups} />}
    </WidgetContainer>
  );
};

export default withRouter(GoalWidget);
