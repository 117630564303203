import _ from 'lodash-es';
import React from "react";
import { appRoutes } from '../../const';
import { Result } from 'antd';

const NotFound: React.FC<{}> = () => {
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<div style={{ textAlign: 'center' }}>
        <a href={appRoutes.home(0)}>Back to Home</a>
      </div>}
      />
    </>
  );
}

export default NotFound;
