import _ from 'lodash-es';
import React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeleteOutlined, LockOutlined, TeamOutlined, CalendarOutlined, UserOutlined, ShareAltOutlined, ContainerOutlined, UndoOutlined } from '@ant-design/icons';
import { Card, Row, Col, Popconfirm, Space, Tooltip } from 'antd';
import { apiRoutes } from '../../const';
import { del, put } from '../../http';
import { getBasePathWithTeam } from '../../helpers';
import { formatPeriod } from './helpers';
import { GoalGroup, GoalGroupVisibility, GoalGroupShare } from '../../models';
import './styles.less';


interface CardTitleProps {
  goalGroup: GoalGroup;
  history: any;
}

const CardTitle: React.FC<CardTitleProps> = ({ goalGroup, history }) => {

  return (
    <div onClick={() => { history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}`) }}>
      <div>{goalGroup.name}</div>
    </div>
  );
}

interface GoalGroupCardProps extends RouteComponentProps<any> {
  goalGroup: GoalGroup;
  editable: boolean;
  onRemoteChanged: () => void;
}

const GoalGroupCard: React.FC<GoalGroupCardProps> = ({ goalGroup, editable, onRemoteChanged, history }) => {
  const deleteGoalGroup = async function(goalGroupID:string) {
    await del(apiRoutes.goalGroupSingle(goalGroupID));
    onRemoteChanged();
  }

  const updateArchiveStatus = async function(archived: boolean) {
    const archivedGoalGroup = _.assign({}, goalGroup, {
      archived,
    });
    await put(apiRoutes.goalGroupSingle(goalGroup.id), archivedGoalGroup);
    onRemoteChanged();
  }

  let actions: Array<React.ReactNode> = [];
  if (editable) {
    if (goalGroup.archived) {
      actions.push(
        <Popconfirm
          key="unarchive"
          placement="top"
          title={"Are you sure you want to unarchive the goal group?"}
          onConfirm={() => updateArchiveStatus(false)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip placement="bottom" title="Unarchive">
            <UndoOutlined />
          </Tooltip>
        </Popconfirm>
      );
    } else {
      actions.push(
        <Popconfirm
          key="archive"
          placement="top"
          title={"Are you sure you want to archive the goal group? You still can retrieve it later."}
          onConfirm={() => updateArchiveStatus(true)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip placement="bottom" title="Archive">
            <ContainerOutlined />
          </Tooltip>
        </Popconfirm>
      );
    }
    actions.push(
      <Popconfirm
        key="delete"
        placement="top"
        title={`Are you sure you want to delete? You won't be able to retrive it later.${!goalGroup.archived ? ' If you want to just hide it, use the Archive option instead.' : ''}`}
        onConfirm={()=>{deleteGoalGroup(goalGroup.id)}}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip placement="bottom" title="Delete">
          <DeleteOutlined />
        </Tooltip>
      </Popconfirm>
    );
  }

  const onCardClick = (e:any) => {
    // Special handler for card body click, to handle clicks that are in the padding
    const allowedClasses: Array<string> = [
      'ant-card-body',
      'ant-card-head',
      'ant-card-head-title'
    ]
    if (_.includes(allowedClasses,e.target.className)) {
      history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}`);
    }
  }

  return (
    <Card
      className="teamkit-small-listing-card"
      title={<CardTitle goalGroup={goalGroup} history={history} />}
      actions={actions}
      onClick={onCardClick}
    >
      <div onClick={() => { history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}`) }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row>
            <Col span={24}>
              <div className="goal-group-details-summary">
                {goalGroup.visibility === GoalGroupVisibility.Private ? <span><LockOutlined /> Private</span> : <span><TeamOutlined /> Public</span>}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <CalendarOutlined /> {formatPeriod(goalGroup)}
              </div>
              
              <div className="goal-group-details-summary">
                <UserOutlined /> Owner: {goalGroup.owner}
                {goalGroup.visibility === GoalGroupVisibility.Private && <>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <ShareAltOutlined /> Shares: {_.size(goalGroup.goalgroupshare_set) === 0 ? 'None' : _.map(goalGroup.goalgroupshare_set, (share: GoalGroupShare) => share.user).join(', ')}
                </>}
              </div>
              
              
            </Col>
          </Row>
        </Space>
      </div>
    </Card>
  );
}

export default withRouter(GoalGroupCard);
