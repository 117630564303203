import React from "react";
import {
  withRouter
} from "react-router-dom";
import { BoardShow } from './boards';

const BoardPage: React.FC<any> = ({ history }) => {
  return (
    <div>
      <BoardShow />
    </div>
  );
}

export default withRouter(BoardPage);
