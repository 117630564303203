import _ from 'lodash-es';
import React, { useState, useContext, createElement } from 'react';
import './styles.less';
import { Card, Button, Dropdown, Menu, Tooltip, Space, Divider, Modal, Input } from 'antd';
import { Comment } from '@ant-design/compatible';
import { EllipsisOutlined, LikeOutlined, LikeFilled, LoadingOutlined, CommentOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { MdDragIndicator } from 'react-icons/md';
import { Item, Todo, Comment as CommentModel, ItemEvent, ItemEventType, TodoEvent, User, Board } from '../../../models';
import { apiRoutes } from '../../../const';
import { post, del } from '../../../http';
import { getItemCacheKey } from '../../../helpers';
import { AppContext } from '../../../../store';
import { UserAvatar } from '../../common';
import ItemForm from './ItemForm';
import { TodoForm } from '../todos';
import TodoCard from './TodoCard';
import { CommentForm } from '../../comments';
import EditableComment from './EditableComment';

const { confirm } = Modal;
const { TextArea } = Input;

interface ItemCardProps {
  board: Board;
  item: Item;
  todos: Array<Todo>;
  members?: Array<User>;
  dragHandleProps?: any;
  presentationMode: boolean;
  onCardUpdate: (event:ItemEvent) => void;
  onTodoUpdate: (event:TodoEvent) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ board, item, todos, members, dragHandleProps, presentationMode, onCardUpdate, onTodoUpdate }) => {
  const { state: { currentUser, liveEditingItems, liveEditingTodos, liveEditingComments }, dispatch } = useContext(AppContext);
  let [waitingRemote, setWaitingRemote] = useState<boolean>(false);
  let [showCommentEditor, setShowCommentEditor] = useState<boolean>(liveEditingComments[`item-${item.id}`] ? true : false);
  let [showTodoCreateForm, setShowTodoCreateForm] = useState<boolean>(liveEditingTodos[`todo-${item.id}-${undefined}`] ? true : false);

  const editCacheKey = getItemCacheKey(item.id);
  let totalLikes = 0;
  let isLiked = false;
  _.each(item.itemreaction_set, (reaction) => {
    totalLikes += reaction.upvotes;
    if (reaction.owner === currentUser?.username && reaction.upvotes > 0) {
      isLiked = true;
    }
  });

  let editingItem:Item|undefined;
  _.each(liveEditingItems, (i:Item) => {
    if (i.id === item.id) {
      editingItem = i;
    }
  });

  const setEditMode = (isEditting:boolean) => {
    if (isEditting === false) {
      delete liveEditingItems[editCacheKey];
      dispatch({ liveEditingItems });
    } else {
      dispatch({
        liveEditingItems: _.assign({}, liveEditingItems, {
          [editCacheKey]: item,
        }),
      });
    }
  }

  const onDeleteClicked = async (boardID:string, itemID:string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete?',
      onOk: async () => {
        await deleteItem(boardID, itemID);
      },
      onCancel: () => {},
    });
  }

  const deleteItem = async (boardID:string, itemID:string) => {
    await del(apiRoutes.boardItemSingle(boardID, itemID));
    onCardUpdate({
      type: ItemEventType.Delete,
      user: !item.anonymous ? currentUser : undefined
    });
  }

  const deleteComment = async (boardID:string, itemID:string, commentID:string) => {
    await del(apiRoutes.boardItemCommentSingle(boardID, itemID, commentID));
    onCardUpdate({
      type: ItemEventType.RemoveComment,
      user: currentUser
    });
  }

  const toggleLike = async () => {
    setWaitingRemote(true);
    try {
      if (isLiked) {
        await dislike();
        onCardUpdate({
          type: ItemEventType.Dislike,
          user: currentUser
        });
      } else {
        await like();
        onCardUpdate({
          type: ItemEventType.Like,
          user: currentUser
        });
      }
    } finally {
      setWaitingRemote(false);
    }
  };

  const like = async () => {
    await post(apiRoutes.itemLike(item.id), {});
  };

  const dislike = async () => {
    await post(apiRoutes.itemDislike(item.id), {});
  };

  const onEditClicked = () => {
    setEditMode(true);
  }

  const onUpdateCompleted = (event:ItemEvent) => {
    setEditMode(false);
    onCardUpdate(event);
  }

  const onTodoCreated = (event:TodoEvent) => {
    onTodoUpdate(event);
    setShowTodoCreateForm(false);
  }

  const menu = (
    <Menu>
      <Menu.Item key="item-create-task" onClick={() => { setShowTodoCreateForm(true) }}>Create task</Menu.Item>
      <Divider style={{ margin: 0 }} />
      <Menu.Item key="item-edit" onClick={onEditClicked}>Edit</Menu.Item>
      <Menu.Item key="item-delete" onClick={()=>{onDeleteClicked(board.id, item.id)}}>Delete</Menu.Item>
    </Menu>
  );

  const actions = [];
  if (presentationMode) {
    actions.push(
      <span>
        <Space>
          <LikeOutlined />
          <span>{totalLikes}</span>
        </Space>
      </span>,
      <span>
        <Space>
          <span className="comment-reply-link"><CommentOutlined /></span>
          <span>{item.itemcomment_set.length}</span>
        </Space>
      </span>,
    );
  } else {
    actions.push(
      <Tooltip key="item-like" title={isLiked ? 'Unlike' : 'Like'}>
        <span onClick={!waitingRemote ? toggleLike : () => {}}>
          <Space>
            {createElement(isLiked ? LikeFilled : LikeOutlined)}
            {waitingRemote ? <LoadingOutlined /> : <span>{totalLikes}</span>}
          </Space>
        </span>
      </Tooltip>,
      <Tooltip key="item-reply" title={'Reply'}>
        <span onClick={() => { setShowCommentEditor(true) }}>
          <Space>
            <span className="comment-reply-link"><CommentOutlined /></span>
            <span>{item.itemcomment_set.length}</span>
          </Space>
        </span>
      </Tooltip>,
    );
  }
  actions.push(
    <span onClick={() => { setShowTodoCreateForm(true) }}>Create task</span>
  );

  let extraProps = {};
  if (dragHandleProps) {
    extraProps = dragHandleProps;
  }
  return (
    <Card className="item-card">
      <span className="item-card-drag-span" {...extraProps}>
        <MdDragIndicator />
      </span>
      {editingItem && 
        <ItemForm
          board={board}
          item={editingItem}
          boardColumnID={item.board_column}
          onModificationCompleted={ onUpdateCompleted }
          onCancel={() => { setEditMode(false) }}
        />}
      {!editingItem &&
        <div>
          <Space direction="vertical" style={{ width: '100%' }}>
          <Comment
            className="teamkit-comment"
            actions={actions}
            author={item.anonymous || item.owner === 'public' ? 'Anonymous' : item.owner}
            avatar={<UserAvatar username={item.anonymous ? undefined : item.owner} />}
            content={
              <TextArea bordered={false} autoSize readOnly value={item.content} />
            }
          >
            {item.itemcomment_set.length > 0 && <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
              {_.map(item.itemcomment_set, (comment:CommentModel) => {
                return (
                  <EditableComment
                    board={board}
                    item={item}
                    comment={comment}
                    presentationMode={presentationMode}
                    onCommentDeleted={onCardUpdate}
                    onCommentEdited={onCardUpdate}
                  />
                );
              })}
            </Space>}
            {showCommentEditor && <Comment
              style={{ marginTop: '8px' }}
              className="item-comment-form"
              avatar={
                <UserAvatar username={currentUser?.username} />
              }
              content={
                <CommentForm
                  boardID={board.id}
                  item={item}
                  onCancel={() => { setShowCommentEditor(false) }}
                  onCommentCreated = { () => {
                    setShowCommentEditor(false);
                    onCardUpdate({
                      type: ItemEventType.AddComment,
                      user: currentUser,
                    });
                  }}
                />
              }
            />}
          </Comment>
          {_.map(todos, (todo:Todo) => {
            return (
              <TodoCard
                boardID={board.id}
                todo={todo}
                members={members}
                onRemoteChanged={(todoEvent:TodoEvent) => { onTodoUpdate(todoEvent) }}
              />
            );
          })}
          {showTodoCreateForm && <Card className="column-card-form">
            <TodoForm
              boardID={board.id}
              itemID={item.id}
              cancelOnCreate={true}
              onCancel={() => { setShowTodoCreateForm(false) }}
              onFormSubmitted={onTodoCreated}
              members={members}
            />
          </Card>}
          {!presentationMode && currentUser && currentUser.username === item.owner && <Dropdown overlay={menu}>
            <Button size="small" className="item-card-menu-button">
              <EllipsisOutlined key="ellipsis" />
            </Button>
          </Dropdown>}
          </Space>
        </div>}
      
    </Card>
  );
}

export default ItemCard;
