import _ from 'lodash-es';
import React, { useState } from "react";
import { formatPeriod } from './helpers';
import { Row, Col, Card, Space, Tag } from 'antd';
import { EditOutlined, LockOutlined, TeamOutlined, CalendarOutlined, ShareAltOutlined, UserOutlined } from '@ant-design/icons';
import GoalGroupForm from './GoalGroupForm';
import { GoalGroup, GoalGroupVisibility, GoalGroupShare, User } from '../../models';
import './styles.less';

interface GoalGroupDetailsProps {
  goalGroup: GoalGroup;
  currentUser: User;
  onUpdateCompleted: () => void;
}
const GoalGroupDetails: React.FC<GoalGroupDetailsProps> = ({ goalGroup, currentUser, onUpdateCompleted }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const canEditGoalGroup = (): boolean => {
    return !goalGroup.archived && goalGroup.owner === currentUser.username;
  }

  const onEditClicked = () => {
    if (canEditGoalGroup()) {
      setEditMode(true);
    }
  }

  let detailCardClassName = 'teamkit-show-top-detail-card-no-edit';
  if (canEditGoalGroup()) {
    detailCardClassName = 'teamkit-show-top-detail-card';
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        {!editMode &&
          <Card className={detailCardClassName} onClick={onEditClicked}>
            <Space>
              <h2>{goalGroup.archived && <><Tag color="grey">Archived</Tag></>}{goalGroup.name}</h2>
              <EditOutlined />
            </Space>
            
            <div className="goal-group-details-summary">
              {goalGroup.visibility === GoalGroupVisibility.Private ? <span><LockOutlined /> Private</span> : <span><TeamOutlined /> Public</span>}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <CalendarOutlined /> {formatPeriod(goalGroup)}
            </div>
            
            <div className="goal-group-details-summary">
              <UserOutlined /> Owner: {goalGroup.owner}
            </div>
            
            {goalGroup.visibility === GoalGroupVisibility.Private && <div className="goal-group-details-summary">
              <ShareAltOutlined /> Shares: {_.size(goalGroup.goalgroupshare_set) == 0 ? 'None' : _.map(goalGroup.goalgroupshare_set, (share: GoalGroupShare) => share.user).join(', ')}
            </div>}
          </Card>}
        {editMode &&
          <GoalGroupForm
          goalGroup={goalGroup}
            onCreateCancelled={() => { setEditMode(false) }}
            onCreateCompleted={() => {
              onUpdateCompleted();
              setEditMode(false);
            }}
          />}
      </Col>
    </Row>
  );
}

export default GoalGroupDetails;
