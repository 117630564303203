import _ from 'lodash-es';
import { withRouter, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import React, { useContext, useState, useEffect } from "react";
import './styles.less';
import { Button, Select, Space } from 'antd';
import { apiRoutes } from '../../../const';
import { Team, TodoLabel, User } from '../../../models';
import { AppContext } from '../../../../store';
import { get } from '../../../http';
import { getCurrentTeam } from '../../../helpers';

const { Option } = Select;

interface TodoFilterProps extends RouteComponentProps {
  visible: boolean;
  members?: Array<User>;
}
const TodoFilter:React.FC<TodoFilterProps> = ({ history, location, members }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [existingTodoLabels, setExistingTodoLabels] = useState<Array<TodoLabel>>([]);

  useEffect(() => {
    if (currentTeam) {
      fetchExistingTodoLabels(currentTeam);
    }
  }, [currentTeam]);

  const fetchExistingTodoLabels = async function(team:Team) {
    const data = await get(apiRoutes.todoLabelListStandAlone, {
      team: team.id
    });
    setExistingTodoLabels(data);
  }

  const clearAllFilter = () => {
    history.push({
      pathname: location.pathname
    });
  }

  const onAssigneeChange = (values:any) => {
    const queryParams = [];
    let { labels } = queryString.parse(location.search);
    if (labels) {
      queryParams.push(`labels=${labels}`);
    }
    if (values.length > 0) {
      queryParams.push(`assignees=${values.join(',')}`);
    }
    history.push({
      pathname: location.pathname,
      search: `?${queryParams.join('&')}`
    });
  }

  const onLabelChange = (values:any) => {
    const queryParams = [];
    let { assignees } = queryString.parse(location.search);
    if (assignees) {
      queryParams.push(`assignees=${assignees}`);
    }
    if (values.length > 0) {
      queryParams.push(`labels=${values.join(',')}`);
    }
    history.push({
      pathname: location.pathname,
      search: `?${queryParams.join('&')}`
    });
  }
  
  let labelOptions = _.map(existingTodoLabels, (label:string,i:number) => {
    return <Option key={`label-${i}`} value={label}>{label}</Option>;
  });

  let assigneeOptions = _.map(members, (member:User,i:number) => {
    let username = member.username;
    if (member.username === currentUser?.username) {
      username = 'Assigned to me';
    }
    return <Option key={`member-${i}`} value={member.username}>{username}</Option>;
  });

  let { labels, assignees } = queryString.parse(location.search);

  return (
    <Space direction="vertical" className="todo-filter-popover">
      <div>
        Labels
      </div>
      <div>
        <Select style={{ width: '100%' }} value={Array.isArray(labels) ? labels : labels ? labels.split(',') : []} mode="tags" placeholder="Label" allowClear onChange={onLabelChange}>
          {labelOptions}
        </Select>
      </div>
      <div>
        Assignees
      </div>
      <div>
        <Select style={{ width: '100%' }} value={Array.isArray(assignees) ? assignees : assignees ? assignees.split(',') : []} mode="tags" placeholder="Assignee" allowClear onChange={onAssigneeChange}>
          {assigneeOptions}
        </Select>
      </div>
      <div>
        <Button onClick={clearAllFilter}>Clear all</Button>
      </div>
    </Space>
  )
}

export default withRouter(TodoFilter);
