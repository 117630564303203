import React, { useEffect } from "react";
import './AboutPage.less';
import { Layout, Typography, } from 'antd';

const { Paragraph } = Typography;

const { Content } = Layout;

const AboutPage: React.FC<{}> = () => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = 'RetroPlus - About';
    return () => {
      document.title = currentTitle;
    }
  }, []);
  
  return (
    <>
      <div
        className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 rounded-lg mt-10 mb-10"
        style={{ background: "white" }}
      >
        <Paragraph>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our story
          </h3>
          <div className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            <p>
              <b>RetroPlus</b> aimed to be a{" "}
              <span className="primary-color-text">one stop destination</span>{" "}
              that provides the right tools to run a highly efficient scrum
              team. The app is built by a software engineer, who has experience
              in running a scrum team in a big product company. The tool is
              inspired from his real life experiences, with a hope to help many
              other teams around the world to operate better.
            </p>
            <p>
              RetroPlus started as a retrospective tool. We truly believe that
              retrospective is a must-have process for any success team. That is
              where big problems are identified, and great ideas are borned.
            </p>
          </div>
        </Paragraph>
      </div>
      <div
        className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 rounded-lg mb-10"
        style={{ background: "white" }}
      >
        <Paragraph>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why choose us?
          </h3>
          <div className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            <p>
              <b>RetroPlus</b> offers many great features at a very competitive
              price. This came from a real life problem. Our team found many
              useful tools for our operation, but oftenly, the free tier
              subscription offers too limited features, and the paid
              subscription is too expensive that it actually targets the entire
              company instead of a single team.
            </p>
            <p>
              At RetroPlus, we believe every team is different, and therefore,
              need a different combination of tools for their day to day
              operation. No more lengthy company wide discussion to opt-in to a
              huge enterprise subscription. Every team can mix and match RetroPlus
              features to cater for their own needs, and pay a relatively low
              price that is optimized for a small team instead of the entire
              organization.
            </p>
          </div>
        </Paragraph>
      </div>
    </>
  );
}

export default AboutPage;
