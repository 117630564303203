import _ from 'lodash-es';
import React, { useContext } from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";
import './MainHeader.less';
import { Layout, Avatar, Menu, Dropdown } from 'antd';
import { RightOutlined, MenuOutlined } from '@ant-design/icons';
import { AppContext } from './store';
import { djangoPageRoutes, APP_BASE_PATH } from './components/const';
import { UserAvatar } from './components/modules/common';
import iconImg from './resources/images/logo192.png';

const { Header } = Layout;
const { SubMenu } = Menu;

const MainHeader: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);

  const menu = (
    <Menu mode="horizontal" style={{justifyContent: 'flex-end'}}>
      <SubMenu key="/features" title="Features">
        <Menu.Item key="/features/retrospective" onClick={() => { history.push('/features/retrospective') }}>Retrospective</Menu.Item>
        <Menu.Item key="/features/goal_tracking" onClick={() => { history.push('/features/goal_tracking') }}>Goal Tracking</Menu.Item>
        <Menu.Item key="/features/daily_checkin" onClick={() => { history.push('/features/daily_checkin') }}>Daily Check-in</Menu.Item>
        <Menu.Item key="/features/scrum_poker" onClick={() => { history.push('/features/scrum_poker') }}>Scrum Poker</Menu.Item>
      </SubMenu>
      <Menu.Item key="/about" onClick={() => { history.push('/about') }}>About us</Menu.Item>
      {currentUser &&
        <Menu.Item key="app" onClick={() => { history.push(APP_BASE_PATH)}}>
          <UserAvatar username={currentUser.username} />  Go to app <RightOutlined />
        </Menu.Item>}
      {!currentUser &&
        <Menu.Item key="login" onClick={() => { window.location.href=djangoPageRoutes.login }}>
          Login
        </Menu.Item>}
    </Menu>
  );

  return (
      <Header className="header" style={{ padding: 0 }}>
        <img src={iconImg} className="header-logo" alt="main-icon" onClick={() => { history.push('/')}} />
        <Dropdown overlay={menu} className="landing-header-menu-xs">
          <MenuOutlined />
        </Dropdown>
        <div className="landing-header-menu">
          {menu}
        </div>
      </Header>
  );
};

export default withRouter(MainHeader);
