import _ from 'lodash-es';
import React, { useEffect, useContext } from "react";
import { Button, Input, Tooltip, Spin, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { AppContext } from '../../../store';
import { apiRoutes } from '../../const';
import { Invite, InviteType } from '../../models';
import { getCurrentTeam } from '../../helpers';
import { post } from '../../http';
import './InvitePopover.less';

interface InvitePopoverProps {
  inviteType: InviteType;
  inviteID: string;
  inviteResource: string;
  inviteResourceName: string;
}

const InvitePopover: React.FC<InvitePopoverProps> = ({ inviteType, inviteID, inviteResource, inviteResourceName }) => {
  const [inviteLink, setInviteLink] = React.useState<string|undefined>(undefined);
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);

  useEffect(() => {
    if (currentTeam) {
      generateInviteLink();
    }
  }, [currentTeam]);

  const generateInviteLink = async () => {
    const data = await post(apiRoutes.inviteList, {
      invite_type: inviteType,
      invite_id: inviteID,
      display_name: inviteResourceName,
      team: currentTeam?.id,
    });
    setInviteLink(`${window.location.host}/app/invites/${data.id}`);
  }

  return (
    <div className='invite-popover-container'>
      <h3>
        <b>Invite people</b>
      </h3>
      <div>
        All invited people will be able to join your team, and have access to all resources, including the current {inviteResource}.
      </div>
      {!inviteLink && <Spin />}
      {inviteLink && (
        <>
          <br/>
          <Input.Group compact>
            <Input
              className='invite-popover-input'
              defaultValue={inviteLink}
              disabled
            />
            <Tooltip title="Copy link">
              <Button type="primary" icon={<CopyOutlined />} onClick={async () => {
                await navigator.clipboard.writeText(inviteLink);
                notification['success']({
                  message: '',
                  description: 'Invite link copied to your clipboard',
                  duration: 3,
                });
              }}/>
            </Tooltip>
          </Input.Group>
        </>
        )}
    </div>
  );
}

export default InvitePopover;
