import React, { useContext, useState } from "react";
import { Input, Form, Button, Select, Modal, Checkbox } from 'antd';
import { post, put } from '../../http';
import { apiRoutes } from '../../const';
import { AppContext } from '../../../store';
import { PollCreate, Poll, Board, BoardColumn, PollEvent, PollEventType, Item } from '../../models';
import { getCurrentTeam } from '../../helpers';

const { TextArea } = Input;

interface PollEditModalProps {
  board?: Board;
  item?: Item;
  poll?: Poll;
  onCompleted: (event: PollEvent) => void;
  onCanceled: () => void;
}

const PollEditModal: React.FC<PollEditModalProps> = ({ board, item, poll, onCompleted, onCanceled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);

  const [form] = Form.useForm();
  const [optionCount, setOptionCount] = useState<number>(poll ? JSON.parse(poll.options).length : 4);
  const [deletedOptions, setDeletedOptions] = useState<Record<number, boolean>>({});

  const onFinish = async (values: any) => {
    let options: string[] = [];
    for (let i = 0; i < optionCount; i++) {
      if (deletedOptions[i]) {
        continue;
      }
      options.push(values[`option-${i}`]);
    }

    let payload: PollCreate = {
      question: values.question,
      multiple_choices: values.multiple_choices,
      options: JSON.stringify(options),
      team: currentTeam.id,
      board_id: board.id,
      board_column_id: values['board_column_id'],
    };
    if (!poll) {
      await post(apiRoutes.pollList, payload)
      onCompleted({
        type: PollEventType.Add,
        user: currentUser,
      });
    } else {
      await put(apiRoutes.pollSingle(poll.id), payload)
      onCompleted({
        type: PollEventType.Update,
        user: currentUser,
      });
    }
  };

  let options = [];
  for (let i = 0; i < optionCount; i++) {
    if (deletedOptions[i]) {
      continue;
    }
    
    options.push(
      <div style={{ display: 'flex', alignItems: 'left' }}>
        <Button disabled={poll && poll.pollresponse_set.length > 0} danger style={{ marginRight: '8px' }} onClick={() => {
          setDeletedOptions({
            ...deletedOptions,
            [i]: true,
          });
        }}>x</Button>
        <div style={{ flex: 1 }}>
          <Form.Item 
            name={`option-${i}`}
            key={`option-${i}`} 
            rules={[{ required: true, message: 'Please input the option or remove!' }]}
          >
            <TextArea
              disabled={poll && poll.pollresponse_set.length > 0}
              placeholder={`Option ${i + 1}`}
              rows={2}
              maxLength={255}
              bordered={false}
            />
          </Form.Item>
        </div>
      </div>
    );
  }

  const getFormInitialValue = (): any=> {
    if (!poll) {
      return {
        board_column_id: board.boardcolumn_set[0].id,
      }
    }

    let initialValue = {
      question: poll.question,
      multiple_choices: poll.multiple_choices,
      board_column_id: item.board_column,
    };
    JSON.parse(poll.options).forEach((option:string, index:number) => {
      initialValue[`option-${index}`] = option;
    });
    return initialValue;
  }

  return (
    <Modal
      title={poll ? 'Edit Poll' : 'Create Poll'}
      maskClosable={false}
      open={true}
      okText="Create"
      onOk={form.submit}
      onCancel={onCanceled}
    >
      <Form form={form} onFinish={onFinish} initialValues={getFormInitialValue()}>
        <Form.Item
          label="Display Column"
          name="board_column_id"
          rules={[{ required: true, message: 'Please select a column!' }]}
        >
          <Select
            showSearch
            placeholder="Select a column"
            optionFilterProp="children"
            options={board.boardcolumn_set.map((column:BoardColumn) => ({
              value: column.id,
              label: column.name,
            }))}
          />
        </Form.Item>
        <Form.Item name="question">
          <TextArea
            disabled={poll && poll.pollresponse_set.length > 0}
            showCount
            maxLength={10000}
            rows={4}
            bordered={false}
            placeholder="Poll question"
          />
        </Form.Item>
        <Form.Item name="multiple_choices" valuePropName="checked">
          <Checkbox disabled={poll && poll.pollresponse_set.length > 0}>Allow multiple selections</Checkbox>
        </Form.Item>
        {options}
        <Button disabled={poll && poll.pollresponse_set.length > 0} onClick={() => setOptionCount(optionCount + 1)}>+Add Option</Button>
      </Form>
    </Modal>
  );
}

export default PollEditModal;
