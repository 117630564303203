import _ from 'lodash-es';
import './UserProfile.less';
import React, { useContext, useState } from "react";
import uuid from 'react-uuid';
import { Spin, Card, Button, notification } from 'antd';
import Avatar from "react-avatar-edit";
import { Buffer } from 'buffer';
import { get, put, post } from '../../http';
import axios from 'axios';
import { apiRoutes } from '../../const';
import { AppContext } from '../../../store';
import { UserAvatar } from '../common';
import UserDetailForm from './UserDetailForm';

interface AvatarUploadProps {
  hasProfilePic: boolean;
  onCanceled: () => void;
  onCompleted: () => void;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ hasProfilePic, onCanceled, onCompleted}) => {
  const [preview, setPreview] = useState(undefined);

  const onClose = () => {
    setPreview(undefined);
  }

  const onCrop = (preview: any) => {
    console.log(preview);
    setPreview(preview);
  }

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 1000000) {
      notification['error']({
        message: 'File size is too big!',
        description: "Maximun size is 1MB",
      });
      elem.target.value = "";
    }
  }

  const getPreSignPutObjectURL = async (fileName: string) => {
    const response = await post(apiRoutes.fileUploadPreSign, {
      fileName,
    });
    return response.data.url;
  }

  const fileUpload = async () => {
    if (!preview) {
      return;
    }
    const fileName = `${uuid()}.png`;
    const uploadURL = await getPreSignPutObjectURL(fileName);    
    var buf = Buffer.from(preview.replace(/^data:image\/\w+;base64,/, ""),'base64')
    const response = await axios.put(uploadURL, buf, {
      headers: {
        'x-amz-acl': 'public-read',
        'Content-Type': 'image/png',
        'Content-Encoding': 'base64',
      }
    });

    if (response.status > 299) {
      console.error(response.data);
      notification['error']({
        message: 'File Upload error',
        description: response.data,
      });
      throw new Error(response.data);
    }

    await put(apiRoutes.currentUser, {
      profile_pic_url: `${new URL(uploadURL).protocol}//${new URL(uploadURL).hostname}/${fileName}`,
    });
    onCompleted();
  };

  const removeProfileImage = async () => {
    await put(apiRoutes.currentUser, {
      profile_pic_url: null,
    });
    onCompleted();
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Avatar
        width={295}
        height={295}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
      />
      <br/>
      <div>
        <Button type="primary" onClick={fileUpload} disabled={!preview}>Update</Button>
        &nbsp;&nbsp;
        <Button onClick={onCanceled}>Cancel</Button>
        {hasProfilePic && 
        <>
          &nbsp;&nbsp;
        <Button danger onClick={removeProfileImage}>Unset Profile Image</Button>
        </>}
      </div>
    </div>
  );
}

const UserProfile: React.FC<{}> = () => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState<boolean>(false);
  
  const fetchCurrentUser = async function() {
    const data = await get(apiRoutes.currentUser);
    dispatch({
      currentUser: data,
    });
  }

  if (!currentUser) {
    return <Spin />
  }

  if (isEditing) {
    let initialValues = {
      username: currentUser.username,
      email: currentUser.email,
      default_team: '',
    }
    // Populate default_team if user has a membership
    if (currentUser.team_membership.length > 0) {
      if (currentUser.user_config?.default_team) {
        for (let team of currentUser.team_membership) {
          if (team.id === currentUser.user_config?.default_team) {
            initialValues.default_team = team.id;
          }
        }
      }
      // if currentUser.user_config?.default_team not in membership list (maybe user has quited), just use first value
      if (initialValues.default_team === '') {
        initialValues.default_team = currentUser.team_membership[0].id;
      }
    }
    
    return (
      <Card>
        <UserDetailForm
          user={currentUser}
          onCanceled={() => setIsEditing(false)}
          onCompleted={() => {
            setIsEditing(false);
            fetchCurrentUser();
          }}
        />
      </Card>
    );
  }

  if (isUpdatingAvatar) {
    return (
      <Card>
        <AvatarUpload
          hasProfilePic={currentUser.user_config?.profile_pic_url ? true : false}
          onCanceled={() => setIsUpdatingAvatar(false)}
          onCompleted={() => {
            setIsUpdatingAvatar(false);
            fetchCurrentUser();
          }}
        />
      </Card>
    );
  }

  let defaultTeam = '<None>';
  if (currentUser.user_config?.default_team) {
    for (let team of currentUser.team_membership) {
      if (team.id === currentUser.user_config?.default_team) {
        defaultTeam = team.name;
      }
    }
  }
  if (defaultTeam === '<None>' && currentUser.team_membership.length > 0) {
    defaultTeam = currentUser.team_membership[0].name;
  }

  return (
    <Card>
      <div style={{ textAlign: 'center', marginBottom: '8px', position: 'relative' }}>
        <span className="profile-page-avatar-container" onClick={() => setIsUpdatingAvatar(true)}>
          <UserAvatar size={80} username={currentUser.username} />
          <span className="profile-page-avatar-edit">Edit</span>
        </span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <span><b>Username:</b> {currentUser.username}</span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <span><b>Email:</b> {currentUser.email}</span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <span><b>Default team:</b> {defaultTeam}</span>
      </div>
      <div style={{ textAlign: 'center', marginTop: 8 }}>
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      </div>
    </Card>
  );
}

export default UserProfile;
