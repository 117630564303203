import _ from 'lodash-es';
import moment from 'moment';
import classNames from 'classnames';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Space, Dropdown, Button, Drawer, Tag } from 'antd';
import { Comment } from '@ant-design/compatible';
import { EllipsisOutlined} from '@ant-design/icons';
import { apiRoutes } from '../../../const';
import { useWindowDimensions } from '../../../hooks';
import { User, Todo, Comment as CommentModel, TodoEvent, TodoEventType, TodoLabel, Team } from '../../../models';
import { AppContext } from '../../../../store';
import { put, get } from '../../../http';
import { getTodoCacheKey, getCurrentTeam } from '../../../helpers';
import { UserAvatar, AssignmentDropdown } from '../../common';
import { CommentSingle, CommentForm } from '../../comments';
import { TodoDescriptionDisplay, TodoTitleDisplay, TodoStatusDisplay, TodoLabelDisplay } from './fields';
import TodoActionMenu from './TodoActionMenu';
import './styles.less';

interface TodoDrawerProps {
  todo: Todo;
  visible: boolean;
  members?: Array<User>;
  onRemoteChanged: (event:TodoEvent) => void;
  onHide: () => void;
}

const TodoDrawer: React.FC<TodoDrawerProps> = ({ todo, members, visible, onRemoteChanged, onHide }) => {
  const { state: { liveEditingComments, currentUser }, dispatch } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [existingTodoLabels, setExistingTodoLabels] = useState<Array<TodoLabel>>([]);
  const { width } = useWindowDimensions();

  const editCacheKey = getTodoCacheKey(undefined, todo.id);
  let [showNewCommentEditor, setShowNewCommentEditor] = useState<boolean>(liveEditingComments[editCacheKey] ? true : false);

  const closeDrawer = () => {
    dispatch({
      drawerTodo: undefined,
    });
  }

  useEffect(() => {
    if (currentTeam) {
      fetchExistingTodoLabels(currentTeam);
    }
  }, [currentTeam]);

  const fetchExistingTodoLabels = async function(team:Team) {
    const data = await get(apiRoutes.todoLabelListStandAlone, {
      team: team.id
    });
    setExistingTodoLabels(data);
  }

  const updateTodo = async function(todoID:string, values:any, event:TodoEvent, cb?: () => void) {
    await put(apiRoutes.todoSingle(todoID), values);
    if (cb) {
      cb();
    }
    onRemoteChanged(event);
  }

  const changeAssignee = async (value:string) => {
    await updateTodo(todo.id, _.assign({}, todo, {
      assignee: value,
    }), {
      type: value === '' ? TodoEventType.Unassign : TodoEventType.Assign,
      user: currentUser,
    })
  }

  const onCommentCreated = () => {
    setShowNewCommentEditor(false);
    onRemoteChanged({
      type: TodoEventType.AddComment,
      user: currentUser,
    });
  }

  const onDrawerClosed = () => {
    dispatch({
      drawerTodo: undefined,
    })
    onHide();
  }

  return (
    <Drawer
      title={todo.archived ? <span><Tag color="grey">Archived</Tag> Task</span>: 'Task'}
      placement="right"
      closable={true}
      onClose={onDrawerClosed}
      visible={visible}
      // maskClosable={false}
      className={classNames('todo-drawer', {
        'todo-drawer-archived': todo.archived,
      })}
      width={width < 600 ? width : 600}
    >
      <Dropdown overlay={<TodoActionMenu todo={todo} onRemoteChanged={onRemoteChanged} onDeleted={closeDrawer} />}>
        <Button size="small" className="todo-drawer-action-btn">
          <EllipsisOutlined key="ellipsis" />
        </Button>
      </Dropdown>
      <TodoTitleDisplay
        key={`todo-title-display-${todo.id}`}
        todo={todo}
        onRemoteChanged={onRemoteChanged}
      />
      <div className="todo-drawer-field-row">
        <span className="todo-drawer-field-name">
          <h4 style={{ verticalAlign: 'sub' }}>Assignee</h4>
        </span>
        <span className="todo-drawer-field-content">
          <AssignmentDropdown
            assignable={todo}
            members={members}
            changeAssignee={changeAssignee}
          />
        </span>
      </div>
      <div className="todo-drawer-field-row">
        <span className="todo-drawer-field-name">
          <h4 style={{ verticalAlign: 'sub' }}>Labels</h4>
        </span>
        <span className="todo-drawer-field-content">
          <TodoLabelDisplay
            key={`todo-label-display-${todo.id}`}
            todo={todo}
            onRemoteChanged={onRemoteChanged}
            existingTodoLabels={existingTodoLabels}
          />
        </span>
      </div>
      <div className="todo-drawer-field-row">
        <span className="todo-drawer-field-name">
          <h4>Status</h4>
        </span>
        <span className="todo-drawer-field-content">
          <TodoStatusDisplay
            key={`todo-status-display-${todo.id}`}
            todo={todo}
            onRemoteChanged={onRemoteChanged}
          />
        </span>
      </div>
      <div className="todo-drawer-field-row">
        <span className="todo-drawer-field-name">
          <h4>Created</h4>
        </span>
        <span className="todo-drawer-field-content todo-created-timestamp">
          {moment(todo.created_at).format("MMM Do YYYY, h:mm:ss a")}
        </span>
      </div>
      <div className="todo-drawer-field-row">
        <span className="todo-drawer-field-name">
          <h4>Description</h4>
        </span>
        <span className="todo-drawer-field-content">
          <TodoDescriptionDisplay
            key={`todo-desc-display-${todo.id}`}
            todo={todo}
            onRemoteChanged={onRemoteChanged}
          />
        </span>
      </div>
      <br />
      <div className="todo-comment-section-container">
        <Fragment>
          <Space direction="vertical" style={{ width: '100%' }}>
            {_.map(todo.todocomment_set, (comment:CommentModel) => {
              return (
                <CommentSingle
                comment={comment}
                todo={todo}
                currentUser={currentUser}
                onDeleted={() => { 
                  onRemoteChanged({
                    type: TodoEventType.RemoveComment,
                    user: currentUser,
                  });
                }}
                onUpdated={() => { 
                  onRemoteChanged({
                    type: TodoEventType.RemoveComment,
                    user: currentUser,
                  });
                }}
              />
              );
            })}
            {!showNewCommentEditor && <span className="comment-reply-link" onClick={() => { setShowNewCommentEditor(true) }}>Add comment</span>}
            {showNewCommentEditor && <Comment
              avatar={
                <UserAvatar username={currentUser?.username} />
              }
              content={
                <CommentForm
                  todo={todo}
                  onCancel={() => { setShowNewCommentEditor(false) }}
                  onCommentCreated = {onCommentCreated}
                />
              }
            />}
          </Space>
        </Fragment>
      </div>
    </Drawer>
  );
}

export default TodoDrawer;
