import React, { useEffect, useState } from "react";
import { Space, Button, Drawer, Input, Card, Empty, Row, Col, Divider, Spin, Collapse } from 'antd';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Comment } from '@ant-design/compatible';
import { apiRoutes } from '../../const';
import { useWindowDimensions } from '../../hooks';
import { Board, BoardReport, BoardReportStatus, BoardReportTopic, Item, BoardColumn } from '../../models';
import { post, get } from '../../http';

import { UserAvatar } from '../common';
import { colorSchemeClassMapping } from '../../const';
import './styles.less';

const { TextArea } = Input;

interface TopicReportProps {
  report: BoardReport;
  itemsById: Record<string, Item>;
  columnById: Record<string, BoardColumn>;
}

const TopicReport: React.FC<TopicReportProps> = ({ report, itemsById, columnById}) => {
  const topics = report.topics;
  if (report.status !== BoardReportStatus.Done) {
    if (report.status === BoardReportStatus.Pending || report.status === BoardReportStatus.HotTopic) {
      return (
        <div>
          <Spin tip="Identiyfing the hot topics ...">
            <Card style={{ minHeight: 100 }}/>
          </Spin>
        </div>
      );
    } else if (report.status === BoardReportStatus.Suggest) {
      return (
        <div>
          <Spin tip="Pulling some suggestions for you ...">
            <Card style={{ minHeight: 100 }}/>
          </Spin>
        </div>
      );
    } else {
      return (
        <div>
          <Spin tip="AI is working hard for you ...">
            <Card style={{ minHeight: 100 }}/>
          </Spin>
        </div>
      );
    }
  }

  return (
    <div>
      {
        topics.length === 0 && <p>No similarities found, the team members are talking about different topics</p>
      }
      <Space direction="vertical" style={{width: '100%'}}>
        {topics.length > 0 && topics.map((topic:BoardReportTopic,i:number) => {
            return (
              <Card title={`🔥 Topic ${i+1}: ${topic.topic_name}`}>
                <Collapse
                  size="small"
                  items={[
                    {
                      key: '1',
                      label: 'What people talk about?',
                      children: <Row>
                      {topic.sentence_ids.map((sentenceID:string) => {
                        const item = itemsById[sentenceID];
                        const column = columnById[item.board_column];
                        return (
                          <Col xxl={8} lg={12} xs={24}>
                            <Card
                              size="small" 
                              className="item-card report-item-card"
                              style={{ margin: 4 }}
                            >
                              <Comment
                                className="teamkit-comment"
                                author={item.anonymous ? 'Anonymous' : item.owner}
                                avatar={<UserAvatar username={item.anonymous ? undefined : item.owner} />}
                                content={
                                  <div>
                                    <TextArea bordered={false} autoSize readOnly value={item.content} />
                                    <div style={{ padding: 11 }}>
                                      <span className={`report-item-card-title ${colorSchemeClassMapping[column.color_scheme]}`} style={{ color: 'white' }}>{column.name}</span>
                                    </div>
                                  </div>
                                }
                              />
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>,
                    }
                  ]}
                />
                <Divider />
                <h3>Summary</h3>
                <p>{topic.summary}</p>
                <Divider />
                <h3>AI suggestion</h3>
                <Markdown remarkPlugins={[remarkGfm]}>{topic.suggestions}</Markdown>
              </Card>
            )
          })
        }
      </Space>
    </div>
  );
}

interface BoardReportDrawerProps {
  visible: boolean;
  board: Board;
  items?: Item[];
  onDrawerClosed: () => void;
}

const BoardReportDrawer: React.FC<BoardReportDrawerProps> = ({ board, items, visible, onDrawerClosed }) => {
  const { width } = useWindowDimensions();
  const [reports, setReports] = useState<BoardReport[]|null>(null);
  const [currentReportIndex, setCurrentReportIndex] = useState<number>(0);
  const [itemsById, setItemsById] = useState<Record<string, Item>>({});
  const [columnById, setColumnById] = useState<Record<string, BoardColumn>>({});
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);

  useEffect(() => {
    if (visible && !reports) {
      fetchReport();
    } else {
      let hasPendingReport = false;
      if (reports) {
        reports.forEach((report:BoardReport) => {
          if (report.status !== BoardReportStatus.Done) {
            hasPendingReport = true;
          }
        });
      }

      if (hasPendingReport) {
        setIsGeneratingReport(true);
        setTimeout(() => {
          fetchReport();
        }, 3000);
      } else {
        setIsGeneratingReport(false);
      }
    }
  }, [visible, reports]);

  useEffect(() => {
    if (!items) {
      return;
    }

    let m:Record<string, any> = {};
    items.forEach((item:Item) => {
      m[item.id] = item;
    });
    setItemsById(m);
  }, [items]);

  useEffect(() => {
    if (!board) {
      return;
    }

    let m:Record<string, any> = {};
    board.boardcolumn_set.forEach((boardcolumn:BoardColumn) => {
      m[boardcolumn.id ?? ''] = boardcolumn;
    });
    setColumnById(m);
  }, [board]);

  const fetchReport = async () => {
    let data:BoardReport[] = await get(apiRoutes.boardReportList(board.id));
    if (!data) {
      data = [];
    }
    setReports(data);
    setCurrentReportIndex(0);
  }

  const generateReport = async () => {
    setIsGeneratingReport(true);
    try {
      await post(apiRoutes.boardReportList(board.id), {
        board: board.id,
      });
    } finally {
      setIsGeneratingReport(false);
    }
    fetchReport();
  }

  return (
    <Drawer
      title="AI Report"
      placement="right"
      closable={true}
      onClose={onDrawerClosed}
      visible={visible}
      width={width < 768 ? width : width < 992 ? width - 80 : width - 160}
    >
      {reports && reports.length > 0 &&
      <div style={{ marginBottom: 8 }}>
        {reports.map((report:BoardReport, i:number) => {
          const reportNo = reports.length - i;
          return (
            <Button
              type={currentReportIndex === i ? 'primary' : 'default'}
              onClick={() => setCurrentReportIndex(i)}
              style={{ marginRight: 4 }}
            >
              {reportNo}
            </Button>
          );
        })}
        <Button disabled={reports.length >= 3} onClick={generateReport} loading={isGeneratingReport}>{!isGeneratingReport ? `Regenerate Report (${3 - reports.length} remaining)` : "AI is working hard for you"}</Button>
      </div>}

      <Space direction="vertical" style={{width: '100%'}}>
        {reports && reports.length === 0 &&
          <Empty description="No report yet. To ensure optimal performance of AI, please ensure that all items are added in complete sentence format. Please avoid abbreviation.">
            <Button onClick={generateReport} loading={isGeneratingReport}>{!isGeneratingReport ? "Generate Report with AI" : "AI is working hard for you"}</Button>
          </Empty>}
        {reports && reports.length > 0 && currentReportIndex >= 0 && currentReportIndex < reports.length && <TopicReport
          report={reports[currentReportIndex]}
          itemsById={itemsById}
          columnById={columnById}
        /> }
      </Space>
    </Drawer>
  );
}

export default BoardReportDrawer;
