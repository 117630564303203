import _ from 'lodash-es';
import React, { useContext, useState } from "react";
import './styles.less';
import { Space, Button, Form, Select, Tag } from 'antd';
import { apiRoutes } from '../../../../const';
import { Todo, TodoLabel, TodoEvent, TodoEventType } from '../../../../models';
import { AppContext } from '../../../../../store';
import { post, del } from '../../../../http';

const { Option } = Select;

interface TodoLabelDisplayProps {
  todo: Todo;
  existingTodoLabels: Array<TodoLabel>;
  onRemoteChanged: (event:TodoEvent) => void;
}
const TodoLabelDisplay:React.FC<TodoLabelDisplayProps> = ({ todo, existingTodoLabels, onRemoteChanged }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onEditClicked = () => {
    setIsEditting(true);
  }
  
  if (!isEditting) {
    if (!todo || todo.todolabel_set.length === 0 ) {
      return (
        <div className="todo-editable-content" onClick={onEditClicked}>
          None
        </div>
      );
    }
    return (
      <div className="todo-editable-content" onClick={onEditClicked}>
        {_.map(todo.todolabel_set, (label: TodoLabel) => <Tag className="teamkit-clickable todo-label-tag" onClick={onEditClicked}>{label.label}</Tag>)}
      </div>
    );
  }

  const onFormSubmit = async (values:any) => {
    setIsSubmitting(true);

    try {
      let toDelete:Array<number> = [];
      let toCreate: Array<string> = [];
  
      // find label to delete
      for (let currenLabel of todo.todolabel_set) {
        let keepLabel = false;
        for (let newLabel of values.todolabel_set) {
          if (newLabel === currenLabel.label) {
            keepLabel = true;
            break;
          }
        }
        if (!keepLabel) {
          toDelete.push(currenLabel.id);
        }
      }
      
      // find label to create
      for (let newLabel of values.todolabel_set) {
        let existingLabel = false;
        for (let currenLabel of todo.todolabel_set) {
          if (newLabel === currenLabel.label) {
            existingLabel = true;
            break;
          }
        }
        if (!existingLabel) {
          toCreate.push(newLabel);
        }
      }

      await Promise.all(_.map(toDelete, async (labelID:number) => {
        await del(apiRoutes.todoLabelSingle(todo.id, labelID));
      }));

      await Promise.all(_.map(toCreate, async (label:string) => {
        await post(apiRoutes.todoLabelList(todo.id), {
          label,
        });
      }));

      setIsEditting(false);
      if (toDelete.length > 0 || toCreate.length > 0) {
        onRemoteChanged({
          type: TodoEventType.Update,
          user: currentUser,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const cancelEdit = () => {
    setIsEditting(false);
  }

  let labelOptions = [];
  if (existingTodoLabels.length === 0 && todo && todo?.todolabel_set?.length > 0) {
    labelOptions =  _.map(todo.todolabel_set, (label:TodoLabel) => {
      return <Option key={label.id} value={label.label}>{label.label}</Option>;
    })
  } else {
    labelOptions =  _.map(existingTodoLabels, (label:string,i:number) => {
      return <Option key={`label-${i}`} value={label}>{label}</Option>;
    })
  }

  return (
    <Form
      initialValues={{ todolabel_set: todo ? _.map(todo.todolabel_set, (label:TodoLabel) => label.label) : [] }}
      name={`todo-label-form-${todo?.id}`}
      onFinish={onFormSubmit}
      style={{ marginBottom: '1em' }}
    >
      <Form.Item
        name="todolabel_set"
        style={{ marginBottom: 8 }}
      >
        <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode">
          {labelOptions}
        </Select>
      </Form.Item>

      <div>
        <Space>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>Save</Button>
          <Button onClick={cancelEdit}>Cancel</Button>
        </Space>
      </div>
    </Form>
  )
}

export default TodoLabelDisplay;
