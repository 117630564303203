import React, { useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Goal } from '../../../models';
import { apiRoutes, longTextLength } from '../../../const';
import { post } from '../../../http';

const { TextArea } = Input;

interface GoalCommentFormProps {
  goal: Goal;
  hideCancel?: boolean;
  onCancel: () => void;
  onCommentCreated: () => void;
}
const GoalCommentForm: React.FC<GoalCommentFormProps> = ({ goal, hideCancel, onCancel, onCommentCreated }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFormSubmit = async (values:any) => {
    setIsSubmitting(true);
    try {
      await post(apiRoutes.goalCommentList(goal.id), values);
    } catch (error) {
      setIsSubmitting(false);
      throw(error);
    }
    setIsSubmitting(false);
    form.resetFields();
    onCommentCreated();
  }

  const onCancelClicked = () => {
    form.resetFields();
    onCancel();
  }
  
  return (
    <Form form={form} name={`comment-form-goal-${goal.id}`} onFinish={onFormSubmit}>
      <Form.Item
        name="content"
        rules={[{ required: true, message: 'Please input the content!' }]}
      >
        <TextArea rows={2} maxLength={longTextLength} placeholder="Any updates or notes on the goal?" />
      </Form.Item>
      <Form.Item style={{marginBottom: 0}}>
        <Space>
          <Button htmlType="submit" loading={isSubmitting} onClick={() => {}} type="primary">
            Add Comment
          </Button>
          {!hideCancel &&<Button htmlType="submit" onClick={onCancelClicked}>
            Cancel
          </Button>}
        </Space>
      </Form.Item>
    </Form>
  );
}

export default GoalCommentForm;
