import _ from 'lodash-es';
import React from "react";
import { Card, Button, Dropdown, Menu, Tag } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Team, User, FutureMember, TeamAdmin, Role } from '../../../models';
import { isTeamAdmin } from '../../../helpers';
import { apiRoutes } from '../../../const';
import { post } from '../../../http';
import { UserAvatar } from '../../common';

interface MemberCardProps {
  team: Team;
  member?: User;
  futureMember?: FutureMember;
  isCurrentUserAdmin: boolean;
  onCardUpdated: () => void;
  onDeleted: () => void;
}
const MemberCard: React.FC<MemberCardProps> = ({ team, futureMember, member, isCurrentUserAdmin, onCardUpdated, onDeleted }) => {
  if (!member && !futureMember) {
    return null;
  }

  const removeMember = async (username:string) => {
    await post(apiRoutes.teamRemoveMember(team.id), {
      "usernames": [username],
    })
    onDeleted();
  };

  const setRole = async (username:string, role:Role) => {
    await post(apiRoutes.teamRoleChange(team.id), {
      username,
      role,
    })
    onCardUpdated();
  };

  const removeFutureMember = async (userID:string) => {
    await post(apiRoutes.teamRemoveMember(team.id), {
      "future_user_ids": [userID],
    })
    onCardUpdated();
  };

  let cardTitle = '';
  let cardDescription = '';
  let deleteClick = () => {};
  if (member) {
    cardTitle = member.username;
    cardDescription = member.email;
    deleteClick = () => {
      removeMember(member.username);
    }
  } else if (futureMember) {
    cardTitle = 'Pending registration';
    cardDescription = futureMember.email;
    deleteClick = () => {
      removeFutureMember(futureMember.id);
    }
  }

  const isAdmin = member ? isTeamAdmin(member, team): false;
  const menuItems = [
    <Menu.Item key="1" onClick={deleteClick}>Remove</Menu.Item>,
  ];
  if (member) {
    if (_.find(team.teamadmin_set, (admin: TeamAdmin) => admin.username === member?.username)) {
      menuItems.push(<Menu.Item key="2" onClick={() => setRole(member.username, Role.Member)}>Set as Member</Menu.Item>);
    } else {
      menuItems.push(<Menu.Item key="2" onClick={() => setRole(member.username, Role.Admin)}>Set as Admin</Menu.Item>);
    }
  }

  return (
    <Card>
      <div className="teamkit-display-flex">
        <div style={{ marginRight: '1em' }}>
          <UserAvatar username={member?.username} />
        </div>
        <div className="teamkit-flex-full">
          <div>{cardTitle}</div>
          <div className="teamkit-small-listing-card-summary">{cardDescription}</div>
          <div>{isAdmin ? <Tag color="green">Admin</Tag >: <Tag color="gray">Member</Tag>}</div>
        </div>
      </div>
      {isCurrentUserAdmin && <Dropdown overlay={<Menu>{menuItems}</Menu>}>
        <Button className="teamkit-card-floating-button">
          <EllipsisOutlined key="ellipsis" />
        </Button>
      </Dropdown>}
    </Card>
  );
}

export default MemberCard;
