import React from "react";
import './AboutPage.less';
import { Layout, Typography, Avatar, Space } from 'antd';
import { EnvironmentOutlined, MailOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const { Content } = Layout;

const ContactPage: React.FC<{}> = () => {
  return (
    <Content className="site-content">
      <div className="static-page-container">
        <div className="site-content-inner" style={{ background: 'white'}}>
          <Paragraph>
            <h3>Contact</h3>
            <p><Space><Avatar><EnvironmentOutlined /></Avatar> Singapore</Space></p>
            <p><Space><Avatar><MailOutlined /></Avatar> admin@retroplus.io</Space></p>
          </Paragraph>
        </div>
      </div>
    </Content>
  )
}

export default ContactPage;
