import React from "react";
import FeaturePageTailwind from './FeaturePageTailwind';
import { featureScrumPoker } from './features';

const FeatureScrumPokerTailwind: React.FC<{}> = () => {
  return (
    <FeaturePageTailwind featureDefinition={featureScrumPoker} />
  );
}

export default FeatureScrumPokerTailwind;
