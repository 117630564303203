import React from "react";
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { Layout } from 'antd';
import { Home } from './modules/home';

const { Content } = Layout;

const HomePage: React.FC<any> = () => {
  return (
    <Switch>
      <Route>
        <Content>
            <Home />
        </Content>
      </Route>
    </Switch>
  );
}

export default withRouter(HomePage);
