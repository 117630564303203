import React from "react";
import FeaturePageTailwind from './FeaturePageTailwind';
import { featureRetrospective } from './features';


const FeatureRetrospectiveTailwind: React.FC<{}> = () => {
  return (
    <FeaturePageTailwind featureDefinition={featureRetrospective} />
  );
}

export default FeatureRetrospectiveTailwind;
