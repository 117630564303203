import React from "react";
import { Modal } from 'antd';
import { Kudo, Board, KudoEvent, User } from '../../models';
import KudoForm from './KudoForm';

interface KudoEditModalProps {
  board?: Board;
  kudo?: Kudo;
  members: User[];
  onCompleted: (event: KudoEvent) => void;
  onCanceled: () => void;
}

const KudoEditModal: React.FC<KudoEditModalProps> = ({ board, kudo, members, onCompleted, onCanceled }) => {
  return (
    <Modal
      title={kudo ? 'Edit Kudo' : 'Create Kudo'}
      maskClosable={false}
      open={true}
      footer={null}
      onCancel={onCanceled}
    >
      <KudoForm
        boardID={board?.id}
        members={members}
        onCompleted={onCompleted}
        onCanceled={onCanceled}
      />
    </Modal>
  );
}

export default KudoEditModal;
