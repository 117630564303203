import _ from "lodash-es";
import React, { useContext, useEffect, useState } from "react";
import {
  withRouter
} from "react-router-dom";
import { Spin, Space, Breadcrumb, Empty } from 'antd';
import { getCurrentTeam, getBasePathWithTeam } from './helpers';
import { get } from './http';
import { Todo, Team, User} from './models';
import { apiRoutes } from './const';
import { AppContext } from '../store';
import { TodoCard } from './modules/boards/todos';
import TodoDrawer from './modules/boards/todos/TodoDrawer';

const ArchivedTodoPage: React.FC<any> = ({ history }) => {
  const { state: { currentUser, drawerTodo }, dispatch } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [archivedTodos, setArchivedTodos] = useState<Array<Todo>>();
  const [members, setMembers] = useState<Array<User>>();

  const fetchTeamMember = async function(team:Team) {
    const data = await get(apiRoutes.teamMemberList(team.id));
    setMembers(data);
  }

  const fetchTodos = async function(team:Team) {
    const data = await get(apiRoutes.todosList, {
      team: team.id,
      archived: true,
    });
    for (let todo of data) {
      if (drawerTodo && drawerTodo.id === todo.id) {
        dispatch({
          drawerTodo: todo,
        });
      }
    }
    setArchivedTodos(data);
  }

  useEffect(() => {
    if (currentTeam) {
      fetchTodos(currentTeam);
      fetchTeamMember(currentTeam);
    }
  }, [currentTeam]);

  if (!currentTeam || archivedTodos === undefined) {
    return <Spin />
  }

  return (
    <>
      <div>
        <Breadcrumb className="page-breadcrum">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a onClick={() => { history.push(`${getBasePathWithTeam()}/tasks`) }}>Tasks</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Archived</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Space direction="vertical" style={{ width: '100%' }}>
        {archivedTodos.length === 0 && <Empty />}
        {_.map(archivedTodos, (todo:Todo):any => {
          return (
            <TodoCard
              horizontal={true}
              todo={todo}
              members={members}
              onRemoteChanged={() => fetchTodos(currentTeam)}
            />
          );
        })}
      </Space>
      {drawerTodo && <TodoDrawer
        todo={drawerTodo}
        members={members}
        onRemoteChanged={() => { fetchTodos(currentTeam) }}
        visible={drawerTodo ? true : false}
        onHide={() => {}}
      />}
    </>
  );
}

export default withRouter(ArchivedTodoPage);
