import React from "react";
import { Row, Col, Card, Space } from 'antd';
import { Board } from '../../../models';
import './styles.less';

interface BoardDetailsProps {
  board: Board;
  onUpdateCompleted: () => void;
}
const BoardDetails: React.FC<BoardDetailsProps> = ({ board, onUpdateCompleted }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card className="teamkit-show-top-detail-card-smaller">
          <Space>
            <h3>{board.name}</h3>
          </Space>
        </Card>
      </Col>
    </Row>
  );
}

export default BoardDetails;
