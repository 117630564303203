import _ from 'lodash-es';
import React, { useContext } from "react";
import { Menu, Modal } from 'antd';
import { ContainerOutlined, DeleteOutlined, ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { apiRoutes } from '../../../const';
import { del, put } from '../../../http';
import { Todo, TodoEvent, TodoEventType } from '../../../models';
import { AppContext } from '../../../../store';
import './styles.less';

const { confirm } = Modal;

interface TodoActionMenuProps {
  todo: Todo;
  onRemoteChanged: (event:TodoEvent) => void;
  onDeleted: () => void;
}

const TodoActionMenu: React.FC<TodoActionMenuProps> = ({ todo, onRemoteChanged, onDeleted }) => {
    const { state: { currentUser } } = useContext(AppContext);

    const onDeleteClicked = () => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete?',
        onOk: async () => {
          await deleteTodo();
        },
        onCancel: () => {},
      });
    }

    const updateTodo = async function(todoID:string, values:any, event:TodoEvent, cb?: () => void) {
      await put(apiRoutes.todoSingle(todoID), values);
      if (cb) {
        cb();
      }
      onRemoteChanged(event);
    }

    const deleteTodo = async function() {
      await del(apiRoutes.todoSingle(todo.id));
      onRemoteChanged({
        type: TodoEventType.Delete,
        user: currentUser,
      });
      onDeleted();
    }
  
    const archiveTodo = async function() {
      updateTodo(todo.id, _.assign({}, todo, {
        archived: true,
      }), {
        type: TodoEventType.Archive,
        user: currentUser,
      });
      onDeleted();
    }
  
    const restoreTodo = async function() {
      updateTodo(todo.id, _.assign({}, todo, {
        archived: false,
      }), {
        type: TodoEventType.Restore,
        user: currentUser,
      });
      onDeleted();
    }

    const menuItems = [];
    if (!todo.archived) {
      menuItems.push(
        <Menu.Item key="todo-archive" className="todo-menu-item" onClick={()=>{archiveTodo()}}><ContainerOutlined /> Archive</Menu.Item>,
      );
    } else {
      menuItems.push(
        <Menu.Item key="todo-restore" className="todo-menu-item" onClick={()=>{restoreTodo()}}><ReloadOutlined /> Restore</Menu.Item>,
      );
    }
    menuItems.push(
      <Menu.Item key="todo-delete" className="todo-menu-item" onClick={()=>{onDeleteClicked()}}><DeleteOutlined /> Delete</Menu.Item>,
    );

  return (
    <Menu>{menuItems}</Menu>
  );
}

export default TodoActionMenu;
