import React, { useContext } from "react";
import { Row, Col } from 'antd';
import { withRouter } from "react-router-dom";
import TeamForm from './TeamForm';
import { getPathPrefix, getCurrentTeamIndex } from '../../helpers';
import { get } from '../../http';
import { apiRoutes, appRoutes } from '../../const';
import { User } from '../../models';
import { AppContext } from '../../../store';

const TeamCreate: React.FC<any> = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  const onCreateCompleted = async (newTeamID:string) => {
    const currentUser:User = await get(apiRoutes.currentUser);
    dispatch({
      currentUser,
    });
    let newTeamIndex = getCurrentTeamIndex();
    for (let i = 0; i < currentUser.team_membership.length; i++) {
      if (currentUser.team_membership[i].id === newTeamID) {
        newTeamIndex = i;
      }
    }
    // If this is the first team, navigate to retro board page, hacky solution for onboarding experience
    if (currentUser.team_membership.length === 1) {
      history.push(appRoutes.home(newTeamIndex));
    } else {
      history.push(`${getPathPrefix(newTeamIndex)}/teams/${newTeamID}`);
    }
  }
  return (
    <Row gutter={[16, 16]}>
      <Col lg={4} md={0} sm={0} xs={0} />
      <Col lg={16} md={24} sm={24} xs={24}>
        <TeamForm
          onCreateCancelled={() => {  }}
          onCreateCompleted={(newTeamID:string) => {
            onCreateCompleted(newTeamID);
          }}
        />
      </Col>
    </Row>
  );
}

export default withRouter(TeamCreate);
