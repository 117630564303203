import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import KudoMiniCard from './KudoMiniCard';
import confetti from 'canvas-confetti';
import { Kudo } from '../../models';

interface KudoNotificationModalProps {
  kudo?: Kudo;
}

const KudoNotificationModal: React.FC<KudoNotificationModalProps> = ({ kudo }) => {
  const [toNotify, setToNotify] = useState<Kudo>();

  useEffect(() => {
    if (!kudo) {
      return;
    }

    setToNotify(kudo);
    confetti({
      particleCount: 300,
      spread: 800,
      zIndex: 2000,
      origin: { 
        x: 0.5,
        y: 0.1,
      },
    });
  }, [kudo]);
  return (
    <>
      {toNotify &&
      <Modal className="kudo-notification-modal" title={null} open={kudo ? true : false} footer={null} maskClosable={false} closeIcon={<span className="kudo-notification-modal-close-btn"><CloseOutlined /></span>} onCancel={() => setToNotify(null)}>
        <KudoMiniCard viewOnly kudo={kudo} />
      </Modal>}
    </>
    
  );
}

export default KudoNotificationModal;
