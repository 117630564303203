import React, { useState } from "react";
import classNames from 'classnames';
import { Row, Col, Card, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import BoardForm from './BoardForm';
import { Board } from '../../models';
import './styles.less';

interface BoardDetailsProps {
  board: Board;
  onUpdateCompleted: () => void;
}
const BoardDetails: React.FC<BoardDetailsProps> = ({ board, onUpdateCompleted }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        {!editMode &&
          <Card
            className={classNames('teamkit-show-top-detail-card', {
              'teamkit-show-top-detail-card-smaller': !editMode
            })}
            onClick={() => {setEditMode(true)}}
          >
            <Space>
              <h3>{board.name}</h3>
              <EditOutlined />
            </Space>
          </Card>}
        {editMode &&
          <BoardForm
            board={board}
            onCreateCancelled={() => { setEditMode(false) }}
            onCreateCompleted={() => {
              onUpdateCompleted();
              setEditMode(false);
            }}
          />}
      </Col>
    </Row>
  );
}

export default BoardDetails;
