import React, { Fragment, useState, useEffect, useContext } from "react";
import { Spin, Row, Col, Card, Button, Popconfirm, Space } from 'antd';
import { useParams as useRouterParams, RouteComponentProps, withRouter } from 'react-router-dom';
import { Team, TeamAdmin, User } from '../../models';
import TeamDetails from './TeamDetails';
import { MemberForm, MemberCard } from './members';
import { apiRoutes, appRoutes, APP_BASE_PATH } from '../../const';
import { get, del, post } from '../../http';
import { AppContext } from '../../../store';

const TeamShow: React.FC<RouteComponentProps<any>> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);

  const teamID = useRouterParams<any>().id;
  const [team, setTeam] = useState<Team>();
  const { dispatch } = useContext(AppContext);

  const fetchTeam = async function(teamID:string) {
    const data = await get(apiRoutes.teamSingle(teamID));
    setTeam(data);
  }

  const deleteTeam = async () => {
    await del(apiRoutes.teamSingle(teamID));
    dispatch({
      currentUser: await get(apiRoutes.currentUser),
    });
    history.push(APP_BASE_PATH);
  }

  useEffect(() => {
    fetchTeam(teamID);
  }, [teamID]);

  const refreshCurrentUser = async () => {
    const currentUser:User = await get(apiRoutes.currentUser);
    dispatch({
      currentUser,
    });
  }

  const leaveTeam = async () => {
    await post(apiRoutes.teamLeave, {
      team_id: team?.id
    });
    await onLeavedTeam();
  }

  const onLeavedTeam = async () => {
    await refreshCurrentUser();
    history.push(appRoutes.teamsWithoutCurrentTeamID);
  }

  if (!team || !currentUser) {
    return <Spin />
  }

  const isAdmin = team && team.teamadmin_set.filter((admin:TeamAdmin) => {
    return admin.username === currentUser.username;
  }).length > 0;

  return (
    <Fragment>
      { !team && <Spin /> }
      { team &&
        <Fragment>
          <Space direction="vertical" style={{ width: '100%' }}>
            {isAdmin && <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Popconfirm
                  key="delete"
                  placement="top"
                  title={"Are you sure you want to delete the team? All content will be removed."}
                  onConfirm={deleteTeam}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="teamkit-float-right" onClick={() => {}} danger>Delete team</Button>
                </Popconfirm>
              </Col>
            </Row>}
            {!isAdmin && <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Popconfirm
                  key="delete"
                  placement="top"
                  title={"Are you sure you want to leave the team?"}
                  onConfirm={leaveTeam}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="teamkit-float-right" onClick={() => {}} danger>Leave team</Button>
                </Popconfirm>
              </Col>
            </Row>}
            <TeamDetails
              team={team}
              currentUser={currentUser}
              onUpdateCompleted={() => { fetchTeam(teamID) }}
            />
            <Row gutter={[16, 16]}>
              <Col xs={24} className="teamkit-section-title">
                MEMBERS
              </Col>
            </Row>
            {isAdmin && <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Card>
                  <MemberForm team={team} onCreateComplete={() => { fetchTeam(teamID) }}/>
                </Card>
              </Col>
            </Row>}
            <Row gutter={[16, 16]}>
              {team.members.map((member) => {
                return (
                  <Col key={`member-card-${member.username}`} lg={8} md={12} sm={24} xs={24}>
                    <MemberCard
                      team={team}
                      isCurrentUserAdmin={isAdmin}
                      member={member}
                      onDeleted={async () => {
                        if (member.username === currentUser.username) {
                          await onLeavedTeam();
                        } else {
                          await fetchTeam(teamID);
                        }
                      }}
                      onCardUpdated={() => { fetchTeam(teamID) }}
                    />
                  </Col>
                );
              })}
              {team.teamfuturemember_set.map((futuremember) => {
                return (
                  <Col key={`future-card-${futuremember.id}`} lg={8} md={12} sm={24} xs={24}>
                    <MemberCard
                      team={team}
                      isCurrentUserAdmin={isAdmin}
                      futureMember={futuremember}
                      onDeleted={() => {}}
                      onCardUpdated={() => { fetchTeam(teamID) }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Space>
        </Fragment>
      }
    </Fragment>
  );
};

export default withRouter(TeamShow);
