import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Layout, Row, Col, Space } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import MainHeader from './MainHeader';
import {
  LandingPageTailwinds,
  FeatureGoalTailwind,
  AboutPage,
  ContactPage,
  TermsPage,
  CookiesPage,
  PrivacyPage,
  HowToPage,
  FeatureRetrospectiveTailwind,
  FeatureDailyCheckinTailwind,
  FeatureScrumPokerTailwind,
} from "./components/static";

import './Main.less';

const { Footer } = Layout;

const withHeaderFooter = (Component: React.FC<any>) => {
  return () => (
    <>
      <MainHeader />
      <Component />
      <Footer style={{ textAlign: 'center', background: '#1c2331', color: 'white' }}>
        <div className="landing-page-container">
          <Row style={{ width: '100%' }}>
            <Col xs={12} sm={6} lg={5} className="footer-resource-link-container">
              <div><b>Resources</b></div>
              <br />
              <div><a href="/about" style={{ color: 'inherit' }}>About</a></div>
              <div><a href="/features/retrospective" style={{ color: 'inherit' }}>Retrospective</a></div>
              <div><a href="/features/goal_tracking" style={{ color: 'inherit' }}>Goal Tracking</a></div>
              <div><a href="/features/daily_checkin" style={{ color: 'inherit' }}>Daily Check-in</a></div>
              <div><a href="/features/scrum_poker" style={{ color: 'inherit' }}>Scrum Poker</a></div>
            </Col>
            <Col xs={12} sm={6} lg={5} className="footer-resource-link-container">
              <div><b>Useful Links</b></div>
              <br />
              <div><a href="/terms" style={{ color: 'inherit' }}>Terms of Use</a></div>
              <div><a href="/privacy" style={{ color: 'inherit' }}>Privacy policy</a></div>
              <div><a href="/cookies" style={{ color: 'inherit' }}>Cookie policy</a></div>
              <div><a href="/contact" style={{ color: 'inherit' }}>Contact us</a></div>
            </Col>
            <Col xs={24} sm={12} lg={14}>
              <div className="footer-resource-link-container">
                <p>©RetroPlus 2024</p>
                <p><Space><MailOutlined />admin@retroplus.io</Space></p>
              </div>
            </Col>
          </Row>
        </div>
      </Footer>
    </>
  )
}


const Main: React.FC<any> = () => {
  return (
      <Layout>
        <Layout className="site-layout">
          <Switch>
            <Route path="/about" component={withHeaderFooter(AboutPage)} />
            <Route path="/contact" component={withHeaderFooter(ContactPage)} />
            <Route path="/terms" component={withHeaderFooter(TermsPage)} />
            <Route path="/cookies" component={withHeaderFooter(CookiesPage)} />
            <Route path="/privacy" component={withHeaderFooter(PrivacyPage)} />
            <Route path="/howto" component={withHeaderFooter(HowToPage)} />
            <Route path="/features/retrospective" component={FeatureRetrospectiveTailwind} />
            <Route path="/features/goal_tracking" component={FeatureGoalTailwind} />
            <Route path="/features/daily_checkin" component={FeatureDailyCheckinTailwind} />
            <Route path="/features/scrum_poker" component={FeatureScrumPokerTailwind} />
            <Route path="/" component={LandingPageTailwinds} />
          </Switch>
        </Layout>
      </Layout>
  );
}

export default Main;
