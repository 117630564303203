import _ from 'lodash-es';
import React from "react";
import { Progress } from 'antd';
import { Target, TargetProgressType, TargetProgressCompareDirection, TargetStatus, isNumericProgressType, formatNumberProgress } from '../../../models';
import TargetStatusTag from './TargetStatusTag';
import './styles.less';


interface TargetProgressBarProps {
  style?: React.CSSProperties;
  target: Target;
  showActualValue?: boolean;
  noProgressBarBool?: boolean;
  small?: boolean;
}

export const percentageFromTarget = (target: Target): number => {
  if (target.target_progress.type === TargetProgressType.Bool) {
    return target.status === TargetStatus.Done ? 100 : 0;
  }

  if (isNumericProgressType(target.target_progress.type)) {
    if (target.target_progress.compare_direction === TargetProgressCompareDirection.Asc) {
      let startValue = target.target_progress.initial_value_float; // TODO: Update this?;
      let percent = (target.target_progress.current_value_float - startValue) / (target.target_progress.target_value_float - startValue) * 100;
      if (target.target_progress.target_value_float === target.target_progress.current_value_float) {
        percent = 100;
      }
      return Math.round(percent);
    }
  }

  return 0;
} 

const TargetProgressBar: React.FC<TargetProgressBarProps> = ({ style, target, showActualValue, small, noProgressBarBool }) => {
  if (target.status === TargetStatus.Canceled) {
    return (
      <div>
        <TargetStatusTag status={target.status} />
      </div>
    );
  }

  const percentage = percentageFromTarget(target);
  let summaryText = `${percentage}%`;
  let fullText = `${percentage}%`;
  if (target.target_progress.type === TargetProgressType.Num || target.target_progress.type === TargetProgressType.Currency) {
    let currenValFmt = formatNumberProgress(target.target_progress.current_value_float);
    let targetValFmt = formatNumberProgress(target.target_progress.target_value_float);
    if (target.target_progress.type === TargetProgressType.Currency) {
      currenValFmt = `${target.target_progress.unit}${currenValFmt}`;
      targetValFmt = `${target.target_progress.unit}${targetValFmt}`;
    }
    fullText = `${currenValFmt} / ${targetValFmt}`;
  }

  let status: "normal" | "active" | "exception" | "success" = 'normal';
  switch (target.status) {
    case TargetStatus.AtRisk:
      status = 'exception';
      break;
    // case TargetStatus.Canceled:
    //   status = 'exception';
    //   break;
    case TargetStatus.Done:
      status = 'success';
      break;
  }

  return (
    <div>
      {showActualValue && <div className="teamkit-display-flex">
        <div className="teamkit-flex-full">
          <TargetStatusTag status={target.status} />
        </div>
        {target.target_progress.type !== TargetProgressType.Bool && <div>
          {fullText}
        </div>}
      </div>}
      {(target.target_progress.type !== TargetProgressType.Bool || !noProgressBarBool) && <div>
        <Progress
          style={_.assign(style ? style : {}, {width: small ? 100 : '100%'})}
          percent={percentage}
          status={status}
          showInfo={!showActualValue}
          format={() => summaryText}
        />
      </div>}
    </div>
  );
} 

export default TargetProgressBar;