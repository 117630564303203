import _ from 'lodash-es';
import React from "react";
import moment from 'moment-timezone';
import './styles.less';
import { Timeline, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { VotingItem, VotingSession, VotingItemStatus, EstimationUnit } from '../../models';
import { getAverageScoreFormatted, getScoreFormatted } from './helpers';

interface VotingSessionHistoryProps {
  votingSession: VotingSession;
  votingItems?: Array<VotingItem|undefined>;
}
const VotingSessionHistory: React.FC<VotingSessionHistoryProps> = ({ votingSession, votingItems }) => {
  if (!votingItems || votingItems.length === 0) {
    return (
      <Empty />
    );
  }

  return (
    <Timeline>
      {_.map(_.reverse(_.clone(votingItems)), (votingItem: VotingItem) => {
        if (!votingItem) {
          return null;
        }

        let avgScore = -1;
        let color = 'green';
        if (votingItem.status === VotingItemStatus.Voting) {
          color = 'blue';
        } else if (votingItem.status === VotingItemStatus.Ended) {
          let sumScore = 0;
          let sumValidVotes = 0;
          if (votingItem.vote_set.length > 0) {
            for (let vote of votingItem.vote_set) {
              // Only count valid score
              if (vote.score >= 0 ) {
                sumScore += vote.score;
                sumValidVotes++;
              }
            }
          }
          if (sumValidVotes > 0) {
            avgScore = sumScore / sumValidVotes;
          } else {
            color = 'gray'
          }
        }

        let historyTitle = votingItem.title;
        if (votingItem.status === VotingItemStatus.Ended && votingSession.estimation_unit !== EstimationUnit.Freestyle && avgScore >= 0) {
          historyTitle += ` [Avg: ${getAverageScoreFormatted(votingSession, votingItem)}]`
        }
        return (
          <Timeline.Item key={`history-${votingItem.id}`} color={color} dot={votingItem.status !== VotingItemStatus.Ended ? <LoadingOutlined /> : null}>
            <p>
              {historyTitle} <br />
              <span className="teamkit-small-listing-card-summary">{moment(votingItem.created_at).format('MMM D, YYYY h:mm A')}</span>
            </p>
            {_.map(votingItem.vote_set, (vote:any,index:number) => {
              return (
                <p key={`vote-${votingItem.id}-${index}`}>{vote.owner}: {getScoreFormatted(vote.score, votingSession)}</p>
              );
            })}
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
}

export default VotingSessionHistory;
