import React, { Fragment, useState, useEffect, useContext } from "react";
import './styles.less';
import { Row, Col, Spin, Empty } from 'antd';
import VotingSessionCard from './VotingSessionCard';
import { get } from '../../http';
import { getCurrentTeam } from '../../helpers';
import { apiRoutes } from '../../const';
import { Team } from '../../models';
import { AppContext } from '../../../store';

const VotingSessionList: React.FC<any> = () => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [votingSessions, setVotingSessions] = useState<any>();

  const fetchScumPokers = async function(team:Team) {
    const data = await get(apiRoutes.scrumPokerSessionList, {
      team: team.id,
    });
    setVotingSessions(data);
  }
  useEffect(() => {
    if (currentTeam) {
      fetchScumPokers(currentTeam);
    }
  }, [currentTeam]);

  if (!currentTeam || !votingSessions) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="teamkit-section-title">
          PAST
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {Array.isArray(votingSessions) && votingSessions.length === 0 && <Empty />}
        {Array.isArray(votingSessions) && votingSessions.length > 0 && votingSessions.map((session:any) => (
          <Col key={session.id} xl={8} md={12} xs={24}>
            <VotingSessionCard votingSession={session} onRemoteChanged={() => {fetchScumPokers(currentTeam)}}/>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
}

export default VotingSessionList;
