import _ from "lodash-es";
import { Team, User } from '../models';
import { APP_BASE_PATH } from '../const';

export const getCurrentTeam = (currentUser:User|undefined):Team|undefined => {
  if (!currentUser) {
    return;
  }
  const teamIndex = getCurrentTeamIndex();
  if (teamIndex < 0 || teamIndex >= currentUser.team_membership.length) {
    return;
  }

  return currentUser.team_membership[teamIndex];
}

export const getCurrentTeamIndex = ():number => {
  const path = window.location.pathname;
  const pathSplitted = path.split('/');
  for (let i = 0; i < pathSplitted.length-1; i++) {
    if (pathSplitted[i] === 't') {
      return parseInt(pathSplitted[i+1]);
    }
  }

  return -1;
}

export const getBasePathWithTeam = ():string => {
  const path = window.location.pathname;
  const teamPrefixIndex = path.indexOf(`${APP_BASE_PATH}t/`);
  // if no team index, base path is emty (root)
  if (teamPrefixIndex < 0 ) {
    // remove trailing /
    if (APP_BASE_PATH.length > 0 && APP_BASE_PATH[APP_BASE_PATH.length-1] === '/') {
      return APP_BASE_PATH.slice(0, APP_BASE_PATH.length-1)
    } 
    return (APP_BASE_PATH);
  }
  const pathSplitted = path.split('/');
  const basePath:Array<string> = [];
  for (let i = 0; i < pathSplitted.length; i++) {
    basePath.push(pathSplitted[i])
    if (pathSplitted[i] === 't') {
      if (i < pathSplitted.length-1) {
        basePath.push(pathSplitted[i+1]);
      }
      break;
    }
  }
  return basePath.join('/');
}

export const getCurrentPathWithNewTeamIndex = (teamIndex:number):string => {
  const currentPath = window.location.pathname;
  // if no team index, just append the team as prefix
  if (currentPath.indexOf(`${APP_BASE_PATH}t/`) < 0 ) {
    if (currentPath.indexOf(`${APP_BASE_PATH}`) < 0 ) {
      return `${getPathPrefix(teamIndex)}${currentPath}`;
    }

    return `${getPathPrefix(teamIndex)}/${currentPath.substring(currentPath.indexOf(`${APP_BASE_PATH}`)+APP_BASE_PATH.length)}`;
  }

  // replace the route param after /t to the new team
  const pathSplitted = currentPath.split('/');
  const newPath:Array<string> = [];
  for (let i = 0; i < pathSplitted.length; i++) {
    newPath.push(pathSplitted[i])
    if (pathSplitted[i] === 't') {
      newPath.push(teamIndex.toString());
      // append the main page after team index
      if (i < pathSplitted.length-2) {
        newPath.push(pathSplitted[i+2]);
      }
      break;
    }
  }
  return newPath.join('/');
}

export const getPathPrefix = (teamIndex:number):string => {
  return `${APP_BASE_PATH}t/${teamIndex}`;
}

// Cache helper for live edit mode
export const getTodoCacheKey = (itemID?:string,todoID?:string) => {
  return `todo-${itemID}-${todoID}`
}

export const getItemCacheKey = (itemID?:string) => {
  return `item-${itemID}`
}

export const isTeamAdmin = (currentUser:User, team:Team):boolean => {
  return _.filter(team.teamadmin_set, (admin) => admin.username === currentUser.username).length > 0
}

export const isLiteMode = ():boolean => {
  return window.location.pathname.indexOf('lite') >= 0;
}

export const isRequireLogin = ():boolean => {
  let basePath = APP_BASE_PATH;
  if (basePath.length > 0 && basePath.charAt(basePath.length-1) === '/') {
    basePath = basePath.substring(0, basePath.length-1);
  }
  const isAppPage = window.location.pathname.indexOf(basePath) >= 0;
  const boardSinglePageRegex = /.*boards\/.+$/g;
  const isRetroboardPage = window.location.pathname.match(boardSinglePageRegex) != null;
  const isInvitedPage = window.location.pathname.indexOf('invites') >= 0;

  return isAppPage && !isRetroboardPage && !isInvitedPage;
}
