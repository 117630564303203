import _ from 'lodash-es';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Spin, Card, Space, Tooltip, Progress, Checkbox, Popconfirm, Collapse, Badge } from 'antd';
import { Comment } from '@ant-design/compatible';
import { get, put, del } from '../../http';
import CheckinForm from './CheckinForm';
import { apiRoutes } from '../../const';
import { UserAvatar, AssignmentDropdown } from '../common';
import { getCurrentTeam } from '../../helpers';
import { CheckinGroup, CheckinSession, CheckinItemType, CheckinItem, CheckinItemStatus, User, Comment as CommentModel } from '../../models';
import { CommentForm, CommentSingle} from '../comments';
import { AppContext } from '../../../store';
import { customIcons, maxMoodScore, moodTagFromScore } from './const';
import CheckinGroupSummary, { getStrokeColorFromMood } from './CheckinGroupSummary';
import './styles.less';

const { Panel } = Collapse;

interface CheckinItemWithSession {
  checkin_item: CheckinItem;
  checkin_session: CheckinSession;
}

interface BlockersAssignedToMeProps {
  checkinSessions: CheckinSession[];
  currentUser: User;
  members: Array<User>;
  onItemUpdated: () => void;
}

const BlockersAssignedToMe: React.FC<BlockersAssignedToMeProps> = ({ currentUser, members, checkinSessions, onItemUpdated }) => {
  const updateAssignee = async (checkinSession: CheckinSession, checkinItem: CheckinItem, value:string) => {
    await put(apiRoutes.checkinItemSingle(checkinSession.id, checkinItem.id), _.assign({}, checkinItem, {
      assignee: value,
    }));
    onItemUpdated();
  }

  const updateItemStatus = async (checkinSession: CheckinSession, blocker: CheckinItem, value:boolean) => {
    await put(apiRoutes.checkinItemSingle(checkinSession.id, blocker.id), _.assign({}, blocker, {
      status: value ? CheckinItemStatus.Done : CheckinItemStatus.Todo,
    }));
    onItemUpdated();
  }

  const blockersAssignedToMe: CheckinItemWithSession[] = [];
  _.each(checkinSessions, (session:CheckinSession) => {
    _.each(session.checkinitem_set, (item) => {
      if (item.type === CheckinItemType.Blocker && item.assignee && item.assignee === currentUser.username) {
        blockersAssignedToMe.push({
          checkin_item: item,
          checkin_session: session,
        });
      }
    });
  });
   
  if (blockersAssignedToMe.length === 0) {
    return <></>;
  }

  return (
    <Card>
      <Collapse ghost>
        <Panel header={<div>Blockers assigned to me <Badge count={blockersAssignedToMe.length} /></div>} key="1">
          <Space direction="vertical" style={{ width: "100%" }}>
            {_.map(blockersAssignedToMe, (blocker:CheckinItemWithSession) => {
              return (
                <Card className={classNames("single-checkin-item-cards", {
                  "checkin-item-blocker-done": blocker.checkin_item.status === CheckinItemStatus.Done,
                })}>
                <div className="teamkit-show-line-break">
                  <div className="teamkit-display-flex">
                    <span style={{ marginRight: 8 }}>
                      <Tooltip placement="bottom" title="Resolve">
                        <Checkbox
                          checked={blocker.checkin_item.status === CheckinItemStatus.Done}
                          onChange={(values) => updateItemStatus(blocker.checkin_session, blocker.checkin_item, values.target.checked)}
                        />
                      </Tooltip>
                    </span>
                    <span style={{ marginRight: 8 }}>
                      <AssignmentDropdown
                        assignable={blocker.checkin_item}
                        members={members}
                        changeAssignee={(value) => updateAssignee(blocker.checkin_session, blocker.checkin_item, value)}
                        hideUsername={true}
                      />
                    </span>
                    <span className="teamkit-flex-full">{blocker.checkin_item.content}</span>
                  </div>
                </div>
                </Card>
              );
            })}
          </Space>
        </Panel>
      </Collapse>
    </Card>
  )
}

interface CheckinSessionCardProps {
  checkinSession: CheckinSession
  currentUser: User;
  members: User[];
  onSessionUpdated: () => void;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
}

const CheckinSessionCard: React.FC<CheckinSessionCardProps> = ({ checkinSession, currentUser, members, onSessionUpdated, onEditClicked, onDeleteClicked }) => {
  const [isShowComments, setIsShowComments] = useState(false);
  const [newCommentID, setNewCommentID] = useState<number>(1);
  const [comments, setComments] = useState<CommentModel[]>();

  useEffect(() => {
    if (checkinSession) {
      fetchComments();
    }
  }, [checkinSession]);


  const fetchComments = async () => {
    const comments = await get(apiRoutes.checkinSessionCommentList(checkinSession.id));
    setComments(comments);
  }

  const updateAssignee = async (checkinSession: CheckinSession, checkinItem: CheckinItem, value:string) => {
    await put(apiRoutes.checkinItemSingle(checkinSession.id, checkinItem.id), _.assign({}, checkinItem, {
      assignee: value,
    }));
    onSessionUpdated();
  }

  const moodPercent = 100*checkinSession.mood_score/maxMoodScore;
  const passItem = _.find(checkinSession.checkinitem_set, (item) => item.type === CheckinItemType.Past);
  const futureItems = _.filter(checkinSession.checkinitem_set, (item) => item.type === CheckinItemType.Future);
  const blockers = _.filter(checkinSession.checkinitem_set, (item) => item.type === CheckinItemType.Blocker);
  const strokeColor = getStrokeColorFromMood(moodPercent);

  const updateItemStatus = async (checkinSession: CheckinSession, blocker: CheckinItem, value:boolean) => {
    await put(apiRoutes.checkinItemSingle(checkinSession.id, blocker.id), _.assign({}, blocker, {
      status: value ? CheckinItemStatus.Done : CheckinItemStatus.Todo,
    }));
    onSessionUpdated();
  }

  const isMine = checkinSession.owner === currentUser.username;
  const actionsNodes: React.ReactNode[] = [
    <span className="comment-reply-link" onClick={() => setIsShowComments(!isShowComments)}>Comments ({comments ? comments.length : 0})</span>,
  ];
  if (isMine) {
    actionsNodes.push(
      <span className="comment-reply-link" onClick={onEditClicked}>Edit</span>,
      <Popconfirm
        key="delete"
        placement="top"
        title={"Are you sure you want to delete?"}
        onConfirm={onDeleteClicked}
        okText="Yes"
        cancelText="No"
      >
        <span className="comment-reply-link">Delete</span>
      </Popconfirm>,
    );
  }

  return (
    <Card className="single-checkin-item-cards">
      <Comment
        className="item-comment-form"
        avatar={
          <UserAvatar user={checkinSession.owner_user} />
        }
        author={checkinSession.owner === currentUser.username ? `${checkinSession.owner} - You` : checkinSession.owner}
        datetime={
          <Tooltip title={moment(checkinSession.created_at).format('MMMM Do YYYY, h:mm:ss')}>
            <span>{moment(checkinSession.created_at).fromNow()}</span>
          </Tooltip>
        }
        actions={actionsNodes}
        content={
          <Space direction="vertical" style={{width: '100%'}}>
            <div>
              Mood:
              &nbsp;<Progress percent={moodPercent} steps={maxMoodScore} showInfo={false} strokeColor={getStrokeColorFromMood(moodPercent)}/>
              &nbsp;{customIcons[checkinSession.mood_score] && <span style={{color: strokeColor}}>{customIcons[checkinSession.mood_score]}</span>}
              &nbsp;{moodTagFromScore(checkinSession.mood_score)}
            </div>
            <div>
              <div>
                <b>What I worked on yesterday?</b>
              </div>
              <div className="teamkit-show-line-break">
                {passItem?.content}
              </div>
            </div>
            <div>
              <div>
                <b>What I will work on today?</b>
              </div>
              <Space direction="vertical" style={{ width: "100%" }}>
                {futureItems.map((futureItem: CheckinItem) => {
                  return (
                    <Card style={{ padding: 8 }} className={futureItem.status === CheckinItemStatus.Done ? "checkin-item-blocker-done" : ""}>
                      <div className="teamkit-show-line-break">
                        <div className="teamkit-display-flex">
                          <span style={{ marginRight: 8 }}>
                            <Tooltip placement="bottom" title="Resolve">
                              <Checkbox
                                checked={futureItem.status === CheckinItemStatus.Done}
                                onChange={(values) => updateItemStatus(checkinSession, futureItem, values.target.checked)}
                              />
                            </Tooltip>
                          </span>
                          <span className="teamkit-flex-full">{futureItem.content}</span>
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </Space>
            </div>
            <div>
              <div>
                <b>Blockers</b>
              </div>
              <Space direction="vertical" style={{ width: "100%" }}>
                {blockers.length === 0 && <i>No blockers</i>}
                {blockers.map((blocker) => {
                  return (
                    <Card style={{ padding: 8 }} className={blocker.status === CheckinItemStatus.Done ? "checkin-item-blocker-done" : ""}>
                      <div className="teamkit-show-line-break">
                        <div className="teamkit-display-flex">
                          <span style={{ marginRight: 8 }}>
                            <Tooltip placement="bottom" title="Resolve">
                              <Checkbox
                                checked={blocker.status === CheckinItemStatus.Done}
                                onChange={(values) => updateItemStatus(checkinSession, blocker, values.target.checked)}
                              />
                            </Tooltip>
                          </span>
                          <span style={{ marginRight: 8 }}>
                            <AssignmentDropdown
                              assignable={blocker}
                              members={members}
                              changeAssignee={(value) => updateAssignee(checkinSession, blocker, value)}
                              hideUsername={true}
                            />
                          </span>
                          <span className="teamkit-flex-full">{blocker.content}</span>
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </Space>
            </div>
          </Space>
        }
      />
      {isShowComments &&
        <Space direction="vertical" style={{ width: '100%' }}>
            <Comment
              className="item-comment-form checkin-session-comment-form"
              avatar={
                <UserAvatar username={currentUser?.username} />
              }
              content={
                <CommentForm
                  key={`checkin-session-${checkinSession.id}-comment-form-${newCommentID}`}
                  checkinSession={checkinSession}
                  hideCancel
                  onCancel={() => {}}
                  onCommentCreated = {() => {
                    setNewCommentID(newCommentID + 1);
                    fetchComments();
                  }}
                />
              }
            />
            {comments && comments.map((comment) => {
              return (
                <CommentSingle
                  className="item-comment-form"
                  checkinSession={checkinSession}
                  currentUser={currentUser}
                  comment={comment}
                  onUpdated={fetchComments}
                  onDeleted={fetchComments}
                />
              )
            })}
        </Space>}
    </Card>
  );
};

interface CheckingSessionListProps {
  checkinGroup: CheckinGroup;
  checkinSessions: Array<CheckinSession>;
  members: Array<User>;
  onSessionUpdated: (checkinSession: CheckinSession) => void;
  onEditClicked: () => void;
}

const CheckingSessionList: React.FC<CheckingSessionListProps> = ({ checkinGroup, checkinSessions, members, onSessionUpdated, onEditClicked }) => {
  const { state: { currentUser } } = useContext(AppContext);

  if (!currentUser) {
    return <Spin />;
  }

  const deleteSession = async (checkinSession: CheckinSession) => {
    await del(apiRoutes.checkinSessionSingle(checkinGroup.id, checkinSession.id));
    onSessionUpdated(checkinSession);
  }

  return (
    <Row gutter={[16, 16]}>
      {checkinSessions.map((checkinSession: CheckinSession) => (
        <Col md={12} xs={24}>
          <CheckinSessionCard
            checkinSession={checkinSession}
            currentUser={currentUser}
            members={members}
            onSessionUpdated={() => onSessionUpdated(checkinSession)}
            onEditClicked={onEditClicked}
            onDeleteClicked={() => deleteSession(checkinSession)}
          />
        </Col>
      ))}
    </Row>
  );
}

interface CheckinCreateProps {
  checkinGroup: CheckinGroup;
  members: Array<User>;
  latestCheckinSession?: CheckinSession;
  onCreated: () => void;
}

const CheckinCreate: React.FC<CheckinCreateProps> = ({ checkinGroup, members, latestCheckinSession, onCreated }) => {
  const { state: { currentUser } } = useContext(AppContext);
  if (!currentUser) {
    return <Spin />;
  }

  return (
    <Card title="Your check-in">
      <CheckinForm
        onSaved={onCreated}
        members={members}
        checkinGroup={checkinGroup}
        latestCheckinSession={latestCheckinSession}
      />
    </Card>
  );
}

interface CheckinUpdateProps {
  checkinGroup: CheckinGroup;
  checkinSession: CheckinSession;
  members: Array<User>;
  onUpdated: () => void;
  onCanceled: () => void;
}

const CheckinUpdate: React.FC<CheckinUpdateProps> = ({ checkinGroup, checkinSession, members, onUpdated, onCanceled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  if (!currentUser) {
    return <Spin />;
  }

  return (
    <Card title="Your check-in">
      <CheckinForm
        members={members}
        checkinGroup={checkinGroup}
        checkinSession={checkinSession}
        allowCancel
        onSaved={onUpdated}
        onCanceled={onCanceled}
      />
    </Card>
  );
}

interface CheckingGroupShowProps {
  checkinGroup: CheckinGroup;
  members: Array<User>;
}

const CheckingGroupShow: React.FC<CheckingGroupShowProps> = ({ checkinGroup, members }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [checkinSessions, setCheckinSessions] = useState<Array<CheckinSession>>();
  const [myCheckinSession, setMyCheckinSession] = useState<CheckinSession|undefined>();
  const [latestCheckinSession, setLatestCheckinSession] = useState<CheckinSession|undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser && checkinGroup) {
      setIsEditing(false);
      fetchCheckinSessions(checkinGroup);
    }
  }, [currentUser, checkinGroup]);

  useEffect(() => {
    if (currentUser && checkinGroup && currentTeam) {
      fetchLatestCheckinSession();
    }
  }, [currentUser, checkinGroup, currentTeam, myCheckinSession]);

  const fetchLatestCheckinSession = async () => {
    let data = await get(apiRoutes.checkinSessionLatest, { team: currentTeam?.id });
    setLatestCheckinSession(data);
  }

  const fetchCheckinSessions = async (checkinGroup: CheckinGroup) => {
    let data: Array<CheckinSession> = await get(apiRoutes.checkinSessionList(checkinGroup.id));
    data = _.sortBy(data, (checkinSession: CheckinSession) => {
      if (checkinSession.owner === currentUser?.username) {
        return -1;
      }
      return moment(checkinSession.created_at).unix();
    });

    setCheckinSessions(data);
    let myCheckinSession = _.find(data, (checkinSession: CheckinSession) => {
      return checkinSession.owner === currentUser?.username;
    });
    setMyCheckinSession(myCheckinSession);
  }

  const onCheckinUpdated = () => {
    setIsEditing(false);
    fetchCheckinSessions(checkinGroup);
  }

  if (!checkinSessions || !currentUser) {
    return <Spin />;
  }

  return (
    <Row gutter={[16, 16]}>
      <Space direction="vertical" style={{ width: '100%'}}>
        <CheckinGroupSummary
          checkinGroup={checkinGroup}
          checkinSessions={checkinSessions}
          members={members}
        />
        <BlockersAssignedToMe
          checkinSessions={checkinSessions}
          members={members}
          currentUser={currentUser}
          onItemUpdated={onCheckinUpdated}
        />
        {!myCheckinSession && <CheckinCreate
          members={members}
          checkinGroup={checkinGroup}
          onCreated={onCheckinUpdated}
          latestCheckinSession={latestCheckinSession}
        />}
        {myCheckinSession && isEditing && <CheckinUpdate
          members={members}
          checkinGroup={checkinGroup}
          checkinSession={myCheckinSession}
          onUpdated={onCheckinUpdated}
          onCanceled={() => { setIsEditing(false) }}
        />}
        <CheckingSessionList
          checkinGroup={checkinGroup}
          checkinSessions={checkinSessions}
          members={members}
          onSessionUpdated={onCheckinUpdated}
          onEditClicked={() => { setIsEditing(true) }}
        />
      </Space>
    </Row>
  );
}

export default CheckingGroupShow;
