import React from "react";
import { notification } from 'antd';
import { ItemEvent, TodoEvent, ItemEventType, TodoEventType, PollEvent, PollEventType, KudoEvent, KudoEventType } from '../../models';

export const notifyItemEvent = (itemEvent:ItemEvent) => {
  const username = itemEvent.user ? itemEvent.user.username : 'Someone';
  let message:any;
  if (itemEvent.type === ItemEventType.Add) {
    message = <span><b>{username}</b> just added an item</span>;
  } else if (itemEvent.type === ItemEventType.Update) {
    message = <span><b>{username}</b> just updated an item</span>;
  } else if (itemEvent.type === ItemEventType.Delete) {
    message = <span><b>{username}</b> just deleted an item</span>;
  } else if (itemEvent.type === ItemEventType.AddComment) {
    message = <span><b>{username}</b> just added a comment on an item</span>;
  } else if (itemEvent.type === ItemEventType.RemoveComment) {
    message = <span><b>{username}</b> just removed a comment from an item</span>;
  } else if (itemEvent.type === ItemEventType.UpdateComment) {
    message = <span><b>{username}</b> just updated a comment</span>;
  } else if (itemEvent.type === ItemEventType.Like) {
    message = <span><b>{username}</b> just up-voted an item</span>;
  } else if (itemEvent.type === ItemEventType.Dislike) {
    message = <span><b>{username}</b> just down-voted an item</span>;
  } else if (itemEvent.type === ItemEventType.Rearrange) {
    message = <span><b>{username}</b> just re-arranged an item</span>;
  }
  if (message) {
    notification['info']({
      message: '',
      description: message,
      duration: 3,
    });
  }
}

export const notifyTodoEvent = (todoEvent:TodoEvent) => {
  const username = todoEvent.user ? todoEvent.user.username : 'Someone';
  let message:any;
  if (todoEvent.type === TodoEventType.Add) {
    message = <span><b>{username}</b> just added a task</span>;
  } else if (todoEvent.type === TodoEventType.Update) {
    message = <span><b>{username}</b> just updated a task</span>;
  } else if (todoEvent.type === TodoEventType.Delete) {
    message = <span><b>{username}</b> just deleted a task</span>;
  } else if (todoEvent.type === TodoEventType.Archive) {
    message = <span><b>{username}</b> just archived a task</span>;
  } else if (todoEvent.type === TodoEventType.Restore) {
    message = <span><b>{username}</b> just restore a task</span>;
  } else if (todoEvent.type === TodoEventType.AddComment) {
    message = <span><b>{username}</b> just added a comment on a task</span>;
  } else if (todoEvent.type === TodoEventType.RemoveComment) {
    message = <span><b>{username}</b> just removed a comment from a task</span>;
  } else if (todoEvent.type === TodoEventType.MarkAsDone) {
    message = <span><b>{username}</b> just marked a task as Done</span>;
  } else if (todoEvent.type === TodoEventType.UpdateStatus) {
    message = <span><b>{username}</b> just update status of a task</span>;
  } else if (todoEvent.type === TodoEventType.Assign) {
    message = <span><b>{username}</b> just assigned a task to his teammate</span>;
  } else if (todoEvent.type === TodoEventType.Unassign) {
    message = <span><b>{username}</b> just unassigned a task</span>;
  }
  if (message) {
    notification['info']({
      message: '',
      description: message,
      duration: 3,
    });
  }
}

export const notifyPollEvent = (pollEvent:PollEvent) => {
  const username = pollEvent.user ? pollEvent.user.username : 'Someone';
  let message:any;
  if (pollEvent.type === PollEventType.Add) {
    message = <span><b>{username}</b> just added a poll</span>;
  } else if (pollEvent.type === PollEventType.Delete) {
    message = <span><b>{username}</b> just deleted a poll</span>;
  } else if (pollEvent.type === PollEventType.Respond) {
    message = <span><b>{username}</b> just responded a poll</span>;
  } else if (pollEvent.type === PollEventType.Update) {
    message = <span><b>{username}</b> just updated a poll</span>;
  }
  if (message) {
    notification['info']({
      message: '',
      description: message,
      duration: 3,
    });
  }
}

export const notifyKudoEvent = (kudoEvent:KudoEvent) => {
  const username = kudoEvent.user ? kudoEvent.user.username : 'Someone';
  let message:any;
  if (kudoEvent.type === KudoEventType.Add) {
    message = <span><b>{username}</b> just gave a kudo</span>;
  } else if (kudoEvent.type === KudoEventType.Delete) {
    message = <span><b>{username}</b> just deleted a kudo</span>;
  } else if (kudoEvent.type === KudoEventType.Update) {
    message = <span><b>{username}</b> just updated a kudo</span>;
  }
  if (message) {
    notification['info']({
      message: '',
      description: message,
      duration: 3,
    });
  }
}
