import _ from 'lodash-es';
import moment from 'moment';
import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import { Row, Col, Button, Card, Spin, Space, Alert, Collapse, Badge } from 'antd';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams as useRouterParams, withRouter, useLocation, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { ItemForm, ItemCard } from './items';
import { PollCard } from '../../polls';
import { notifyPollEvent, notifyTodoEvent, notifyItemEvent, notifyKudoEvent } from '../../boards';
import { NotFound } from '../../common';
import { KudoMiniCard } from '../../kudos';
import BoardDetails from './BoardDetails';
import { appRoutes, apiRoutes, publicApiRoutes, colorSchemeClassMapping, localStorageKeys } from '../../../const';
import { useWindowDimensions } from '../../../hooks';
import { get, post, put } from '../../../http';
import { Board, BoardColumn, Item, Todo, User, Team, ItemEvent, ItemEventType, Poll, ItemType, Kudo, KudoEvent, KudoEventType } from '../../../models';
import { AppContext } from '../../../../store';
import KudoNotificationModal from '../../kudos/KudoNotificationModal';
import './styles.less';

const { Panel } = Collapse;

const SOCKET_CONNECT_RETRY_TIMEOUT_MS = 3000;

const DEFAULT_PAGE_SIZE = 4;
const MD_MAX_WIDTH = 1200; 
const MD_PAGE_SIZE= 3;
const SM_MAX_WIDTH = 992;
const SM_PAGE_SIZE = 2;
const XS_MAX_WIDTH = 700;
const XS_PAGE_SIZE = 1;

interface ItemColumnProps {
  board: Board;
  column: BoardColumn;
  items?: Array<Item>;
  todos?: Array<Todo>;
  polls?: Array<Poll>;
  currentUser: User;
  presentationMode: boolean;
  onItemRemoteChanged: (event:ItemEvent) => void;
}

const ItemColumn: React.FC<ItemColumnProps> = ({ board, column, items, todos, polls, currentUser, presentationMode, onItemRemoteChanged }) => {
  const itemListRef = useRef(null)
  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);

  useEffect(() => {
    let _items = _.sortBy(items, (i) => i.sort_index ? i.sort_index : moment(i.created_at).unix());
    setSortedItems(_items);
  }, [items]);

  const getTodosForItem = (itemID:string):Array<Todo> => {
    const itemTodos:Array<Todo> = [];
    _.each(todos, (todo:Todo) => {
      if (todo.item === itemID) {
        itemTodos.push(todo);
      }
    })
    return itemTodos;
  }

  const getListStyle = (isDraggingOver: any) => {
    return {
      background: isDraggingOver ? '#E1E3E6' : 'none',
      width: '100%',
      minHeight: (isDraggingOver && itemListRef.current) ? itemListRef.current.clientHeight : undefined,
    };
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    const styles = {
      userSelect: 'none',
      margin: '0 0 8px 0',
      ...draggableStyle
    };
    return styles;
  };

  const onDragEnd = async (result:any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    if (!sortedItems) {
      return;
    }

    let newItems = [];
    const item = sortedItems[source.index];
    for (let i = 0; i < sortedItems.length; i++) {
      if (i !== source.index) {
        newItems.push(sortedItems[i]);
      }
    }
    newItems.splice(destination.index, 0, item);

    let prevIndex = 0;
    if (destination.index > 0) {
      prevIndex = newItems[destination.index - 1].sort_index ? newItems[destination.index - 1].sort_index : moment(newItems[destination.index - 1].created_at).unix();
    }
    let nextIndex = -1;
    if (destination.index < newItems.length - 1) {
      nextIndex = newItems[destination.index+1].sort_index ? newItems[destination.index+1].sort_index : moment(newItems[destination.index+1].created_at).unix();
    }

    let newIndex = 0;
    if (nextIndex === -1) {
      newIndex = prevIndex + 1;
    } else {
      newIndex = (prevIndex + nextIndex) / 2;
    }

    sortedItems[source.index].sort_index = newIndex;
    setSortedItems(_.sortBy(sortedItems, (i) => i.sort_index ? i.sort_index : moment(i.created_at).unix()));

    await updateItemSortIndex(item, newIndex);
    
    onItemRemoteChanged({
      type: ItemEventType.Rearrange,
      user: currentUser,
    });
  }

  const updateItemSortIndex = async (item: Item, sortIndex: number) => {
    const itemModified = _.assign({}, item, {
      sort_index: sortIndex,
    });
    await put(publicApiRoutes.boardItemSingle(board.id, item?.id), itemModified);
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card className={classNames('column-card-title', {
        [`${colorSchemeClassMapping[column.color_scheme]}`]: column.color_scheme in colorSchemeClassMapping,
      })}>
        {column.name}
      </Card>
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={column.id ? column.id : `${column.index}`}>
          {(provided:any, snapshot:any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="todo-col-droppable"
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div ref={itemListRef}>
                {sortedItems?.map((item:Item, index:number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided:any, snapshot:any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {(() => {
                          if (item.type === ItemType.Poll) {
                            const poll = polls?.find((p) => p.id === item.poll);
                            if (poll) {
                              return (
                                <PollCard
                                  viewOnly
                                  viewOnlyReason="in public mode"
                                  poll={poll}
                                  board={board}
                                  item={item}
                                  dragHandleProps={provided.dragHandleProps}
                                  onPollUpdate={() => {}} // no update poll in public mode
                                />
                              );
                            }
                            return null;
                          }

                          return (
                            <ItemCard
                              key={`item-${item.id}`}
                              dragHandleProps={provided.dragHandleProps}
                              board={board}
                              item={item}
                              todos={getTodosForItem(item.id)}
                              presentationMode={presentationMode}
                              onCardUpdate={onItemRemoteChanged}
                            />
                          );
                        })()}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Space>
  );
};

interface ItemCreateCardProps {
  board: Board;
  boardColumnID: string;
  onItemRemoteChanged: (event:ItemEvent) => void;
}
const ItemCreateCard: React.FC<ItemCreateCardProps> = ({board, boardColumnID, onItemRemoteChanged}) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const onModificationCompleted = (event:ItemEvent) => {
    setShowForm(false);
    onItemRemoteChanged(event);
  }

  return (
    <div>
      {showForm &&
        <Card className="column-card-form">
          <ItemForm
            board={board}
            boardColumnID={boardColumnID}
            onModificationCompleted={onModificationCompleted}
            onCancel={() => setShowForm(false)}
          />
        </Card>}
      {!showForm && <Button style={{ width: '100%' }} onClick={() => setShowForm(true)}>Add item</Button>}
    </div>
  );
};

interface KudoRowProps {
  kudos: Array<Kudo>;
  newKudoEvent?: KudoEvent;
}

const KudoRow: React.FC<KudoRowProps> = ({ kudos, newKudoEvent }) => {
  const { width } = useWindowDimensions();

  let colCount = 1;
  let span = 24;
  if (width >= 1600) {
    colCount = 4;
    span = 6;
  } else if (width >= 1200) {
    colCount = 3;
    span = 8;
  } else if (width >= 768) {
    colCount = 2;
    span = 12;
  }

  return (
    <>
      {newKudoEvent && <KudoNotificationModal kudo={newKudoEvent.kudo} />}
      {kudos?.length ? <Card className="board-kudo-container-card">
        <Collapse ghost>
          <Panel header={<div>Kudos <Badge count={kudos?.length ?? 0} /></div>} key="1">
            <Row gutter={[16, 16]}>
              {[...Array(colCount).keys()].map((colIndex:number) => {
                return (
                  <Col span={span} key={`kudo-col-${colIndex}`}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {kudos && _.map(kudos, (kudo:Kudo,i:number) => {
                        if (i%colCount === colIndex) {
                          return (
                            <KudoMiniCard viewOnly kudo={kudo} />
                          );
                        }
                      })}
                    </Space>
                  </Col>
                );
              })}
            </Row>
          </Panel>
        </Collapse>
      </Card> : null}
    </>
  );
}

const BoardShow: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const boardID = useRouterParams<any>().id;
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const location = useLocation();

  const [board, setBoard] = useState<Board>();
  const [items, setItems] = useState<Array<Item>>();
  const [todos, setTodos] = useState<Array<Todo>>();
  const [polls, setPolls] = useState<Array<Poll>>();
  const [kudos, setKudos] = useState<Array<Kudo>>();
  const [newKudoEvent, setNewKudoEvent] = useState<KudoEvent>();
  const [notFound, setNotFound] = useState<boolean>(false);

  const { width } = useWindowDimensions();

  const [boardSessionID] = useState<number>(Math.floor( Math.random()*1000000))
  const [socket, setSocket] = useState<WebSocket>();
  const [socketHealthy, setSocketHealthy] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const queryParamInviteId = new URLSearchParams(location.search).get("invite_id");

  const fetchBoard = async function(boardID:string) {
    try {
      const data = await get(publicApiRoutes.boardSingle(boardID));
      setBoard(data);
    } catch (error) {
      if (error?.response?.status === 404) {
        setNotFound(true);
      }
    }
  }
  const fetchItems = async function(boardID:string) {
    const data = await get(publicApiRoutes.boardItemsList(boardID));
    setItems(data);
  }
  
  const fetchTodos = async function(boardID:string) {
    const data = await get(publicApiRoutes.boardTodosList(boardID));
    setTodos(data);
  }

  const fetchPolls = async function(boardID:string) {
    const data = await get(publicApiRoutes.boardPollsList(boardID));
    setPolls(data);
  }

  const connectSocket = ():WebSocket => {
    const socketProtocol = window.location.protocol.replace('http', 'ws')
    const endpoint = `${socketProtocol}//${window.location.host}/ws/board/${boardID}/`;
    const _socket = new WebSocket(endpoint);
    _socket.onopen = () => {
      setSocketHealthy(true);
      _socket.onclose = socketOnClose(_socket, true);
      setSocket(_socket);
      fetchItems(boardID);
      fetchTodos(boardID);
      fetchPolls(boardID);
      fetchKudos(boardID);
    };
    _socket.onmessage = socketOnMessage;
    _socket.onclose = socketOnClose(_socket, false);
    return _socket;
  }

  const socketOnMessage = (e:MessageEvent<any>) => {
    const data = JSON.parse(e.data);

    if (data?.board_session_id === boardSessionID) {
      // this message is emit by this component itself, no need to do anything
      return;
    }
    if (data?.change === 'item') {
      fetchItems(boardID);
      if (data?.event) {
        notifyItemEvent(data.event);
      }
    } else if (data?.change === 'todo') {
      fetchTodos(boardID);
      if (data?.event) {
        notifyTodoEvent(data.event);
      }
    } else if (data?.change === 'poll') {
      fetchItems(boardID);
      fetchPolls(boardID);
      if (data?.event) {
        notifyPollEvent(data.event);
      }
    } else if (data?.change === 'kudo') {
      fetchKudos(boardID);
      if (data?.event) {
        notifyKudoEvent(data.event);
        if (data.event.type === KudoEventType.Add && data.event.user !== currentUser?.username) {
          setNewKudoEvent(data.event);
        }
      }
    }
  }

  const socketOnClose = (_socket:WebSocket, connected:boolean) => {
    return () => {
      if (_socket) {
        _socket.onopen = null;
        _socket.onclose = null;
        _socket.onmessage = null;
      }
      setSocket(undefined);
      if (!connected) {
        // only set not healthy when the socket is never opened (connection error, not on disconnect). GCP auto disconnect socket conn every 30s, so conn close after open success is expected
        // see https://cloud.google.com/load-balancing/docs/https#websocket_support
        setSocketHealthy(false);
      }
      console.error('Web socket closed unexpectedly');
      setTimeout(() => {
        connectSocket();
      }, SOCKET_CONNECT_RETRY_TIMEOUT_MS)
    };
  };

  useEffect(() => {
    if (boardID) {
      fetchBoard(boardID);
      fetchItems(boardID);
      fetchTodos(boardID);
      fetchPolls(boardID);
      fetchKudos(boardID);

      const _socket = connectSocket();
      return () => {
        _socket.onopen = null;
        _socket.onclose = null;
        _socket.onmessage = null;
        _socket.close();
      }
    }
  }, [boardID]);

  useEffect(() => {
    if (currentUser && board) {
      currentUser.team_membership.forEach((team:Team, index: number) => {
        if (team.id === board.team) {
          history.push(appRoutes.board(index, boardID));
        }
      });
    }
  }, [currentUser, board]);

  useEffect(() => {
    dispatch({
      liveEditingItems: {},
      liveEditingTodos: {},
      liveEditingComments: {},
    });

    return () => {
      dispatch({
        liveEditingItems: {},
        liveEditingTodos: {},
        liveEditingComments: {},
      });
    }
  }, []);

  useEffect(() => {
    let newPageSize = DEFAULT_PAGE_SIZE;
    if (width < XS_MAX_WIDTH) {
      newPageSize = XS_PAGE_SIZE;
    } else if (width < SM_MAX_WIDTH) {
      newPageSize = SM_PAGE_SIZE;
    } else if (width < MD_MAX_WIDTH) {
      newPageSize = MD_PAGE_SIZE;
    }

    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setCurrentPage(1);
    }
  }, [width]);

  const onItemRemoteChanged = (event: ItemEvent) => {
    fetchItems(boardID);
    socket?.send(JSON.stringify({
      'change': "item",
      'board_session_id': boardSessionID,
      'event': event,
    }));
  }

  const fetchKudos = async function(boardID:string) {
    const data = await get(`${publicApiRoutes.kudoList}?board=${boardID}`);
    setKudos(data);
  }

  const joinTeam = async function joinTeam(board:Board) {
    await post(apiRoutes.teamJoin(board.team), {
      invite_type: 'retroboard',
      invite_id: board.id,
    });
    refreshPage();
  }

  if (notFound) {
    return (
      <NotFound />
    );
  }

  if (!board) {
    return (
      <Spin />
    );
  }

  const loginOrSignUp = async () => {
    if (queryParamInviteId) {
      localStorage.setItem(localStorageKeys.loginRedirect,appRoutes.invite(queryParamInviteId));
    } else {
      localStorage.setItem(localStorageKeys.loginRedirect,  window.location.pathname);
    }
    window.location.href = '/accounts/login/';
  }

  const refreshPage = () => {
    window.location.reload();
  };


  // START RENDERING CALCULATION LOGIC, TO DETERMINE CAROUSEL PAGE RENDERING
  let totalCols = board.boardcolumn_set.length;
  let itemsByCols:{
    [boardcolumn_id:string]: Array<Item>,
  } = {};
  _.each(items, (item) => {
    if (!itemsByCols[item.board_column]) {
      itemsByCols[item.board_column] = []
    }
    itemsByCols[item.board_column].push(item);
  });

  const colSpan = 24/Math.min(pageSize, totalCols);
  const totalPages = Math.ceil(totalCols / pageSize);
  const paginatedBoardColumns:Array<Array<BoardColumn>> = [];
  for (let i = 0; i < totalPages; i++) {
    paginatedBoardColumns.push(board.boardcolumn_set.slice(i*pageSize, Math.min((i+1)*pageSize, totalCols)))
  }
  // END RENDERING CALCULATION LOGIC, TO DETERMINE CAROUSEL PAGE RENDERING

  return (
    <Fragment>
      {!socketHealthy &&
        <Row style={{ marginBottom: '15px' }}>
          <Col xs={24}>
            <Alert message="Lost connection to server, updates from other users will not be  reflected. Trying to reconect ..." type="warning" />
          </Col>
        </Row>}
      <Row style={{ marginBottom: '8px' }}>
        <Col span={24}>
          <Alert type="info" message={<div style={{ textAlign: 'center' }}>
            You are in public mode&nbsp;{currentUser ? <>because you are not a team member</> : <>because you are currently not logged in</>}, some features are not available.
            <br />
            {!currentUser && <><Button type="link" style={{ padding: 0, background: 'none' }} onClick={loginOrSignUp}>Login or sign up</Button> now to enjoy the full retrospective experience.</>}
            {currentUser && <><Button type="link" style={{ padding: 0, background: 'none' }} onClick={() => { joinTeam(board) }}>Join team</Button> now to enjoy the full retrospective experience.</>}
          </div>} />
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col xs={24}>
          <BoardDetails board={board} onUpdateCompleted={() => { fetchBoard(boardID) }} />
        </Col>
      </Row>
      <KudoRow kudos={kudos} newKudoEvent={newKudoEvent} />
      {totalPages > 1 &&
        <div className="teamkit-align-center" style={{ margin: '1.5em 0em 1em' }}>
          {_.map(Array(totalPages), (_, i) => (
              <Button
                key={`page-${i}`}
                className="board-pagination-button"
                type={i === currentPage-1 ? "primary" : "default"}
                style={{ marginRight: i < totalPages-1 ? '0.5em' : '0em' }}
                onClick={() => setCurrentPage(i+1)}
              >
                {i+1}
              </Button>
            ))}
        </div>}
        <Row gutter={[16, 16]}>
          {_.map(paginatedBoardColumns[currentPage-1], (boardColumn:BoardColumn) => {
            return (
              <Col key={`column-${boardColumn.id}`} xs={colSpan}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <ItemColumn
                    board={board}
                    column={boardColumn}
                    items={itemsByCols[boardColumn.id ?? '']}
                    todos={todos}
                    polls={polls}
                    currentUser={currentUser}
                    presentationMode={false}
                    onItemRemoteChanged={onItemRemoteChanged}
                  />
                  <ItemCreateCard
                    board={board}
                    boardColumnID={boardColumn.id ?? ''}
                    onItemRemoteChanged={onItemRemoteChanged}
                  />
                </Space>
              </Col>
            );
          })}
        </Row>
    </Fragment>
  );
}

export default withRouter(BoardShow);
