import React, { useState, useEffect } from 'react';
import { Switch, Route,withRouter, RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { Layout } from 'antd';
import classNames from 'classnames';
import { AppBasePage, BoardPage, TodoPage, ArchivedTodoPage, TeamPage, ProfilePage, ScrumPokerPage, GoalPage, InvitePage, CheckinPage, HomePage, KudoPage } from './components';
import { BoardPage as LiteBoardPage } from './components/modules/lite';
import AppSider from './AppSider';
import AppHeader from './AppHeader';
import { PromoBanner, NotFound } from './components/modules/common';
import { APP_BASE_PATH, localStorageKeys } from './components/const';
import 'antd/dist/reset.css';
import './App.less';

const { Content, Footer } = Layout;

const HEADER_HEIGHT = 64; // this is the height of team select
const taskPageRoute = `${APP_BASE_PATH}t/:team_index/tasks`;

const TeamKitFooter: React.FC = () => {
  return (
    <Footer style={{ textAlign: 'center' }}>©RetroPlus 2024</Footer>
  );
}

const App: React.FC<RouteComponentProps> = () => {
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)

  const updateWindowSize = () => {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    if (queryParams.has('login_success')) {
      const redirectURL = localStorage.getItem(localStorageKeys.loginRedirect);
      localStorage.removeItem(localStorageKeys.loginRedirect);
      queryParams.delete('login_success')
      history.replace({
        search: queryParams.toString(),
      });
      if (redirectURL) {
        window.location.pathname = redirectURL;
      }
    }
  });

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    }
  }, []);

  const isXScrollablePage = matchPath(window.location.pathname, {
    path: taskPageRoute,
    exact: true,
    strict: false
  }) ? true : false;

  return (
    <Layout>
      <Switch>
        <Route path={[`${APP_BASE_PATH}lite`]} component={LiteBoardPage}>
          <Layout className="site-layout">
            <AppHeader liteMode />
            <Content className={classNames('', {
              'site-content': !isXScrollablePage,
              'site-content-x-scrollable': isXScrollablePage,
            })} style={isXScrollablePage ? { maxHeight: `${windowHeight - HEADER_HEIGHT}px` } : {}}>
              <div className="container">
                <div className="site-content-inner">
                  <Switch>
                    <Route path={[`${APP_BASE_PATH}lite/boards/:id`]} component={LiteBoardPage} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </Content>
            {!isXScrollablePage && <TeamKitFooter />}
          </Layout>
        </Route>
        <Route path={`${APP_BASE_PATH}`}>
          <AppSider />
          <Layout className="site-layout">
            <AppHeader />
            <PromoBanner />
            <Content className={classNames('', {
              'site-content': !isXScrollablePage,
              'site-content-x-scrollable': isXScrollablePage,
            })} style={isXScrollablePage ? { maxHeight: `${windowHeight - HEADER_HEIGHT}px` } : {}}>
              <div className="container">
                <div className="site-content-inner">
                  <Switch>
                    <Route path={[`${APP_BASE_PATH}t/:team_index/teams`, `${APP_BASE_PATH}teams`]} component={TeamPage} />
                    <Route path={[`${APP_BASE_PATH}t/:team_index/profile`, `${APP_BASE_PATH}profile`]} component={ProfilePage} />
                    <Route path={[`${APP_BASE_PATH}t/:team_index/boards`, `${APP_BASE_PATH}boards/:id`]} component={BoardPage} />
                    <Route path={[`${APP_BASE_PATH}t/:team_index/scrum-poker-sessions`, `${APP_BASE_PATH}scrum-poker-sessions/:id`]} component={ScrumPokerPage} />
                    <Route path={[`${APP_BASE_PATH}t/:team_index/goals`, `${APP_BASE_PATH}goals/:id`]} component={GoalPage} />
                    <Route path={[`${APP_BASE_PATH}t/:team_index/invites`, `${APP_BASE_PATH}invites/:id`]} component={InvitePage} />
                    <Route path={[`${APP_BASE_PATH}t/:team_index/checkins`]} component={CheckinPage} />
                    <Route path={`${APP_BASE_PATH}t/:team_index/tasks/archived`} component={ArchivedTodoPage} />
                    <Route path={`${APP_BASE_PATH}t/:team_index/tasks`} component={TodoPage} />
                    <Route path={`${APP_BASE_PATH}t/:team_index/kudos`} component={KudoPage} />
                    <Route path={`${APP_BASE_PATH}t/:team_index/home`} component={HomePage} />
                    <Route path={`${APP_BASE_PATH}`} component={AppBasePage} />
                  </Switch>
                </div>
              </div>
            </Content>
            {!isXScrollablePage && <TeamKitFooter />}
          </Layout>
        </Route>
      </Switch>
    </Layout>
  );
}

export default withRouter(App);
