import React, { Fragment, useEffect, useState } from "react";
import './styles.less';
import { Row, Col, Spin, Button, Space } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { apiRoutes } from '../../../const';
import { get } from '../../../http';
import { getBasePathWithTeam } from '../../../helpers';
import { GoalGroup, GoalWithTarget, Goal, User } from '../../../models';
import { FirstGoalCard, GoalCard } from './';
import './styles.less';

interface GoalListProps extends RouteComponentProps<any> {
  currentUser: User;
  goalGroup: GoalGroup;
}

const GoalList: React.FC<GoalListProps> = ({ history, currentUser, goalGroup }) => {
  const [goals, setGoals] = useState<Array<Goal>>();
  const [goalWithTargets, setGoalWithTargets] = useState<Array<GoalWithTarget>>();

  useEffect(() => {
    if (goalGroup) {
      fetchGoals(goalGroup.id);
    }
  }, [goalGroup]);

  useEffect(() => {
    if (goals) {
      fetchTargets(goals);
    }
  }, [goals]);

  const canEdit = (): boolean => {
    return !goalGroup.archived && goalGroup.owner === currentUser.username;
  }

  const fetchGoals = async function(goalGroupID:string) {
    const data = await get(apiRoutes.goalList(goalGroupID));
    setGoals(data);
  };

  const fetchTargets = async function(goals:Array<Goal>) {
    if (!goals) {
      return;
    }

    let _goalWithTargets:Array<GoalWithTarget> = [];
    for (let goal of goals) {
      const data = await get(apiRoutes.goalTargetList(goal.id));
      _goalWithTargets.push({
        goal: goal,
        targets: data
      });
    }
    setGoalWithTargets(_goalWithTargets);
  };

  const onCreateGoalClick = () => {
    if (goalGroup) {
      history.push(`${getBasePathWithTeam()}/goals/${goalGroup.id}/create`);
    }
  };

  if (!goalGroup || !currentUser) {
    return (
      <Spin />
    );
  }

  return(
    <Fragment>
      {goalGroup.goal_count === 0 && <FirstGoalCard  onCreateClicked={onCreateGoalClick}/>}
      {goalGroup.goal_count > 0 && !goalWithTargets && <Spin />}
      {goalGroup.goal_count > 0 && goalWithTargets && 
        <div className="goal-group-goals-container">
          {canEdit() && <Row gutter={[16, 16]}>
            <Col span={24} className="goal-group-goals-list-action-row">
              <div className="teamkit-display-flex">
                <span className="teamkit-flex-full" />
                <Button type="primary" htmlType="submit" onClick={onCreateGoalClick}>
                  Create goal
                </Button>
              </div>
            </Col>
          </Row>}
          <Space direction="vertical" style={{ width: '100%' }}>
            {goalWithTargets.map((goalWithTarget, i) => {
              return (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <GoalCard
                      key={goalWithTarget.goal.id}
                      currentUser={currentUser}
                      goalWithTarget={goalWithTarget}
                      goalGroup={goalGroup}
                      onDeleted={() => { fetchGoals(goalGroup.id) } }
                    />
                  </Col>
                </Row>
              );
            })}
          </Space>
        </div>}
    </Fragment>
  );
}

export default withRouter(GoalList);
