import _ from 'lodash-es';
import React from "react";
import { Select, Space, Popover } from 'antd';
import { User, Assignable } from '../../models';
import { UserAvatar } from './';

const { Option } = Select;

interface AssignmentDropdownProps {
  assignable: Assignable;
  members?: Array<User>;
  hideUsername?:boolean;
  changeAssignee?: (value:string) => void;
}

const getFormattedAssignee = (assignable: Assignable):string => {
  return assignable.assignee ?? 'Unassigned';
}

const AssignmentDropdown: React.FC<AssignmentDropdownProps> = ({ assignable, members, hideUsername, changeAssignee }) => {
  const UNASSIGNED = 'Unassigned';
  let maxTextLength = UNASSIGNED.length
  _.each(members, (member:User) => {
    if (member.username.length > maxTextLength) {
      maxTextLength = member.username.length;
    }
  });

  const avatar = <UserAvatar username={assignable.assignee} />;

  // no popover if do not have change assignee callback
  if (!changeAssignee) {
    return (
      <span>
        {avatar}
        {!hideUsername && <span>&nbsp;&nbsp;{getFormattedAssignee(assignable)}</span>}
      </span>
    );
  }

  const popoverMinWidth = _.max([maxTextLength*10+30, 200])
  const popoverContent = (
    <Space direction="vertical">
      <div>
        <b>Assignee</b>
      </div>
      <div>
        <Select
            showSearch
            style={{ minWidth: `${popoverMinWidth}px` }}
            value={assignable.assignee ? assignable.assignee : ''}
            optionFilterProp="children"
            onChange={changeAssignee}
            loading={members ? false : true}
          >
            { members && <Option value="">Unassigned</Option> }
            { _.map(members, (member:User) => {
              return (
                <Option value={member.username}>{member.username}</Option>
              );
            })}
          </Select>
        </div>
    </Space>
  );
  return (
    <Popover
        content={popoverContent}
        trigger="click"
        placement="bottomLeft"
    >
        <span style={{ cursor: 'pointer' }}>
          {avatar}
          {!hideUsername && <span>&nbsp;&nbsp;{getFormattedAssignee(assignable)}</span>}
        </span>
    </Popover>
  );
}

export default AssignmentDropdown;
