import _ from 'lodash-es';
import React, { useEffect } from "react";
import moment from "moment";
import { Tooltip, Popconfirm, Form, Input, Button } from 'antd';
import { Comment } from '@ant-design/compatible';
import { UserAvatar } from '../common';
import { apiRoutes, textAreaLength } from '../../const';
import { del, put} from '../../http';
import { User, Comment as CommentModel, Todo, Item, Goal, CheckinSession } from '../../models';


interface CommentSingleProps {
  className?: string;
  currentUser?: User;
  comment: CommentModel;
  onDeleted: () => void;
  onUpdated: () => void;
  // context fields, camment can be from any of the thing below
  todo?: Todo;
  item?: Item;
  goal?: Goal;
  checkinSession?: CheckinSession;
  boardID?: string;
}

const CommentSingle: React.FC<CommentSingleProps> = ({ className, currentUser, comment, boardID, item, todo, goal, checkinSession, onDeleted, onUpdated }) => {
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      content: comment.content,
    });
  }, [form, comment]);

  const deleteComment = async () => {
    let deleteUrl = '';
    if (todo) {
      deleteUrl = apiRoutes.todoCommentSingle(todo.id, comment.id);
    } else if (item) {
      deleteUrl = apiRoutes.boardItemCommentSingle(boardID, item.id, comment.id);
    } else if (goal) {
      deleteUrl = apiRoutes.goalCommentSingle(goal.id, comment.id);
    } else if (checkinSession) {
      deleteUrl = apiRoutes.checkinSessionCommentSingle(checkinSession.id, comment.id);
    } else {
      throw new Error('CommentSingle: No object to comment on');
    }

    await del(deleteUrl);
    onDeleted();
  }

  const updateComment = async (values: any) => {
    let updateUrl = '';
    if (todo) {
      updateUrl = apiRoutes.todoCommentSingle(todo.id, comment.id);
    } else if (item) {
      updateUrl = apiRoutes.boardItemCommentSingle(boardID, item.id, comment.id);
    } else if (goal) {
      updateUrl = apiRoutes.goalCommentSingle(goal.id, comment.id);
    } else if (checkinSession) {
      updateUrl = apiRoutes.checkinSessionCommentSingle(checkinSession.id, comment.id);
    } else {
      throw new Error('CommentSingle: No object to comment on');
    }

    await put(updateUrl, values);
    onUpdated();
  }

  const commentEditSubmit = async (values:any) => {
    await updateComment(_.assign({}, comment, {
      content: values.content,
    }));
    setIsEdit(false);
  }

  let content: React.ReactNode = <p>{ comment.content }</p>;
  if (isEdit) {
    content = (
      <div>
        <Form
          form={form}
          name="comment-edit"
          initialValues={{ content: comment.content }}
          onFinish={commentEditSubmit}
        >
          <Form.Item name="content">
            <Input.TextArea  maxLength={textAreaLength} rows={4} />
          </Form.Item>
        </Form>
      </div>
    );
  }

  let actions:Array<React.ReactNode> = [];
  if (isEdit) {
    actions = [
      <Button type="primary" onClick={() => { form.submit() }}>Save</Button>,
      <span>&nbsp;</span>,
      <Button onClick={() => { setIsEdit(false) }}>Cancel</Button>,
    ];
  } else if (comment.owner === currentUser?.username) {
    actions = [
      <span className="comment-reply-link" onClick={() => { setIsEdit(true) }}>Edit</span>,
      <Popconfirm
        key="delete"
        placement="top"
        title={"Are you sure you want to delete?"}
        onConfirm={deleteComment}
        okText="Yes"
        cancelText="No"
      >
        <span className="comment-reply-link">Delete</span>
      </Popconfirm>,
    ];
  }

  return (
    <Comment
      className={className}
      actions={actions}
      avatar={<UserAvatar user={comment.owner_user} username={comment.owner} />}
      author={comment.owner}
      datetime={
        <Tooltip title={moment(comment.created_at).format('MMMM Do YYYY, h:mm:ss')}>
          <span>{moment(comment.created_at).fromNow()}</span>
        </Tooltip>
      }
      content={content}
    />
  )
} 

export default CommentSingle;