import _ from 'lodash-es';
import React from "react";
import './styles.less';
import { Tag } from 'antd';
import { TodoStatus, TodoStatusTitle } from '../../../models';

interface TodoStatusTagProps {
  status: TodoStatus;
}
const TodoStatusTag: React.FC<TodoStatusTagProps> = ({ status }) => {
  let color = 'grey';
  if (status === TodoStatus.InProgress) {
    color = 'blue';
  } else if (status === TodoStatus.Done) {
    color = 'green';
  } else if (status === TodoStatus.Canceled) {
    color = 'red';
  }
  return (
    <Tag className="status-tag" color={color}>{_.toUpper(TodoStatusTitle[status] ? TodoStatusTitle[status] : status)}</Tag>
  );
}

export default TodoStatusTag;
