import _ from 'lodash-es';
import React from "react";
import './styles.less';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Row, Col, Popconfirm, Space, Tooltip } from 'antd';
import { apiRoutes } from '../../const';
import { del } from '../../http';
import { getBasePathWithTeam } from '../../helpers';
import { Board, BoardColumn } from '../../models';

interface BoardCardProps extends RouteComponentProps<any> {
  board: Board;
  onRemoteChanged: () => void;
}

interface CardTitleProps {
  board: Board;
  history: any;
}

const CardTitle: React.FC<CardTitleProps> = ({ board, history }) => {
  const columnNames: Array<string> = _.map(board.boardcolumn_set, (boardcolumn: BoardColumn) => boardcolumn.name);

  return (
    <div onClick={() => { history.push(`${getBasePathWithTeam()}/boards/${board.id}`) }}>
      <div>{board.name}</div>
      <div  className="teamkit-small-listing-card-summary">{ columnNames.join(' - ') }</div>
    </div>
  );
}

const BoardCard: React.FC<BoardCardProps> = ({ board, onRemoteChanged, history }) => {
  const deleteBoard = async function(boardID:string) {
    await del(apiRoutes.boardSingle(boardID));
    onRemoteChanged();
  }

  const actions = [
    <Popconfirm
      key="delete"
      placement="top"
      title={"Are you sure you want to delete?"}
      onConfirm={()=>{deleteBoard(board.id)}}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip placement="bottom" title="Delete">
        <DeleteOutlined />
      </Tooltip>
    </Popconfirm>,
  ];

  const onCardClick = (e:any) => {
    // Special handler for card body click, to handle clicks that are in the padding
    const allowedClasses: Array<string> = [
      'ant-card-body',
      'ant-card-head',
      'ant-card-head-title'
    ]
    if (_.includes(allowedClasses,e.target.className)) {
      history.push(`${getBasePathWithTeam()}/boards/${board.id}`);
    }
  }

  return (
    <Card
      className="teamkit-small-listing-card"
      title={<CardTitle board={board} history={history} />}
      actions={actions}
      onClick={onCardClick}
    >
      <div onClick={() => { history.push(`${getBasePathWithTeam()}/boards/${board.id}`) }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row className="teamkit-small-listing-card-summary">
            <Col xs={8}>
              {board.user_count} users
            </Col>
            <Col xs={8}>
              {board.item_count} items
            </Col>
            <Col xs={8}>
              {board.todo_count} tasks
            </Col>
          </Row>
        </Space>
      </div>
    </Card>
  );
}

export default withRouter(BoardCard);
