import _ from 'lodash-es';
import React, { useContext, useState } from "react";
import './styles.less';
import { Space, Button, Input, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { apiRoutes, textAreaLength } from '../../../../const';
import { Todo, TodoEvent, TodoEventType } from '../../../../models';
import { AppContext } from '../../../../../store';
import { put } from '../../../../http';

interface TodoTitleDisplayProps {
  todo: Todo;
  onRemoteChanged: (event:TodoEvent) => void;
}
const TodoTitleDisplay:React.FC<TodoTitleDisplayProps> = ({ todo, onRemoteChanged }) => {
  const [form] = Form.useForm();
  const { state: { currentUser } } = useContext(AppContext);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onEditClicked = () => {
    setIsEditting(true);
  }
  
  if (!isEditting) {
    return (
      <div className="todo-display-title todo-editable-content" onClick={onEditClicked}>
        <h2>{ todo.content ? todo.content : <span className="todo-no-content-text">No title</span> }<EditOutlined /></h2>
      </div>
    );
  }

  const onFormSubmit = async (values:any) => {
    setIsSubmitting(true);
    try {
      await put(apiRoutes.todoSingle(todo.id), _.assign({}, todo, {
        content: values.content,
      }));
      form.resetFields();
      setIsEditting(false);
      onRemoteChanged({
        type: TodoEventType.Update,
        user: currentUser,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  const cancelEdit = () => {
    form.resetFields();
    setIsEditting(false);
  }

  return (
    <Form
      initialValues={{ content: todo.content }}
      form={form}
      name={`todo-content-form-${todo?.id}`}
      onFinish={onFormSubmit}
      style={{ marginBottom: '1em' }}
    >
      <Form.Item
        name="content"
        rules={[{ required: true, message: 'Please input the title!' }]}
      >
        <Input.TextArea placeholder="Title" maxLength={textAreaLength} rows={2} />
      </Form.Item>

      <div>
        <Space>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>Save</Button>
          <Button onClick={cancelEdit}>Cancel</Button>
        </Space>
      </div>
    </Form>
  )
}

export default TodoTitleDisplay;
