import _ from 'lodash-es';
import React, { useContext } from 'react';
import classNames from 'classnames';
import './AppHeader.less';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Layout, Menu, Dropdown, Space } from 'antd';
import { AppContext } from './store';
import { post } from './components/http';
import { APP_BASE_PATH, djangoPageRoutes, appRoutes } from './components/const';
import { TeamSelect } from './components/modules/teams';
import iconImg from './resources/images/logo192.png';
import { getBasePathWithTeam } from './components/helpers';
import { UserAvatar } from './components/modules/common';

const { Header } = Layout;

interface AppHeaderProps extends RouteComponentProps{
  liteMode?: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({ history, liteMode }) => {
  const { state: { currentUser, isUnAuthenticated } } = useContext(AppContext);
  const basePathWithTeam = getBasePathWithTeam();

  const logout = async () => {
    const data = await post(djangoPageRoutes.logout, {});
    if (data.location) {
      window.location.href = data.location;
    } else {
      window.location.href = djangoPageRoutes.login;
    }
  }

  const changePassword = async () => {
    window.location.href = djangoPageRoutes.changePassword;
  }

  const acountMenu = (
    <Menu>
      <Menu.Item onClick={() => { history.push(appRoutes.profile(basePathWithTeam)) }}>
        Profile
      </Menu.Item>
      <Menu.Item onClick={changePassword}>
        Change password
      </Menu.Item>
      <Menu.Item onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  
  // Non login mode
  if (liteMode && isUnAuthenticated) {
    return (
      <Header className="header app-header" style={{ padding: 0 }}>
        <img className="header-logo" src={iconImg} alt="main-icon" />
        <span className="header-lite-text">Public Mode</span>
      </Header>
    );
  }

  return (
    <Header className="header app-header" style={{ padding: 0 }}>
      {!liteMode && isUnAuthenticated && <img
        src={iconImg} 
        className="header-logo"
        alt="main-icon"
      />}
      <img
        src={iconImg} 
        className={classNames('header-logo', {
          'header-logo-app-mode': !liteMode
        })}
        alt="main-icon"
        onClick={() => { history.push(APP_BASE_PATH) }}
      />
      {liteMode && <span className="header-lite-text">Public Mode</span>}
      {currentUser &&
        <>
          <div className="header-main-bar">
            {!liteMode && <TeamSelect currentUser={currentUser} />}
          </div>
          <Dropdown overlay={acountMenu} className="profile-actions-dropdown" placement="bottomLeft">
            <Space>
              <UserAvatar username={currentUser?.username} />
              <span className="profile-user-name">{ currentUser.username }</span>
            </Space>
          </Dropdown>
        </>}
    </Header>
  );
};

export default withRouter(AppHeader);
