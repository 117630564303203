import _ from 'lodash-es';
import React, { useContext, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Goal, Todo, Item, CheckinSession } from '../../models';
import { getItemCacheKey } from '../../helpers';
import { apiRoutes, longTextLength } from '../../const';
import { post } from '../../http';
import { AppContext } from '../../../store';

const { TextArea } = Input;

interface CommentFormProps {
  todo?: Todo;
  item?: Item;
  goal?: Goal;
  checkinSession?: CheckinSession;
  boardID?: string;
  hideCancel?: boolean;
  onCancel: () => void;
  onCommentCreated: () => void;
}
const CommentForm: React.FC<CommentFormProps> = ({ boardID, goal, item, todo, checkinSession, hideCancel, onCancel, onCommentCreated }) => {
  const { state: { liveEditingComments }, dispatch } = useContext(AppContext);
  const [editDebounceTimer, setEditDebounceTimer] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const editCacheKey = item ? getItemCacheKey(item?.id) : null;
  const isLiveEditingSupported = item ? true : false;

  const onFormSubmit = async (values:any) => {
    setIsSubmitting(true);
    let postUrl = '';
    if (todo) {
      postUrl = apiRoutes.todoCommentList(todo.id);
    } else if (item) {
      postUrl = apiRoutes.boardItemCommentList(boardID, item.id);
    } else if (goal) {
      postUrl = apiRoutes.goalCommentList(goal.id);
    } else if (checkinSession) {
      postUrl = apiRoutes.checkinSessionCommentList(checkinSession.id);
    } else {
      throw new Error('CommentForm: No object to comment on');
    }

    try {
      await post(postUrl, values);
    } catch (error) {
      setIsSubmitting(false);
      throw(error);
    }
    setIsSubmitting(false);
    form.resetFields();
    if (isLiveEditingSupported) {
      delete liveEditingComments[editCacheKey];
      dispatch({ liveEditingComments });
    }
    onCommentCreated();
  }

  const debounce = (func: any, timeout = 1000) => {
    return (...args: any) => {
      if (editDebounceTimer) {
        clearTimeout(editDebounceTimer);
      };
      setEditDebounceTimer(setTimeout(() => { func.apply(this, args); }, timeout));
    };
  }

  const onContentChange = (e:any) => {
    if (!isLiveEditingSupported) {
      return;
    }
    debounce(() => {
      const newContent:string = e.target.value;
      dispatch({
        liveEditingComments: _.assign({}, liveEditingComments, {
          [editCacheKey]: newContent,
        })
      });
    })();
  }

  const onCancelClicked = () => {
    form.resetFields();
    delete liveEditingComments[editCacheKey];
    dispatch({ liveEditingComments });
    onCancel();
  }
  
  const initialVal = {
    content: liveEditingComments[editCacheKey]
  };
  return (
    <Form initialValues={initialVal} form={form} name={`comment-form-item-${item?.id}-todo-${todo?.id}`} onFinish={onFormSubmit}>
      <Form.Item
        name="content"
        rules={[{ required: true, message: 'Please input the content!' }]}
      >
        <TextArea rows={2} maxLength={longTextLength} onChange={onContentChange} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button htmlType="submit" loading={isSubmitting} onClick={() => {}} type="primary">
            Add
          </Button>
          {!hideCancel &&<Button htmlType="submit" onClick={onCancelClicked}>
            Cancel
          </Button>}
        </Space>
      </Form.Item>
    </Form>
  );
}

export default CommentForm;
