import React, { useState } from "react";
import { Button } from 'antd';
import KudoEditModal from './KudoEditModal';
import { Board, KudoEvent, User } from '../../models';

interface KudoCreateButtonProps {
  board?: Board;
  members: User[];
  asLink?: boolean;
  type?: "default" | "link" | "text" | "primary" | "dashed";
  onCompleted: (event:KudoEvent) => void;
}

const KudoCreateButton: React.FC<KudoCreateButtonProps> = ({ board, members, type, asLink, onCompleted }) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [formId, setFormId] = useState<number>(0);

  const createButton = asLink ? <a target="_blank" onClick={() => setIsCreating(true)}>Give Kudo</a> : <Button type={type ?? "default"} onClick={() => setIsCreating(true)}>Give Kudo</Button>;

  if (!isCreating) {
    return createButton;
  }

  const onKudoCompleted = (event: KudoEvent) => {
    setIsCreating(false);
    setFormId(formId + 1);
    onCompleted(event);
  };

  const onKudoCanceled = () => {
    setIsCreating(false);
    setFormId(formId + 1);
  };

  return (
    <>
      {createButton}
      <KudoEditModal
        key={formId}
        board={board}
        members={members}
        onCompleted={onKudoCompleted}
        onCanceled={onKudoCanceled}
      />
    </>
  );
}

export default KudoCreateButton;
