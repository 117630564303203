import _ from 'lodash-es';
import './UserProfile.less';
import React from "react";
import { Button, Form, Input, Select } from 'antd';
import { put } from '../../http';
import { apiRoutes } from '../../const';
import { Team, User } from '../../models';

const { Option } = Select;

interface UserDetailFormProps {
  user: User;
  onCanceled: () => void;
  onCompleted: () => void;
}

const UserDetailForm: React.FC<UserDetailFormProps> = ({ user, onCanceled, onCompleted }) => {
  const updateUser = async (values:any) => {
    const { username, default_team } = values;
    await put(apiRoutes.currentUser, { username, default_team });
    onCompleted();
  }

  let initialValues = {
    username: user.username,
    email: user.email,
    default_team: '',
  }
  // Populate default_team if user has a membership
  if (user.team_membership.length > 0) {
    if (user.user_config?.default_team) {
      for (let team of user.team_membership) {
        if (team.id === user.user_config?.default_team) {
          initialValues.default_team = team.id;
        }
      }
    }
    // if user.user_config?.default_team not in membership list (maybe user has quited), just use first value
    if (initialValues.default_team === '') {
      initialValues.default_team = user.team_membership[0].id;
    }
  }
  
  return (
    <Form
      name="user-profile-form"
      className="user-profile-form"
      initialValues={initialValues}
      onFinish={updateUser}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          { required: true, message: 'Please input the username!' },
          {
            pattern: new RegExp(/^[a-z0-9_]+$/),
            message: "invalid username"
           }
        ]}
      >
        <Input maxLength={150} />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input the email!' }]}
      >
        <Input disabled={true} maxLength={150} />
      </Form.Item>
      {user.team_membership.length > 0 && <Form.Item
        label="Default team"
        name="default_team"
        rules={[{ required: true, message: 'Please select the default team!' }]}
      >
        <Select>
          {_.map(user.team_membership, (team:Team) => {
            return (
              <Option value={team.id}>{team.name}</Option>
            );
          })}
        </Select>
      </Form.Item>}
      <Button type="primary" htmlType="submit" style={{ marginRight: '6px' }}>
        Update
      </Button>
      <Button onClick={onCanceled}>
        Cancel
      </Button>
    </Form>
  );
}

export default UserDetailForm;
