import React from "react";
import './styles.less';
import { Card, Avatar, Badge } from 'antd';
import { CheckOutlined, UserOutlined } from '@ant-design/icons';
import { UserAvatar } from '../common';


interface VotingMemberStatusProps {
  username?: string;
  isOnline?: boolean;
  isVoted?: boolean;
}
const VotingMemberStatus:React.FC<VotingMemberStatusProps> = ({username, isOnline, isVoted}) => {
  if (isVoted) {
    return (
      <Card className="voting-status-card voting-status-card-voted">
        <div style={{ display: 'flex' }}>
          <Avatar style={{ backgroundColor: '#87d068' }} icon={<CheckOutlined />} />
          <div className="voting-status-card-username">
            {username}
          </div>
        </div>
      </Card>
    );
  }

  if (isOnline) {
    return (
      <Card className="voting-status-card">
        <div style={{ display: 'flex' }}>
          <Badge dot>
            <UserAvatar username={username} />
          </Badge>
          <div className="voting-status-card-username">
            {username}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="voting-status-card voting-status-card-not-online">
      <div style={{ display: 'flex' }}>
        <UserAvatar username={username} />
        <div className="voting-status-card-username">
          {username}
        </div>
      </div>
    </Card>
  );
}
export default VotingMemberStatus;
