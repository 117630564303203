import _ from 'lodash-es';
import React, { useEffect } from "react";
import './Feature.less';
import { Layout, Typography, Row, Col, Image, Space } from 'antd';
import teamImg from '../../resources/images/tutorial-team-1-members.png';
import createGoalGroupImg from '../../resources/images/tutorial-goal-1-create-goal-group.png';
import createGoalImg from '../../resources/images/tutorial-goal-2-create-goal-details.png';
import createTargetImg from '../../resources/images/tutorial-goal-2-create-targets.png';
import recordProgresstImg from '../../resources/images/tutorial-goal-3-record-target-process-update.png';
import visualiseAndCommunicateImg from '../../resources/images/tutorial-goal-4-visualise-and-communicate.png';
import './styles.less';

const { Paragraph } = Typography;

const { Content } = Layout;

interface HowToContent {
  title: string;
  content?: string;
  images: Array<string>;
  imgClass?: string;
}
const FeatureGoal: React.FC<{}> = () => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = 'RetroPlus - How it works?';
    return () => {
      document.title = currentTitle;
    }
  }, []);

  const contents: Array<HowToContent> = [
    { title: '1. Setup your team', images: [teamImg] },
    { title: '2. Create goal group', content: 'Goal group is a way to groop related goals together, normally goals from the same department in the same quarter', images: [createGoalGroupImg] },
    { title: '3. Create multiple goals with measurable milestones', content: 'Goal is more about objective. Milestone is a breakdown of a goal into multiple measurable components', images: [createGoalImg, createTargetImg] },
    { title: '4. Record target progress update regularly', images: [recordProgresstImg]},
    { title: '5. Visualise and communicate to hit the goals and targets', images: [visualiseAndCommunicateImg] },
  ];

  return (
    <Content className="site-content">
      <div className="static-page-container" style={{ background: 'white' }}>
        <div className="site-content-inner">
          <Paragraph><h3>Goal Tracking</h3></Paragraph>
        </div>
        {_.map(contents, ((content: HowToContent) => (
          <div className="site-content-inner">
            <Row>
              <Col lg={6} md={24}>
                <h4>{content.title}</h4>
                {content.content && <p className="step-description-text">{content.content}</p>}
                <br/>
              </Col>
              <Col lg={18} md={24} className="howto-img-container">
                <Space direction="vertical" style={{ width: '100%' }}>
                  {_.map(content.images, (img: string) => {
                    return (
                        <div>
                            <Image
                            className={`landing-feature-img ${content.imgClass}`}
                            src={img}
                            alt="feature-img"
                            />
                        </div>
                    );
                    })}
                </Space>
              </Col>
            </Row>
          </div>
        )))}
      </div>
    </Content>
  )
}

export default FeatureGoal;
