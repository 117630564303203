import React, { useEffect, useState } from "react";
import { Card, Row, Col } from 'antd';
import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import BoardForm from './BoardForm';

interface BoardCreateProps {
  onCreateCompleted: (newBoardID:string) => void;
  expand: boolean;
}
const BoardCreate: React.FC<BoardCreateProps> = ({ expand, onCreateCompleted }) => {
  const [showForm, setShowForm] = useState<boolean>(expand);

  useEffect(() => {
    if (expand) {
      setShowForm(true);
    }
  }, [expand]);

  return (
    <Row gutter={[16, 16]}>
      {!showForm &&
        <Col className="teamkit-create-card-container" xl={8} md={12} xs={24}>
          <Card className="teamkit-small-listing-card teamkit-create-card" onClick={() => { setShowForm(true) }}>
            Create new board
          </Card>
          <a className="teamkit-create-card-help" href="https://retroplus.io/features/retrospective" target="_blank" ><QuestionCircleOutlined /></a>
        </Col>}
      {showForm &&
        <Col xxl={12} xs={24}>
          <BoardForm
            onCreateCancelled={() => { setShowForm(false) }}
            onCreateCompleted={(newBoardID:string) => {
              onCreateCompleted(newBoardID);
              setShowForm(false);
            }}
          />
        </Col>}
    </Row>
  );
}

export default BoardCreate;
