import React, { useState } from "react";
import { Row, Col, Card, Space, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import TeamForm from './TeamForm';
import { Team, User } from '../../models';
import { isTeamAdmin } from '../../helpers';
import './styles.less';

interface TeamDetailsProps {
  team: Team;
  currentUser: User;
  onUpdateCompleted: () => void;
}

const TeamDetails: React.FC<TeamDetailsProps> = ({ team, currentUser, onUpdateCompleted }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const isAdmin = isTeamAdmin(currentUser, team)

  if (!isAdmin) {
    return (
      <Card className="teamkit-show-top-detail-card-no-edit">
        <Space>
          <h2>{team.name}</h2>
        </Space>
        <p className="team-details-timezone">{team.timezone}</p>
      </Card>
    );
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        {!editMode &&
          <Card className="teamkit-show-top-detail-card" onClick={() => {setEditMode(true)}}>
            <Space>
              <h2>{team.name}</h2>
              <EditOutlined />
            </Space>
            <p className="team-details-timezone">{team.timezone}</p>
          </Card>}
        {editMode &&
          <TeamForm
            team={team}
            onCreateCancelled={() => { setEditMode(false) }}
            onCreateCompleted={() => {
              onUpdateCompleted();
              setEditMode(false);
            }}
          />}
      </Col>
    </Row>
  );
}

export default TeamDetails;
