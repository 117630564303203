import _ from 'lodash-es';
import React, { useEffect } from "react";
import './Feature.less';
import { Layout, Typography, Row, Col, Image, Space } from 'antd';
import teamImg from '../../resources/images/tutorial-team-1-members.png';
import createCheckin from '../../resources/images/tutorial-checkin-1-log-your-daily-checkin.png';
import visualiseCheckin from '../../resources/images/tutorial-checkin-2-visualise.png';
import commentCheckin from '../../resources/images/tutorial-checkin-3-comments.png';
import './styles.less';

const { Paragraph } = Typography;

const { Content } = Layout;

interface HowToContent {
  title: string;
  content?: string;
  images: Array<string>;
  imgClass?: string;
}
const FeatureCheckin: React.FC<{}> = () => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = 'RetroPlus - How it works?';
    return () => {
      document.title = currentTitle;
    }
  }, []);

  const contents: Array<HowToContent> = [
    { title: '1. Setup your team', images: [teamImg] },
    { title: '2. Log your daily check-in', content: 'Identify blockers that prevent you from delivering your work, make sure you assign it to an individual who can potentially clear out those obstacles for you.', images: [createCheckin] },
    { title: '3. Visualise your team progress and overal daily analysis', content: 'Proactively identify and mitigate redundant and overlapping tasks to optimize productivity. Foster a supportive team environment by providing assistance to resolve any obstacles.', images: [visualiseCheckin] },
    { title: '4. Communicate via comments', images: [commentCheckin]},
  ];

  return (
    <Content className="site-content">
      <div className="static-page-container" style={{ background: 'white' }}>
        <div className="site-content-inner">
          <Paragraph><h3>Daily Check-in</h3></Paragraph>
        </div>
        {_.map(contents, ((content: HowToContent) => (
          <div className="site-content-inner">
            <Row>
              <Col lg={6} md={24}>
                <h4>{content.title}</h4>
                {content.content && <p className="step-description-text">{content.content}</p>}
                <br/>
              </Col>
              <Col lg={18} md={24} className="howto-img-container">
                <Space direction="vertical" style={{ width: '100%' }}>
                  {_.map(content.images, (img: string) => {
                    return (
                        <div>
                            <Image
                            className={`landing-feature-img ${content.imgClass}`}
                            src={img}
                            alt="feature-img"
                            />
                        </div>
                    );
                    })}
                </Space>
              </Col>
            </Row>
          </div>
        )))}
      </div>
    </Content>
  )
}

export default FeatureCheckin;
