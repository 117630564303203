import _ from 'lodash-es';
import './styles.less';
import moment from 'moment';
import React, { useEffect, useContext, useState } from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { get } from '../../http';
import { apiRoutes, appRoutes } from '../../const';
import { AppContext } from '../../../store';
import { getCurrentTeam, getBasePathWithTeam } from '../../helpers';
import { Team, CheckinGroup, CheckinSession, User } from '../../models';
import { CheckinGroupSummary } from '../checkins';
import WidgetContainer from './WidgetContainer';

const CheckinWidget: React.FC<RouteComponentProps> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);
  const [checkinSessions, setCheckinSessions] = useState<Array<CheckinSession>>();
  const [myCheckinSession, setMyCheckinSession] = useState<CheckinSession|undefined>();
  const [members, setMembers] = useState<Array<User>>([]);
  const [latestCheckinGroup, setLatestCheckinGroup] = useState<CheckinGroup|undefined>();

  
  useEffect(() => {
    if (currentTeam) {
      fetchCheckinGroups(currentTeam);
      fetchTeamMember(currentTeam);
    }
  }, [currentTeam]);

  const fetchCheckinGroups = async (team: Team) => {
    const data: Array<CheckinGroup> = await get(apiRoutes.checkinGroupList, {
      team: team.id,
    });
    if (data.length > 0) {
      setLatestCheckinGroup(data[0]);
    }
  }

  useEffect(() => {
    if (currentUser && latestCheckinGroup) {
      fetchCheckinSessions(latestCheckinGroup);
    }
  }, [currentUser, latestCheckinGroup]);

  const fetchTeamMember = async function(team:Team) {
    const data = await get(apiRoutes.teamMemberList(team.id));
    setMembers(data);
  }

  const fetchCheckinSessions = async (checkinGroup: CheckinGroup) => {
    let data: Array<CheckinSession> = await get(apiRoutes.checkinSessionList(checkinGroup.id));
    data = _.sortBy(data, (checkinSession: CheckinSession) => {
      if (checkinSession.owner === currentUser?.username) {
        return -1;
      }
      return moment(checkinSession.created_at).unix();
    });

    setCheckinSessions(data);
    let myCheckinSession = _.find(data, (checkinSession: CheckinSession) => {
      return checkinSession.owner === currentUser?.username;
    });
    setMyCheckinSession(myCheckinSession);
  }

  return (
    <WidgetContainer
      className="checkin-widget"
      route={appRoutes.checkins}
      title={latestCheckinGroup ? `Daily Check-in for ${moment(latestCheckinGroup.start_time).tz(currentTeam.timezone).format('MMMM Do, YYYY')}` : 'Daily Check-in'}
    >
      {(!latestCheckinGroup || !checkinSessions || !members) ? 
      <Spin /> :
      <CheckinGroupSummary
        checkinGroup={latestCheckinGroup}
        checkinSessions={checkinSessions}
        members={members}
        hideTitle
      />}
      {latestCheckinGroup && checkinSessions && !myCheckinSession && <div style={{ textAlign: 'center' }} >
        You have not checked in today yet, <Button type="link" style={{ padding: 0 }} href={`${getBasePathWithTeam()}/checkins/${latestCheckinGroup.id}/`}>start check-in</Button> now
      </div>}
    </WidgetContainer>
  );
}

export default withRouter(CheckinWidget);
