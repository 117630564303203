import _ from 'lodash-es';
import './styles.less';
import React, { useContext, useState } from "react";
import uuid from 'react-uuid';
import { Spin, Card, Row, Button, notification, Col, Space } from 'antd';
import Avatar from "react-avatar-edit";
import { Buffer } from 'buffer';
import { get, put, post } from '../../http';
import { User } from '../../models';
import axios from 'axios';
import { apiRoutes } from '../../const';
import { getCurrentTeam } from '../../helpers';
import { AppContext } from '../../../store';
import RetrospectiveWidget from './RetrospectiveWidget';
import TeamWidget from './TeamWidget';
import GoalWidget from './GoalWidget';
import CheckinWidget from './CheckinWidget';


const Home: React.FC<{}> = () => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);

  if (!currentUser) {
    return <Spin />
  }

  const isAdmin = currentTeam && currentTeam.teamadmin_set.filter((admin) => {
    return admin.username === currentUser.username;
  }).length > 0;

  return (
    <div style={{ padding: 20 }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col span={24}>
            <h2>Welcome {currentUser.username}!</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card>
              <RetrospectiveWidget />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xl={12} xs={24}>
            <Card>
              <GoalWidget />
            </Card>
          </Col>
          <Col xl={12} xs={24}>
            <Card>
              <CheckinWidget />
            </Card>
            {isAdmin && <Card style={{ marginTop: 8 }}>
              <TeamWidget />
            </Card>}
          </Col>
        </Row>
      </Space>
    </div>
  );
}

export default Home;
