import _ from 'lodash-es';
import React, { useEffect, useState, useContext } from "react";
import { Button, Row, Spin, notification } from 'antd';
import { apiRoutes, djangoPageRoutes } from '../../const';
import { Plan } from '../../models';
import { get, put } from '../../http';
import { AppContext } from '../../../store';

interface UsageProps {
  content: React.ReactNode;
}

const Usage: React.FC<UsageProps> = ({ content }) => {
  return (
    <li className="flex items-center space-x-3">
      <svg
        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span>{content}</span>
    </li>
  );
}

const PlanListTailwinds: React.FC<{}> = () => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const [configuredPlans, setConfiguredPlans] = useState<Array<Plan>>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fetchCurrentUser = async function() {
    const data = await get(apiRoutes.currentUser);
    dispatch({
      currentUser: data,
    });
  }

  const fetchPlans = async () => {
    const data = await get(apiRoutes.planList);
    setConfiguredPlans(data);
  }

  const onSelectPlanClicked = async (selectedPlan?: Plan) => {
    if (!selectedPlan) {
      return
    }
    try {
      setIsSubmitting(true);
      await put(apiRoutes.currentUserPlan, {
        plan: selectedPlan.id,
      });
    } finally {
      setIsSubmitting(false);
    }
    notification['info']({
      message: '',
      description: `Successfully switched to ${selectedPlan.title} plan`,
    });
    fetchCurrentUser();
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  if (!configuredPlans) {
    return (
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Spin />
      </Row>
    );
  }

  let currentPlanIndex = -1;
  if (currentUser) {
    _.each(configuredPlans, (plan:Plan, index:number) => {
      if (plan.id === currentUser.current_plan) {
        currentPlanIndex = index;
      }
    })
  }

  let nextDiscountPlan:Plan|undefined = undefined;
  for (let i = currentPlanIndex+1; i < configuredPlans.length; i++) {
    if (configuredPlans[i].discounted_amount >= 0) {
      nextDiscountPlan = configuredPlans[i];
      break;
    }
  }

  let promoBannerCTA = undefined;
  if (nextDiscountPlan) {
    if (!currentUser) {
      promoBannerCTA = (
        <Button onClick={() => { window.location.href=djangoPageRoutes.signup }}>Sign up</Button>
      );
    } else {
      promoBannerCTA = (
        <Button onClick={() => { onSelectPlanClicked(nextDiscountPlan) }} loading={isSubmitting}>Upgrade</Button>
      );
    }
  }

  const colSpan = 24/configuredPlans.length;
  
  return (
    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
      {configuredPlans.map((plan:Plan, index:number) => {
        const onDiscount = plan.discounted_amount >= 0;
        return (
          <div className="flex flex-col p-6 max-w-lg mx-auto lg:mx-0 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <b className="mb-4 text-2xl font-semibold">{plan.title}</b>
            {!plan.reveal && 
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                Coming soon.
              </p>}
            {plan.reveal &&
              <>
                <div className="flex justify-center items-baseline my-8">
                  <span className="mr-2 text-5xl font-extrabold">${onDiscount ? plan.discounted_amount : plan.amount}</span>
                  {onDiscount && <span>&nbsp;<span className="pricing-tier-monetary-value-strikethrough">${plan.amount}</span></span>}
                  <span className="text-gray-500 dark:text-gray-400">/ member / month</span>
                </div>
                <ul className="mb-8 space-y-4 text-left">
                  <Usage content={<span>{plan.team_count >= 0 ? plan.team_count : <span>&infin;</span>} teams</span>} />
                  <Usage content={<span>{plan.member_count >= 0 ? plan.member_count : <span>&infin;</span>} members</span>} />
                  <Usage content={<span>{plan.board_count >= 0 ? plan.board_count : <span>&infin;</span>} retrospective boards</span>} />
                  <Usage content={<span>{plan.goalgroup_count >= 0 ? plan.goalgroup_count : <span>&infin;</span>} goal groups</span>} />
                  <Usage content={<span>{plan.checkingroup_count >= 0 ? plan.checkingroup_count : <span>&infin;</span>} daily check-in sessions</span>} />
                  <Usage content={<span>{plan.votingsession_count >= 0 ? plan.votingsession_count : <span>&infin;</span>} scrum poker sessions</span>} />
                  <Usage content={<span>{plan.todo_count >= 0 ? plan.todo_count : <span>&infin;</span>} active tasks</span>} />
                </ul>
              </>}
          </div>
        );
      })}
    </div>
  );
}

export default PlanListTailwinds;
