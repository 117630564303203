import _ from 'lodash-es';
import React, { useState, useContext } from 'react';
import './styles.less';
import { Card, Button, Dropdown, Menu, Tooltip, Space, Modal, Popconfirm, Input } from 'antd';
import { Comment } from '@ant-design/compatible';
import { EllipsisOutlined, LikeOutlined, DeleteOutlined, CommentOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { MdDragIndicator } from 'react-icons/md';
import { Item, Todo, Comment as CommentModel, ItemEvent, ItemEventType, TodoEvent, User, Board } from '../../../../models';
import { publicApiRoutes } from '../../../../const';
import { del } from '../../../../http';
import { getItemCacheKey } from '../../../../helpers';
import { AppContext } from '../../../../../store';
import { UserAvatar } from '../../../common';
import ItemForm from './ItemForm';
import TodoCard from './TodoCard';
import { CommentForm } from '../../comments';

const { confirm } = Modal;
const { TextArea } = Input;

interface ItemCardProps {
  board: Board;
  item: Item;
  todos: Array<Todo>;
  dragHandleProps?: any;
  presentationMode: boolean;
  onCardUpdate: (event:ItemEvent) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ board, item, todos, dragHandleProps, presentationMode, onCardUpdate }) => {
  const { state: { currentUser, liveEditingItems, liveEditingComments }, dispatch } = useContext(AppContext);
  let [showCommentEditor, setShowCommentEditor] = useState<boolean>(liveEditingComments[`item-${item.id}`] ? true : false);

  const editCacheKey = getItemCacheKey(item.id);
  let totalLikes = 0;
  _.each(item.itemreaction_set, (reaction) => {
    totalLikes += reaction.upvotes;
    if (reaction.owner === currentUser?.username && reaction.upvotes > 0) {
    }
  });

  let editingItem:Item|undefined;
  _.each(liveEditingItems, (i:Item) => {
    if (i.id === item.id) {
      editingItem = i;
    }
  });

  const setEditMode = (isEditting:boolean) => {
    if (isEditting === false) {
      delete liveEditingItems[editCacheKey];
      dispatch({ liveEditingItems });
    } else {
      dispatch({
        liveEditingItems: _.assign({}, liveEditingItems, {
          [editCacheKey]: item,
        }),
      });
    }
  }

  const onDeleteClicked = async (boardID:string, itemID:string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete?',
      onOk: async () => {
        await deleteItem(boardID, itemID);
      },
      onCancel: () => {},
    });
  }

  const deleteItem = async (boardID:string, itemID:string) => {
    await del(publicApiRoutes.boardItemSingle(boardID, itemID));
    onCardUpdate({
      type: ItemEventType.Delete,
      user: !item.anonymous ? currentUser : undefined
    });
  }

  const deleteComment = async (boardID:string, itemID:string, commentID:string) => {
    await del(publicApiRoutes.boardItemCommentSingle(boardID, itemID, commentID));
    onCardUpdate({
      type: ItemEventType.RemoveComment,
      user: currentUser
    });
  }

  const onEditClicked = () => {
    setEditMode(true);
  }

  const onUpdateCompleted = (event:ItemEvent) => {
    setEditMode(false);
    onCardUpdate(event);
  }

  const showItemDropdown = ():boolean => {
    if (presentationMode) {
      return false;
    }
    
    // lite mode, cannot edit if item has owner
    if (item.owner === 'public') {
      return true;
    }
    
    return false;
  }

  const menu = (
    <Menu>
      <Menu.Item key="item-edit" onClick={onEditClicked}>Edit</Menu.Item>
      <Menu.Item key="item-delete" onClick={()=>{onDeleteClicked(board.id, item.id)}}>Delete</Menu.Item>
    </Menu>
  );

  const actions = [];
  actions.push(
    <Tooltip title="Vote is disabled in lite mode">
      <span style={{ cursor: 'default' }}>
        <Space>
          <LikeOutlined />
          <span>{totalLikes}</span>
        </Space>
      </span>
    </Tooltip>,
  );
  if (presentationMode) {
    actions.push(
      <span>
        <Space>
          <span className="comment-reply-link"><CommentOutlined /></span>
          <span>{item.itemcomment_set.length}</span>
        </Space>
      </span>,
    );
  } else {
    actions.push(
      <Tooltip key="item-reply" title={'Reply'}>
        <span onClick={() => { setShowCommentEditor(true) }}>
          <Space>
            <span className="comment-reply-link"><CommentOutlined /></span>
            <span>{item.itemcomment_set.length}</span>
          </Space>
        </span>
      </Tooltip>,
    );
  }

  let extraProps = {};
  if (dragHandleProps) {
    extraProps = dragHandleProps;
  }
  
  return (
    <Card className="item-card">
      <span className="item-card-drag-span" {...extraProps}>
        <MdDragIndicator />
      </span>
      {editingItem && 
        <ItemForm
          board={board}
          item={editingItem}
          boardColumnID={item.board_column}
          onModificationCompleted={ onUpdateCompleted }
          onCancel={() => { setEditMode(false) }}
        />}
      {!editingItem &&
        <div>
          <Space direction="vertical" style={{ width: '100%' }}>
          <Comment
            className="teamkit-comment"
            actions={actions}
            author={item.anonymous || item.owner === 'public' ? 'Anonymous' : item.owner}
            avatar={<UserAvatar username={item.anonymous ? undefined : item.owner} />}
            content={
              <TextArea bordered={false} autoSize readOnly value={item.content} />
            }
          >
            {item.itemcomment_set.length > 0 && <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
              {_.map(item.itemcomment_set, (comment:CommentModel) => {
                return (
                  <Comment
                    className="item-comment"
                    avatar={<UserAvatar username={comment.owner} />}
                    content={
                      <div className="teamkit-display-flex">
                        <p className="teamkit-flex-full">{ comment.content }</p>
                        {!presentationMode && (!comment.owner || comment.owner === currentUser?.username) && 
                          <Popconfirm
                          key="delete"
                          placement="top"
                          title={"Are you sure you want to delete?"}
                          onConfirm={()=>{deleteComment(board.id, item.id, comment.id)}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip placement="bottom" title="Delete">
                            <span className="comment-reply-link"><DeleteOutlined /></span>
                          </Tooltip>
                        </Popconfirm>}
                      </div>
                    }
                  />
                );
              })}
            </Space>}
            {showCommentEditor && <Comment
              style={{ marginTop: '8px' }}
              className="item-comment-form"
              avatar={
                <UserAvatar username={currentUser?.username} />
              }
              content={
                <CommentForm
                  boardID={board.id}
                  item={item}
                  onCancel={() => { setShowCommentEditor(false) }}
                  onCommentCreated = { () => {
                    setShowCommentEditor(false);
                    onCardUpdate({
                      type: ItemEventType.AddComment,
                      user: currentUser,
                    });
                  }}
                />
              }
            />}
          </Comment>
          {_.map(todos, (todo:Todo) => {
            return (
              <TodoCard todo={todo} />
            );
          })}
          {showItemDropdown() && <Dropdown overlay={menu}>
            <Button size="small" className="item-card-menu-button">
              <EllipsisOutlined key="ellipsis" />
            </Button>
          </Dropdown>}
          </Space>
        </div>}
      
    </Card>
  );
}

export default ItemCard;

