import React  from 'react';
import './TeamSelect.less';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Dropdown, Menu, Space } from 'antd';
import { User } from '../../models';
import { getCurrentTeam, getCurrentPathWithNewTeamIndex } from '../../helpers';

interface TeamSelectProps extends RouteComponentProps<any> {
  currentUser: User;
}
const TeamSelect: React.FC<TeamSelectProps> = ({ currentUser, history }) => {
    if (currentUser && currentUser.team_membership && currentUser.team_membership.length === 0) {
        return null;
    }

    const team = getCurrentTeam(currentUser);
    const teamMenu = (
      <Menu>
        { currentUser && currentUser.team_membership.map((team, index) => {
          return (
            <Menu.Item
              key={`team-select-global-${team.id}`}
              onClick={() => {
                getCurrentPathWithNewTeamIndex(index);
                history.push(getCurrentPathWithNewTeamIndex(index));
              }}
            >
              { team.name }
            </Menu.Item>
          );
        })}
      </Menu>
    );

    let selectedTeam = "Select a team";
    if (team) {
        selectedTeam = team.name;
    }

    return (
        <Dropdown className="team-select" overlay={teamMenu} placement="bottomLeft">
            <Space>
              {selectedTeam}
            </Space>
        </Dropdown>
    )
}

export default withRouter(TeamSelect);
