import _ from 'lodash-es';
import React, { Fragment, useEffect, useState } from "react";
import { Spin, Breadcrumb, Button } from 'antd';
import { useParams as useRouterParams, withRouter, RouteComponentProps } from 'react-router-dom';
import { apiRoutes } from '../../const';
import { get, put } from '../../http';
import { getCurrentPathWithNewTeamIndex, getBasePathWithTeam } from '../../helpers';
import { GoalGroup, User } from '../../models';
import { GoalList } from './goal';
import GoalGroupDetails from './GoalGroupDetails';
import './styles.less';

interface GoalGroupShowProps extends RouteComponentProps<any> {
  currentUser: User;
}
const GoalGroupShow: React.FC<GoalGroupShowProps> = ({ history, currentUser }) => {
  const goalGroupID = useRouterParams<any>().id;
  const teamIndex = useRouterParams<any>().team_index;
  const [goalGroup, setGoalGroup] = useState<GoalGroup>();

  useEffect(() => {
    if (goalGroupID) {
      fetchGoalGroup(goalGroupID);
    }
  }, [goalGroupID]);

  const fetchGoalGroup = async function(goalGroupID:string) {
    const data = await get(apiRoutes.goalGroupSingle(goalGroupID));
    setGoalGroup(data);
  };

  if (!goalGroup || !currentUser) {
    return (
      <Spin />
    );
  }

  const unarchive = async () => {
    const archivedGoalGroup = _.assign({}, goalGroup, {
      archived: false,
    });
    await put(apiRoutes.goalGroupSingle(goalGroup.id), archivedGoalGroup);
    fetchGoalGroup(goalGroupID);
  }


  const intTeamIndex = parseInt(teamIndex);
  // Redirect if the tem index is not valid
  if (isNaN(intTeamIndex) || intTeamIndex < 0 || intTeamIndex >= currentUser.team_membership.length || currentUser.team_membership[intTeamIndex].id !== goalGroup.team) {
    let newTeamIndex = -1;
    currentUser.team_membership.forEach((team, i) => {
      if (team.id === goalGroup.team) {
        newTeamIndex = i;
      }
    });

    if (newTeamIndex >= 0) {
      history.push(getCurrentPathWithNewTeamIndex(newTeamIndex));
    } else {
      return (
        <div>
          Not found
        </div>
      );
    }
  }

  return (
    <Fragment>
      <Breadcrumb className="page-breadcrum">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a onClick={() => history.push(`${getBasePathWithTeam()}/goals`)}>Goal Group</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{goalGroup.name}</Breadcrumb.Item>
      </Breadcrumb>

      {goalGroup.archived && goalGroup.owner === currentUser.username &&
        <div className="teamkit-control-panel">
          <Button onClick={unarchive}>Unarchive</Button>
        </div>
      }

      <GoalGroupDetails
        goalGroup={goalGroup}
        currentUser={currentUser}
        onUpdateCompleted={() => { fetchGoalGroup(goalGroupID) }}
      />
      <GoalList
        currentUser={currentUser}
        goalGroup={goalGroup}
      />
    </Fragment>
  );
}

export default withRouter(GoalGroupShow);
