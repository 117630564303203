import React, { useEffect, useReducer } from 'react';
import './Site.less';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { 
  AppContext, 
  initialState, 
  reducer 
} from './store';
import { ConfigProvider } from 'antd';
import { apiRoutes } from './components/const';
import { get } from './components/http';
import App from './App';
import Main from './Main';

const Site: React.FC<any> = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetchCurrentUser = async function() {
    try {
      const data = await get(apiRoutes.currentUser);
      dispatch({
        currentUser: data,
      });
    } catch (error) {
      if (error?.response?.status === 403 && error?.response?.data.detail === 'Authentication credentials were not provided.') {
        dispatch({
          isUnAuthenticated: true,
        });
      }
    }
  }

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Varela, sans-serif',
            colorPrimary: '#9f1996ff',
            colorInfo: '#9f1996ff',
          },
        }}
      >
        <Router>
          <Switch>
            <Route path="/app" component={App} />
            <Route path="/" component={Main} />
          </Switch>
        </Router>
    </ConfigProvider>
    </AppContext.Provider>
  );
}

export default Site;
