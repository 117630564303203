
export interface UserConfig {
  default_team: string;
  profile_pic_url?: string;
}

export interface User {
  username: string;
  email: string;
  current_plan: string;
  team_membership: Array<Team>;
  user_config?: UserConfig;
}

export interface BoardColumn {
  id?: string;
  name: string;
  index: number;
  color_scheme: BoardColumnColorScheme;
}

export enum BoardColumnColorScheme {
  Neutral = 'NEUTRAL',
  Neutral1 = 'NEUTRAL_1',
  Positive = 'POSITIVE',
  Positive1 = 'POSITIVE_1',
  Negative = 'NEGATIVE',
  Negative1 = 'NEGATIVE_1'
}

export enum BoardPostMode {
  Anonymous = 'AN',
  Identified = 'ID',
  All = 'AL'
}

export interface Board {
  id: string;
  name: string;
  owner: string;
  team: string;
  public: boolean;
  post_mode: BoardPostMode;
  boardcolumn_set: Array<BoardColumn>;
  item_count: number;
  todo_count: number;
  user_count: number;
  created_at: string;
}

export enum BoardReportStatus {
  Pending = 'PENDING',
  HotTopic = 'HOT_TOPIC',
  Suggest = 'SUGGEST',
  Done = 'DONE',
}

export interface BoardReport {
  id: string;
  board: string;
  owner: string;
  status: BoardReportStatus;
  topics: Array<BoardReportTopic>;
}

export enum BoardTopicSummaryMood {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
}

export interface BoardReportTopic {
  topic_name: string;
  sentence_ids: Array<string>;
  summary: string;
  mood: BoardTopicSummaryMood;
  suggestions: string;
}

export interface ItemReaction {
  owner: string;
  upvotes: number;
}

export interface Comment {
  id: string;
  owner: string;
  owner_user?: UserProfile;
  content: string;
  created_at: string;
}

export interface GoalComment extends Comment {
  metadata: string;
}

export interface GoalCommentCreate {
  metadata: string;
  content: string;
}

export interface GoalCommentMetadata {
  v1: {
    old_target: Target;
    new_target: Target;
  };
}

export enum ItemType {
  Item = 'ITEM',
  Todo = 'TODO',
  Poll = 'POLL',
}

export interface Item {
  id: string;
  content: string;
  anonymous: boolean;
  board_column: string;
  type: ItemType;
  poll?: string;
  owner: string;
  itemreaction_set: Array<ItemReaction>;
  itemcomment_set: Array<Comment>;
  sort_index?: number;
  created_at: string;
}

export interface TodoLabel {
  id: number;
  label: string;
}

export interface Todo {
  id: string;
  type: TodoType;
  content: string;
  description: string;
  status: TodoStatus;
  board: string | null;
  item: string | null;
  owner: string;
  todocomment_set: Array<Comment>;
  todolabel_set: Array<TodoLabel>;
  assignee: string;
  archived: boolean;
  sort_score: number;
  created_at: string;
}

export interface Assignable {
  assignee?: string;
}

export enum TodoStatus {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
  Canceled = 'CANCELED'
}

export enum TodoType {
  Task = 'TASK',
  SubTask = 'SUBT',
  EPIC = 'EPIC',
  UserStory = 'US'
}

export const TodoStatusTitle = {
  [TodoStatus.Todo]: 'Not started',
  [TodoStatus.InProgress]: 'In progress',
  [TodoStatus.Done]: 'Done',
  [TodoStatus.Canceled]: 'Canceled',
}

export interface FutureMember {
  id: string;
  email: string;
}

export interface TeamAdmin {
  username: string;
  team: string;
}

export enum Role {
  Admin = 'admin',
  Member = 'member',
}

export interface Team {
  id: string;
  name: string;
  owner: string;
  timezone: string;
  members: Array<User>;
  teamfuturemember_set: Array<FutureMember>;
  teamadmin_set: TeamAdmin[];
}

export const UnlimitedAmount = -1;
export enum PlanAmountPerUnit {
  User = 'user',
  Account = 'account'
}
export enum PlanBillingCycle {
  Monthly = 'monthly',
}
export interface Plan {
  id: string;
  title: string;
  reveal: boolean;
  index: number;
  amount: number;
  discounted_amount: number;
  amount_unit: PlanAmountPerUnit;
  currency: string;
  billing_cycle: PlanBillingCycle;
  team_count: number;
  member_count: number;
  board_count: number;
  todo_count: number;
  votingsession_count: number;
  goalgroup_count: number;
  checkingroup_count: number;
}

export interface Template {
  title: string;
  board_columns: Array<BoardColumn>;
}

export enum ItemEventType {
  Add = 'add',
  Delete = 'delete',
  Update = 'update',
  AddComment = 'add_comment',
  RemoveComment = 'remove_comment',
  UpdateComment = 'update_comment',
  Like = 'like',
  Dislike = 'dislike',
  Rearrange = 'rearrange'
}
export interface ItemEvent {
  type: ItemEventType,
  user?: User;
}

export enum TodoEventType {
  Add = 'add',
  Delete = 'delete',
  Archive = 'archive',
  Restore = 'restore',
  Update = 'update',
  AddComment = 'add_comment',
  RemoveComment = 'remove_comment',
  MarkAsDone = 'mard_done',
  UpdateStatus = 'update_status',
  Assign = 'assign',
  Unassign = 'unassign',
}
export interface TodoEvent {
  type: TodoEventType,
  user?: User;
}

export enum PollEventType {
  Add = 'add',
  Respond = 'respond',
  Delete = 'delete',
  Update = 'update',
}
export interface PollEvent {
  type: PollEventType,
  user?: User;
}

export enum KudoEventType {
  Add = 'add',
  Delete = 'delete',
  Update = 'update',
}

export interface KudoEvent {
  type: KudoEventType,
  user?: User;
  receiver?: User;
  kudo?: Kudo;
}

export enum EstimationUnit {
  Point = 'P',
  Hour = 'H',
  Freestyle = 'F',
}

export interface EstimationUnitOption {
  displayName: string;
  value: EstimationUnit;
};

export const EstimationUnitOptions:Array<EstimationUnitOption> = [
  { displayName: 'Story Point', value: EstimationUnit.Point },
  { displayName: 'Time', value: EstimationUnit.Hour },
  { displayName: 'Freestyle', value: EstimationUnit.Freestyle },
];

export interface VotingSession {
  id: string;
  name: string;
  owner: string;
  team: string;
  estimation_unit: EstimationUnit;
  hours_per_day: number;
  days_per_week: number;
  votingitem_count: number;
  vote_count: number;
  user_count: number;
  freestyle_options: string;
  created_at: string;
}

export enum VotingItemStatus {
  Initial = 'I',
  Voting = 'V',
  Ended = 'E',
}

export interface VotingItem {
  id: string;
  owner: string;
  title: string;
  content: string;
  voting_session: string;
  status: VotingItemStatus;
  vote_set: Array<Vote>;
  created_at: string;
}

export interface Vote {
  id: string;
  owner: string;
  voting_item: string;
  score: number;
  created_at: string;
}

export interface ScoreCardOption {
  displayName: string;
  val: number;
};

export const StoryPointOptions: Array<ScoreCardOption> = [
  { displayName: '0', val: 0 },
  { displayName: '1/2', val: 0.5 },
  { displayName: '1', val: 1 },
  { displayName: '2', val: 2 },
  { displayName: '3', val: 3 },
  { displayName: '5', val: 5 },
  { displayName: '8', val: 8 },
  { displayName: '13', val: 13 },
  { displayName: '20', val: 20 },
  { displayName: '40', val: 40 },
  { displayName: '100', val: 100 },
  { displayName: '?', val: -2 },
];

export const GetHourOptions = (hoursPerDay: number, daysPerWeek: number): Array<ScoreCardOption> => {
  let cardOptions:Array<ScoreCardOption> = [
    { displayName: '30m', val: 0.5 },
    { displayName: '1h', val: 1 },
    { displayName: '2h', val: 2 },
    { displayName: '4h', val: 4 },
  ];
  for (let day = 1; day <= daysPerWeek; day++) {
    cardOptions.push({ displayName: day+'d', val: hoursPerDay*day });
  }
  for (let week = 1.5; week <= 3; week += 0.5) {
    cardOptions.push({ displayName: week+'w', val: week*daysPerWeek*hoursPerDay });
  }
  for (let week = 4; week <= 5; week++) {
    cardOptions.push({ displayName: week+'w', val: week*daysPerWeek*hoursPerDay });
  }
  cardOptions.push({ displayName: '?', val: -1 });
  
  return cardOptions;
}

export interface GoalGroup {
  id: string;
  owner: string;
  team: string;
  name: string;
  visibility: GoalGroupVisibility;
  goalgroupshare_set: Array<GoalGroupShare>;
  start_time: string | null | undefined;
  end_time:  string | null | undefined;
  goal_count: number;
  archived: boolean;
  created_at: string;
}

export interface GoalGroupShare {
  id: string;
  user: string;
}

export enum GoalGroupVisibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export enum GoalStatus {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
  Canceled = 'CANCELED',
}

export const GoalStatusTitle = {
  [GoalStatus.Todo]: 'Not started',
  [GoalStatus.InProgress]: 'In progress',
  [GoalStatus.Done]: 'Done',
  [GoalStatus.Canceled]: 'Canceled',
}

export interface Goal {
  id: string;
  owner: string;
  assignee?: string;
  goal_group: string;
  title: string;
  content: string;
  target_count: number;
  status: GoalStatus;
  start_time: string | null | undefined;
  end_time:  string | null | undefined;
}

export enum TargetStatus {
  OnTrack = 'ON_TRACK',
  AtRisk = 'AT_RISK',
  Done = 'DONE',
  Canceled = 'CANCELED',
}

export const TargetStatusTitle = {
  [TargetStatus.OnTrack]: 'On Track',
  [TargetStatus.AtRisk]: 'At Risk',
  [TargetStatus.Done]: 'Done',
  [TargetStatus.Canceled]: 'Canceled',
}

export const TargetStatusColor = {
  [TargetStatus.OnTrack]: 'blue',
  [TargetStatus.AtRisk]: 'red',
  [TargetStatus.Done]: 'green',
  [TargetStatus.Canceled]: 'gray',
};

export interface Target extends TargetCreate{
  id: string;
  owner: string;
  goal: string;
  status: TargetStatus;
  target_progress: TargetProgress;
  start_time: string | null | undefined;
  end_time:  string | null | undefined;
}

export interface TargetCreate {
  assignee?: string;
  title: string;
  content?: string;
  target_progress: TargetProgressCreate;
}

export enum TargetProgressType {
  Num = 'NUM',
  Percentage = 'PERCENT',
  Currency = 'CURRENCY',
  Bool = 'BOOL',
  Array = 'ARR',
}

export const isNumericProgressType = (type: TargetProgressType): boolean => {
  return type === TargetProgressType.Num || type === TargetProgressType.Percentage || type === TargetProgressType.Currency;
}

export const formatNumberProgress = (value: number): string => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const parseNumberProgress = (value: string): number => Number(value!.replace(/(,*)/g, ''));

export enum TargetProgressCompareDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface TargetProgress extends TargetProgressCreate {
  id: string;
}

export interface TargetProgressCreate {
  type: TargetProgressType;
  unit: string;
  compare_direction: TargetProgressCompareDirection;
  initial_value_float?: number;
  current_value_float?: number;
  target_value_float?: number;
  current_value_bool?: boolean;
  target_value_bool?: boolean;
  current_value_json?: string;
  target_value_json?: string;
}

export interface GoalWithTarget {
  goal: Goal;
  targets: Array<Target>;
};

export enum InviteType {
  Board = 'BOARD',
  Team = 'TEAM',
  ScrumPoker = 'SCRUM_POKER',
}

export interface Invite {
  id: string;
  owner: string;
  team: string;
  invite_type: InviteType;
  invite_id: string;
  display_name: string;
}

export interface CheckinGroup {
  id: string;
  team: string;
  start_time: string;
  end_time: string;
  checkin_session_count: number;
  created_at: string;
}

export interface UserProfile {
  username: string;
  profile_pic_url?: string;
}

export interface CheckinSession {
  id: string;
  owner: string;
  owner_user?: UserProfile;
  checkin_group: string;
  mood_score: number;
  checkinitem_set: Array<CheckinItem>;
  created_at: string;
}

export enum CheckinItemStatus {
  Todo = 'TODO',
  Done = 'DONE',
  Canceled = 'CANCELED',
}

export interface CheckinItem {
  id: string;
  owner: string;
  assignee?: string;
  type: string;
  content: string;
  status: CheckinItemStatus;
}

export interface CheckinItemCreate {
  assignee?: string;
  type: CheckinItemType;
  content: string;
  status: CheckinItemStatus;
}

export enum CheckinItemType {
  Past = 'PAST',
  Future = 'FUTURE',
  Blocker = 'BLOCKER',
}

export interface PollCreate {
  team: string,
  board_id?: string,
  board_column_id?: string,
  question: string,
  options: string,
  multiple_choices: boolean,
}

export enum PollStatus {
  InProgress = 'IN_PROGRESS',
  Ended = 'ENDED',
}
export interface Poll {
  id: string,
  owner: string,
  team: string,
  question: string,
  options: string,
  multiple_choices: boolean,
  status: PollStatus,
  pollresponse_set: Array<PollResponse>,
  created_at: string,
}

export interface PollResponse {
  id: string,
  owner: string,
  poll: string,
  answer: string,
  created_at: string,
}

export interface KudoCreate {
  receiver: string,
  team: string,
  board?: string,
  content: string,
}

export interface Kudo extends KudoCreate {
  id: string,
  owner: string,
  created_at: string,
}
