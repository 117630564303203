import _ from 'lodash-es';
import React, { useEffect, useContext, useState } from "react";
import { ArrowRightOutlined } from '@ant-design/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Button, Tag, Divider } from 'antd';
import { get } from '../../http';
import { apiRoutes, appRoutes } from '../../const';
import { AppContext } from '../../../store';
import { getCurrentTeam, getCurrentTeamIndex } from '../../helpers';
import { User, FutureMember, Team } from '../../models';
import { MemberForm } from '../teams/members';
import WidgetContainer from './WidgetContainer';

interface UserWithStatus {
  email: string;
  isActive: boolean;
}

const TeamWidget: React.FC<RouteComponentProps> = ({ history }) => {
  const { state: { currentUser }, dispatch } = useContext(AppContext);
  const [team, setTeam] = useState<Team|undefined>(undefined);
  const currentTeam = getCurrentTeam(currentUser);

  useEffect(() => {
    if (currentTeam) {
      fetchTeam(currentTeam.id);
    }
  }, [currentTeam]);

  const fetchCurrentUser = async function() {
    const data = await get(apiRoutes.currentUser);
    dispatch({
      currentUser: data,
    });
  }

  const fetchTeam = async function(teamID:string) {
    const data = await get(apiRoutes.teamSingle(teamID));
    setTeam(data);
  }

  let userEmails:UserWithStatus[] = [];
  if (team) {
    userEmails = _.map(team.members, (member:User):UserWithStatus => ({
      email: member.email,
      isActive: true,
    }));
  }
  if (team?.teamfuturemember_set && team?.teamfuturemember_set.length > 0) {
    team.teamfuturemember_set.forEach((futureMember:FutureMember) => {
      userEmails.push({
        email: futureMember.email,
        isActive: false,
      });
    })
  }

  const goToTeamPage = () => {
    history.push(`${appRoutes.teams(getCurrentTeamIndex())}${team?.id}`);
  }

  const toDisplay = _.take(userEmails, 5);
  const toClapse = _.drop(userEmails, 5);

  return (
    <WidgetContainer route={appRoutes.teams} title="Quick Team Invite">
      <MemberForm showButtonNextRow={true} team={currentTeam} onCreateComplete={fetchCurrentUser}/>
      <Divider plain>Current members</Divider>
      <div>
        {_.map(toDisplay, (user:UserWithStatus, index:number) => {
          return (
            <Card size="small" style={{ display: "inline-block", marginRight: 5, marginBottom: 5 }}>
              <div>
                {user.email}&nbsp;
                {user.isActive ? <Tag color="success">Active</Tag> : <Tag color="default">Pending</Tag>}
              </div>
            </Card>
          );
        })}
        {toClapse && toClapse.length > 0 && (
          <Card size="small" style={{ display: "inline-block", marginRight: 5, marginBottom: 5 }}>
            <Button type="link" onClick={goToTeamPage}>
            {toClapse.length} more <ArrowRightOutlined />
            </Button>
          </Card>
        )}
      </div>
    </WidgetContainer>
  );
}

export default withRouter(TeamWidget);
