import React from "react";
import teamFormBg from '../../../resources/images/team-create-bg.png';
import moment from 'moment-timezone';
import { Form, Input, Button, Space, Card, Select } from 'antd';
import { apiRoutes, shortTextLength, momentTimezones } from '../../const';
import { Team } from '../../models';
import { post, put } from '../../http';

interface TeamFormProps {
  team?: Team;
  onCreateCompleted: (id:string) => void,
  onCreateCancelled?: () => void;
}
const TeamForm: React.FC<TeamFormProps> = ({ team, onCreateCompleted, onCreateCancelled }) => {
  const isEditMode = () => {
    return team ? true : false;
  }

  const formSubmit = async (values: any) => {
    if (team) {
      await updateTeam(team.id, values);
      return;
    }
    await createTeam(values);
  }

  const createTeam = async (values: any) => {
    const data = await post(apiRoutes.teamList, values)
    onCreateCompleted(data.id);
  }

  const updateTeam = async (teamID:string, values: any) => {
    const data = await put(apiRoutes.teamSingle(teamID), values)
    onCreateCompleted(data.id);
  }

  return(
    <Card>
      <Form
        name={`team-form-${team?.id}`}
        initialValues={{
          name: team ? team.name : '',
          timezone: team ? team.timezone : moment.tz.guess(),
        }}
        onFinish={formSubmit}
      >
        {!isEditMode() &&
          <div className="create-board-bg-image-container">
            <a
              className="card-image-attribute-link"
              href="http://www.freepik.com"
              target="_blank"
              rel="noreferrer"
            >
              Image designed by pch.vector / Freepik
            </a>
            <img src={teamFormBg} className="create-board-bg-image" alt="Board create"/>
          </div>}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the team name!' }]}
        >
          <Input maxLength={shortTextLength} placeholder="Input your team name" />
        </Form.Item>
        <Form.Item
          label="Timezone"
          name="timezone"
          rules={[{ required: true, message: 'Please input the prefered timezone!' }]}
        >
          <Select showSearch>
            {momentTimezones.map((timezone) => {
              return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
            })}
          </Select>
        </Form.Item>

        <Form.Item >
          <Space>
            <Button type="primary" htmlType="submit">
              { isEditMode() ? 'Update team' : 'Create team'}
            </Button>
            {isEditMode() && <Button onClick={() => { if (onCreateCancelled) { onCreateCancelled() } }}>
              Cancel
            </Button>}
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default TeamForm;
