import _ from 'lodash-es';
import React, { useContext } from "react";
import uuid from 'react-uuid';
import { Input, Form, Select, Spin, Button, Space } from 'antd';
import { post, put } from '../../http';
import { apiRoutes } from '../../const';
import { AppContext } from '../../../store';
import { KudoCreate, Kudo, KudoEvent, KudoEventType, User } from '../../models';
import { getCurrentTeam } from '../../helpers';

const { TextArea } = Input;

interface KudoFormProps {
  boardID?: string;
  kudo?: Kudo;
  members: User[];
  onCompleted: (event: KudoEvent) => void;
  onCanceled: () => void;
}

const KudoForm: React.FC<KudoFormProps> = ({ boardID, kudo, members, onCompleted, onCanceled }) => {
  const { state: { currentUser } } = useContext(AppContext);
  const currentTeam = getCurrentTeam(currentUser);

  const [form] = Form.useForm();

  const otherMembers = _.filter(members, (member:User) => member.username !== currentUser.username);

  const onFinish = async (values: any) => {
    if (!kudo) {
      let payload: KudoCreate = {
        board: boardID,
        receiver: values.receiver,
        content: values.content,
        team: currentTeam.id,
      };
      await post(apiRoutes.kudoList, payload)
      onCompleted({
        type: KudoEventType.Add,
        user: currentUser,
        receiver: values.receiver,
        kudo: _.assign({}, payload, {
          id: uuid(),
          owner: currentUser.username,
          created_at: '',
        }),
      });
    } else {
      let payload = _.assign({}, kudo, values);
      await put(apiRoutes.kudoSingle(kudo.id), payload)
      onCompleted({
        type: KudoEventType.Update,
        user: currentUser,
      });
    }
  };

  const getFormInitialValue = (): any=> {
    if (!kudo) {
      return {}
    }

    return kudo;
  }

  if (!currentTeam) {
    return <Spin />
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={getFormInitialValue()}>
      <Form.Item
        label="Receiver"
        name="receiver"
        style={{ display: kudo ? 'none' : 'block' }}
        rules={[{ required: true, message: 'Please select a receiver!' }]}
      >
        <Select
          showSearch
          disabled={kudo ? true : false}
          placeholder="Select a receiver"
          optionFilterProp="children"
          options={otherMembers ? otherMembers.map((member:User) => ({
            value: member.username,
            label: member.username,
          })) : []}
        />
      </Form.Item>
      <Form.Item name="content">
        <TextArea
          showCount
          maxLength={10000}
          rows={4}
          bordered={false}
          placeholder="What did the teammate do well?"
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Space>
          <Button onClick={onCanceled}>Cancel</Button>
          <Button type="primary" onClick={form.submit}>{kudo ? 'Update' : 'Create'}</Button>
        </Space>
      </div>
    </Form>
  );
}

export default KudoForm;
