import React from "react";
import { Switch, Route } from "react-router-dom";
import { TeamCreate, TeamShow, TeamList } from './modules/teams';
import { APP_BASE_PATH } from './const';

const TeamPage: React.FC<any> = () => {
  return (
    <Switch>
      <Route path={[`${APP_BASE_PATH}teams/create`,`${APP_BASE_PATH}t/:team_index/teams/create`]}>
        <TeamCreate />
      </Route>
      <Route path={[`${APP_BASE_PATH}teams/:id`, `${APP_BASE_PATH}t/:team_index/teams/:id`]}>
        <TeamShow />
      </Route>
      <Route path={[`${APP_BASE_PATH}teams`, `${APP_BASE_PATH}t/:team_index/teams`]}>
        <TeamList />
      </Route>
    </Switch>
  )
};

export default TeamPage;
