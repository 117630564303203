import React from "react";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter
} from "react-router-dom";
import { Breadcrumb } from 'antd';
import { getBasePathWithTeam } from './helpers';

import { KudoList } from './modules/kudos';

const CheckinPage: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Route>
        <div>
          <Breadcrumb className="page-breadcrum">
            <Breadcrumb.Item><a onClick={() => { history.push(`${getBasePathWithTeam()}/home`) }}>Home</a></Breadcrumb.Item>
            <Breadcrumb.Item>Kudo</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <KudoList />
      </Route>
    </Switch>
  );
}

export default withRouter(CheckinPage);
