import React, { Fragment, useState, useEffect } from "react";
import { Spin, Breadcrumb } from 'antd';
import { User, GoalGroup } from '../../../models';
import { useParams as useRouterParams, withRouter, RouteComponentProps } from 'react-router-dom';
import { getBasePathWithTeam } from '../../../helpers';
import { get } from '../../../http';
import { apiRoutes } from '../../../const';
import GoalForm from './GoalForm';

interface GoalCreateProps extends RouteComponentProps<any> {
  currentUser: User;
}

const GoalCreate: React.FC<GoalCreateProps> = ({ history, currentUser }) => {
  const goalGroupID = useRouterParams<any>().id;
  const [goalGroup, setGoalGroup] = useState<GoalGroup>();

  const fetchGoalGroup = async function(goalGroupID:string) {
    const data = await get(apiRoutes.goalGroupSingle(goalGroupID));
    setGoalGroup(data);
  };

  useEffect(() => {
    if (goalGroupID) {
      fetchGoalGroup(goalGroupID);
    }
  }, [goalGroupID]);

  if (!currentUser || !goalGroup) {
    return <Spin />;
  }

  const onCreateCompleted = () => {
    history.push(`${getBasePathWithTeam()}/goals/${goalGroupID}`);
  };

  const onCreateCancelled = () => {
    history.push(`${getBasePathWithTeam()}/goals/${goalGroupID}`);
  };

  return (
    <Fragment>
      <Breadcrumb className="page-breadcrum">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a onClick={() => history.push(`${getBasePathWithTeam()}/goals`)}>Goal Group</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a onClick={() => history.push(`${getBasePathWithTeam()}/goals/${goalGroupID}`)}>{goalGroup.name}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Create Goal
        </Breadcrumb.Item>
      </Breadcrumb>

      <GoalForm
        goalGroupID={goalGroupID}
        onCreateCompleted={onCreateCompleted}
        onCreateCancelled={onCreateCancelled}
      />
    </Fragment>
  );
}

export default withRouter(GoalCreate);
